import { defineComponent as _defineComponent } from 'vue'
import * as xlsx from 'xlsx';
import Swal from 'sweetalert2';
import { IHeadersTable } from '@/types';
import { ref, watch, onMounted, computed } from 'vue';
import 'vue-good-table-next/dist/vue-good-table-next.css';
import { VueGoodTable } from 'vue-good-table-next';
import { state } from '../../store/modules/layout';
import { MoleculeFilters, MoleculePagination, MoleculeSheetBottom } from '@/components/molecules';
import {
  AtomBadge,
  exportIcon,
  AtomButton,
  AtomInput,
  AtomInputMoney,
  AtomSelect,
  IconChevronLeft,
  IconChevronRight,
  SearchIcon,
  LoaderIcon,
} from '../atoms';

interface IProps {
  headers: Array<IHeadersTable>;
  items: any[];
  filters?: boolean;
  enableOptions?: boolean;
  disableSelectInfo?: boolean;
  selectOnCheckboxOnly?: boolean;
  textEmpty?: string;
  selectedClass?: string;
  indexSelect?: number;
  perPage?: number;
  page?: number;
  totalItems?: number;
  update?: boolean;
  pagination?: boolean;
  loading?: boolean;
  itemSelect?: any;
  hasOptions?: boolean;
  isFinanceira?: boolean;
  widthSearch?: string;
  scroolyn?: boolean;
  searchFalse?: boolean;
  noclickrow?: boolean;
  textBtn?: string;
  searchMobile?: boolean;
  searchFilterNone?: boolean;
  // checkbox?: boolean;
  onlyActived: boolean;
  headersNotExport?: Array<IHeadersTable>;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeTable',
  props: {
    headers: { type: Array, required: true },
    items: { type: Array, required: true },
    filters: { type: Boolean, required: false, default: false },
    enableOptions: { type: Boolean, required: false },
    disableSelectInfo: { type: Boolean, required: false },
    selectOnCheckboxOnly: { type: Boolean, required: false },
    textEmpty: { type: String, required: false, default: 'Sem informações para exibição' },
    selectedClass: { type: String, required: false, default: '' },
    indexSelect: { type: Number, required: false },
    perPage: { type: Number, required: false, default: 10 },
    page: { type: Number, required: false, default: 1 },
    totalItems: { type: Number, required: false, default: 0 },
    update: { type: Boolean, required: false, default: false },
    pagination: { type: Boolean, required: false, default: true },
    loading: { type: Boolean, required: false },
    itemSelect: { type: null, required: false },
    hasOptions: { type: Boolean, required: false, default: true },
    isFinanceira: { type: Boolean, required: false },
    widthSearch: { type: String, required: false },
    scroolyn: { type: Boolean, required: false },
    searchFalse: { type: Boolean, required: false },
    noclickrow: { type: Boolean, required: false, default: false },
    textBtn: { type: String, required: false },
    searchMobile: { type: Boolean, required: false },
    searchFilterNone: { type: Boolean, required: false },
    onlyActived: { type: Boolean, required: true, default: true },
    headersNotExport: { type: Array, required: false }
  },
  emits: [
  'handleUpload',
  'rowClick',
  'changePerPage',
  'changePage',
  'updateRow',
  'searching',
  'handleSort',
  'blur',
  'itemClick',
  'itemClickDelete',
],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;

const headerMobile = computed(() => {
  const itemsHeader = props.headers?.filter((x) => x.field != 'actions');
  const startIndex = (currentPageHeader.value - 1) * itemsPerPageHeader.value;
  const endIndex = startIndex + itemsPerPageHeader.value;

  return itemsHeader.slice(startIndex, endIndex);
});

const totalColumns = computed(() => {
  return Math.floor(props.headers?.filter((x) => x.field != 'actions').length / 2);
});
const table = ref(null);
const lengthItems = computed(() => {
  return Math.ceil(props.totalItems / perPageTable.value);
});

const containerItems = ref<HTMLElement>();
const hasLastItem = ref(false);
const currentPageHeader = ref(1);
const itemsPerPageHeader = ref(2);

const up = ref<boolean>();
const transfer = ref<any>();
const search = ref<string>('');
const props = __props;

const updateValues = ref<any>({});
const pageTable = ref(1);
const perPageTable = ref(10);
// let badges = props.headers.filter((f) => f.type == 'badge');
const btnDisabled = ref(true);
function proxColum() {
  if (totalColumns.value > currentPageHeader.value) {
    currentPageHeader.value += 1;
    btnDisabled.value = false;
  }
}
function prevColum() {
  if (currentPageHeader.value > 1) {
    currentPageHeader.value -= 1;
    btnDisabled.value = false;
  } else {
    btnDisabled.value = true;
  }
}

const checkLastItemVisibility = () => {
  if (!containerItems.value) return;
  const lastItem = containerItems.value.querySelector('.color-row:last-child');
  if (lastItem) {
    const rect = lastItem.getBoundingClientRect();
    const containerRect = containerItems.value.getBoundingClientRect();

    if (rect.bottom <= containerRect.bottom) {
      hasLastItem.value = false;
    } else {
      hasLastItem.value = true;
    }
  }
};

const computedItems = computed(() => {
  return props.onlyActived ? props.items?.filter((x) => (x.archived ? x.archived == 0 : true)) : props.items;
});

onMounted(() => {
  pageTable.value = props.page;
  perPageTable.value = props.perPage;

  if (props.itemSelect) {
    props.headers
      .filter((f) => !!f.update)
      .map((x) => {
        updateValues.value[String(x.update)] = props.itemSelect[String(x.update)];
      });
  }

  setTimeout(() => {
    checkLastItemVisibility();
  }, 1000);
});
watch(
  () => props.headers,
  () => {
    checkLastItemVisibility();
  },
);
watch(
  () => pageTable.value,
  () => {
    emit('changePage', pageTable.value);
  },
);
watch(
  () => props.page,
  () => {
    pageTable.value = props.page;
  },
);
watch(
  () => perPageTable.value,
  () => {
    emit('changePerPage', perPageTable.value);
  },
);
watch(
  () => props.perPage,
  () => {
    perPageTable.value = props.perPage;
  },
);
watch(
  () => updateValues.value,
  () => {
    emit('updateRow', updateValues.value);
  },
  { deep: true },
);

watch(
  () => search.value,
  () => {
    emit('searching', search.value);
  },
);

function onSortChange(params) {
  emit('handleSort', {
    type: params[0].type,
    field: params[0].field,
  });
}

watch(
  () => props.itemSelect,
  () => {
    if (props.itemSelect) {
      updateValues.value = {};

      const data: any = {};

      props.headers
        .filter((f) => !!f.update)
        .map((x) => {
          data[String(x.update)] = props.itemSelect[String(x.update)];
        });
      updateValues.value = data;
    }
  },
);

function handleBlur(event) {
  emit('blur', event);
}

function rowStyleClassFn(row) {
  const clss = ` ${row.archived != undefined ? (row.archived == 1 ? 'inactive' : '') : ''} row-table  ${props.indexSelect && row.id == props.indexSelect ? `selected-row-sp ${props.selectedClass}` : ''} ${
    props.indexSelect && row.id == props.indexSelect && props.isFinanceira ? 'background-orange' : ''
  }`;

  return clss;
}

function validLine(row) {
  return props.indexSelect && row.id == props.indexSelect;
}
function handleUpload() {
  emit('handleUpload');
}
function onRowClick(params) {
  emit('rowClick', params);
}

const headerComputed = computed(() => {
  return props.headers.map((x) => ({ field: x.field, label: x.label }));
});

const exportToExcel = () => {
  const XLSX = xlsx;
  const workbook = XLSX.utils.book_new();

  // Mapear os itens para filtrar apenas os campos que estão presentes em headerComputed
  const worksheetData = props.items.map((item) => {
    const filteredItem = {};
    headerComputed.value
      .filter((x) => {
        return !(props.headersNotExport ?? []).find((y) => y.field === x.field) && x.field != 'actions';
      })
      .forEach((header) => {
        let value = item[header.field];
        // Verificar se o campo é "Status de Funcionamento" e traduzir
        if (header.field === 'offLine') {
          value = value ? 'On-line' : 'Off-line';
        }

        filteredItem[header.label] = value;
      });
    return filteredItem;
  });

  // Adiciona o cabeçalho com os labels
  const worksheet = XLSX.utils.json_to_sheet(worksheetData, {
    header: headerComputed.value
      .filter((x) => {
        return !(props.headersNotExport ?? []).find((y) => y.field === x.field) && x.field != 'actions';
      })
      .map((header) => header.label),
  });

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  XLSX.writeFile(workbook, 'relatorio.xlsx');

  Swal.fire({
    text: 'Arquivo XLSX exportado!',
    icon: 'success',
    title: 'Sucesso',
  });
};

const __returned__ = { emit, headerMobile, totalColumns, table, lengthItems, containerItems, hasLastItem, currentPageHeader, itemsPerPageHeader, up, transfer, search, props, updateValues, pageTable, perPageTable, btnDisabled, proxColum, prevColum, checkLastItemVisibility, computedItems, onSortChange, handleBlur, rowStyleClassFn, validLine, handleUpload, onRowClick, headerComputed, exportToExcel, get VueGoodTable() { return VueGoodTable }, get state() { return state }, get MoleculeFilters() { return MoleculeFilters }, get MoleculePagination() { return MoleculePagination }, get MoleculeSheetBottom() { return MoleculeSheetBottom }, get AtomBadge() { return AtomBadge }, get exportIcon() { return exportIcon }, get AtomButton() { return AtomButton }, get AtomInput() { return AtomInput }, get AtomInputMoney() { return AtomInputMoney }, get AtomSelect() { return AtomSelect }, get IconChevronLeft() { return IconChevronLeft }, get IconChevronRight() { return IconChevronRight }, get SearchIcon() { return SearchIcon }, get LoaderIcon() { return LoaderIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})