import { defineComponent as _defineComponent } from 'vue'


interface IProps {
  title: string;
  color?: string;
  classes?: string[];
  noMargin?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomTitle',
  props: {
    title: { type: String, required: true },
    color: { type: String, required: false, default: 'var(--color-title)' },
    classes: { type: Array, required: false },
    noMargin: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})