import { defineComponent as _defineComponent } from 'vue'


interface IProps {
  text: string;
  location?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomTooltip',
  props: {
    text: { type: String, required: true },
    location: { type: String, required: false, default: 'bottom' }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})