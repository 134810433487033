import { createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between" }
const _hoisted_2 = { class: "d-flex container-cards justify-space-around mt-4" }
const _hoisted_3 = { class: "d-flex justify-space-between" }
const _hoisted_4 = { class: "text-value" }
const _hoisted_5 = { class: "text-value" }
const _hoisted_6 = { class: "d-flex justify-space-between" }
const _hoisted_7 = { class: "text-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode($setup["AtomButton"], {
        background: "transparent",
        class: "pa-0",
        onClick: _withModifiers($setup.handlePrev, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode($setup["BtnChevronLeft"])
        ]),
        _: 1 /* STABLE */
      }),
      _createVNode($setup["AtomButton"], {
        background: "transparent",
        class: "pa-0",
        onClick: _withModifiers($setup.handleNext, ["prevent"])
      }, {
        default: _withCtx(() => [
          _createVNode($setup["BtnChevronRight"])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.items, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: "border rounded-lg card-step pa-4"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _cache[0] || (_cache[0] = _createElementVNode("p", { class: "text-xs text-muted" }, "latencia", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_4, _toDisplayString(item.latencia), 1 /* TEXT */)
            ]),
            _createElementVNode("div", null, [
              _cache[1] || (_cache[1] = _createElementVNode("p", { class: "text-xs text-muted" }, "Jitter", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_5, _toDisplayString(item.jittler), 1 /* TEXT */)
            ])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", null, [
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "text-xs text-muted" }, "Perda de pacote", -1 /* HOISTED */)),
              _createElementVNode("p", _hoisted_7, _toDisplayString(item.perda), 1 /* TEXT */)
            ])
          ])
        ]))
      }), 128 /* KEYED_FRAGMENT */))
    ])
  ], 64 /* STABLE_FRAGMENT */))
}