import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      opacity: "0.2",
      d: "M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M14.2981 8.64124L12 10.9393L9.70189 8.64124C9.41197 8.35133 8.93114 8.35133 8.64123 8.64124C8.35131 8.93116 8.35131 9.41199 8.64123 9.7019L10.9393 12L8.64123 14.2981C8.35131 14.588 8.35131 15.0688 8.64123 15.3588C8.93114 15.6487 9.41197 15.6487 9.70189 15.3588L12 13.0607L14.2981 15.3588C14.588 15.6487 15.0688 15.6487 15.3587 15.3588C15.6487 15.0688 15.6487 14.588 15.3587 14.2981L13.0606 12L15.3587 9.7019C15.6487 9.41199 15.6487 8.93116 15.3587 8.64124C15.0688 8.35133 14.588 8.35133 14.2981 8.64124Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}