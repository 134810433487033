import { defineComponent as _defineComponent } from 'vue'
import { Container, Draggable } from 'vue3-smooth-dnd';
import { AtomButton, DeleteIcon, EditSquareIcon, AtomInput, AtomSelect } from '@/components/atoms';
import { AtomCheckBox, ColsIcon } from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { IHeadersTable, IOptionsSelect } from '@/types';

import { getUnidadeAll, postUnidade, putUnidade } from '@/core/services/UnitsService';
import { ref, onMounted, computed, watch } from 'vue';
import { Preloader } from '@/store';
import Swal from 'sweetalert2';
import { Unidade } from '@/core/models';
import { getCompanhiaAll } from '@/core/services/CompanhiasService';
import { findUsers } from '@/core/services/AuthService';
interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'UnitsPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const listItems = ref<any>([
  { placeholder: 'ID', value: '' },
  { placeholder: 'Nome', value: '' },
  { placeholder: 'Logradouro', value: '' },
  { placeholder: 'Cidade', value: '' },
]);
const sortable = ref<any>();
const steps = ref([
  { label: 'Endereço', completed: false },
  { label: 'Outras informações', completed: false },
]);
const currentStep = ref(steps.value[0].label);

const data = ref<Unidade>({});
const perPage = ref<number>(10);
const page = ref<number>(1);
const term = ref<string>('');
const addNew = ref<boolean>(false);
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}
const totalItems = ref<number>(0);
const showModalUpload = ref<boolean>(false);
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Nome', field: 'nome' },
  { label: 'Logradouro', field: 'logradouro' },
  { label: 'Número', field: 'numero' },
  { label: 'Bairro', field: 'neighborhood' },
  { label: 'Cidade', field: 'cidade' },
  { label: 'Estado', field: 'uf' },
  { label: 'Cep', field: 'cep' },
  { label: 'Empresa', field: 'companhiaNome' },
  { label: 'Latitude', field: 'latitude' },
  { label: 'Longitude', field: 'longitude' },
  { label: 'Opções', field: 'actions' },
]);
const items = ref<Unidade[]>([]);

const companhias = ref<IOptionsSelect[]>([]);

const Country = computed(() => {
  const lista = ['China', 'Brasil', 'Estados Unidos'].map((x) => {
    return { text: x, value: x } as IOptionsSelect;
  });
  return lista;
});
const usuarios = ref<IOptionsSelect[]>([]);

const ufs = ref<IOptionsSelect[]>([
  { text: 'AC', value: 'AC' },
  { text: 'AL', value: 'AL' },
  { text: 'AP', value: 'AP' },
  { text: 'AM', value: 'AM' },
  { text: 'BA', value: 'BA' },
  { text: 'CE', value: 'CE' },
  { text: 'DF', value: 'DF' },
  { text: 'ES', value: 'ES' },
  { text: 'GO', value: 'GO' },
  { text: 'MA', value: 'MA' },
  { text: 'MT', value: 'MT' },
  { text: 'MS', value: 'MS' },
  { text: 'MG', value: 'MG' },
  { text: 'PA', value: 'PA' },
  { text: 'PB', value: 'PB' },
  { text: 'PR', value: 'PR' },
  { text: 'PE', value: 'PE' },
  { text: 'PI', value: 'PI' },
  { text: 'RJ', value: 'RJ' },
  { text: 'RN', value: 'RN' },
  { text: 'RS', value: 'RS' },
  { text: 'RO', value: 'RO' },
  { text: 'RR', value: 'RR' },
  { text: 'SC', value: 'SC' },
  { text: 'SP', value: 'SP' },
  { text: 'SE', value: 'SE' },
  { text: 'TO', value: 'TO' },
]);
const showModalColunas = ref<boolean>(false);

const textSaveModal = computed(() => {
  const isLast = steps.value[steps.value.length - 1].label === currentStep.value;
  return isLast ? 'Salvar' : 'Avançar';
});
const textSaveMobile = computed(() => {
  const isLast = steps.value[steps.value.length - 1].label === currentStep.value;
  return type.value == 'C' && isLast ? 'Concluir cadastro' : type.value != 'C' && isLast ? 'Concluir edição' : 'Avançar';
});
const textCancelModal = computed(() => {
  const isLast = steps.value[0].label === currentStep.value;
  return isLast ? 'Cancelar' : 'Voltar';
});

const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = payload;

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function onDrop(dropResult) {
  listItems.value = applyDrag(listItems.value, dropResult);
}
function getGhostParent() {
  return document.body;
}

function handleEdit(payload: Unidade, isApp: boolean = false) {
  data.value = payload;
  currentStep.value = 'Endereço';

  type.value = 'E';

  if (isApp) {
    addNew.value = true;
  } else {
    showModalUpload.value = true;
  }
}

function clear() {
  data.value = {};
  data.value.id = 0;
  currentStep.value = 'Endereço';
}

function loadEmpresa() {
  getCompanhiaAll(0, 99999999).then((r) => {
    companhias.value = r.lista.map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
  });
}
// function handleAdd(payload) {
//   console.log(payload, 'Add');
// }
function handleDelete(payload: Unidade) {
  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente arquivar a unidade <b>${payload.nome}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);

      payload.archived = 1;
      putUnidade(payload)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: `Unidade ${payload.nome} foi arquivada com sucesso!`,
            });
          }
          loadData();
        })

        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}
function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalItems.value = 0;
  let promisse;

  if (sortable.value) {
    promisse = getUnidadeAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = getUnidadeAll(page.value - 1, perPage.value, term.value);
  }
  promisse
    .then((resp) => {
      items.value = resp.lista;
      totalItems.value = resp.totalItems - items.value.filter((f) => f.archived == 1).length;
    })

    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function handleNew() {
  const idx = steps.value.findIndex((x) => x.label === currentStep.value);

  if (idx !== -1 && steps.value[idx + 1]) {
    steps.value[idx].completed = true;
    currentStep.value = steps.value[idx + 1].label;
  }

  if (steps.value.length - 1 === idx) {
    handleAddOrUpdate();
  }
}

function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);

  data.value.cep = Number(data.value.cep?.toString().replace('.', '').replace('-', ''));
  if (data.value?.id == 0) {
    data.value.archived = 0;
    data.value.id = undefined;
    postUnidade(data.value)
      .then(() => {
        // Swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Unidade ${data.value?.nome} foi criado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    putUnidade(data.value as Unidade)
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Unidade ${data.value?.nome} foi atualizado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}
function handleCancelNew() {
  const idx = steps.value.findIndex((x) => x.label === currentStep.value);

  if (idx !== -1 && steps.value[idx - 1]) {
    steps.value[idx - 1].completed = false;
    currentStep.value = steps.value[idx - 1].label;
  } else if (!addNew.value) {
    showModalUpload.value = false;
  } else if (addNew.value) {
    addNew.value = false
    type.value = ''
    data.value = {}
  }
}
function handleCancelColunas() {
  showModalColunas.value = false;
}

function searching(event: string | undefined) {
  term.value = String(event);
}
function buscaCep() {
  const cep = data.value.cep?.toString().replace('.', '').replace(/-/g, '').replace(/\//g, '');
  if (cep?.length !== 8) {
    Swal.fire({
      title: 'Atenção',
      icon: 'warning',
      text: 'confira o cep digitado!',
    });
    return;
  }

  if (cep.length === 8) {
    fetch(`https://viacep.com.br/ws/${cep}/json/`, {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
    }).then((r) => {
      r.json().then((dd) => {
        if (!dd.erro) {
          const keys = Object.keys(dd);
          for (const k of keys) {
            data.value[k] = dd[k];
            if (k === 'localidade') {
              data.value.cidade = dd[k];
            }
            if (k === 'bairro') {
              data.value.neighborhood = dd[k];
            }
          }

          if (data.value.numero) changeNumber();
        }
      });
    });
  }
}

async function loadUser() {
  const dados = await findUsers();
  usuarios.value = dados.map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
}
watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'units') {
      loadEmpresa();
      loadUser();
      loadData();
    }
  },
);

watch(
  () => page.value,
  () => {
    loadData();
  },
);

watch(
  () => perPage.value,
  () => {
    loadData();
  },
);

watch(
  () => term.value,
  () => {
    loadData();
  },
);

function changeNumber() {
  if (data.value.logradouro && data.value.numero && data.value.neighborhood && data.value.cidade)
    fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${data.value.logradouro},${data.value.numero} - ${data.value.neighborhood},${data.value.cidade}`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
      },
    ).then((r) => {
      r.json().then((dd) => {
        if (dd.length) {
          data.value.latitude = dd[0].lat;
          data.value.longitude = dd[0].lon;
        }
      });
    });
}
onMounted(() => {
  loadEmpresa();
  loadUser();
  loadData();
});

const __returned__ = { props, listItems, sortable, steps, currentStep, data, perPage, page, term, addNew, type, replacement, totalItems, showModalUpload, headers, items, companhias, Country, usuarios, ufs, showModalColunas, textSaveModal, textSaveMobile, textCancelModal, applyDrag, sort, onDrop, getGhostParent, handleEdit, clear, loadEmpresa, handleDelete, loadData, handleNew, handleAddOrUpdate, handleCancelNew, handleCancelColunas, searching, buscaCep, loadUser, changeNumber, get Container() { return Container }, get Draggable() { return Draggable }, get AtomButton() { return AtomButton }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get AtomCheckBox() { return AtomCheckBox }, get ColsIcon() { return ColsIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})