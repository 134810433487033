import { defineComponent as _defineComponent } from 'vue'
import { ref, watch} from 'vue';
import { MoleculeTabItem, MoleculeTabs, MoleculeTabWindow } from '@/components/molecules';
// import AgentPage from './pages/agent/AgentPage.vue';
import { state } from '../../store/modules/layout';
import JumpCollectorAnaliysisPage from './pages/jumpCollectorAnalysis/JumpCollectorAnaliysisPage.vue';
import QuadrantPage from './pages/quadrant/QuadrantPage.vue';
import CollectorInstalledPage from './pages/collectorInstalled/CollectorInstalledPage.vue';
import { ITab } from '@/types';
import { useRoute } from 'vue-router';
import { onMounted } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AgentsPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const tabs = ref<ITab[]>([
  // {
  //   label: 'Agente',
  //   value: 'agent',
  // },
  {
    label: 'Análise Coletor Saltos',
    value: 'jump_collector',
  },
  {
    label: 'Quadrante',
    value: 'quadrant',
  },
  // {
  //   label: 'Coletores em Execução',
  //   value: 'collectorInstalled',
  // },
]);
const tab = ref<string>(tabs.value[0].value);

watch(
  () => route.query,
  () => {
    tab.value = String(route.query.page);
  }
);

onMounted(() => {
  const { page } = route.query
  if (page)
    tab.value = String(route.query.page)
})

const __returned__ = { route, tabs, tab, get MoleculeTabItem() { return MoleculeTabItem }, get MoleculeTabs() { return MoleculeTabs }, get MoleculeTabWindow() { return MoleculeTabWindow }, get state() { return state }, JumpCollectorAnaliysisPage, QuadrantPage, CollectorInstalledPage }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})