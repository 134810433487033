import { createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toHandlers as _toHandlers, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "input-wapper" }
const _hoisted_2 = { class: "ps-2 d-flex align-center" }
const _hoisted_3 = { class: "flex-1 d-flex justify-center" }
const _hoisted_4 = {
  key: 0,
  class: "range"
}
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AtomButton = _resolveComponent("AtomButton")!

  return (_openBlock(), _createBlock($setup["DatePicker"], {
    "is-range": "",
    modelValue: $setup.range,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.range) = $event)),
    columns: 2
  }, {
    default: _withCtx(({ inputEvents }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _mergeProps(_toHandlers(inputEvents.start, true), {
          class: ["d-flex align-center h-100", [$setup.range.start && $setup.range.end ? 'justify-space-between' : '']]
        }), [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["CalendarIcon"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            ($setup.range.start && $setup.range.end)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
                  _createElementVNode("p", null, "Inicio: " + _toDisplayString($setup.format(new Date($props.dateRange.start), 'dd/MM/yyyy')), 1 /* TEXT */),
                  _createElementVNode("p", null, "Fim: " + _toDisplayString($setup.format(new Date($props.dateRange.end), 'dd/MM/yyyy')), 1 /* TEXT */)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_5, "Range de data"))
          ]),
          ($setup.range.start && $setup.range.end)
            ? (_openBlock(), _createBlock(_component_AtomButton, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
              () => {
                _ctx.$emit('cleanRange');
              }
            ),
                class: "pe-2 cursor-pointer"
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("X")
                ])),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ], 16 /* FULL_PROPS */)
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["modelValue"]))
}