import { defineComponent as _defineComponent } from 'vue'
import { onMounted, computed } from 'vue';
import { MoleculeCard } from '@/components/molecules';
import { AtomToogleButton } from '@/components/atoms';
import { IDataPolarChart, IOptionsSelect, IToggleButon } from '@/types';
import { Chart as ChartJS, RadialLinearScale, ArcElement, Tooltip, Legend } from 'chart.js';
import { PolarArea } from 'vue-chartjs';

interface IProps {
  title: string;
  legend?: boolean;
  data: Array<IDataPolarChart>;
  hasToggle?: boolean;
  loading?: boolean;
  buttons?: IToggleButon[];
  hasDropdownList?: boolean;
  optionsDropDown?: IOptionsSelect[];
  height?: number;
  labels?: string[];
  textTooltip?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsPolarChart',
  props: {
    title: { type: String, required: true, default: '' },
    legend: { type: Boolean, required: false, default: false },
    data: { type: Array, required: true },
    hasToggle: { type: Boolean, required: false },
    loading: { type: Boolean, required: false },
    buttons: { type: Array, required: false },
    hasDropdownList: { type: Boolean, required: false },
    optionsDropDown: { type: Array, required: false },
    height: { type: Number, required: false },
    labels: { type: Array, required: false },
    textTooltip: { type: String, required: false }
  },
  emits: ['handleToggle', 'onChangeDropDownList'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);
const props = __props;
const emit = __emit;
const data = computed(() => {
  return {
    labels: props.labels,
    datasets: props.data.map((f) => {
      return {
        fill: true,
        ...f,
      };
    }),
  };
});
const options = computed(() => {
  return {
    responsive: true,
    plugins: {},
    elements: {
      line: {
        borderWidth: 3,
      },
    },
  };
});

// function changeChartOptions() {
//   const data = {
//     labels: props.labels,
//     datasets: props.data.map((f) => {
//       return {
//         fill: true,
//         ...f,
//       };
//     }),
//   };
//   chartOptions.value = {
//     type: 'radar',
//     data: data,
//     options: {
//       responsive: true,
//       plugins: {},
//       elements: {
//         line: {
//           borderWidth: 3,
//         },
//       },
//     },
//   };
//   if (chart.value) {
//     chart.value.clear();
//     chart.value.destroy();
//   }
//   const dad = document.getElementById('canvasRadar') as HTMLElement;
//   dad.style.width = '100%';
//   if (!dad) return;
//   chart.value = new Chart(canvasRadar.value, chartOptions.value);
//   chart.value.resize(props.height, props.height);
// }
function handleChangeDropdownList(event) {
  emit('onChangeDropDownList', event);
}

// watch(
//   () => props.data,
//   () => {
//     setTimeout(() => {
//       changeChartOptions();
//     }, 300);
//   },
//   { deep: true }
// );
function handleToogleChange($event) {
  emit('handleToggle', $event);
}
onMounted(() => {
  // setTimeout(() => {
  //   changeChartOptions();
  // }, 300)
});

const __returned__ = { props, emit, data, options, handleChangeDropdownList, handleToogleChange, get MoleculeCard() { return MoleculeCard }, get AtomToogleButton() { return AtomToogleButton }, get PolarArea() { return PolarArea } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})