import { defineComponent as _defineComponent } from 'vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'Filter2Icon',
  setup(__props, { expose: __expose }) {
  __expose();

// import { defineProps, withDefaults } from 'vue';

// interface IProps {
//   color?: string;
// }
// const props = withDefaults(defineProps<IProps>(), {});

const __returned__ = {  }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})