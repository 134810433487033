import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["width", "height", "viewBox"]
const _hoisted_2 = ["stroke"]
const _hoisted_3 = ["stroke"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    width: $setup.props.size,
    height: _ctx.$props.size,
    viewBox: `0 0 ${$setup.props.size} ${$setup.props.size}`,
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, [
    _createElementVNode("line", {
      x1: "1.41421",
      y1: "1",
      x2: "10.7349",
      y2: "10.3207",
      stroke: $props.color || '#212529',
      "stroke-width": "2",
      "stroke-linecap": "round"
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("line", {
      x1: "1.26465",
      y1: "10.3206",
      x2: "10.5853",
      y2: "0.999981",
      stroke: $props.color || '#212529',
      "stroke-width": "2",
      "stroke-linecap": "round"
    }, null, 8 /* PROPS */, _hoisted_3)
  ], 8 /* PROPS */, _hoisted_1))
}