import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card bg-surface" }
const _hoisted_2 = { class: "header-card" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "py-5 px-4 flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString($setup.props.title), 1 /* TEXT */),
      (_ctx.$slots.iconTitle)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('clickIconTitle')))
          }, [
            _renderSlot(_ctx.$slots, "iconTitle", {}, undefined, true)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}