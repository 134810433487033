import { defineComponent as _defineComponent } from 'vue'
import { watch, ref } from 'vue';
import { AtomInfoCard, AtomToogleButton } from '@/components/atoms';
import * as icons from '@/components/atoms';

import { MoleculeCard } from '.';
import { IDataInfo, IToggleButon } from '@/types';
interface IProps {
  data: IDataInfo[];
  hasToggle?: boolean;
  buttons?: IToggleButon[];
  title: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeConsumoInternet',
  props: {
    data: { type: Array, required: true },
    hasToggle: { type: Boolean, required: false, default: false },
    buttons: { type: Array, required: false },
    title: { type: String, required: true }
  },
  emits: ['handleToggle'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const mapComp = {};
const props = __props;
const emit = __emit;
props.data.map((data, index) => {
  data.order = index;
});

for (const iterator of props.data.filter((f) => f.icon)) {
  mapComp[String(iterator.order)] = icons[String(iterator.icon)];
}
const toggleBtn = ref('');
watch(
  () => props.data,
  () => {
    props.data.map((data, index) => {
      data.order = index;
    });

    for (const iterator of props.data.filter((f) => f.icon)) {
      mapComp[String(iterator.order)] = icons[String(iterator.icon)];
    }
  }
);
watch(
  () => toggleBtn.value,
  () => {
    emit('handleToggle', toggleBtn.value);
  }
);
function handleToogleChange($event) {
  emit('handleToggle', $event);
}

const __returned__ = { mapComp, props, emit, toggleBtn, handleToogleChange, get AtomInfoCard() { return AtomInfoCard }, get AtomToogleButton() { return AtomToogleButton }, get MoleculeCard() { return MoleculeCard } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})