import { createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "scroll" }
const _hoisted_2 = { class: "text-align-center" }
const _hoisted_3 = { class: "px-10" }
const _hoisted_4 = {
  key: 0,
  class: "text-muted my-5"
}
const _hoisted_5 = {
  key: 1,
  class: "text-muted my-5"
}
const _hoisted_6 = {
  key: 2,
  class: "text-muted my-5"
}
const _hoisted_7 = { key: 3 }
const _hoisted_8 = {
  key: 4,
  class: "text-muted my-5"
}
const _hoisted_9 = {
  key: 5,
  class: "text-muted my-5"
}
const _hoisted_10 = { key: 6 }
const _hoisted_11 = { class: "px-10" }
const _hoisted_12 = {
  key: 0,
  class: "text-muted my-5"
}
const _hoisted_13 = {
  key: 1,
  class: "text-muted my-5"
}
const _hoisted_14 = {
  key: 2,
  class: "text-muted my-5"
}
const _hoisted_15 = { class: "px-10" }
const _hoisted_16 = {
  key: 0,
  class: "text-muted my-5"
}
const _hoisted_17 = {
  key: 1,
  class: "text-muted my-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[51] || (_cache[51] = _createElementVNode("h3", { class: "mb-3" }, "Notificação", -1 /* HOISTED */)),
    _cache[52] || (_cache[52] = _createElementVNode("p", { class: "text-muted mb-5" }, "Veja abaixo as informações referentes ao link de internet da sua unidade", -1 /* HOISTED */)),
    _createCommentVNode(" notificação 1 "),
    ($setup.typeNotification == '1')
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          class: "ms-3"
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" <v-col cols=\"6\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          width=\"100%\"\r\n          :hasError=\"listError.includes('grupos')\"\r\n          label=\"Empresa:\"\r\n          :color=\"`#697B8E`\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  type: 'text',
                  width: "100%",
                  "background-color": `#697B8E`,
                  hasError: $setup.listError.includes('unidade'),
                  label: "Unidade:",
                  modelValue: $setup.notify.bodyComplete.unidade,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.notify.bodyComplete.unidade) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  width: "100%",
                  hasError: $setup.listError.includes('Serviço'),
                  label: "Serviços / Links:",
                  color: `#697B8E`,
                  class: "mb-5",
                  modelValue: $setup.notify.bodyComplete.servico,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.notify.bodyComplete.servico) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Jitter'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Jitter Atual:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Latência'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('valor'),
                      label: "Latência:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'qualidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Qualidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'disponibilidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 3,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Disponibilidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'perda de pacote'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 4,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Perda de Pacote:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" notificação 2 "),
    ($setup.typeNotification == '2')
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 1,
          class: "ms-3"
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" <v-col cols=\"6\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          width=\"100%\"\r\n          :hasError=\"listError.includes('grupos')\"\r\n          label=\"Grupos:\"\r\n          :color=\"`#697B8E`\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  type: 'text',
                  width: "100%",
                  "background-color": `#697B8E`,
                  hasError: $setup.listError.includes('unidade'),
                  label: "Unidade:",
                  modelValue: $setup.notify.bodyComplete.unidade,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.notify.bodyComplete.unidade) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  width: "100%",
                  hasError: $setup.listError.includes('Serviço'),
                  label: "Serviços / Links:",
                  color: `#697B8E`,
                  class: "mb-5",
                  modelValue: $setup.notify.bodyComplete.servico,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.notify.bodyComplete.servico) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            ($setup.typeData == 'qualidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Qualidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'disponibilidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Disponibilidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'perda de pacote'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Perda de Pacote:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Jitter'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 3,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('valor'),
                      label: "Jitter:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Jitter'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 4,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('unidade'),
                      label: "Unidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.predict.JiTTER,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.predict.JiTTER) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Latência'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 5,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('valor'),
                      label: "Latência:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Latência'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 6,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Latência preditiva'),
                      label: "Latência preditiva:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.predict.LATENCY,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.predict.LATENCY) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  hasError: $setup.listError.includes('Estado atual'),
                  label: "Estado atual:",
                  color: `#697B8E`,
                  width: "100%",
                  class: "mb-5",
                  modelValue: $setup.notify.bodyComplete.estadoAtual,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.notify.bodyComplete.estadoAtual) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  hasError: $setup.listError.includes('Estado preditivo (Próximas 3 horas)'),
                  label: "Estado preditivo (Próximas 3 horas):",
                  color: `#697B8E`,
                  width: "100%",
                  class: "mb-5",
                  modelValue: $setup.predict.status,
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.predict.status) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" notificação 3 "),
    ($setup.typeNotification == '3')
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 2,
          class: "ms-3"
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" <v-col cols=\"6\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          width=\"100%\"\r\n          :hasError=\"listError.includes('grupos')\"\r\n          label=\"Grupos:\"\r\n          :color=\"`#697B8E`\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  type: 'text',
                  width: "100%",
                  "background-color": `#697B8E`,
                  hasError: $setup.listError.includes('unidade'),
                  label: "Unidade:",
                  modelValue: $setup.notify.bodyComplete.unidade,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.notify.bodyComplete.unidade) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  width: "100%",
                  hasError: $setup.listError.includes('Serviço'),
                  label: "Serviços / Links:",
                  color: `#697B8E`,
                  class: "mb-5",
                  modelValue: $setup.notify.bodyComplete.servico,
                  "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.notify.bodyComplete.servico) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            ($setup.typeData == 'qualidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Qualidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'disponibilidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Disponibilidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'perda de pacote'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Perda de Pacote:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Jitter'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 3,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('valor'),
                      label: "Jitter:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Jitter'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 4,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('jitter preditivo'),
                      label: "Jitter preditivo:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.predict.JiTTER,
                      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($setup.predict.JiTTER) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Latência'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 5,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('valor'),
                      label: "Latência:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Latência'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 6,
                  cols: "3"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Latência preditiva'),
                      label: "Latência preditiva:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.predict.LATENCY,
                      "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($setup.predict.LATENCY) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  hasError: $setup.listError.includes('Estado atual'),
                  label: "Estado atual:",
                  color: `#697B8E`,
                  width: "100%",
                  class: "mb-5",
                  modelValue: $setup.notify.bodyComplete.estadoAtual,
                  "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (($setup.notify.bodyComplete.estadoAtual) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  hasError: $setup.listError.includes('Estado preditivo (Próximas 3 horas)'),
                  label: "Estado preditivo (Próximas 3 horas):",
                  color: `#697B8E`,
                  width: "100%",
                  class: "mb-5",
                  modelValue: $setup.predict.status,
                  "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (($setup.predict.status) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" notificação 4 "),
    ($setup.typeNotification == '4')
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 3,
          class: "ms-3"
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" <v-col cols=\"6\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          width=\"100%\"\r\n          :hasError=\"listError.includes('grupos')\"\r\n          label=\"Grupos:\"\r\n          :color=\"`#697B8E`\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  type: 'text',
                  width: "100%",
                  "background-color": `#697B8E`,
                  hasError: $setup.listError.includes('unidade'),
                  label: "Unidade:",
                  modelValue: $setup.notify.bodyComplete.unidade,
                  "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => (($setup.notify.bodyComplete.unidade) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            _createVNode(_component_v_col, {
              cols: $setup.typeData == 'Jitter & latencia'.toUpperCase() ? 3 : 6
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  width: "100%",
                  hasError: $setup.listError.includes('Serviço'),
                  label: "Serviços / Links:",
                  color: `#697B8E`,
                  class: "mb-5",
                  modelValue: $setup.notify.bodyComplete.servico,
                  "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (($setup.notify.bodyComplete.servico) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["cols"]),
            ($setup.typeData == 'qualidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Qualidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'disponibilidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Disponibilidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'perda de pacote'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Perda de Pacote:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData.toUpperCase() == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Jitter'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 3,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Jitter:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'Jitter & latencia'.toUpperCase() || $setup.typeData == 'Latência'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 4,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Latência:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'qualidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 5,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Qualidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'disponibilidade'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 6,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Disponibilidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'perda de pacote'.toUpperCase())
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 7,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Perda de Pacote:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(" notificação 5 "),
    (
        $setup.typeNotification == '5-blue' ||
        $setup.typeNotification == '5-green' ||
        $setup.typeNotification == '5-orange' ||
        $setup.typeNotification == '5-orange-2'
      )
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 4,
          class: "ms-3"
        }, {
          default: _withCtx(() => [
            _createCommentVNode(" <v-col cols=\"6\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          width=\"100%\"\r\n          :hasError=\"listError.includes('grupos')\"\r\n          label=\"Grupos:\"\r\n          :color=\"`#697B8E`\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createVNode(_component_v_col, { cols: "3" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  type: 'text',
                  width: "100%",
                  "background-color": `#697B8E`,
                  hasError: $setup.listError.includes('unidade'),
                  label: "Unidade:",
                  modelValue: $setup.notify.bodyComplete.unidade,
                  "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => (($setup.notify.bodyComplete.unidade) = $event))
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { cols: "3" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: true,
                  width: "100%",
                  hasError: $setup.listError.includes('Serviço'),
                  label: "Serviços / Links:",
                  color: `#697B8E`,
                  modelValue: $setup.notify.bodyComplete.servico,
                  "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => (($setup.notify.bodyComplete.servico) = $event)),
                  class: "mb-5"
                }, null, 8 /* PROPS */, ["hasError", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            ($setup.typeData == 'qualidade'.toUpperCase() && $setup.typeNotification != '5-green')
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Qualidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'disponibilidade'.toUpperCase() && $setup.typeNotification != '5-green')
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Disponibilidade:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeData == 'perda de pacote'.toUpperCase() && $setup.typeNotification != '5-green')
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Perda de Pacote:",
                      color: `#697B8E`,
                      width: "100%",
                      class: "mb-5",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeNotification != '5-green')
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 3,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('valor'),
                      label: "Valor:",
                      color: `#697B8E`,
                      width: "100%",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event)),
                      class: "mb-5"
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.typeNotification != '5-green')
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 4,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      disabled: true,
                      hasError: $setup.listError.includes('Valor'),
                      label: "Valor:",
                      color: `#697B8E`,
                      width: "100%",
                      modelValue: $setup.notify.bodyComplete.valor,
                      "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => (($setup.notify.bodyComplete.valor) = $event)),
                      class: "mb-5"
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createCommentVNode(" <v-col :cols=\"typeNotification == '5-green' ? 12 : 6\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          :hasError=\"listError.includes('Estado atual (Qualidade x Disponibilidade)')\"\r\n          label=\"Estado atual (Qualidade x Disponibilidade):\"\r\n          :color=\"`#697B8E`\"\r\n          width=\"100%\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createCommentVNode(" <v-col cols=\"6\" v-if=\"typeNotification != '5-green'\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          :hasError=\"listError.includes('Estado futuro (Qualidade x Disponibilidade)')\"\r\n          label=\"Estado futuro (Qualidade x Disponibilidade)::\"\r\n          :color=\"`#697B8E`\"\r\n          width=\"100%\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createCommentVNode(" <v-col cols=\"6\" v-if=\"typeNotification != '5-green' && typeNotification == '5-orange-2'\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          :hasError=\"listError.includes('Somatória total de saltos')\"\r\n          label=\"Somatória total de saltos:\"\r\n          :color=\"`#697B8E`\"\r\n          width=\"100%\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> "),
            _createCommentVNode(" <v-col cols=\"6\" v-if=\"typeNotification != '5-green' && typeNotification == '5-orange-2'\">\r\n        <AtomInput\r\n          :disabled=\"true\"\r\n          :hasError=\"listError.includes('Resultado final da perda de pacote (Salto final)')\"\r\n          label=\"Resultado final da perda de pacote (Salto final):\"\r\n          :color=\"`#697B8E`\"\r\n          width=\"100%\"\r\n          class=\"mb-5\"\r\n        >\r\n        </AtomInput>\r\n      </v-col> ")
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_v_divider, { class: "my-5" }),
    _createElementVNode("div", {
      class: _normalizeClass(
        $setup.typeNotification == '3' || $setup.typeNotification == '5-blue'
          ? 'dottedbox-blue mb-5'
          : $setup.typeNotification == '4' || $setup.typeNotification == '5-green'
            ? 'dottedbox-green mb-5'
            : $setup.typeNotification == '1' ||
                $setup.typeNotification == '2' ||
                $setup.typeNotification == '5-orange' ||
                $setup.typeNotification == '5-orange-2'
              ? 'dottedbox mb-5'
              : ''
      )
    }, [
      _createElementVNode("div", _hoisted_2, [
        (
            $setup.typeNotification == '1' ||
            $setup.typeNotification == '2' ||
            $setup.typeNotification == '5-orange' ||
            $setup.typeNotification == '5-orange-2'
          )
          ? (_openBlock(), _createBlock($setup["AtomAccordion"], {
              key: 0,
              titleOrange: true,
              cardClass: "pa-5",
              noChevron: "",
              initialShow: true,
              title: "Condicionamento do gatilho e notificação "
            }, {
              icon: _withCtx(() => [
                _createVNode($setup["ArrowCircleIcon"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, [
                  ($setup.typeNotification == '1')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_4, " Por Regra, o analista irá receber a primeira notificação de jitter e latência do estado atual em 15 minutos. O analista não realizará nenhuma intervenção, apenas irá observar os dados informados. "))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '2')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_5, " Por Regra, o analista irá receber a segunda notificação de jitter do estado atual e da predição para os próximos 30 minutos (somado o tempo da primeira notificação). Após essa segunda notificação, o analista deverá avaliar o estado do link de internet preditivo, nos detalhes investigativos, das próximas 3 horas. "))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '5-orange')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_6, " Por Regra, as notificações de indisponibilidade e perda de conectividade do link serão enviadas aos analistas e administradores de rede após 30 minutos da primeira detecção. Será exibido as informações de qualidade x disponibilidade do estado atual x futuro, a somatória total final dos saltos e o resultado final da perda de pacote baseado no salto final pelo coletor. "))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '5-orange')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _cache[48] || (_cache[48] = [
                        _createElementVNode("strong", null, "Observação:", -1 /* HOISTED */),
                        _createElementVNode("span", { class: "text-muted" }, " analista poderá visualizar e acompanhar na dashboard principal se houve alteração do status em real time ou preditivo.", -1 /* HOISTED */)
                      ])))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '5-orange-2')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_8, " Por Regra, as notificações de indisponibilidade e perda de conectividade do link serão enviadas aos analistas e administradores de rede após 60 minutos da segunda detecção. "))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '5-orange-2')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_9, " Isso visa evitar intervenções desnecessárias devido a picos temporários de uso e horários, garantindo um período de 60 minutos contínuos antes de encaminhar para o administrador da rede atuar na correção. "))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '5-orange-2')
                    ? (_openBlock(), _createElementBlock("p", _hoisted_10, _cache[49] || (_cache[49] = [
                        _createElementVNode("strong", null, "Observação: ", -1 /* HOISTED */),
                        _createElementVNode("span", { class: "text-muted" }, "O analista poderá visualizar e acompanhar na dashboard principal se houve alteração do status em real time ou preditivo.", -1 /* HOISTED */)
                      ])))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.typeNotification == '3' || $setup.typeNotification == '5-blue')
          ? (_openBlock(), _createBlock($setup["AtomAccordion"], {
              key: 1,
              titleBlue: true,
              cardClass: "pa-5",
              noChevron: "",
              initialShow: true,
              title: "Condicionamento do gatilho e notificação "
            }, {
              icon: _withCtx(() => [
                _createVNode($setup["ArrowIconBlue"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  ($setup.typeNotification == '3')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_12, _cache[50] || (_cache[50] = [
                        _createTextVNode(" Por Regra, o analista irá receber a terceira notificação de Jitter e Latência no período de 45 minutos (somado o tempo das duas primeiras notificações) entre o estado atual apresentando o resultado, se os mesmos se encontram com o estado elevado ou sadio, e a exibição da análise preditiva informando como ficará nas próximas 3 horas. "),
                        _createElementVNode("br", null, null, -1 /* HOISTED */),
                        _createTextVNode("Após essa terceira notificação, o analista deverá avaliar novamente o estado do link de internet preditivo, nos detalhes investigativos, das próximas 3 horas. "),
                        _createElementVNode("br", null, null, -1 /* HOISTED */),
                        _createTextVNode(" Caso o problema de jitter e latência ainda persista, e após a análise preditiva do analista, foi identificado que o link continuará com este problema durante as próximas 3 horas. Sendo assim, o analista deve entrar nos detalhes da notificação e realizar a abertura do chamado para o administrador de rede do Go Connect Analytics Web atuar de forma preventiva e corretiva. ")
                      ])))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '5-blue')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_13, " Por Regra, a terceira notificação de indisponibilidade e perda de conectividade do link será enviada aos analistas e administradores de rede após 90 minutos da segunda detecção. "))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '5-blue')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_14, " Caso, a indisponibilidade persista após a segunda e terceira notificação, e a análise preditiva indique que o problema continuará por mais 3 horas, o analista deverá abrir um chamado para que o administrador do sistema tome medidas preventivas e corretivas. "))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.typeNotification == '4' || $setup.typeNotification == '5-green')
          ? (_openBlock(), _createBlock($setup["AtomAccordion"], {
              key: 2,
              titleGreen: true,
              cardClass: "pa-5",
              noChevron: "",
              initialShow: true,
              title: "Condicionamento do gatilho e notificação "
            }, {
              icon: _withCtx(() => [
                _createVNode($setup["ArrowIconGreen"])
              ]),
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_15, [
                  ($setup.typeNotification == '5-green')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_16, " Por regra, após a abertura, o recebimento, a ação e a resolução do chamado pelo administrador de rede do Go Connect Analytics Web, o sistema deverá notificar o(a) analista que o problema foi resolvido. "))
                    : _createCommentVNode("v-if", true),
                  ($setup.typeNotification == '4')
                    ? (_openBlock(), _createElementBlock("li", _hoisted_17, " Por regra, após a abertura, o recebimento, a ação e a resolução do chamado pelo administrador de rede do Go Connect Analytics Web, o sistema deverá notificar o(a) analista que o problema foi resolvido, e o jitter e a latência se encontram sadios novamente. "))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ])
    ], 2 /* CLASS */),
    ($setup.typeNotification == '3' || $setup.typeNotification == '5-blue')
      ? (_openBlock(), _createBlock(_component_v_row, { key: 5 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "text-center" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomButton"], {
                  center: "text-center",
                  text: "Voltar",
                  background: "gray-05",
                  class: "w-100",
                  onClick: _cache[46] || (_cache[46] = ($event: any) => ($setup.emits('closeModal', true)))
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { class: "text-center" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomButton"], {
                  center: "text-center",
                  text: "Abrir chamado",
                  background: "primary",
                  class: "w-100",
                  onClick: $setup.abrirChamado
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createBlock(_component_v_row, { key: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "text-center" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomButton"], {
                  center: "text-center",
                  text: "Voltar",
                  background: "gray-05",
                  class: "w-100",
                  onClick: _cache[47] || (_cache[47] = ($event: any) => ($setup.emits('closeModal', true)))
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
  ]))
}