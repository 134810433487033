import { defineComponent as _defineComponent } from 'vue'
import { computed, useAttrs } from 'vue';
import omit from '@/utils/omit';
import { state } from '@/store/modules/layout';

// const { calcFieldSize } = useField()


const __default__ = {
  inheritAttrs: false,
};

interface InputProps {
  modelValue?: any;
  label?: string;
  disabled?: boolean;
  checked?: boolean;
  classes?: string;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'AtomCheckBox',
  props: {
    modelValue: { type: null, required: false, default: '' },
    label: { type: String, required: false },
    disabled: { type: Boolean, required: false },
    checked: { type: Boolean, required: false },
    classes: { type: String, required: false }
  },
  emits: ["update:modelValue", "click", "keydown", "focus", "update:hint"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;
// const emit = defineEmits(['click', 'keydown', 'update:modelValue', 'focus', 'update:hint'])

const attrs = useAttrs();

const listeners = computed(() => ({
  input: emitValue,
  keydown: processValue,
  blur: (e: Event) => {
    if (typeof attrs.onBlur === 'function') attrs.onBlur(e);
  },
  focus: (e: PointerEvent) => emit('focus', e),
}));

const attributes = computed(() => omit(attrs, 'class'));

const processValue = (event: KeyboardEvent) => {
  if (!event.key) return;

  emit('keydown', event);
};

const emitValue = (event: InputEvent) => {
  const value = (event.target as HTMLInputElement).value;
  emit('update:modelValue', value);
};

const __returned__ = { props, emit, attrs, listeners, attributes, processValue, emitValue, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})