import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import { state } from '../../store/modules/layout';
import {
  CollerctorsPage,
  CompanyPage,
  EquipamentPage,
  GroupsPage,
  InternetLinkPage,
  ProviderPage,
  ServicesPage,
  TypeOfEquipmentPage,
  UnitsPage,
  CollectorsVersionPage,
  TriggersPage,
} from './pages';
import { MoleculeTabItem, MoleculeTabWindow, MoleculeTabs } from '@/components/molecules';
import { ITab } from '@/types';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsPage',
  setup(__props, { expose: __expose }) {
  __expose();

const route = useRoute();
const tabs = ref<ITab[]>([
  {
    label: 'Grupos',
    value: 'groups',
    tooltips: 'Permitem organizar as empresas e unidades em categorias específicas. Facilitam a gestão e o monitoramento de múltiplas entidades relacionadas.'
  },
  {
    label: 'Empresas',
    value: 'companys',
    tooltips: 'Representam as organizações ou clientes atendidos pela plataforma. São utilizadas para agrupar e administrar as unidades e equipamentos associados.'
  },
  {
    label: 'Unidades',
    value: 'units',
    tooltips: 'Correspondem aos locais físicos ou filiais das empresas. Permitem monitorar e gerenciar os recursos de rede em diferentes localidades.'
  },
  {
    label: 'Tipo de equipamentos',
    value: 'typeEquipaments',
    tooltips: 'Definem as categorias ou classificações dos equipamentos. Facilitam a organização e o gerenciamento dos dispositivos na plataforma.'
  },
  {
    label: 'Equipamentos',
    value: 'equipaments',
    tooltips: 'Referem-se aos dispositivos de rede ou servidores a serem monitorados. Incluem informações como endereço IP, porta de acesso e credenciais de autenticação.'
  },
  {
    label: 'Provedor',
    value: 'provedor',
    tooltips: 'Registram os fornecedores de serviços de internet utilizados pelas empresas. São usados para configurar e monitorar os links de internet fornecidos por cada provedor.'
  },
  {
    label: 'Link',
    value: 'internetLink',
    tooltips: 'Representam as conexões de internet disponíveis em cada unidade. Incluem detalhes como tipo de conexão, velocidade de banda e configurações de roteamento.'
  },
  {
    label: 'Serviços',
    value: 'services',
    tooltips: 'Correspondem aos serviços específicos a serem monitorados, como aplicações web ou servidores de e-mail. Permitem avaliar o desempenho e a disponibilidade desses serviços para os usuários finais.'
  },
  {
    label: 'Coletores',
    value: 'collectors',
    tooltips: 'São os agentes responsáveis pela coleta de dados de desempenho da rede. Instalados localmente ou externamente, coletam informações sobre os links de internet e serviços monitorados.'
  },
  {
    label: 'Gatilhos',
    value: 'triggers',
    tooltips: 'Permitem definir condições e eventos que acionam alertas ou notificações automáticas. São configurados com base em métricas de desempenho, como qualidade da conexão, disponibilidade de serviços e problemas de latência.'
  },
  {
    label: 'Versão dos Coletores',
    value: 'collectorVersion',
    tooltips: 'Registram as diferentes versões do software de coleta de dados. Permitem controlar e atualizar as versões instaladas nos coletores para garantir a compatibilidade e o bom funcionamento do sistema.'
  },
]);
const tab = ref<string>(tabs.value[0].value);

const isMobile = computed(() => {
  return true;
});

watch(
  () => route.query,
  () => {
    tab.value = String(route.query.page);
  },
);
onMounted(() => {
  const { page } = route.query;
  if (page) tab.value = String(route.query.page);
});

const __returned__ = { route, tabs, tab, isMobile, get state() { return state }, get CollerctorsPage() { return CollerctorsPage }, get CompanyPage() { return CompanyPage }, get EquipamentPage() { return EquipamentPage }, get GroupsPage() { return GroupsPage }, get InternetLinkPage() { return InternetLinkPage }, get ProviderPage() { return ProviderPage }, get ServicesPage() { return ServicesPage }, get TypeOfEquipmentPage() { return TypeOfEquipmentPage }, get UnitsPage() { return UnitsPage }, get CollectorsVersionPage() { return CollectorsVersionPage }, get TriggersPage() { return TriggersPage }, get MoleculeTabItem() { return MoleculeTabItem }, get MoleculeTabWindow() { return MoleculeTabWindow }, get MoleculeTabs() { return MoleculeTabs } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})