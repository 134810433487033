import { defineComponent as _defineComponent } from 'vue'
import {
  AtomButton,
  AtomUpload,
  DeleteIcon,
  AtomInput
} from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { IHeadersTable } from '@/types';
import Swal from 'sweetalert2';
import { Preloader } from '@/store';
import { ref, onMounted, watch, computed } from 'vue';
import {
  downloadVersaoColetor,
  listarVersaoColetor,
  deleteVersaoColetor,
  uploadVersaoColetor,
} from '@/core/services/Coletores';
import { format } from 'date-fns';

interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CollectorsVersionPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const perPage = ref<number>(10);
const page = ref<number>(1);
const totalItems = ref<number>(0);
const term = ref<string>('');
const showModalUpload = ref<boolean>(false);
const data = ref<any>({});
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  {
    label: 'Versão',
    field: 'versao',
  },
  {
    label: 'Tamanho',
    field: 'Tamanho',
  },
  { label: 'Cadastrado em', field: 'dataCadastroFormat' },
  { label: 'Opções', field: 'actions' },
]);
function handleUpload(evt: FileList) {
  data.value.file = evt[0];
  const sizeInMB = ((evt[0] as File).size / (1024 * 1024)).toFixed(2);
  data.value.tamanho = `${sizeInMB} MB`;
}

const items = ref<any[]>([]);
const sortable = ref<any>();
  const addNew = ref<boolean>(false);
    const type = ref<string>();
  function replacement(){
  if(addNew.value == false){
    addNew.value = true;
    type.value = 'C';
  }else{
    addNew.value = false;
    type.value = '';
  }
}
function handleDownload() {
  Preloader.actions.ActionSetPreloader(true);
  downloadVersaoColetor()
    .then((f) => {
      const file = new Blob([f], { type: 'application/java-archive' });
      const filename = `coletor_atual.jar`;
      const a = document.createElement('a');
      a.target = '_blank';
      a.download = filename;
      a.href = URL.createObjectURL(file);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}
function clear() {
  data.value = {};
  data.value.id = 0;
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}

function searching(event: string | undefined) {
  page.value = 1;
  term.value = String(event);
}

function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);

  uploadVersaoColetor(data.value.version, data.value.file, data.value.tamanho)
    .then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: `Versão do Coletor foi criada com sucesso!`,
      });
      loadData();
      showModalUpload.value = false;
    })
    .finally(() => {
      data.value = {};

      Preloader.actions.ActionSetPreloader(false);
    });
}

const simulateTableData = computed(() => {
  return items.value.filter((f) => {
    return !term.value ? true : f.versao.includes(term.value);
  });
});
const paginationTableData = computed(() => {
  const data = items.value
    .filter((f) => {
      return !term.value ? true : f.versao.includes(term.value);
    })
    .slice((page.value - 1) * perPage.value, (page.value - 1) * perPage.value + perPage.value);
  return data;
});
function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalItems.value = 0;



  const promisse = listarVersaoColetor();

  // getGruposAll(page.value - 1, perPage.value, term.value)
  promisse
    .then((resp) => {
      resp.map((f) => {
        f.dataCadastroFormat = format(new Date(f.dataCadastro), 'dd/MM/yyyy');
      });
      items.value = resp;
      totalItems.value = resp.length;
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

onMounted(() => {
  loadData();
});
watch(
  () => page.value,
  () => {
    loadData();
  }
);

watch(
  () => term.value,
  () => {
    loadData();
  }
);

watch(
  () => perPage.value,
  () => {
    loadData();
  }
);

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'collectorVersion') {
      page.value = 1;
      loadData();
    }
  }
);

function handleDelete(payload) {
  console.log(payload, 'Delete');

  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente deletar a versão do coletor <b>${payload.versao}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);

      deleteVersaoColetor(payload.id)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              title: 'Sucesso',
              text: `Versão  ${payload.versao} foi deletado com sucesso!`,
              icon: 'success',
            });
          }
          loadData();
        })

        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}

const __returned__ = { props, perPage, page, totalItems, term, showModalUpload, data, headers, handleUpload, items, sortable, addNew, type, replacement, handleDownload, clear, sort, searching, handleAddOrUpdate, simulateTableData, paginationTableData, loadData, handleDelete, get AtomButton() { return AtomButton }, get AtomUpload() { return AtomUpload }, get DeleteIcon() { return DeleteIcon }, get AtomInput() { return AtomInput }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})