import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "64",
  height: "65",
  viewBox: "0 0 64 65",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M55.2531 49.14L52.7465 46.6334C54.0531 44.66 54.8265 42.2867 54.8265 39.7267C54.8265 32.7934 49.1998 27.1667 42.2665 27.1667C35.3331 27.1667 29.7065 32.7934 29.7065 39.7267C29.7065 46.66 35.3331 52.2867 42.2665 52.2867C44.8265 52.2867 47.1731 51.5134 49.1731 50.2067L51.6798 52.7134C52.1865 53.22 52.8265 53.46 53.4931 53.46C54.1598 53.46 54.7998 53.22 55.3065 52.7134C56.2398 51.7534 56.2398 50.1534 55.2531 49.14Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M52.2131 11.22V17.14C52.2131 19.3 50.8798 21.9934 49.5465 23.3534L49.0665 23.78C48.6931 24.1267 48.1331 24.2067 47.6531 24.0467C47.1198 23.86 46.5865 23.7267 46.0531 23.5934C44.8798 23.3 43.6265 23.1667 42.3465 23.1667C33.1465 23.1667 25.6798 30.6334 25.6798 39.8334C25.6798 42.8734 26.5065 45.86 28.0798 48.42C29.4131 50.66 31.2798 52.5267 33.3065 53.78C33.9198 54.18 34.1598 55.0334 33.6265 55.5134C33.4398 55.6734 33.2531 55.8067 33.0665 55.94L29.3331 58.3667C25.8665 60.5267 21.0931 58.1 21.0931 53.78V39.5134C21.0931 37.62 20.0265 35.1934 18.9598 33.86L8.85312 23.0867C7.51979 21.7267 6.45312 19.3 6.45312 17.7V11.4867C6.45312 8.26004 8.85312 5.83337 11.7598 5.83337H46.9065C49.8131 5.83337 52.2131 8.26004 52.2131 11.22Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}