import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "36",
  height: "36",
  viewBox: "0 0 36 36",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      width: "36",
      height: "36",
      rx: "18",
      fill: $setup.props.color || '#FF7D33',
      "fill-opacity": "0.4"
    }, null, 8 /* PROPS */, _hoisted_2),
    _cache[0] || (_cache[0] = _createElementVNode("rect", {
      x: "0.5",
      y: "0.5",
      width: "35",
      height: "35",
      rx: "17.5",
      stroke: "white",
      "stroke-opacity": "0.2"
    }, null, -1 /* HOISTED */)),
    _cache[1] || (_cache[1] = _createElementVNode("path", {
      d: "M25.3419 20.49L24.3419 18.83C24.1319 18.46 23.9419 17.76 23.9419 17.35V14.82C23.9419 12.47 22.5619 10.44 20.5719 9.49C20.0519 8.57 19.0919 8 17.9919 8C16.9019 8 15.9219 8.59 15.4019 9.52C13.4519 10.49 12.1019 12.5 12.1019 14.82V17.35C12.1019 17.76 11.9119 18.46 11.7019 18.82L10.6919 20.49C10.2919 21.16 10.2019 21.9 10.4519 22.58C10.6919 23.25 11.2619 23.77 12.0019 24.02C13.9419 24.68 15.9819 25 18.0219 25C20.0619 25 22.1019 24.68 24.0419 24.03C24.7419 23.8 25.2819 23.27 25.5419 22.58C25.8019 21.89 25.7319 21.13 25.3419 20.49Z",
      fill: "white"
    }, null, -1 /* HOISTED */)),
    _cache[2] || (_cache[2] = _createElementVNode("path", {
      d: "M20.8297 26.01C20.4097 27.17 19.2997 28 17.9997 28C17.2097 28 16.4297 27.68 15.8797 27.11C15.5597 26.81 15.3197 26.41 15.1797 26C15.3097 26.02 15.4397 26.03 15.5797 26.05C15.8097 26.08 16.0497 26.11 16.2897 26.13C16.8597 26.18 17.4397 26.21 18.0197 26.21C18.5897 26.21 19.1597 26.18 19.7197 26.13C19.9297 26.11 20.1397 26.1 20.3397 26.07C20.4997 26.05 20.6597 26.03 20.8297 26.01Z",
      fill: "white"
    }, null, -1 /* HOISTED */))
  ]))
}