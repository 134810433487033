import { defineComponent as _defineComponent } from 'vue'


interface IProps {
  value: string;
}
// const emit = defineEmits(['update:modelValue']);


export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeTabItem',
  props: {
    value: { type: String, required: true, default: '' }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;


const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})