import { defineComponent as _defineComponent } from 'vue'

import {
  AtomSubTitle,
  ArrowCircleRightIcon,
  EyeIcon,
  LoaderIcon,
  AtomButton,
  AtomTooltip,
  AlertIcon,
} from '@/components/atoms';
import * as comps from '@/components/atoms';
import { state } from '@/store/modules/layout';

import { IItemDiagnostic } from '@/types';
interface IProps {
  itemDiagnostic: IItemDiagnostic;
  loading?: boolean;
  hasTooltip?: boolean;
  textTooltip?: string;
  badgeColor?: string;
  loadingColor?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeDiagnostic',
  props: {
    itemDiagnostic: { type: Object, required: true },
    loading: { type: Boolean, required: false },
    hasTooltip: { type: Boolean, required: false },
    textTooltip: { type: String, required: false },
    badgeColor: { type: String, required: false },
    loadingColor: { type: String, required: false }
  },
  emits: ['handleToggle', 'clickMore'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

function handleCLickMore() {
  emit('clickMore');
}

const __returned__ = { props, emit, handleCLickMore, get AtomSubTitle() { return AtomSubTitle }, get ArrowCircleRightIcon() { return ArrowCircleRightIcon }, get EyeIcon() { return EyeIcon }, get LoaderIcon() { return LoaderIcon }, get AtomButton() { return AtomButton }, get AtomTooltip() { return AtomTooltip }, get AlertIcon() { return AlertIcon }, get comps() { return comps }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})