import { defineComponent as _defineComponent } from 'vue'
import {
  AtomButton,
  AtomInput,
  AtomSelect,
  RelatoryIcon,
  SearchIconMobile,
  timerIcon,
  ColsIcon,
  exportIcon,
} from '@/components/atoms';
import { OrganismsChart } from '@/components/organisms';
import { MoleculeTable, MoleculeTitleSetings, MoleculeReorder } from '@/components/molecules';
import { IHeadersTable, IOptionsSelect, IToggleButon } from '@/types';
import { ref, computed, onMounted } from 'vue';
import {
  RelatoryAll,
  RelatoryFullTime,
  RelatoryCosume,
  RelatoryPercent,
  RelatoryLatencyUnity,
  RelatoryLatencyState,
  RelatoryAvailabilityGeral,
  Relatoryconsumption,
  PerformanceVariation,
  PerformanceService,
  ComparationperformanceService,
  CostTotal,
  DescontTotal,
  PercentVariation,
  PercentEconomic,
  VariationMounthDescont,
  VariationMounthCost,
  HistoryNotification,
  MediaVelocidadeInternetPorLocalidade,
  DesempenhoInternetPorColetor,
  DesempenhoInternetPorLinkServicos,
  DesempenhoInternetPorUnidade,
} from '@/core/services/RelatoryService';
import { Relatory } from '@/core/models';
import { Preloader } from '@/store';

import { IDataChartMore } from '@/types';
import { getColetoresAll } from '@/core/services/Coletores';
// import { getInterfaceDispositivosAll } from '@/core/services/InterfaceDispositivosService';
import { getUnidadeColetoresInstalados } from '@/core/services/UnitsService';
// import { getCompanhia } from '@/core/services/CompanhiasService';
import { getServicoPorUnidade, getServicosAll } from '@/core/services/ServicoService';
import { format as dateFormat } from 'date-fns';
import Swal from 'sweetalert2';


export default /*@__PURE__*/_defineComponent({
  __name: 'RelatoryPage',
  setup(__props, { expose: __expose }) {
  __expose();

// import * as xlsx from 'xlsx';

const loaderChartGeral = ref<boolean>(true);
const perPage = ref<number>(10);
const page = ref<number>(1);
const dataDate = ref<any>({});
const filter = ref<any>({});
const totalRows = ref<number>(0);

const headers1 = ref<IHeadersTable[]>([
  { label: 'Serviço', field: 'servico', checked: true },
  { label: 'serviços associados', field: 'servico', checked: true },
  { label: 'Média jitter', field: 'mediaJitter', checked: true },
  // { label: 'Tipo', field: 'type' },
  { label: 'Média latência', field: 'mediaLatency', checked: true },
  { label: 'Média perda de pacotes', field: 'mediaPacketLoss', checked: true },
  { label: 'Hora', field: 'hora', checked: true },
  { label: 'Data', field: 'dataH', checked: true },
]);
const headers2 = ref<IHeadersTable[]>([
  { label: 'Link Serviço id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Tempo total de inatividade', field: 'tempo_total_inatividade', checked: true },
]);
const headers3 = ref<IHeadersTable[]>([
  { label: 'Link Serviço id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Status', field: 'status', checked: true },
  { label: 'Status da porcentagem', field: 'status_percentage', checked: true },
]);
const headers4 = ref<IHeadersTable[]>([
  { label: 'Link Serviço id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Status', field: 'status', checked: true },
  { label: 'Status da porcentagem', field: 'status_percentage', checked: true },
]);
const headers5 = ref<IHeadersTable[]>([
  { label: 'Link Serviço id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Latência', field: 'latencia', checked: true },
  { label: 'Dia', field: 'dia', checked: true },
  { label: 'Hora', field: 'hora', checked: true },
  { label: 'Minuto', field: 'minuto', checked: true },
]);
const headers6 = ref<IHeadersTable[]>([
  { label: 'Link Serviço id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Status', field: 'status', checked: true },
  { label: 'Latência média', field: 'latencia_media', checked: true },
]);
const headers7 = ref<IHeadersTable[]>([
  { label: 'Link Serviço id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Disponibilidade', field: 'disponibilidade', checked: true },
  { label: 'Dia', field: 'dia', checked: true },
  { label: 'Hora', field: 'hora', checked: true },
  { label: 'Minuto', field: 'minuto', checked: true },
]);
const headers8 = ref<IHeadersTable[]>([
  { label: 'Link Serviço id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Tempo total de inatividade', field: 'tempo_total_inatividade', checked: true },
]);
const headers9 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'servicolinkid', checked: true },
  { label: 'Link Serviço', field: 'servicolink', checked: true },
  { label: 'Desvio padrão de latência', field: 'desvio_padrao_latencia', checked: true },
  { label: 'Dia', field: 'dia', checked: true },
  { label: 'Hora', field: 'hora', checked: true },
]);
const headers10 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'linkservicoid', checked: true },
  { label: 'Link Serviço', field: 'linkservico', checked: true },
  { label: 'Latência', field: 'latencia', checked: true },
  { label: 'Dia', field: 'dia', checked: true },
  { label: 'Hora', field: 'hora', checked: true },
]);
const headers11 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'servicolinkid', checked: true },
  { label: 'Link Serviço', field: 'servicolink', checked: true },
  { label: 'Latência Média', field: 'latencia_media', checked: true },
  { label: 'Taxa de Disponibilidade', field: 'taxa_disponibilidade', checked: true },
  { label: 'Taxa de indisponibilidade', field: 'taxa_indisponibilidade', checked: true },
  { label: 'Dia', field: 'dia', checked: true },
  { label: 'Hora', field: 'hora', checked: true },
]);
const headers12 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'linkServicoId', checked: true },
  { label: 'Nome do link', field: 'linkNome', checked: true },
  { label: 'Id Operadora', field: 'operadoraId', checked: true },
  { label: 'Operadora', field: 'operadora', checked: true },
  { label: 'Custo Total', field: 'custoTotal', checked: true },
]);
const headers13 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'linkServicoId', checked: true },
  { label: 'Nome do link', field: 'linkNome', checked: true },
  { label: 'Id Operadora', field: 'operadoraId', checked: true },
  { label: 'Operadora', field: 'operadora', checked: true },
  { label: 'Valor de desconto', field: 'valorDesconto', checked: true },
]);
const headers14 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'linkServicoId', checked: true },
  { label: 'Nome do link', field: 'linkNome', checked: true },
  { label: 'Id Operadora', field: 'operadoraId', checked: true },
  { label: 'Operadora', field: 'operadora', checked: true },
  { label: 'Custo Total atual', field: 'custoTotalAtual', checked: true },
  { label: 'Custo Total anterior', field: 'custoTotalAnterior', checked: true },
  { label: 'Variação percentual de custos', field: 'variacaoPercentualCustos', checked: true },
  { label: 'Desconto total atual', field: 'descontoTotalAtual', checked: true },
  { label: 'Desconto total anterior', field: 'descontoTotalAnterior', checked: true },
  { label: 'Variação percentual de descontos', field: 'variacaoPercentualDescontos', checked: true },
]);
const headers15 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'linkServicoId', checked: true },
  { label: 'Nome do link', field: 'linkNome', checked: true },
  { label: 'Id Operadora', field: 'operadoraId', checked: true },
  { label: 'Operadora', field: 'operadora', checked: true },
  { label: 'Valor Provisionado', field: 'valorprovisionado', checked: true },
  { label: 'Custo Total', field: 'custoTotal', checked: true },
  { label: 'Desconto', field: 'desconto', checked: true },
  { label: 'Valor Pago', field: 'valorpago', checked: true },
  { label: 'Porcentagem da Economia', field: 'porcentagemEconomia', checked: true },
]);
const headers16 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'linkServicoId', checked: true },
  { label: 'Nome do link', field: 'linkServicoNome', checked: true },
  { label: 'Id Operadora', field: 'operadoraId', checked: true },
  { label: 'Operadora', field: 'operadora', checked: true },
  { label: 'Desconto total atual', field: 'descontoTotalAtual', checked: true },
  { label: 'Desconto total anterior', field: 'descontoTotalAnterior', checked: true },
  { label: 'variação percentual', field: 'variacaoPercentual', checked: true },
]);
const headers17 = ref<IHeadersTable[]>([
  { label: 'Link Serviço Id', field: 'linkServicoId', checked: true },
  { label: 'Nome do link', field: 'linkNome', checked: true },
  { label: 'Id Operadora', field: 'operadoraId', checked: true },
  { label: 'Operadora', field: 'operadora', checked: true },
  { label: 'Custo Total atual', field: 'custoTotalAtual', checked: true },
  { label: 'Custo Total anterior', field: 'custoTotalAnterior', checked: true },
  { label: 'variação percentual', field: 'variacaoPercentual', checked: true },
]);
const headers18 = ref<IHeadersTable[]>([
  { label: 'Condição do gatilho', field: 'condicaoGatilho', checked: true },
  {
    label: 'Data da notificação',
    field: 'dataNotificacao',
    checked: true,
    formatFn: (event) => dateFormat(event, 'dd/MM/yyyy'),
  },
  { label: 'Histórico Id', field: 'historicoId', checked: true },
  // { label: 'Tipo', field: 'type' },
  { label: 'Operador Lógico', field: 'operadorLogico', checked: true },
  { label: 'Serviço de link', field: 'servicoLink', checked: true },
  { label: 'Tipo de notificação', field: 'tipoNotificacao', checked: true },
  { label: 'Titulo de notificação', field: 'tituloNotificacao', checked: true },
  { label: 'Nome unidade', field: 'unidadeNome', checked: true },
  { label: 'Valor de notificação', field: 'valorNotificado', checked: true },
  { label: 'Valor para disparo de regra', field: 'valorParaDisparoRegra', checked: true },
]);

const headers19 = ref<IHeadersTable[]>([
  { label: 'Unidade', field: 'unidadenome', checked: true },
  {
    label: 'Intervalor',
    field: 'intervalo_tempo',
    checked: true,
    formatFn: (event) => dateFormat(`${event.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm'),
  },
  { label: 'Download Médio / mbps', field: 'download_mbps_medio', checked: true },
  { label: 'Upload Médio / mbps', field: 'upload_mbps_medio', checked: true },
]);

const headers20 = ref<IHeadersTable[]>([
  { label: 'Serviço / Link', field: 'servicolink_nome', checked: true },
  {
    label: 'Intervalor',
    field: 'intervalo_tempo',
    checked: true,
    formatFn: (event) => dateFormat(`${event.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm'),
  },
  { label: 'Download Médio / mbps', field: 'download_mbps_medio', checked: true },
  { label: 'Upload Médio / mbps', field: 'upload_mbps_medio', checked: true },
]);

const headers21 = ref<IHeadersTable[]>([
  { label: 'Coletor', field: 'coletor_nome', checked: true },
  {
    label: 'Intervalor',
    field: 'intervalo_tempo',
    checked: true,
    formatFn: (event) => dateFormat(`${event.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm'),
  },
  { label: 'Download Médio / mbps', field: 'download_mbps_medio', checked: true },
  { label: 'Upload Médio / mbps', field: 'upload_mbps_medio', checked: true },
]);

const headers22 = ref<IHeadersTable[]>([
  { label: 'Localidade', field: 'localidade', checked: true },
  {
    label: 'Intervalor',
    field: 'intervalo_tempo',
    checked: true,
    formatFn: (event) => dateFormat(`${event.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm'),
  },
  { label: 'Download Médio / mbps', field: 'download_mbps_medio', checked: true },
  { label: 'Upload Médio / mbps', field: 'upload_mbps_medio', checked: true },
]);
const items = ref<Relatory[]>([]);
const optionsLinks = ref<IOptionsSelect[]>([]);
const optionsUnidades = ref<IOptionsSelect[]>([]);
const optionsCompany = ref<IOptionsSelect[]>([]);
const optionsColetors = ref<IOptionsSelect[]>([]);
const optionsServicos = ref<IOptionsSelect[]>([]);

const optionTimeServicos = ref<IOptionsSelect[]>([
  { text: '1 hora', value: 1 },
  { text: '2 horas', value: 2 },
  { text: '5 horas', value: 5 },
  { text: '8 horas', value: 8 },
]);
const optionTimeNotification = ref<IOptionsSelect[]>([
  { text: '1 Dia', value: 24 },
  { text: 'Quinzenal', value: 360 },
  { text: 'Mensal', value: 720 },
]);

const optionsRelatory = ref<IOptionsSelect[]>([
  { text: 'Relatório Geral', value: 1 },
  { text: 'Tempo total de inatividade por serviço', value: 2 },
  { text: 'Pico de consumo de banda por serviço', value: 3 },
  { text: 'Percentual de tempo de estados por serviço', value: 4 },
  { text: 'Latência média por unidade', value: 5 },
  { text: 'Latência média nos estados por serviço', value: 6 },
  { text: 'Disponibilidade geral por unidade', value: 7 },
  { text: 'Consumo médio de banda por serviço', value: 8 },
  { text: 'Variação de desempenho por serviço', value: 9 },
  { text: 'Desempenho por serviço', value: 10 },
  { text: 'Comparação de desempenho por serviço', value: 11 },
  { text: 'Custo total mensal por link', value: 12 },
  { text: 'Desconto total mensal por link', value: 13 },
  { text: 'Percentual de variação mensal de custos e descontos por link', value: 14 },
  { text: 'Porcentagem de economia mensal por link', value: 15 },
  { text: 'Variação mensal de descontos por link', value: 16 },
  { text: 'Variação mensal de custos por link', value: 17 },
  { text: 'Histórico de notificações por período', value: 18 },

  { text: 'Desempenho de Internet por Unidade', value: 19 },
  { text: 'Desempenho de Internet por Serviços/Links', value: 20 },
  { text: 'Desempenho de Internet por Coletor', value: 21 },
  { text: 'Média de Velociadade de Internet por Localidade', value: 22 },
]);

// const optionsMounth = ref<IOptionsSelect[]>([
//   { text: 'Janeiro', value: 1 },
// { text: 'Fevereiro', value: 2 },
// { text: 'Março', value: 3 },
// { text: 'Abril', value: 4 },
// { text: 'Maio', value: 5 },
// { text: 'Junho', value: 6 },
// { text: 'Julho', value: 7 },
// { text: 'Agosto', value: 8 },
// { text: 'Setembro', value: 9 },
// { text: 'Outubro', value: 10 },
// { text: 'Novembro', value: 11 },
// { text: 'Dezembro', value: 12 }
// ]);
// const interfaces = ref<InterfaceDispositivos[]>([]);
// const isNotAll = ref<boolean>([]);
const itemsChart = ref<IDataChartMore[]>([]);
const term = ref<string>('');
const toggleMarcado = ref<string>('Todos');
const format = computed(() => {
  return [
    { value: 'PDF', text: 'PDF' },
    { value: '.XLSX', text: '.XLSX' },
  ] as IOptionsSelect[];
});
const activeColor = computed(() => {
  const color = '#FF5C00';
  return color;
});

const headersResumoComputed = computed(() => {
  let dado;

  if (filter.value.relatory == 1) {
    dado = headers1.value;
  }
  if (filter.value.relatory == 2) {
    dado = headers2.value;
  }
  if (filter.value.relatory == 3) {
    dado = headers3.value;
  }
  if (filter.value.relatory == 4) {
    dado = headers4.value;
  }
  if (filter.value.relatory == 5) {
    dado = headers5.value;
  }
  if (filter.value.relatory == 6) {
    dado = headers6.value;
  }
  if (filter.value.relatory == 7) {
    dado = headers7.value;
  }
  if (filter.value.relatory == 8) {
    dado = headers8.value;
  }
  if (filter.value.relatory == 9) {
    dado = headers9.value;
  }
  if (filter.value.relatory == 10) {
    dado = headers10.value;
  }
  if (filter.value.relatory == 11) {
    dado = headers11.value;
  }
  if (filter.value.relatory == 12) {
    dado = headers12.value;
  }
  if (filter.value.relatory == 13) {
    dado = headers13.value;
  }
  if (filter.value.relatory == 14) {
    dado = headers14.value;
  }
  if (filter.value.relatory == 15) {
    dado = headers15.value;
  }
  if (filter.value.relatory == 16) {
    dado = headers16.value;
  }
  if (filter.value.relatory == 17) {
    dado = headers17.value;
  }
  if (filter.value.relatory == 18) {
    dado = headers18.value;
  }

  if (filter.value.relatory == 19) {
    dado = headers19.value;
  }

  if (filter.value.relatory == 20) {
    dado = headers20.value;
  }

  if (filter.value.relatory == 21) {
    dado = headers21.value;
  }

  if (filter.value.relatory == 22) {
    dado = headers22.value;
  }

  return dado.filter((f) => f.checked);
});
const optionsList = computed((): IToggleButon[] => {
  return [
    { value: 'Jitter', text: 'Jitter', selected: true },
    { value: 'Latência', text: 'Latência ' },
    { value: 'Perda de pacotes', text: 'Perda de pacotes' },
    { value: 'Todos', text: 'Todos' },
  ];
});
// const tabPrevisibilidade = ref(optionsList.value[0].value);

// const addNew = ref<boolean>(false);
// // const sortable = ref<any>();
// const type = ref<string>();
// function replacement() {
//   if (addNew.value == false) {
//     addNew.value = true;
//     type.value = 'C';
//   } else {
//     addNew.value = false;
//     type.value = '';
//   }
// }
function clear() {
  dataDate.value = {};
  filter.value = {};
  items.value = [];
}
async function toggle(evt: string) {
  toggleMarcado.value = evt;
  if (filter.value.relatory == 1) {
    if (evt == 'Latência') {
      itemsChart.value = [
        {
          color: '#FF9797',
          name: 'Latência',
          data: items.value.map((x) => {
            return { title: `${x.dataH} ${x.hora}:00h`, value: x.mediaLatency };
          }),
        },
      ];
    } else if (evt == 'Jitter') {
      itemsChart.value = [
        {
          color: '#FF5C00',
          name: 'Jitter',
          data: items.value.map((x) => {
            return { title: `${x.dataH} ${x.hora}:00h`, value: x.mediaJitter };
          }),
        },
      ];
    } else if (evt == 'Perda de pacotes') {
      itemsChart.value = [
        {
          color: '#283747',
          name: 'Perda de Pacotes',
          data: items.value.map((x) => {
            return { title: `${x.dataH} ${x.hora}:00h`, value: x.mediaPacketLoss };
          }),
        },
      ];
    } else if (evt == 'Todos') {
      itemsChart.value = [
        {
          color: '#FF5C00',
          name: 'Jitter',
          data: items.value.map((x) => {
            return { title: `${x.dataH} ${x.hora}:00h`, value: x.mediaJitter };
          }),
        },
        {
          color: '#FF9797',
          name: 'Latência',
          data: items.value.map((x) => {
            return { title: `${x.dataH} ${x.hora}:00h`, value: x.mediaLatency };
          }),
        },
        {
          color: '#283747',
          name: 'Perda de Pacotes',
          data: items.value.map((x) => {
            return { title: `${x.dataH} ${x.hora}:00h`, value: x.mediaPacketLoss };
          }),
        },
      ];
    }
  } else if (filter.value.relatory == 2) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Total inatividade',
        data: items.value.map((x) => {
          return {
            title: `${x.linkservico}`,
            value: x.tempo_total_inatividade ? Number(x.tempo_total_inatividade.replace(/:/g, '')) : 0,
          };
        }),
      },
    ];
  } else if (filter.value.relatory == 4) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Porcentagem',
        data: items.value.map((x) => {
          return { title: `${x.linkservico}`, value: x.status_percentage ? x.status_percentage : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 5) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'latência',
        data: items.value.map((x) => {
          return { title: `dia - ${x.dia} ${x.hora}:${x.minuto}h`, value: x.latencia ? x.latencia : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 6) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'latência média',
        data: items.value.map((x) => {
          return { title: `${x.linkservico}`, value: x.latencia_media ? x.latencia_media : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 7) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Tempo total',
        data: items.value.map((x) => {
          return { title: `dia - ${x.dia} ${x.hora}:${x.minuto}h`, value: x.disponibilidade ? x.disponibilidade : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 9) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Desvio padrão de latência',
        data: items.value.map((x) => {
          return { title: `dia - ${x.dia} ${x.hora}h`, value: x.desvio_padrao_latencia ? x.desvio_padrao_latencia : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 10) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Latência',
        data: items.value.map((x) => {
          return { title: `dia - ${x.dia} ${x.hora}h`, value: x.latencia ? x.latencia : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 11) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Latência Média',
        data: items.value.map((x) => {
          return { title: `dia - ${x.dia} ${x.hora}h`, value: x.latencia_media ? x.latencia_media : 0 };
        }),
      },
      {
        color: '#FF5C00',
        name: 'Taxa de Disponibilidade',
        data: items.value.map((x) => {
          return { title: `dia - ${x.dia} ${x.hora}h`, value: x.taxa_disponibilidade ? x.taxa_disponibilidade : 0 };
        }),
      },
      {
        color: '#283747',
        name: 'Taxa de indisponibilidade',
        data: items.value.map((x) => {
          return { title: `dia - ${x.dia} ${x.hora}h`, value: x.taxa_indisponibilidade ? x.taxa_indisponibilidade : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 12) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Custo Total',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.custoTotal ? x.custoTotal : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 13) {
    itemsChart.value = [
      {
        color: '#FF9797',
        name: 'Valor de desconto',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.valorDesconto ? x.valorDesconto : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 14) {
    itemsChart.value = [
      {
        color: '#FF5733',
        name: 'Custo total atual',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.custoTotalAtual ? x.custoTotalAtual : 0 };
        }),
      },
      {
        color: '#33FF57',
        name: 'Custo total anterior',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.custoTotalAnterior ? x.custoTotalAnterior : 0 };
        }),
      },
      {
        color: '#3357FF',
        name: 'variação percentual de custos',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.variacaoPercentualCustos ? x.variacaoPercentualCustos : 0 };
        }),
      },
      {
        color: '#FF33B5',
        name: 'DEsconto total atual',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.descontoTotalAtual ? x.descontoTotalAtual : 0 };
        }),
      },
      {
        color: '#FFEB33',
        name: 'Desconto total anterior',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.descontoTotalAnterior ? x.descontoTotalAnterior : 0 };
        }),
      },
      {
        color: '#33FFF7',
        name: 'variação percentual de descontos',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.variacaoPercentualDescontos ? x.variacaoPercentualDescontos : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 15) {
    itemsChart.value = [
      {
        color: '#FF5733',
        name: 'Valor provisionado',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.valorprovisionado ? x.valorprovisionado : 0 };
        }),
      },
      {
        color: '#33FF57',
        name: 'Custo total',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.custoTotal ? x.custoTotal : 0 };
        }),
      },
      {
        color: '#3357FF',
        name: 'Desconto',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.desconto ? x.desconto : 0 };
        }),
      },
      {
        color: '#FF33B5',
        name: 'Valor PAgo',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.valorpago ? x.valorpago : 0 };
        }),
      },
      {
        color: '#FFEB33',
        name: 'Procentagem de economia',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.porcentagemEconomia ? x.porcentagemEconomia : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 16) {
    itemsChart.value = [
      {
        color: '#33FF57',
        name: 'Desconto total atual',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.descontoTotalAtual ? x.descontoTotalAtual : 0 };
        }),
      },
      {
        color: '#3357FF',
        name: 'Desconto total anterior',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.descontoTotalAnterior ? x.descontoTotalAnterior : 0 };
        }),
      },
      {
        color: '#FF33B5',
        name: 'Variação percentual',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.variacaoPercentual ? x.variacaoPercentual : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 17) {
    itemsChart.value = [
      {
        color: '#33FF57',
        name: 'Custo total atual',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.custoTotalAtual ? x.custoTotalAtual : 0 };
        }),
      },
      {
        color: '#3357FF',
        name: 'Custo total anterior',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.custoTotalAnterior ? x.custoTotalAnterior : 0 };
        }),
      },
      {
        color: '#FF33B5',
        name: 'Variação percentual',
        data: items.value.map((x) => {
          return { title: `${x.operadora}`, value: x.variacaoPercentual ? x.variacaoPercentual : 0 };
        }),
      },
    ];
  } else if (filter.value.relatory == 18) {
    itemsChart.value = [
      {
        color: '#33FF57',
        name: 'Valor da notificação',
        data: items.value.map((x) => {
          return {
            title: `${x.servicoLink}: ${x.tituloNotificacao}-${x.tipoNotificacao}`,
            value: x.valorNotificado ? x.valorNotificado : 0,
          };
        }),
      },
      {
        color: '#3357FF',
        name: 'Regra para valor de disparo',
        data: items.value.map((x) => {
          return {
            title: `${x.servicoLink}: ${x.tituloNotificacao}-${x.tipoNotificacao}`,
            value: x.valorParaDisparoRegra ? Number(x.valorParaDisparoRegra) : 0,
          };
        }),
      },
    ];
  } else if (filter.value.relatory == 19) {
    itemsChart.value = [
      {
        color: '#33FF57',
        name: 'Download',
        data: items.value.map((x) => {
          return {
            title: `${x['unidadenome']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['download_mbps_medio'] ?? 0,
          };
        }),
      },
      {
        color: '#3357FF',
        name: 'Upload',
        data: items.value.map((x) => {
          return {
            title: `${x['unidadenome']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['upload_mbps_medio'] ?? 0,
          };
        }),
      },
    ];
  } else if (filter.value.relatory == 20) {
    itemsChart.value = [
      {
        color: '#33FF57',
        name: 'Download',
        data: items.value.map((x) => {
          return {
            title: `${x['servicolink_nome']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['download_mbps_medio'] ?? 0,
          };
        }),
      },
      {
        color: '#3357FF',
        name: 'Upload',
        data: items.value.map((x) => {
          return {
            title: `${x['servicolink_nome']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['upload_mbps_medio'] ?? 0,
          };
        }),
      },
    ];
  } else if (filter.value.relatory == 21) {
    itemsChart.value = [
      {
        color: '#33FF57',
        name: 'Download',
        data: items.value.map((x) => {
          return {
            title: `${x['coletor_nome']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['download_mbps_medio'] ?? 0,
          };
        }),
      },
      {
        color: '#3357FF',
        name: 'Upload',
        data: items.value.map((x) => {
          return {
            title: `${x['coletor_nome']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['upload_mbps_medio'] ?? 0,
          };
        }),
      },
    ];
  } else if (filter.value.relatory == 22) {
    itemsChart.value = [
      {
        color: '#33FF57',
        name: 'Download',
        data: items.value.map((x) => {
          return {
            title: `${x['localidade']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['download_mbps_medio'] ?? 0,
          };
        }),
      },
      {
        color: '#3357FF',
        name: 'Upload',
        data: items.value.map((x) => {
          return {
            title: `${x['localidade']} - ${dateFormat(`${x.intervalo_tempo.split(' ').join('T')}:00`, 'dd/MM/yyyy HH:mm')}`,
            value: x['upload_mbps_medio'] ?? 0,
          };
        }),
      },
    ];
  }

  // loaderChartGeral.value = false;
}

// const exportToExcel = () => {
//   const XLSX = xlsx;
//   const workbook = XLSX.utils.book_new();
//   const worksheet = XLSX.utils.json_to_sheet(
//     items.value.map((f) => {
//       return {
//         Serviço: f.servico,
//         Médiajitter: f.mediaJitter,
//         MédiaLatencia: f.mediaLatency,
//         MédiaPerdaPacotes: f.mediaPacketLoss,
//         Hora: f.hora,
//         Data: f.dataH,
//       };
//     }),
//   );

//   XLSX.utils.book_append_sheet(workbook, worksheet, 'Planilha');

//   XLSX.writeFile(workbook, 'relatorio.xlsx');
//   Swal.fire({
//     text: 'Arquivo XLSX exportado!',
//     icon: 'success',
//     title: 'Sucesso',
//   });
// };

function handleSelect(event) {
  console.log(event);
  loadServices();
}
function loadServices() {
  if (filter.value.unidade) {
    getServicoPorUnidade(Number(filter.value.unidade)).then((f) => {
      const items = f;
      optionsServicos.value = items.map((ff) => {
        return { value: ff.id, text: ff.nome } as IOptionsSelect;
      });
    });
  }
}

function getServicos() {
  getServicosAll(0, 99999999, term.value).then((r) => {
    const items = r.lista
      ? r.lista.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect)
      : [];
    optionsServicos.value = items;
  });
}
function getColetors() {
  getColetoresAll(0, 99999999, term.value).then((r) => {
    const items = r.lista.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    let items2: any[] = [];

    r.lista.map((f) => {
      items2 = [
        ...items2,
        ...f.interfaceDispositivoLista.map((x) => ({ text: x.nome, value: x.internetLinkId }) as IOptionsSelect),
      ];
    });
    // .map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    optionsColetors.value = items;
    optionsLinks.value = items2;
  });
}

function getUnidades() {
  getUnidadeColetoresInstalados().then((r) => {
    const items = r.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    const items2 = r
      .filter((f) => f.archived == 0)
      .map((f) => ({ text: f.companhiaNome, value: f.companhiaId }) as IOptionsSelect);
    optionsCompany.value = items2;
    optionsUnidades.value = items;
  });
}
// function getCompany() {
//   getCompanhia(String(Authentication.state.id)).then((r) => {
//     const items = r.grupoLista
//       .filter((f) => f.archived == 0)
//       .map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
//     optionsCompany.value = items;
//     console.log('chegou aqui');
//     console.log(items);
//   });
// }

const paginationTableData = computed(() => {
  const data = items.value.slice((page.value - 1) * perPage.value, (page.value - 1) * perPage.value + perPage.value);
  return data;
});

function SearchRelatory() {
  Preloader.actions.ActionSetPreloader(true);
  loaderChartGeral.value = true;

  if (filter.value.relatory == 1) {
    RelatoryAll(dataDate.value.dataIni, dataDate.value.dataFim)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        items.value.map((x) => {
          x.mediaJitter = Math.ceil(Number(x.mediaJitter));
          x.mediaLatency = Math.ceil(Number(x.mediaLatency));
          x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 2) {
    RelatoryFullTime(filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }

  if (filter.value.relatory == 3) {
    RelatoryCosume(filter.value.servico, filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 4) {
    RelatoryPercent(filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 5) {
    RelatoryLatencyUnity(filter.value.unidade, filter.value.servico, filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 6) {
    RelatoryLatencyState(filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 7) {
    RelatoryAvailabilityGeral(filter.value.unidade, filter.value.servico, filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 8) {
    Relatoryconsumption(filter.value.servico, filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 9) {
    PerformanceVariation(filter.value.servico, filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 10) {
    PerformanceService(filter.value.servico, filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 11) {
    ComparationperformanceService(filter.value.servico, filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 12) {
    const [ano, mes] = filter.value.mouthAndYear.split('-');
    CostTotal(filter.value.unidade, Number(mes), Number(ano))
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 13) {
    const [ano, mes] = filter.value.mouthAndYear.split('-');
    DescontTotal(filter.value.unidade, Number(mes), Number(ano))
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 14) {
    const [ano, mes] = filter.value.mouthAndYear.split('-');
    PercentVariation(filter.value.unidade, Number(mes), Number(ano))
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 15) {
    const [ano, mes] = filter.value.mouthAndYear.split('-');
    PercentEconomic(filter.value.unidade, Number(mes), Number(ano))
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 16) {
    const [ano, mes] = filter.value.mouthAndYear.split('-');
    VariationMounthDescont(filter.value.unidade, Number(mes), Number(ano))
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 17) {
    const [ano, mes] = filter.value.mouthAndYear.split('-');
    VariationMounthCost(filter.value.unidade, Number(mes), Number(ano))
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }

        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 18) {
    HistoryNotification(filter.value.Time)
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }
        // items.value.map((x) => {
        //   x.mediaJitter = Math.ceil(Number(x.mediaJitter));
        //   x.mediaLatency = Math.ceil(Number(x.mediaLatency));
        //   x.mediaPacketLoss = Math.ceil(Number(x.mediaPacketLoss));
        // });

        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }



  if (filter.value.relatory == 19) {
    DesempenhoInternetPorUnidade()
      .then((r) => {
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }
        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }

  if (filter.value.relatory == 20) {
    DesempenhoInternetPorLinkServicos()
      .then((r) => {
        console.log(r);
      
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }
        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 21) {
    DesempenhoInternetPorColetor()
      .then((r) => {
        console.log(r);
      
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }
        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  if (filter.value.relatory == 22) {
    MediaVelocidadeInternetPorLocalidade()
      .then((r) => {
        console.log(r);
      
        items.value = r;
        if (items.value.length == 0) {
          Swal.fire({
            icon: 'warning',
            title: 'Atenção',
            text: 'Dados não encontrados',
          });
        }
        toggle(toggleMarcado.value);
      })
      .finally(() => {
        Preloader.actions.ActionSetPreloader(false);
        loaderChartGeral.value = false;
      });
  }
  // getCompany();

  totalRows.value = items.value.length;
}
onMounted(() => {
  getColetors();
  getServicos();
  getUnidades();
});

const __returned__ = { loaderChartGeral, perPage, page, dataDate, filter, totalRows, headers1, headers2, headers3, headers4, headers5, headers6, headers7, headers8, headers9, headers10, headers11, headers12, headers13, headers14, headers15, headers16, headers17, headers18, headers19, headers20, headers21, headers22, items, optionsLinks, optionsUnidades, optionsCompany, optionsColetors, optionsServicos, optionTimeServicos, optionTimeNotification, optionsRelatory, itemsChart, term, toggleMarcado, format, activeColor, headersResumoComputed, optionsList, clear, toggle, handleSelect, loadServices, getServicos, getColetors, getUnidades, paginationTableData, SearchRelatory, get AtomButton() { return AtomButton }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get RelatoryIcon() { return RelatoryIcon }, get SearchIconMobile() { return SearchIconMobile }, get timerIcon() { return timerIcon }, get ColsIcon() { return ColsIcon }, get exportIcon() { return exportIcon }, get OrganismsChart() { return OrganismsChart }, get MoleculeTable() { return MoleculeTable }, get MoleculeTitleSetings() { return MoleculeTitleSetings }, get MoleculeReorder() { return MoleculeReorder } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})