import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, renderSlot as _renderSlot, resolveComponent as _resolveComponent, resolveDynamicComponent as _resolveDynamicComponent, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "title" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_card_item = _resolveComponent("v-card-item")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    subtitle: $setup.props.subtitle,
    class: "border",
    elevation: $setup.props.elevation,
    height: $setup.props.height
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_title, {
        class: _normalizeClass(`${$setup.props.headerNoPaddingX || $setup.state.isMobile ? 'px-0' : ''}`)
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["d-flex header-card", {
          'justify-space-between': !$setup.props.hasTabs,
          'border-b': $setup.props.hasTabs && !$setup.state.isMobile,
          'flex-column px-0': $setup.state.isMobile,
          'align-center': !$setup.state.isMobile,
        }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass({
            'border-b pb-2 mb-3 px-2': $setup.state.isMobile,
          })
            }, [
              _createElementVNode("span", _hoisted_1, _toDisplayString($setup.props.title) + "  ", 1 /* TEXT */),
              (!$setup.state.isMobile && $setup.props.textTooltip)
                ? (_openBlock(), _createBlock($setup["AtomTooltip"], {
                    key: 0,
                    text: $setup.props.textTooltip ?? ''
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["AlertIcon"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true)
            ], 2 /* CLASS */),
            ($setup.props.hasDropdownList)
              ? (_openBlock(), _createBlock($setup["AtomSelect"], {
                  key: 0,
                  onOnChangeDropDownList: $setup.handleChangeDropdownList,
                  isCalendar: $props.isCalendarSelect,
                  options: $setup.props.optionsDropDown,
                  label: $setup.props.label,
                  class: "m-0"
                }, null, 8 /* PROPS */, ["isCalendar", "options", "label"]))
              : _createCommentVNode("v-if", true),
            ($setup.props.time)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString($setup.getters.time.value), 1 /* TEXT */))
              : _createCommentVNode("v-if", true),
            ($setup.props.hasToggle)
              ? (_openBlock(), _createBlock($setup["AtomToogleButton"], {
                  key: 2,
                  width: "172px",
                  buttons: $setup.props.optionsToggleButton ?? [],
                  onHandleToggle: $setup.handleChangeDropdownList,
                  class: "m-0"
                }, null, 8 /* PROPS */, ["buttons"]))
              : _createCommentVNode("v-if", true),
            ($setup.props.hasTabs)
              ? _renderSlot(_ctx.$slots, "tabs", { key: 3 }, undefined, true)
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["class"]),
      _createVNode(_component_v_card_item, {
        class: _normalizeClass(`${$setup.state.isMobile ? 'px-0' : ''}`)
      }, {
        default: _withCtx(() => [
          _withDirectives((_openBlock(), _createBlock(_resolveDynamicComponent($setup.LoaderIcon), {
            color: $setup.props.loadingColor,
            class: "ma-auto w-100"
          }, null, 8 /* PROPS */, ["color"])), [
            [_vShow, $setup.props.loading]
          ]),
          _withDirectives(_createElementVNode("div", null, [
            _renderSlot(_ctx.$slots, "default", {}, undefined, true),
            ($setup.props.footer)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  (!$setup.props.noDividerFooter)
                    ? (_openBlock(), _createBlock(_component_v_divider, {
                        key: 0,
                        class: "mb-1"
                      }))
                    : _createCommentVNode("v-if", true),
                  _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true)
          ], 512 /* NEED_PATCH */), [
            [_vShow, !$setup.props.loading]
          ]),
          ($setup.props.hasTabs)
            ? _renderSlot(_ctx.$slots, "tabsMobile", { key: 0 }, undefined, true)
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["class"])
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["subtitle", "elevation", "height"]))
}