import { defineComponent as _defineComponent } from 'vue'
import { ref, reactive, watch } from 'vue';
import { DatePicker } from 'v-calendar';
import { format } from 'date-fns';
import { CalendarIcon } from '@/components/atoms';

interface IProps {
  color: string;
  icon: string;
  size: string;
  dateRange: { start: any; end: any };
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomDatePicker',
  props: {
    color: { type: String, required: true, default: 'warning' },
    icon: { type: String, required: true, default: 'mdi-alert-circle' },
    size: { type: String, required: true, default: 'small' },
    dateRange: { type: Object, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const date = ref(new Date());
date.value.setDate(new Date().getDate() + 35);
const range = reactive({
  start: null,
  end: null,
});

watch(
  () => props.dateRange,
  () => {
    range.start = props.dateRange.start;
    range.end = props.dateRange.end;
  }
);

const __returned__ = { props, date, range, get DatePicker() { return DatePicker }, get format() { return format }, get CalendarIcon() { return CalendarIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})