import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'

import { AlertIcon, AtomSelect, AtomTooltip, AtomToogleButton, LoaderIcon } from '@/components/atoms';
import { state } from '@/store/modules/layout';
import { getters } from '@/store/modules/layout';
import { IOptionsSelect, IToggleButon } from '@/types';

interface IProps {
  title: string;
  subtitle?: string;
  label?: string;
  loading?: boolean;
  textTooltip?: string;
  elevation?: string;
  headerHeight?: string;
  loadingColor?: string;
  hasDropdownList?: boolean;
  hasToggle?: boolean;
  hasTabs?: boolean;
  time?: boolean;
  footer?: boolean;
  optionsDropDown?: IOptionsSelect[];
  optionsToggleButton?: IToggleButon[];
  isCalendarSelect?: boolean;
  headerNoPaddingX?: boolean;
  noDividerFooter?: boolean;
  height?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeCard',
  props: {
    title: { type: String, required: true },
    subtitle: { type: String, required: false },
    label: { type: String, required: false },
    loading: { type: Boolean, required: false, default: false },
    textTooltip: { type: String, required: false },
    elevation: { type: String, required: false },
    headerHeight: { type: String, required: false, default: '72px' },
    loadingColor: { type: String, required: false },
    hasDropdownList: { type: Boolean, required: false },
    hasToggle: { type: Boolean, required: false },
    hasTabs: { type: Boolean, required: false, default: false },
    time: { type: Boolean, required: false },
    footer: { type: Boolean, required: false },
    optionsDropDown: { type: Array, required: false },
    optionsToggleButton: { type: Array, required: false },
    isCalendarSelect: { type: Boolean, required: false, default: false },
    headerNoPaddingX: { type: Boolean, required: false },
    noDividerFooter: { type: Boolean, required: false, default: false },
    height: { type: String, required: false }
  },
  emits: ['onChangeDropDownList'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

_useCssVars(_ctx => ({
  "f1c0f5f0-headerHeight": (_ctx.headerHeight)
}))

const emit = __emit;

const props = __props;
function handleChangeDropdownList(event) {
  emit('onChangeDropDownList', event);
}

const __returned__ = { emit, props, handleChangeDropdownList, get AlertIcon() { return AlertIcon }, get AtomSelect() { return AtomSelect }, get AtomTooltip() { return AtomTooltip }, get AtomToogleButton() { return AtomToogleButton }, get LoaderIcon() { return LoaderIcon }, get state() { return state }, get getters() { return getters } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})