import { defineComponent as _defineComponent } from 'vue'
import { onMounted, ref } from 'vue';
import { useLocalStorage } from '@vueuse/core';
import { AtomInput, DeletexIcon, EditPencil, AtomToogleButton } from '@/components/atoms';
import { Authentication } from '@/store/index';
import { atualizaDados } from '@/core/services/AuthService';
import { useTheme } from 'vuetify';
import Swal from 'sweetalert2';

export default /*@__PURE__*/_defineComponent({
  __name: 'FormLogin',
  setup(__props, { expose: __expose }) {
  __expose();

const theme = useTheme();
const isDarkMode = useLocalStorage('isDarkMode', false);

const darkMode = ref(isDarkMode.value);
const inputfile = ref<any>(null);
const email = ref<string>('');
const nome = ref<string>('');
const fileBase64 = ref<string>('');
const confirmSenha = ref<string>('');
const senha = ref<string>('');
const buttonsToggleTheme = ref([
  {
    text: 'Claro',
    value: 'light',
    bgColor: '#FF5C00',
    textColor: '#fff',
  },
  {
    text: 'Escuro',
    value: 'dark',
    bgColor: '#212529',
    textColor: '#fff',
  },
]);
onMounted(() => {
  email.value = Authentication.state.email as string;
  nome.value = Authentication.state.nome as string;
  fileBase64.value = Authentication.state.fotoBase64 as string;
});

const listError = ref<string[]>([]);

// function emptyinput(field: string) {
//   IconAdress.value = false;
//   user.value[field] = undefined;
//   userOld.value[field] = undefined;
// }

function handleThemeChange(value: string) {
  console.log(value);
  // isDarkMode.value = value === 'dark';
  // theme.global.name.value = isDarkMode.value
  //   ? 'myCustomDarkTheme'
  //   : 'myCustomLightTheme';

  darkMode.value = value == 'dark';
}

function changeImg(event: any) {
  const file = event.target.files[0];
  if (!file) {
    return;
  }
  const maxSizeInBytes = 1 * 1024 * 1024; // 1MB
  if (file.size > maxSizeInBytes) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Só são permitidas imagens até 1MB',
    });

    return;
  }

  const reader = new FileReader();

  reader.onload = function () {
    fileBase64.value = reader.result;
    inputfile.value = null;
  };
  reader.readAsDataURL(file);
}
function editName() {
  nome.value = '';
}
function handleSave() {
  console.log(email.value, nome.value, fileBase64.value);

  if (!nome.value) {
    Swal.fire({
      title: 'Atenção !',
      text: `Nome é campo obrigatório`,
      icon: 'warning',
    });
    return;
  }

  if (senha.value || confirmSenha.value) {
    if (!senha.value) {
      Swal.fire({
        title: 'Atenção !',
        text: `Senha está sem preenchimento, favor preencher para realização da Alteração de senha`,
        icon: 'warning',
      });
      return;
    } else if (!confirmSenha.value) {
      Swal.fire({
        title: 'Atenção !',
        text: `Confirmação de senha está sem preenchimento, favor preencher para realização da Alteração de senha`,
        icon: 'warning',
      });
      return;
    }

    if (senha.value != confirmSenha.value) {
      Swal.fire({
        title: 'Atenção !',
        text: `Confirmação de senha está diferente de Senha!`,
        icon: 'warning',
      });
      return;
    }
  }

  atualizaDados({
    id: Authentication.state.id,
    nomeUsuario: nome.value,
    fileContentBase64: fileBase64.value,
    novaSenha: senha.value ? senha.value : undefined,
    temaescuro: darkMode.value,
  }).then((f) => {
    const user = JSON.parse(JSON.stringify(Authentication.state));
    user.fotoBase64 = fileBase64.value;
    user.nome = nome.value;
    Authentication.actions.ActionSetUser(user);
    Swal.fire({
      title: 'Sucesso',
      text: f,
      icon: 'success',
    });
    isDarkMode.value = darkMode.value;
    theme.global.name.value = darkMode.value ? 'myCustomDarkTheme' : 'myCustomLightTheme';
    window.location.reload();
  });
}

const __returned__ = { theme, isDarkMode, darkMode, inputfile, email, nome, fileBase64, confirmSenha, senha, buttonsToggleTheme, listError, handleThemeChange, changeImg, editName, handleSave, get AtomInput() { return AtomInput }, get DeletexIcon() { return DeletexIcon }, get EditPencil() { return EditPencil }, get AtomToogleButton() { return AtomToogleButton } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})