import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, onMounted, watch } from 'vue';
import { MoleculeTitleSetings } from '@/components/molecules';
import {
  AtomLegend,
  SearchIcon,
  SearchIconMobile,
  AtomInput,
  AtomToogleButton,
  AtomCheckBox,
  AtomSubTitle,
  GraphIconColor,
} from '@/components/atoms';
import { OrganismsChart, OrganismsChartQuadrant, OrganismsPolarChart } from '@/components/organisms';
import { IDataChart, IDataChartMore, IDataPolarChart, IDataset, IOptionsSelect, IToggleButon } from '@/types';
import { state } from '@/store/modules/layout';
// import { state } from '@/store/modules/layout';
import {
  graficoQuadranteServico,
  graficoQuadranteUnidade,
  graficoQuadranteMediasServicoMin,
  graficoQuadranteMediasServicoDia,
  graficoQuadranteMediasServicoHora,
  graficoQuadranteColetor,
  graficoQuadrantePorTipoServico,
} from '@/core/services/GraficoService';
import { Authentication, Preloader } from '@/store';
import { getQuadrant } from '@/core/services/PredictService';
interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'QuadrantPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const buttonsTogglePredict = ref([
  {
    text: 'Atual',
    value: 'atual',
    bgColor: '#FF5C00',
    textColor: '#fff',
  },
  {
    text: 'Previsão',
    value: 'predict',
    bgColor: '#212529',
    textColor: '#fff',
  },
]);

const activeColor = computed(() => {
  const color = mediaQualiServicoTab.value === 'atual' ? '#FF5C00' : '#292E36';
  return color;
});
const props = __props;
const loadingQualidade = ref<boolean>(false);
const loadingDisponibilidade = ref<boolean>(false);
const loadingQuadrant = ref<boolean>(false);
const loadingCaminho = ref<boolean>(false);
const legendPolarChart = computed(() => {
  return filterQuadratEscolha.value == 'links'
    ? ['LNK - Link']
    : ['INT - Internet', 'SVI - Interno', 'SVE - Externo', 'SVN - Nuvem'];
});
const optionsListQualidade = computed((): IToggleButon[] => {
  return mediaQualiServicoTab.value == 'predict'
    ? [{ value: '5horas', text: '+ 5 Horas', selected: true }]
    : [
        { value: 'minuto', text: '30 Minutos', selected: true },
        { value: 'hora', text: '1 Hora' },
        { value: 'dia', text: 'Dia atual' },
      ];
});

const optionsListDisponibilidade = computed((): IToggleButon[] => {
  return mediaQualiServicoTab.value == 'predict'
    ? [{ value: '5horas', text: '+ 5 Horas', selected: true }]
    : [
        { value: 'minuto', text: '30 Minutos', selected: true },
        { value: 'hora', text: '1 Hora' },
        { value: 'dia', text: 'Dia atual' },
      ];
});
// const buttonsMediaQualidade = ref([
//   {
//     text: 'Atual',
//     value: 'atual',
//   },
//   // {
//   //   text: 'Previsão',
//   //   value: 'previsao',
//   // },
// ]);
const dataCharGeralQuali = ref<IDataChartMore[]>([
  {
    color: '#212529',
    name: 'Qualidade',
    data: [],
  },
]);

const dataCharGeralDispo = ref<IDataChartMore[]>([
  {
    color: '#FF5C00',
    name: 'Disponibilidade',
    data: [],
  },
]);

// const optionsList = ref<IOptionsSelect[]>([
//   { value: 'minuto', text: 'Minuto', selected: true },
//   { value: 'hora', text: 'Hora' },
//   { value: 'dia', text: 'Dia' },
// ]);

// const dataCharGeral = ref<IDataChartMore[]>([
//   {
//     color: '#FF5C00',
//     name: 'Disponibilidade',
//     data: [
//       { title: '14:00h', value: 40 },
//       { title: '14:00h', value: 38 },
//       { title: '14:00h', value: 28 },
//       { title: '14:00h', value: 22 },
//       { title: '14:00h', value: 22 },
//       { title: '14:00h', value: 29 },
//       { title: '14:00h', value: 20 },
//       { title: '14:00h', value: 15 },
//     ],
//   },
// ]);

const legends = ref([
  { color: '#FF0D21', label: 'Problemático', status: 'problematico' },
  { color: '#FF9E0D', label: 'Degradado', status: 'degradado' },
  { color: '#FFE70D', label: 'Instável', status: 'instavel' },
  { color: '#15D095', label: 'Saudável', status: 'saudavel' },
]);

const labels = ref(['Jun 2016', 'Jul 2016', 'Aug 2016', 'Sep 2016', 'Oct 2016']);

const dataSet = ref<IDataset[]>([]);
const relatorioInit = ref<string>('Coletores');

const search = ref('');
const buttonsCharts = computed(() => {
  const data: IToggleButon[] = [
    { text: 'Coletores', value: 'collector' },
    { text: 'Unidades', value: 'units' },
    { text: 'Links', value: 'links' },
    { text: 'Serviços', value: 'services' },
  ];

  if (mediaDispoServicoTab.value == 'atual') {
    return data;
  } else {
    return [{ text: 'Serviços', value: 'services' }];
  }
});
const dataPolar = ref<IDataPolarChart[]>([
  {
    label: 'Disponibilidade',
    data: [1, 2, 3, 4, 5, 6, 7, 8],
    borderColor: '#FF5C00',
    backgroundColor: '#FF5C0050',
  },
  {
    label: 'Qualidade',
    data: [1, 8, 2, 7, 3, 6, 4, 5],
    borderColor: '#212529',
    backgroundColor: '#21252950',
  },
]);
const filtradosQuadrant = computed(() => {
  return filterQuadratEscolha.value == 'units'
    ? filtersUnidades.value.filter((f) => f.text.includes(search.value))
    : filterQuadratEscolha.value == 'collector'
      ? filtersColetores.value.filter((f) => f.text.includes(search.value))
      : filtersQuadrant.value.filter((f) => f.text.includes(search.value));
});
const dataCharArea = ref<IDataChart[]>([
  { title: '14:35h', value: 150 },
  { title: '14:43h', value: 149 },
]);

const filterQuadratEscolha = ref<string>('collector');
const filtersUnidades = ref<IOptionsSelect[]>([]);
const filtersColetores = ref<IOptionsSelect[]>([]);

const dataSetUnits = ref<IDataset[]>([]);
const dataSetCollectors = ref<IDataset[]>([]);
const filtersQuadrant = ref<IOptionsSelect[]>([]);

const mediaQualiServicoTab = ref('atual');
const mediaDispoServicoTab = ref('atual');

const predict = ref<any[]>([]);

// const optionsListQuali = computed(() => {
//   return mediaQualiServicoTab.value == 'predict'
//     ? ([
//         { value: 'proxHoras', text: 'Próx. 6 horas', selected: true },
//         { value: 'proxDia', text: 'Próx. Dia' },
//         { value: 'proxSemana', text: 'Próx. Semana' },
//       ] as IOptionsSelect[])
//     : ([
//         { value: 'minuto', text: 'Minuto', selected: true },
//         { value: 'hora', text: 'Hora' },
//         { value: 'dia', text: 'Dia' },
//       ] as IOptionsSelect[]);
// });

// const optionsListDispo = computed(() => {
//   return mediaDispoServicoTab.value == 'previsao'
//     ? ([
//         { value: 'proxHoras', text: 'Próx. 6 horas', selected: true },
//         { value: 'proxDia', text: 'Próx. Dia' },
//         { value: 'proxSemana', text: 'Próx. Semana' },
//       ] as IOptionsSelect[])
//     : ([
//         { value: 'minuto', text: 'Minuto', selected: true },
//         { value: 'hora', text: 'Hora' },
//         { value: 'dia', text: 'Dia' },
//       ] as IOptionsSelect[]);
// });
const dataSetFiltrado = computed(() => {
  if (filterQuadratEscolha.value == 'units') {
    if (filtersUnidades.value.find((f) => f.selected == true)) {
      return dataSetUnits.value.filter((f) =>
        filtersUnidades.value.find((x) => x.text == f.label && x.selected == true),
      ) as IDataset[];
    } else {
      return dataSetUnits.value as IDataset[];
    }
  } else if (filterQuadratEscolha.value == 'collector') {
    if (filtersColetores.value.find((f) => f.selected == true)) {
      return dataSetCollectors.value.filter((f) =>
        filtersColetores.value.find((x) => x.text == f.label && x.selected == true),
      ) as IDataset[];
    } else {
      return dataSetCollectors.value as IDataset[];
    }
  } else {
    if (filtersQuadrant.value.find((f) => f.selected == true)) {
      return dataSet.value.filter((f) =>
        filtersQuadrant.value.find((x) => x.text == f.label && x.selected == true),
      ) as IDataset[];
    } else {
      return dataSet.value as IDataset[];
    }
  }
});

const idChartQuadrant = ref<string>('collector_geral');

//

function handleFoceCheckAll(event) {
  if (filterQuadratEscolha.value == 'units') {
    for (const iterator of filtersUnidades.value) {
      const index = filtersUnidades.value.findIndex((f) => f.value == iterator.value);
      filtersUnidades.value[index].selected = event.target.checked;
    }
  } else if (filterQuadratEscolha.value == 'collector') {
    for (const iterator of filtersColetores.value) {
      const index = filtersColetores.value.findIndex((f) => f.value == iterator.value);
      filtersColetores.value[index].selected = event.target.checked;
    }
  } else {
    for (const iterator of filtersQuadrant.value) {
      const index = filtersQuadrant.value.findIndex((f) => f.value == iterator.value);
      filtersQuadrant.value[index].selected = event.target.checked;
    }
  }
}

function handleFoceCheck(filter: IOptionsSelect) {
  if (filterQuadratEscolha.value == 'units') {
    const index = filtersUnidades.value.findIndex((f) => f.value == filter.value);
    filtersUnidades.value[index].selected = !filter.selected;
  } else if (filterQuadratEscolha.value == 'collector') {
    const index = filtersColetores.value.findIndex((f) => f.value == filter.value);
    filtersColetores.value[index].selected = !filter.selected;
  } else {
    const index = filtersQuadrant.value.findIndex((f) => f.value == filter.value);
    filtersQuadrant.value[index].selected = !filter.selected;
  }
}
function handleAlterToogle(event: string) {
  filterQuadratEscolha.value = event;
  idChartQuadrant.value = `${event}_geral`;
  relatorioInit.value = buttonsCharts.value.find((f) => f.value == event)?.text ?? '';
  loadDados(event);
}

function toggleMediaQualidade(toggle: string) {
  mediaQualiServicoTab.value = toggle;
  GetDataQualidade();
}

function handleMediaQualidadeChange(toggle: string) {
  if (filterMediaQualidade.value == toggle) return;
  filterMediaQualidade.value = toggle;

  GetDataQualidade();
}
function toggleMediaDisponibilidade(toggle: string) {
  mediaDispoServicoTab.value = toggle;

  GetDataDisponibilidade();
}

const filterMediaDisponibilidade = ref<string>('minuto');
const filterMediaQualidade = ref<string>('minuto');

function handleMediaDisponibilidadeChange(toggle: string) {
  if (filterMediaDisponibilidade.value == toggle) return;

  filterMediaDisponibilidade.value = toggle;
  GetDataDisponibilidade();
}

async function loadDados(rodar: string) {
  try {
    loadingQuadrant.value = true;
    if (rodar == 'collector') {
      const dadosColetor = await graficoQuadranteColetor(Authentication.state.id as number);

      dataSetCollectors.value = dadosColetor.map((x) => {
        const legen = legends.value.find((f) => f.status == x.status);
        const dado = {
          label: x.collector_name,
          id: x.max_id,
          legenda: legen?.label,
          backgroundColor: legen?.color,
          borderColor: legen?.color,
          pointStyle: 'rectRounded',
          pointRadius: 8,
          data: [{ x: x.media_qualidade, y: x.media_disponibilidade }],
          dado: x,
        };

        return dado;
      });

      filtersColetores.value = dadosColetor
        .map((x) => x.collector_name)
        .filter((value, index, array) => {
          return array.indexOf(value) === index;
        })
        .map((x) => {
          return { value: x, text: x } as IOptionsSelect;
        });
      filtersColetores.value.map((x) => {
        x['id'] = +dadosColetor.find((f) => f.collector_name == x.value).collector_token;
      });
    } else if (rodar == 'services') {
      if (mediaDispoServicoTab.value == 'predict') {
        // await loadPredict();
        if (!predict.value || !predict.value.length) return;

        // Agrupando os itens por tempo (time)
        const groupedItems = predict.value
          .filter((f) => f.type == 'SRV')
          .reduce((groups, item) => {
            const group = groups[item.time] || [];
            group.push(item);
            groups[item.time] = group;
            return groups;
          }, {});

        const dist = predict.value
          .filter((f) => f.type == 'SRV')
          .map((x) => x.name)
          .filter((value, index, array) => {
            return array.indexOf(value) === index;
          });

        // Encontrando o item com o maior quality em cada grupo

        const result: any[] = [];

        dist.map((y) => {
          Object.keys(groupedItems).forEach((time) => {
            const group = groupedItems[time];
            const xpto = group.filter((x) => x.name == y);

            if (xpto.length) {
              const maxQualityItem = xpto.reduce((maxItem, currentItem) => {
                return currentItem.quality > maxItem.quality ? currentItem : maxItem;
              });
              result.push(maxQualityItem);
            }
          });
        });
        dataSet.value = result.map((x) => {
          const legen = legends.value.find((f) => f.status == x.status);
          const dado = {
            label: x.name,
            id: x.client,
            legenda: legen?.label,
            backgroundColor: legen?.color,
            borderColor: legen?.color,
            pointStyle: 'rectRounded',
            pointRadius: 8,
            data: [{ x: x.quality, y: x.availability }],
            dado: x,
          };

          return dado;
        });
        dataSet.value = dataSet.value.filter((value, index, array) => {
          return array.findIndex((f) => f.label == value.label) === index;
        });
        filtersQuadrant.value = predict.value
          .map((x) => x.name)
          .filter((value, index, array) => {
            return array.indexOf(value) === index;
          })
          .map((x) => {
            return { value: x, text: x } as IOptionsSelect;
          });

        filtersQuadrant.value.map((x) => {
          x['id'] = predict.value.find((f) => f.name == x.value).client;
        });
      } else {
        const dadosServicos = await graficoQuadranteServico(Authentication.state.id as number, 'SRV');
        dataSet.value = dadosServicos.map((x) => {
          const legen = legends.value.find((f) => f.status == x.status);
          const dado = {
            label: x.service_name,
            id: x.service_id,
            legenda: legen?.label,
            backgroundColor: legen?.color,
            borderColor: legen?.color,
            pointStyle: 'rectRounded',
            pointRadius: 8,
            data: [{ x: x.media_qualidade, y: x.media_disponibilidade }],
            dado: x,
          };

          return dado;
        });

        filtersQuadrant.value = dadosServicos
          .map((x) => x.service_name)
          .filter((value, index, array) => {
            return array.indexOf(value) === index;
          })
          .map((x) => {
            return { value: x, text: x } as IOptionsSelect;
          });

        filtersQuadrant.value.map((x) => {
          x['id'] = dadosServicos.find((f) => f.service_name == x.value).service_id;
        });
      }
    } else if (rodar == 'links') {
      const dadosServicos = await graficoQuadranteServico(Authentication.state.id as number, 'LNK');
      dataSet.value = dadosServicos.map((x) => {
        const legen = legends.value.find((f) => f.status == x.status);
        const dado = {
          label: x.service_name,
          id: x.service_id,
          legenda: legen?.label,
          backgroundColor: legen?.color,
          borderColor: legen?.color,
          pointStyle: 'rectRounded',
          pointRadius: 8,
          data: [{ x: x.media_qualidade, y: x.media_disponibilidade }],
          dado: x,
        };

        return dado;
      });

      filtersQuadrant.value = dadosServicos
        .map((x) => x.service_name)
        .filter((value, index, array) => {
          return array.indexOf(value) === index;
        })
        .map((x) => {
          return { value: x, text: x } as IOptionsSelect;
        });

      filtersQuadrant.value.map((x) => {
        x['id'] = dadosServicos.find((f) => f.service_name == x.value).service_id;
      });
    } else if (rodar == 'units') {
      const dadosUnits = await graficoQuadranteUnidade(Authentication.state.id as number);
      dataSetUnits.value = dadosUnits.map((x) => {
        const legen = legends.value.find((f) => f.status == x.status);
        const dado = {
          label: x.client_unity_name,
          id: x.client_unit_id,
          legenda: legen?.label,
          backgroundColor: legen?.color,
          borderColor: legen?.color,
          pointStyle: 'rectRounded',
          pointRadius: 8,
          data: [{ x: x.media_qualidade, y: x.media_disponibilidade }],
          dado: x,
        };

        return dado;
      });

      filtersUnidades.value = dadosUnits
        .map((x) => x.client_unity_name)
        .filter((value, index, array) => {
          return array.indexOf(value) === index;
        })
        .map((x) => {
          return { value: x, text: x } as IOptionsSelect;
        });

      filtersUnidades.value.map((x) => {
        x['id'] = dadosUnits.find((f) => f.client_unity_name == x.value).client_unit_id;
      });
    }
  } finally {
    loadingQuadrant.value = false;
  }
}

function GetDataQuadrant() {
  loadDados('collector');
  // intervalQuad.value = setInterval(() => {
  //   loadDados();
  // }, 120000);
}

async function GetDataDisponibilidade() {
  let dados: IDataChart[] = [];

  try {
    loadingDisponibilidade.value = true;
    if (mediaQualiServicoTab.value == 'predict') {
      // await loadPredict();
      const dist = predict.value
        .map((x) => x.name)
        .filter((value, index, array) => {
          return array.indexOf(value) === index;
        });

      dataCharGeralDispo.value = dist.map((x) => {
        return {
          color: '',
          name: x,
          data: predict.value
            .filter((f) => f.name == x)
            .map((x) => {
              return {
                title: `${x.time}`,
                value: x.availability,
              } as IDataChart;
            }),
        } as IDataChartMore;
      });
    } else {
      dataCharGeralDispo.value = [
        {
          color: '#FF5C00',
          name: 'Disponibilidade',
          data: [],
        },
      ];
      if (filterMediaDisponibilidade.value == 'minuto') {
        const f = await graficoQuadranteMediasServicoMin(Authentication.state.id as number, filterQuadratEscolha.value);

        dados = f.map((x) => {
          const dado = {
            title: `${x.hour_time}:${x.minute_time}`,
            value: x.disponibilidade,
          } as IDataChart;

          return dado;
        });
      } else if (filterMediaDisponibilidade.value == 'hora') {
        const f = await graficoQuadranteMediasServicoHora(
          Authentication.state.id as number,
          filterQuadratEscolha.value,
        );
        dados = f.map((x) => {
          const dado = {
            title: `${x.hour_time}:00`,
            value: x.disponibilidade,
          } as IDataChart;

          return dado;
        });
      }
      if (filterMediaDisponibilidade.value == 'dia') {
        const f = await graficoQuadranteMediasServicoDia(Authentication.state.id as number, filterQuadratEscolha.value);
        dados = f.map((x) => {
          const dado = {
            title: `${x.day_time}/${x.month_time}`,
            value: x.disponibilidade,
          } as IDataChart;

          return dado;
        });
      }
      dataCharGeralDispo.value[0].color = activeColor.value;
      dataCharGeralDispo.value[0].data = dados;
    }
  } finally {
    loadingDisponibilidade.value = false;
  }
}
async function loadPredict() {
  try {
    const id = Authentication.state.id ?? 187;
    let data = { data: null };
    Preloader.actions.ActionSetPreloader(true);
    while (data.data == null) {
      data = await getQuadrant(id);
    }

    predict.value = data.data;

    GetDataQualidade();
    GetDataDisponibilidade();
    loadDados('services');
  } finally {
    Preloader.actions.ActionSetPreloader(false);
  }
}
async function GetDataQualidade() {
  let dados: IDataChart[] = [];
  try {
    loadingQualidade.value = true;

    const filters =
      filterQuadratEscolha.value == 'units'
        ? filtersUnidades.value.filter((f) => f.selected).map((x) => x['id'])
        : filterQuadratEscolha.value == 'collector'
          ? filtersColetores.value.filter((f) => f.selected).map((x) => x['id'])
          : filtersQuadrant.value.filter((f) => f.selected).map((x) => x['id']);

    if (mediaQualiServicoTab.value == 'predict') {
      // await loadPredict();

      const dist = predict.value
        .map((x) => x.name)
        .filter((value, index, array) => {
          return array.indexOf(value) === index;
        });

      dataCharGeralQuali.value = dist.map((x) => {
        return {
          color: '',
          name: x,
          data: predict.value
            .filter((f) => f.name == x)
            .map((x) => {
              return {
                title: `${x.time}`,
                value: x.quality,
              } as IDataChart;
            }),
        } as IDataChartMore;
      });
    } else {
      dataCharGeralQuali.value = [
        {
          color: '#212529',
          name: 'Qualidade',
          data: [],
        },
      ];

      if (filterMediaQualidade.value == 'minuto') {
        const f = await graficoQuadranteMediasServicoMin(
          Authentication.state.id as number,
          filterQuadratEscolha.value,
          filters,
        );
        dados = f.map((x) => {
          const dado = {
            title: `${x.hour_time}:${x.minute_time}`,
            value: x.qualidade,
          } as IDataChart;

          return dado;
        });
      } else if (filterMediaQualidade.value == 'hora') {
        const f = await graficoQuadranteMediasServicoHora(
          Authentication.state.id as number,
          filterQuadratEscolha.value,
          filters,
        );
        dados = f.map((x) => {
          const dado = {
            title: `${x.hour_time}:00`,
            value: x.qualidade,
          } as IDataChart;

          return dado;
        });
      }

      if (filterMediaQualidade.value == 'dia') {
        const f = await graficoQuadranteMediasServicoDia(
          Authentication.state.id as number,
          filterQuadratEscolha.value,
          filters,
        );
        dados = f.map((x) => {
          const dado = {
            title: `${x.day_time}/${x.month_time}`,
            value: x.qualidade,
          } as IDataChart;

          return dado;
        });
      }

      dataCharGeralQuali.value[0].color = activeColor.value;
      dataCharGeralQuali.value[0].data = dados;
    }
  } finally {
    loadingQualidade.value = false;
  }
}

function randomicPolar() {
  loadingCaminho.value = true;
  const filters =
    filterQuadratEscolha.value == 'units'
      ? filtersUnidades.value.filter((f) => f.selected).map((x) => x['id'])
      : filterQuadratEscolha.value == 'collector'
        ? filtersColetores.value.filter((f) => f.selected).map((x) => x['id'])
        : filtersQuadrant.value.filter((f) => f.selected).map((x) => x['id']);
  graficoQuadrantePorTipoServico(Authentication.state.id as number, filterQuadratEscolha.value, filters)
    .then((resp) => {
      if (resp) {
        dataPolar.value[0].data = resp.map((f) => f.media_disponibilidade as number);
        dataPolar.value[1].data = resp.map((f) => f.media_qualidade as number);
      }
    })
    .finally(() => {
      loadingCaminho.value = false;
    });
}
const interval = ref<any>(null);
const intervalQuad = ref<any>(null);
onMounted(() => {
  GetDataQuadrant();
  randomicPolar();

  interval.value = setInterval(() => {
    GetDataQualidade();
    GetDataDisponibilidade();
    randomicPolar();
  }, 60000);
});

/*
 if (filterQuadratEscolha.value == 'units') {
    for (const iterator of filtersUnidades.value) {
      const index = filtersUnidades.value.findIndex((f) => f.value == iterator.value);
      filtersUnidades.value[index].selected = event.target.checked;
    }

  } else if (filterQuadratEscolha.value == 'collector') {
    for (const iterator of filtersUnidades.value) {
      const index = filtersColetores.value.findIndex((f) => f.value == iterator.value);
      filtersColetores.value[index].selected = event.target.checked;
    }

  } else {
    for (const iterator of filtersQuadrant.value) {
      const index = filtersQuadrant.value.findIndex((f) => f.value == iterator.value);
      filtersQuadrant.value[index].selected = event.target.checked;
    }
  } */

watch(
  () => mediaDispoServicoTab.value,
  () => {
    if (mediaQualiServicoTab.value == 'atual') {
      toggleMediaDisponibilidade(mediaDispoServicoTab.value);
      loadDados('services');
    } else {
      filterQuadratEscolha.value = 'services';
      loadPredict();
    }
  },
);
watch(
  () => mediaQualiServicoTab.value,
  () => {
    if (mediaQualiServicoTab.value == 'atual') {
      toggleMediaQualidade(mediaQualiServicoTab.value);
      loadDados('services');
    } else {
      if (filterQuadratEscolha.value != 'services') {
        filterQuadratEscolha.value = 'services';
        loadPredict();
      }
    }
  },
);
watch(
  () => filterQuadratEscolha.value,
  () => {
    GetDataQualidade();
    GetDataDisponibilidade();
    randomicPolar();
  },
);
watch(
  () => filtersUnidades.value,
  () => {
    GetDataQualidade();
    GetDataDisponibilidade();
    randomicPolar();
  },
  { deep: true },
);

watch(
  () => filtersColetores.value,
  () => {
    GetDataQualidade();
    GetDataDisponibilidade();
    randomicPolar();
  },
  { deep: true },
);
watch(
  () => filtersQuadrant.value,
  () => {
    GetDataQualidade();
    GetDataDisponibilidade();
    randomicPolar();
  },
  { deep: true },
);
watch(
  () => props.activeTab,
  () => {
    if (props.activeTab != 'quadrant') {
      clearInterval(interval.value);
      clearInterval(intervalQuad.value);
    } else {
      GetDataQuadrant();

      randomicPolar();

      interval.value = setInterval(() => {
        GetDataQualidade();
        GetDataDisponibilidade();
        randomicPolar();
      }, 60000);
    }
  },
);

const __returned__ = { buttonsTogglePredict, activeColor, props, loadingQualidade, loadingDisponibilidade, loadingQuadrant, loadingCaminho, legendPolarChart, optionsListQualidade, optionsListDisponibilidade, dataCharGeralQuali, dataCharGeralDispo, legends, labels, dataSet, relatorioInit, search, buttonsCharts, dataPolar, filtradosQuadrant, dataCharArea, filterQuadratEscolha, filtersUnidades, filtersColetores, dataSetUnits, dataSetCollectors, filtersQuadrant, mediaQualiServicoTab, mediaDispoServicoTab, predict, dataSetFiltrado, idChartQuadrant, handleFoceCheckAll, handleFoceCheck, handleAlterToogle, toggleMediaQualidade, handleMediaQualidadeChange, toggleMediaDisponibilidade, filterMediaDisponibilidade, filterMediaQualidade, handleMediaDisponibilidadeChange, loadDados, GetDataQuadrant, GetDataDisponibilidade, loadPredict, GetDataQualidade, randomicPolar, interval, intervalQuad, get MoleculeTitleSetings() { return MoleculeTitleSetings }, get AtomLegend() { return AtomLegend }, get SearchIcon() { return SearchIcon }, get SearchIconMobile() { return SearchIconMobile }, get AtomInput() { return AtomInput }, get AtomToogleButton() { return AtomToogleButton }, get AtomCheckBox() { return AtomCheckBox }, get AtomSubTitle() { return AtomSubTitle }, get GraphIconColor() { return GraphIconColor }, get OrganismsChart() { return OrganismsChart }, get OrganismsChartQuadrant() { return OrganismsChartQuadrant }, get OrganismsPolarChart() { return OrganismsPolarChart }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})