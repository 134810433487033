import { createElementVNode as _createElementVNode, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "border rounded-lg pa-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "d-flex justify-space-between w-100",
      onClick: _withModifiers($setup.handleToggle, ["prevent"])
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", null, "Orientações", -1 /* HOISTED */)),
      _createVNode($setup["ArrowDown3Icon"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.infos, (info, index) => {
      return (_openBlock(), _createElementBlock("p", {
        key: String(index)
      }, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "dot bg-primary" }, null, -1 /* HOISTED */)),
        _createTextVNode(" " + _toDisplayString(info), 1 /* TEXT */)
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}