import { defineComponent as _defineComponent } from 'vue'
import html2pdf from 'html2pdf.js';
import { watch, ref, onMounted } from 'vue';
import { MoleculeCard } from '@/components/molecules';
import { AtomToogleButton, AtomButton, exportIcon } from '@/components/atoms';
import VueHighcharts from 'vue3-highcharts';
import { IDataChart, IDataChartMore, IOptionsSelect, IToggleButon } from '@/types';
import { state } from '@/store/modules/layout';

interface IProps {
  textEmpty?: string;
  title: string;
  legend: boolean;
  headerNoPaddingX?: boolean;
  showInfoTitle?: boolean;
  data: Array<IDataChart> | IDataChartMore[];
  type: string;
  textX?: string;
  textValueTitle?: string;
  valueTitle?: number;
  textY?: string;
  label?: string;
  hasToggle?: boolean;
  hasToggleHeader?: boolean;
  noCard?: boolean;
  select?: boolean;
  buttons?: IToggleButon[];
  hasDropdownList?: boolean;
  optionsDropDown?: IOptionsSelect[];
  colorSeries: string;
  backgroundColor?: string;
  height?: number;
  hasTabs?: boolean;
  loading?: boolean;
  footer?: boolean;
  PdfBtn?: boolean;
  regexMostrarY?: () => string;
  regexMostrarX?: () => string;
  isCalendarSelect?: boolean;
  textTooltip?: string;
  loadingColor?: string;
  isChartArray?: boolean;
  heightCard?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsChart',
  props: {
    textEmpty: { type: String, required: false },
    title: { type: String, required: true, default: '' },
    legend: { type: Boolean, required: true, default: false },
    headerNoPaddingX: { type: Boolean, required: false, default: false },
    showInfoTitle: { type: Boolean, required: false, default: false },
    data: { type: Array, required: true },
    type: { type: String, required: true },
    textX: { type: String, required: false },
    textValueTitle: { type: String, required: false },
    valueTitle: { type: Number, required: false },
    textY: { type: String, required: false },
    label: { type: String, required: false },
    hasToggle: { type: Boolean, required: false },
    hasToggleHeader: { type: Boolean, required: false },
    noCard: { type: Boolean, required: false, default: false },
    select: { type: Boolean, required: false },
    buttons: { type: Array, required: false },
    hasDropdownList: { type: Boolean, required: false },
    optionsDropDown: { type: Array, required: false },
    colorSeries: { type: String, required: true, default: '#000' },
    backgroundColor: { type: String, required: false, default: '#fff' },
    height: { type: Number, required: false },
    hasTabs: { type: Boolean, required: false, default: false },
    loading: { type: Boolean, required: false },
    footer: { type: Boolean, required: false },
    PdfBtn: { type: Boolean, required: false },
    regexMostrarY: { type: Function, required: false },
    regexMostrarX: { type: Function, required: false },
    isCalendarSelect: { type: Boolean, required: false, default: false },
    textTooltip: { type: String, required: false },
    loadingColor: { type: String, required: false },
    isChartArray: { type: Boolean, required: false, default: false },
    heightCard: { type: String, required: false }
  },
  emits: ['handleToggle', 'onChangeDropDownList'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const isEmpty = ref<boolean>(false);
const props = __props;
const emit = __emit;
const chartOptions = ref<any>(undefined);

const series = ref<any[]>([]);
const categories = ref<any[] | undefined>([]);

function changeChartOptions() {
  const yAxisLabel = props.regexMostrarY
    ? {
        labels: {
          formatter: props.regexMostrarY,
        },
      }
    : {};

  const xAxisLabel = props.regexMostrarX
    ? {
        labels: {
          formatter: props.regexMostrarX,
        },
      }
    : {};

  if (props.data[0]) {
    if ('name' in props.data[0] && !props.isChartArray) {
      const data = props.data as IDataChartMore[];

      series.value = data.map((f) => {
        return {
          showInLegend: props.legend,
          name: f.name,
          color: f.color,
          data: f.data.map((f) => f.value),
        };
      });

      isEmpty.value = data.filter((f) => f.data.length == 0).length == data.length;
      categories.value = data.map((f) => f.data.map((f) => f.title))[0];
    } else {
      series.value = [
        {
          showInLegend: props.legend,
          name: props.textX,
          data: (props.data as IDataChart[]).map((f) => f.value),
          color: props.colorSeries,
        },
      ];
      isEmpty.value = (props.data as IDataChart[]).length == 0;
      categories.value = (props.data as IDataChart[]).map((f) => f.title);
    }
  } else {
    series.value = [
      {
        showInLegend: props.legend,
        name: props.textX,
        data: (props.data as IDataChart[]).map((f) => f.value),
        color: props.colorSeries,
      },
    ];
    isEmpty.value = (props.data as IDataChart[]).length == 0;
    categories.value = (props.data as IDataChart[]).map((f) => f.title);
  }
  chartOptions.value = {
    chart: {
      backgroundColor: props.backgroundColor,
      type: props.type,
      height: props.height,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: categories.value,
      ...xAxisLabel,
    },
    yAxis: {
      title: {
        text: props.textY,
      },
      ...yAxisLabel,
    },
    plotOptions: {
      series: {
        borderRadius: 18,
      },
    },
    series: series.value,
    tooltip: {
      formatter: !props.regexMostrarY ? undefined : props.regexMostrarY,
    },
  };
}
function exportToPDF() {
  html2pdf(document.getElementById('element-to-convert'), {
    margin: 0,
    padding: 1,
    filename: 'Relatorio-grafico.pdf',
    image: { type: 'jpeg', quality: 0.98 },
    html2canvas: { scale: 1 },
    jsPDF: { unit: 'in', format: 'tabloid', orientation: 'landscape' },
  });
}

function handleToogleChange($event) {
  emit('handleToggle', $event);
}

function handleChangeDropdownList(event) {
  emit('onChangeDropDownList', event);
}

watch(
  () => props.data,
  () => {
    chartOptions.value = undefined;
    setTimeout(() => {
      changeChartOptions();
    }, 300);
  },
  { deep: true },
);

onMounted(() => {
  changeChartOptions();
});

const __returned__ = { isEmpty, props, emit, chartOptions, series, categories, changeChartOptions, exportToPDF, handleToogleChange, handleChangeDropdownList, get MoleculeCard() { return MoleculeCard }, get AtomToogleButton() { return AtomToogleButton }, get AtomButton() { return AtomButton }, get exportIcon() { return exportIcon }, get VueHighcharts() { return VueHighcharts }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})