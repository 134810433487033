import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M8.95358 4.0395L5.14747 0.233395C5.07566 0.161581 4.98333 0.100027 4.88074 0.0589907C4.87048 0.0589907 4.86022 0.0589907 4.84996 0.0487317C4.76789 0.0179546 4.68582 -0.00256348 4.59349 -0.00256348C4.3883 -0.00256348 4.19338 0.0795089 4.04976 0.223135L0.223134 4.0395C-0.074378 4.33701 -0.074378 4.82945 0.223134 5.12696C0.520646 5.42447 1.01308 5.42447 1.31059 5.12696L3.82406 2.61349V19.2331C3.82406 19.6538 4.17286 20.0026 4.59349 20.0026C5.01411 20.0026 5.36291 19.6538 5.36291 19.2331V2.62375L7.86612 5.12696C8.02001 5.28084 8.21493 5.35266 8.40985 5.35266C8.60477 5.35266 8.79969 5.28084 8.95358 5.12696C9.25109 4.82945 9.25109 4.34727 8.95358 4.0395Z",
      fill: $setup.props.color || '#292E36'
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M19.7769 14.873C19.4794 14.5755 18.9869 14.5755 18.6894 14.873L16.1759 17.3865V0.766865C16.1759 0.346244 15.8271 -0.00256348 15.4065 -0.00256348C14.9859 -0.00256348 14.6371 0.346244 14.6371 0.766865V17.3763L12.1339 14.873C11.8364 14.5755 11.3439 14.5755 11.0464 14.873C10.7489 15.1706 10.7489 15.663 11.0464 15.9605L14.8525 19.7666C14.9243 19.8384 15.0167 19.9 15.1193 19.941C15.1295 19.941 15.1398 19.941 15.15 19.9513C15.2321 19.9821 15.3244 20.0026 15.4168 20.0026C15.622 20.0026 15.8169 19.9205 15.9605 19.7769L19.7769 15.9605C20.0744 15.6527 20.0744 15.1706 19.7769 14.873Z",
      fill: $setup.props.color || '#292E36'
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}