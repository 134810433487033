import { defineComponent as _defineComponent } from 'vue'

import { AtomBadge } from '../atoms';
interface IProps {
  columns?: { label: string; field: string; rowColor: string; fieldExtra?: any; fnRowColor?: (value) => string }[];
  rows?: any[];
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeTableSimple',
  props: {
    columns: { type: Array, required: false },
    rows: { type: Array, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props, get AtomBadge() { return AtomBadge } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})