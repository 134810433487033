import { defineComponent as _defineComponent } from 'vue'
import {
  AtomButton,
  DeleteIcon,
  PlusTransparentIcon,
  EditSquareIcon,
  rectangleColorIcon,
  rectangleIcon,
} from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { IHeadersTable } from '@/types';
import Swal from 'sweetalert2';
import { Preloader } from '@/store';
import { ref, onMounted, watch, computed } from 'vue';
import { getTriggersAll, deleteTriggers } from '@/core/services/TriggersNotification';
// import { format } from 'date-fns';
import TriggerModal from '@/pages/notificationmodal/TriggerModal.vue';

interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TriggersPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const editCreate = ref<number>(0);
const perPage = ref<number>(10);
const page = ref<number>(1);
const totalItems = ref<number>(0);
const term = ref<string>('');
const showModalAddTrigger = ref<boolean>(false);
const showModalEditTrigger = ref<boolean>(false);
const data = ref<any>({});

const headers = ref<IHeadersTable[]>([
  {
    label: 'Condição gatilho',
    field: 'condicaoGatilhoEnum',
    // formatFn: (event) => {
    //   return event.length ? event.map(x => `${x.ordemNotificacaoEnum}`).join(" / ") : "";
    // }
  },
  {
    label: 'Tipo notificação',
    field: 'tipoNotificacaoEnum',
    // formatFn: (event) => {
    //   return event.length ? event.map(x => `${x.ordemNotificacaoEnum}`).join(" / ") : "";
    // }
  },
  {
    label: 'Titulo de notificação',
    field: 'tituloNotificacao',
    // formatFn: (event) => {
    //   const transform = JSON.parse( JSON.stringify( event))

    //   return  transform.map(x => x.ordemNotificacaoEnum).join(', ');
    // }
  },
  // {
  //   label: 'Empresa',
  //   field: 'empresa',
  // },
  {
    label: 'Unidade',
    field: 'unidade.nome',
    // formatFn: (event) => {
    //   return event.replace(/.*\/(\d+)\/.*/, '$1');
    // },
  },
  {
    label: 'Link/Serviço',
    field: 'servico.nome',
    // formatFn: (event) => {
    //   return event.replace(/.*\/(\d+)\/.*/, '$1');
    // },
  },
  {
    label: 'Tempo de notificação',
    field: 'regraNotificacaoLista',
    formatFn: (event) => {
      const transform = JSON.parse(JSON.stringify(event));

      return transform
        .map((x) => x.intervaloTempoNotificaEnum)
        .join(', ')
        .replace(/_(\d+)/g, ' $1');
    },
  },
  {
    label: 'Status',
    field: 'status',
  },
  // { label: 'Cadastrado em', field: 'dataCadastroFormat' },
  { label: 'Ações', field: 'actions' },
]);
const stepTriggers = ref<boolean>(false);
const targetTrigger = ref<any>();
const items = ref<any[]>([]);
const sortable = ref<any>();
const addNew = ref<boolean>(false);
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}

function increment() {
  editCreate.value++;
}
function clear() {
  targetTrigger.value = {};
  data.value = {};
  data.value.id = 0;
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function searching(event: string | undefined) {
  page.value = 1;
  term.value = String(event);
}

// const simulateTableData = computed(() => {
//   return items.value.filter((f) => {
//     return !term.value ? true : f.versao.includes(term.value);
//   });
// });
const paginationTableData = computed(() => {
  const data = items.value
    .filter((f) => {
      return !term.value ? true : f.versao.includes(term.value);
    })
    .slice((page.value - 1) * perPage.value, (page.value - 1) * perPage.value + perPage.value);
  return data;
});

// function loadData() {
//   Preloader.actions.ActionSetPreloader(true);
//   totalRows.value = 0;

//   let promisse;

//   if (sortable.value) {
//     promisse = getServicosAll(page.value - 1, perPage.value, term.value, sortable.value);
//   } else {
//     promisse = getServicosAll(page.value - 1, perPage.value, term.value);
//   }
//   promisse
//     .then((resp) => {
//       items.value = resp.lista;
//       totalRows.value = resp.totalItems;
//     })

//     .finally(() => {
//       Preloader.actions.ActionSetPreloader(false);
//     });
// }

function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  // totalItems.value = 0;

  const promisse = getTriggersAll(page.value, perPage.value, sortable.value);

  promisse
    .then((resp: any) => {
      items.value = resp.filter((f) => f.archived === 0);

      items.value.map((x) => {
        x.regraNotificacaoListaOrdem = x?.regraNotificacaoLista
          ? JSON.parse(JSON.stringify(x.regraNotificacaoLista))
          : x.regraNotificacaoLista;
      });

      totalItems.value = resp.filter((f) => f.archived === 0).length;

      console.log(items.value);
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

onMounted(() => {
  loadData();
});
watch(
  () => page.value,
  () => {
    loadData();
  },
);

watch(
  () => term.value,
  () => {
    loadData();
  },
);

watch(
  () => perPage.value,
  () => {
    loadData();
  },
);

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'triggers') {
      page.value = 1;
      loadData();
    }
  },
);

function handleEdit(payload) {
  targetTrigger.value = JSON.parse(JSON.stringify(payload));
  console.log(targetTrigger.value);
  showModalEditTrigger.value = true;
}
function handleDelete(payload) {
  console.log(payload, 'Delete');

  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente deletar esse gatilho? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);

      deleteTriggers(payload.id)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              title: 'Sucesso',
              text: `Gatilho foi deletado com sucesso!`,
              icon: 'success',
            });
          }
          loadData();
        })

        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}

const __returned__ = { props, editCreate, perPage, page, totalItems, term, showModalAddTrigger, showModalEditTrigger, data, headers, stepTriggers, targetTrigger, items, sortable, addNew, type, replacement, increment, clear, sort, searching, paginationTableData, loadData, handleEdit, handleDelete, get AtomButton() { return AtomButton }, get DeleteIcon() { return DeleteIcon }, get PlusTransparentIcon() { return PlusTransparentIcon }, get EditSquareIcon() { return EditSquareIcon }, get rectangleColorIcon() { return rectangleColorIcon }, get rectangleIcon() { return rectangleIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings }, TriggerModal }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})