import { defineComponent as _defineComponent } from 'vue'
import { AddUserIcon, AtomButton, DeleteIcon, EditSquareIcon, AtomInput, AtomSelect,   AlertIcon,
AtomTooltip } from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { state } from '@/store/modules/layout';
import { getInternetLinkAll, postInternetLink, putInternetLink } from '@/core/services/InternetLinkService';
import { IHeadersTable, IOptionsSelect } from '@/types';
import Swal from 'sweetalert2';
import { ref, onMounted, computed, watch } from 'vue';
import { Preloader } from '@/store';
import { InternetLink } from '@/core/models';
import { getEquipamentoAll } from '@/core/services/EquipamentoService';
import { getGerenteDeContasAll, postGerenteDeContas } from '@/core/services/GerenteDeContasService';
import { format } from 'date-fns';
import { getUnidadeAll } from '@/core/services/UnitsService';
import { getProvedorAll } from '@/core/services/ProvedorService';
import { GerenteDeContas } from '@/core/models/GerenteDeContas';
import { getGruposAll } from '@/core/services/GruposService';

interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'InternetLinkPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

function clear() {
  data.value = {};
  data.value.id = 0;
  currentStep.value = 'Link de internet';
}
const steps = ref([
  { label: 'Link de internet', completed: false },
  { label: 'Serviço do tipo link', completed: false },
  { label: 'Interface do link', completed: false },
]);
const equipamentos = ref<IOptionsSelect[]>([]);
const unidades = ref<IOptionsSelect[]>([]);
const provedores = ref<IOptionsSelect[]>([]);
const gerentes = ref<IOptionsSelect[]>([]);
const grupos = ref<IOptionsSelect[]>([]);

const tipoCadastro = ref<IOptionsSelect[]>([
  { text: 'GoConnect', value: 'goConnect' },
  { text: 'Gestão de Links', value: 'gestaoLinks' },
]);

const tecnologias = ref<IOptionsSelect[]>([
  { text: 'Fibra', value: 'Fibra' },
  { text: 'ADSL', value: 'ADSL' },
  { text: 'Cobre', value: 'Cobre' },
  { text: 'Rádio', value: 'Rádio' },
]);
const currentStep = ref(steps.value[0].label);
const data = ref<InternetLink>({});
const novoGerente = ref<GerenteDeContas>({});
const term = ref<string>('');
const totalRows = ref<number>(0);
const perPage = ref<number>(10);
const page = ref<number>(1);
const showModalUpload = ref<boolean>(false);
const sortable = ref<any>();
const newManager = ref<boolean>(false);
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Provedor', field: 'provedor.nome' },
  { label: 'Unidade', field: 'unidade.nome' },
  { label: 'Download', field: 'download' },
  { label: 'Upload', field: 'upload' },
  { label: 'Data Vencimento', field: 'vencimentoText' },
  { label: 'Valor Provisionado', field: 'valorProvisionado' },
  { label: 'SLA', field: 'sla' },
  { label: 'SLA problema', field: 'slaProblem' },
  { label: 'Horas de suporte', field: 'horasServico' },
  { label: 'Contato Suporte', field: 'contatoSuporte' },
  { label: 'Gerente de Conta', field: 'gerenteDeContas.nome' },
  { label: 'Opções', field: 'actions' },
]);
const items = ref<InternetLink[]>([]);

const textSaveModal = computed(() => {
  const isLast = steps.value[steps.value.length - 1].label === currentStep.value;
  return isLast ? 'Salvar' : 'Avançar';
});
const textSaveMobile = computed(() => {
  const isLast = steps.value[steps.value.length - 1].label === currentStep.value;
  return type.value == 'C' && isLast ?  'Concluir cadastro'  : type.value != 'C' && isLast ? 'Concluir edição':'Avançar';
});

const textCancelModal = computed(() => {
  const isLast = steps.value[0].label === currentStep.value;
  return isLast ? 'Cancelar' : 'Voltar';
});
const addNew = ref<boolean>(false);
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}
function handleEdit(payload: InternetLink, isApp: boolean = false) {
  data.value = JSON.parse(JSON.stringify(payload));

  currentStep.value = 'Link de internet';
  type.value = 'E';

  if (isApp) {
    addNew.value = true;
  } else {
    showModalUpload.value = true;
  }
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function addGerenteContas() {
  Preloader.actions.ActionSetPreloader(true);
  postGerenteDeContas(novoGerente.value)
    .then((f) => {
      loadGerentes().then(() => {
        data.value.gerenteDeContasId = f.id;
      });
      newManager.value = false;
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function handleDelete(payload) {
  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente arquivar este link? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);
      payload.archived = 1;
      putInternetLink(payload)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: `Link arquivado com sucesso!`,
            });
          }
          loadData();
        })

        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}

function loadEquipamento() {
  getEquipamentoAll(0, 999999).then((f) => {
    equipamentos.value = f.lista.map(
      (f) => ({ text: `ID: ${f.id}|IP: ${f.ip}|${f.tipoEquipamentoNome}`, value: f.id }) as IOptionsSelect,
    );
  });
}

function loadProvedores() {
  getProvedorAll(0, 999999).then((f) => {
    provedores.value = f.lista.map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
  });
}
function loadGerentes(): Promise<boolean> {
  const promisse = new Promise<boolean>((resolve, reason) => {
    getGerenteDeContasAll(0, 999999)
      .then((f) => {
        gerentes.value = f.lista.map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
        resolve(true);
      })
      .catch((err) => {
        reason(err);
      });
  });

  return promisse;
}

function loadUnidade() {
  getUnidadeAll(0, 999999).then((f) => {
    unidades.value = f.lista.map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
  });
}
function loadGrupos() {
  getGruposAll(0, 999999).then((f) => {
    grupos.value = f.lista.map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
  });
}
function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalRows.value = 0;

  let promisse;

  if (sortable.value) {
    promisse = getInternetLinkAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = getInternetLinkAll(page.value - 1, perPage.value, term.value);
  }
  // getInternetLinkAll(page.value - 1, perPage.value, term.value)
  promisse
    .then((resp) => {
      items.value = resp.lista;
      items.value.map((f) => {
        if (f.vencimento) f.vencimentoText = format(new Date(`${f.vencimento}T00:00:00`), 'dd/MM/yyyy');

        f.unidadeId = f.unidadeId ?? f.unidade?.id;
        f.provedorId = f.provedorId ?? f.provedor?.id;
        f.gerenteDeContasId = f.gerenteDeContasId ?? f.gerenteDeContas?.id;
      });

      totalRows.value = resp.totalItems -  items.value.filter(f=>f.archived == 1).length;
    })

    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}
function searching(event: string | undefined) {
  term.value = String(event);
}

function handleNew() {
  const idx = steps.value.findIndex((x) => x.label === currentStep.value);

  if (idx !== -1 && steps.value[idx + 1]) {
    steps.value[idx].completed = true;
    currentStep.value = steps.value[idx + 1].label;
  }

  if (steps.value[idx].label == 'Interface do link') {
    Preloader.actions.ActionSetPreloader(true);
    if (data.value.vencimento) data.value.vencimento = format(new Date(data.value.vencimento), 'yyyy-MM-dd');
    if (!data.value.id) {
      data.value.archived = 0;
      data.value.financialCost = 0;
      data.value.instalationCost = 0;
      data.value.id = undefined;
      postInternetLink(data.value)
        .then(() => {
          // Swall
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: `Link criado com sucesso!`,
          });
          loadData();
          showModalUpload.value = false;
        })

        .finally(() => {
          data.value = {};
          addNew.value = false;
          Preloader.actions.ActionSetPreloader(false);
        });
    } else {
      putInternetLink(data.value)
        .then(() => {
          // swall
          Swal.fire({
            icon: 'success',
            title: 'Sucesso',
            text: `Link atualizado com sucesso!`,
          });
          loadData();
          showModalUpload.value = false;
        })

        .finally(() => {
          data.value = {};
          addNew.value = false;
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  }
}
function init() {
  loadProvedores();
  loadEquipamento();
  loadUnidade();
  loadGerentes();
  loadGrupos();
  loadData();
}
function handleCancelNew(event: string) {
  if ((event ?? '') == 'fecha') showModalUpload.value = false;

  const idx = steps.value.findIndex((x) => x.label === currentStep.value);
  
  if (idx !== -1 && steps.value[idx - 1]) {
    steps.value[idx - 1].completed = false;
    currentStep.value = steps.value[idx - 1].label;
  } else if(!addNew.value) {
    showModalUpload.value = false;
  }else if(addNew.value){
    addNew.value = false 
    type.value = '' 
    data.value = {}
  }
}
watch(
  () => data.value.tipoDeCadastro,
  () => {
    if (data.value.tipoDeCadastro == 'gestaoLinks') {
      steps.value.pop();
      steps.value.pop();
    } else {
      if (steps.value.length == 1) {
        steps.value = [
          ...steps.value,
          { label: 'Serviço do tipo link', completed: false },
          { label: 'Interface do link', completed: false },
        ];
      }
    }
  },
);

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'internetLink') {
      page.value = 1;
      init();
    }
  },
);
watch(
  () => page.value,
  () => {
    loadData();
  },
);

watch(
  () => term.value,
  () => {
    loadData();
  },
);
watch(
  () => perPage.value,
  () => {
    loadData();
  },
);
onMounted(() => {
  init();
});

const __returned__ = { props, clear, steps, equipamentos, unidades, provedores, gerentes, grupos, tipoCadastro, tecnologias, currentStep, data, novoGerente, term, totalRows, perPage, page, showModalUpload, sortable, newManager, headers, items, textSaveModal, textSaveMobile, textCancelModal, addNew, type, replacement, handleEdit, sort, addGerenteContas, handleDelete, loadEquipamento, loadProvedores, loadGerentes, loadUnidade, loadGrupos, loadData, searching, handleNew, init, handleCancelNew, get AddUserIcon() { return AddUserIcon }, get AtomButton() { return AtomButton }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get AlertIcon() { return AlertIcon }, get AtomTooltip() { return AtomTooltip }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})