import { defineComponent as _defineComponent } from 'vue'
import { IMonthYear } from '@/types';
import { ref, watch, onMounted } from 'vue';

interface IProps {
  modelValue?: any;
  msgError?: string;
  classes?: string;
  label?: string;
  prependIcon?: boolean;
  required?: boolean;
  width?: string;
  noBgPrependIcon?: boolean;
  noBorderPrependIcon?: boolean;
  hasError?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomMonthPicker',
  props: {
    modelValue: { type: null, required: false },
    msgError: { type: String, required: false },
    classes: { type: String, required: false },
    label: { type: String, required: false },
    prependIcon: { type: Boolean, required: false },
    required: { type: Boolean, required: false },
    width: { type: String, required: false },
    noBgPrependIcon: { type: Boolean, required: false },
    noBorderPrependIcon: { type: Boolean, required: false },
    hasError: { type: Boolean, required: false }
  },
  emits: ["update:modelValue", "clean"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const month = ref<IMonthYear>();

const emit = __emit;
watch(
  () => month.value,
  () => {
    emit('update:modelValue', month.value);
  }
);
onMounted(() => {
  month.value = props.modelValue;
});

const __returned__ = { props, month, emit }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})