import { defineComponent as _defineComponent } from 'vue'
import { AtomButton, AtomInput, AtomSelect, DeleteIcon, EditSquareIcon, UsersIcon, PlusIcon } from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { IHeadersTable, IOptionsSelect } from '@/types';
import { ref, onMounted, watch, onActivated } from 'vue';
import { getCompanhiaAll, postCompanhia, putCompanhia } from '@/core/services/CompanhiasService';
import { getGruposAll } from '@/core/services/GruposService';
import { Companhia, Grupos } from '@/core/models';
import Swal from 'sweetalert2';
import { Preloader } from '@/store';
import { state } from '@/store/modules/layout';

interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CompanyPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const data = ref<Companhia>({});

const manageGroup = ref<boolean>(false);
const grupo = ref<number[]>();
const grupos = ref<Grupos[]>();
const groups = ref<IOptionsSelect[]>([]);
const perPage = ref<number>(12);
const page = ref<number>(1);
const term = ref<string>('');

const sortable = ref<any>();
const totalRows = ref<number>(0);
const showModalUpload = ref<boolean>(false);
const showModalGrupo = ref<boolean>(false);
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Nome', field: 'nome' },
  { label: 'Opções', field: 'actions' },
]);
const headersGrupo = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Grupo', field: 'nome' },
  { label: 'Opções', field: 'actions' },
]);

const items = ref<any[]>([]);
const addNew = ref<boolean>(false);
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}
function setGrupo() {
  grupos.value
    .filter((f) => grupo.value?.find((ff) => f.id == ff))
    .map((x) => {
      data.value.grupoLista?.push({ id: x.id, nome: x.nome });
    });

  grupo.value = [];
}
function handleDeleteGrupo(grupo: Grupos) {
  data.value.grupoLista = data.value.grupoLista?.filter((f) => f.id != grupo.id);
}

function handleSaveGrupo() {
  const dados: Companhia = JSON.parse(JSON.stringify(data.value));

  if (!dados.grupoLista) dados.grupoLista = [];
  dados.grupoIntLista = dados.grupoLista?.map((f) => f.id);
  dados.grupoLista = undefined;
  Preloader.actions.ActionSetPreloader(true);
  putCompanhia(dados)
    .then(() => {
      // swall
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: `Gestão de grupos atualizados com sucesso!`,
      });
      loadData();
      showModalGrupo.value = false;
      manageGroup.value = false;
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function handleEdit(payload: Companhia, isApp: boolean = false) {
  data.value = JSON.parse(JSON.stringify(payload));

  data.value.archived = data.value.archived == null || data.value.archived == undefined ? 0 : data.value.archived;

  type.value = 'E';

  if (isApp) {
    addNew.value = true;
  } else {
    showModalUpload.value = true;
  }
}
function handleAdd(payload) {
  console.log(payload, 'Add');
  data.value = JSON.parse(JSON.stringify(payload));

  if (state.isMobile) manageGroup.value = true;
  else showModalGrupo.value = true;
}
function handleDelete(payload: Companhia) {
  console.log(payload, 'Delete');

  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente arquivar a empresa <b>${payload.nome}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);
      payload.archived = 1;
      putCompanhia(payload)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: `Empresa ${payload.nome} foi arquivada com sucesso!`,
            });
            loadData();
          }
        })
        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}

function clear() {
  data.value.id = 0;
  data.value.nome = '';
  data.value.grupoIntLista = [];
}

function searching(event: string | undefined) {
  page.value = 1;
  term.value = String(event);
  loadData();
  // if (event) {
  //   items.value = items.value.filter(
  //     (f) =>
  //       String(f.id).toUpperCase().includes(event.toUpperCase()) ||
  //       String(f.nome).toUpperCase().includes(event.toUpperCase())
  //   );
  // }
}

function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalRows.value = 0;

  let promisse;

  if (sortable.value) {
    promisse = getCompanhiaAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = getCompanhiaAll(page.value - 1, perPage.value, term.value);
  }

  promisse
    .then((resp) => {
      items.value = resp.lista;
      totalRows.value = resp.totalItems;
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });

  // if (localStorage.getItem('companys') != null) {
  //   dados = localStorage.getItem('companys') != null ? JSON.parse(String(localStorage.getItem('companys'))) || [] : [];
  //   items.value = dados;
  // } else {
  //   for (let index = 0; index < 162; index++) {
  //     dados.push({
  //       id: index + 1,
  //       nome: `Default ${index + 1}`,
  //     });

  //     localStorage.setItem('companys', JSON.stringify(dados));
  //     dados;
  //   }
  // }
}

function loadGrupos() {
  getGruposAll(0, 9999).then((r) => {
    groups.value = r.lista.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);

    grupos.value = r.lista.filter((f) => f.archived == 0);
  });
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);
  if (!data.value.id) {
    data.value.archived = 0;
    data.value.id = undefined;
    postCompanhia(data.value)
      .then(() => {
        // Swall
        Swal.fire({
          title: 'Sucesso',
          text: `Empresa ${data.value.nome} foi criada com sucesso!`,
          icon: 'success',
        });
        loadData();
        showModalUpload.value = false;
      })
      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    putCompanhia(data.value)
      .then(() => {
        // swall
        Swal.fire({
          title: 'Sucesso',
          text: `Empresa ${data.value.nome} foi atualizada com sucesso!`,
          icon: 'success',
        });
        loadData();
        showModalUpload.value = false;
      })
      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}
watch(
  () => page.value,
  () => {
    loadData();
  },
);

watch(
  () => perPage.value,
  () => {
    loadData();
  },
);
watch(
  () => term.value,
  () => {
    loadData();
  },
);
onMounted(() => {
  loadData();
  loadGrupos();
});

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'companys') {
      page.value = 1;
      loadData();
      loadGrupos();
    }
  },
);

onActivated(() => {
  loadData();
  loadGrupos();
});

const __returned__ = { props, data, manageGroup, grupo, grupos, groups, perPage, page, term, sortable, totalRows, showModalUpload, showModalGrupo, headers, headersGrupo, items, addNew, type, replacement, setGrupo, handleDeleteGrupo, handleSaveGrupo, handleEdit, handleAdd, handleDelete, clear, searching, loadData, loadGrupos, sort, handleAddOrUpdate, get AtomButton() { return AtomButton }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get UsersIcon() { return UsersIcon }, get PlusIcon() { return PlusIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})