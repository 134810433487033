import { defineComponent as _defineComponent } from 'vue'

import {MoleculeCard} from "@/components/molecules"
import * as comps from '@/components/atoms';
import { IDataFinanceInfo } from '@/types';
import { state } from '@/store/modules/layout';
interface IProps {
  data: IDataFinanceInfo[];
  title: string;
  footer?: boolean
  text?: string;
  loading?: boolean
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeFinanceInfo',
  props: {
    data: { type: Array, required: true },
    title: { type: String, required: true },
    footer: { type: Boolean, required: false },
    text: { type: String, required: false },
    loading: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
// const emit = defineEmits(['handleToggle']);


const __returned__ = { props, get MoleculeCard() { return MoleculeCard }, get comps() { return comps }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})