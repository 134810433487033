import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = {
  key: 0,
  class: "badge-color-dark"
}
const _hoisted_3 = {
  key: 1,
  class: "badge-color-orange"
}
const _hoisted_4 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_5 = { class: "ms-2" }
const _hoisted_6 = {
  key: 0,
  class: "ms-2"
}
const _hoisted_7 = {
  key: 1,
  class: "ms-2"
}
const _hoisted_8 = { class: "ms-2" }
const _hoisted_9 = {
  key: 0,
  class: "ms-2"
}
const _hoisted_10 = {
  key: 1,
  class: "ms-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Grupos\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de Gatilhos' : $setup.type == 'E' ? 'Editando Gatilhos' : 'Gatilhos',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    (!$setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "border"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  searchFilterNone: "",
                  headers: $setup.headers,
                  items: $setup.paginationTableData,
                  totalItems: $setup.paginationTableData.length,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClickDelete: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleDelete($event))),
                  "only-actived": false
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["DeleteIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                    ])
                  ]),
                  status: _withCtx(({ props }) => [
                    ((props.archived ?? 1) === 1)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[13] || (_cache[13] = [
                          _createElementVNode("p", { class: "text-center" }, "Inativo", -1 /* HOISTED */)
                        ])))
                      : (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[14] || (_cache[14] = [
                          _createElementVNode("p", { class: "text-center" }, "Ativo", -1 /* HOISTED */)
                        ])))
                  ]),
                  areaFilter: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "10",
                          "align-self": "center"
                        }, {
                          default: _withCtx(() => _cache[15] || (_cache[15] = [
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, " Observação:"),
                              _createTextVNode(" Selecione a sensibilidade que deseja para definir quando chegará uma notificação alertando que seu link está (ou estará) com problemas. ")
                            ], -1 /* HOISTED */)
                          ])),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, {
                          cols: "2",
                          "align-self": "center",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              prependIcon: "",
                              class: "ms-2",
                              text: "Novo Gatilho",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => {
                  $setup.clear();
                  $setup.showModalAddTrigger = true;
                })
                            }, {
                              prependIcon: _withCtx(() => [
                                _createVNode($setup["PlusTransparentIcon"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createCommentVNode(" <div class=\"d-flex justify-end w-100\">\n            <AtomButton background=\"white\" text=\"Gerar relatório mensal\" outlined> </AtomButton> \n    \n     \n            </div> ")
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode($setup["AtomButton"], {
                        center: "text-center",
                        onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"]),
                        class: "mx-auto w-100 rounded-pill",
                        text: "Excluir",
                        background: "gray-05"
                      }, null, 8 /* PROPS */, ["onClick"]),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["EditSquareIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["TriggerModal"], {
                      id: $setup.targetTrigger,
                      confirma: $setup.editCreate,
                      onCloseModal: _cache[4] || (_cache[4] = ($event: any) => ($setup.showModalAddTrigger = !$setup.showModalAddTrigger)),
                      onLoad: $setup.loadData,
                      stepTri: $setup.stepTriggers
                    }, null, 8 /* PROPS */, ["id", "confirma", "stepTri"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, { class: "mt-5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      class: "mx-auto w-100 rounded-pill",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.stepTriggers == false ? ($setup.stepTriggers = true) : $setup.increment())),
                      text: $setup.stepTriggers && $setup.type == 'C' ? 'Concluir Cadastro' : $setup.stepTriggers && $setup.type == 'C'? 'Concluir edição': 'Avançar'
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.stepTriggers == false ?($setup.addNew = false) : ($setup.stepTriggers = false) , ($setup.type = ''), ($setup.data = {}))),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      width: "1050px",
      bodyScrool: true,
      showHeader: true,
      persistent: false,
      onHandleCancel: _cache[8] || (_cache[8] = ($event: any) => ($setup.stepTriggers == false ? ($setup.showModalAddTrigger = false) : ($setup.stepTriggers = false))),
      onHandleConfirm: _cache[9] || (_cache[9] = ($event: any) => ($setup.stepTriggers == false ? ($setup.stepTriggers = true) : $setup.increment())),
      show: $setup.showModalAddTrigger,
      "btn-close-text": "voltar",
      "btn-confirm-text": $setup.stepTriggers == true ? 'Criar' : 'Avançar',
      title: "Criar gatilho",
      subtitle: 'Preencha todos os campos corretamente para cadastrar um novo gatilho.'
    }, {
      step: _withCtx(() => [
        _createElementVNode("span", _hoisted_5, [
          _createVNode($setup["rectangleColorIcon"])
        ]),
        ($setup.stepTriggers)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
              _createVNode($setup["rectangleColorIcon"])
            ]))
          : _createCommentVNode("v-if", true),
        (!$setup.stepTriggers)
          ? (_openBlock(), _createElementBlock("span", _hoisted_7, [
              _createVNode($setup["rectangleIcon"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      body: _withCtx(() => [
        _createVNode($setup["TriggerModal"], {
          id: $setup.targetTrigger,
          confirma: $setup.editCreate,
          onCloseModal: _cache[7] || (_cache[7] = ($event: any) => ($setup.showModalAddTrigger = !$setup.showModalAddTrigger)),
          onLoad: $setup.loadData,
          stepTri: $setup.stepTriggers
        }, null, 8 /* PROPS */, ["id", "confirma", "stepTri"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "btn-confirm-text"]),
    _createVNode($setup["MoleculeModal"], {
      width: "1050px",
      heigth: "80%",
      bodyScrool: true,
      showHeader: true,
      persistent: false,
      onHandleCancel: _cache[11] || (_cache[11] = ($event: any) => ($setup.stepTriggers == false ? ($setup.showModalEditTrigger = false) : ($setup.stepTriggers = false))),
      onHandleConfirm: _cache[12] || (_cache[12] = ($event: any) => ($setup.stepTriggers == false ? ($setup.stepTriggers = true) : $setup.increment())),
      show: $setup.showModalEditTrigger,
      "btn-close-text": "voltar",
      "btn-confirm-text": $setup.stepTriggers == true && $setup.targetTrigger ? 'Editar' : 'Avançar',
      title: "Editar gatilho",
      subtitle: 'Preencha todos os campos corretamente para cadastrar um novo gatilho.'
    }, {
      step: _withCtx(() => [
        _createElementVNode("span", _hoisted_8, [
          _createVNode($setup["rectangleColorIcon"])
        ]),
        ($setup.stepTriggers)
          ? (_openBlock(), _createElementBlock("span", _hoisted_9, [
              _createVNode($setup["rectangleColorIcon"])
            ]))
          : _createCommentVNode("v-if", true),
        (!$setup.stepTriggers)
          ? (_openBlock(), _createElementBlock("span", _hoisted_10, [
              _createVNode($setup["rectangleIcon"])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      body: _withCtx(() => [
        _createVNode($setup["TriggerModal"], {
          id: $setup.targetTrigger,
          confirma: $setup.editCreate,
          onLoad: $setup.loadData,
          stepTri: $setup.stepTriggers,
          onCloseModal: _cache[10] || (_cache[10] = ($event: any) => ($setup.showModalEditTrigger = !$setup.showModalEditTrigger))
        }, null, 8 /* PROPS */, ["id", "confirma", "stepTri"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "btn-confirm-text"])
  ], 64 /* STABLE_FRAGMENT */))
}