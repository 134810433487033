import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onMounted } from 'vue';
import {
  AtomAccordion,
  ArrowCircleIcon,
  AtomButton,
  AtomInput,
  ArrowIconGreen,
  ArrowIconBlue,
} from '@/components/atoms';
import { Authentication } from '@/store';
import { getPredict, getInvestigate } from '@/core/services/PredictService';
import Swal from 'sweetalert2';

interface IProps {
  typeNotify?: string;
  dados: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationModal',
  props: {
    typeNotify: { type: String, required: false },
    dados: { type: null, required: true }
  },
  emits: ['closeModal'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;
const props = __props;

const notify = ref<any>({});
const typeData = ref<string>('Latência');
const listError = ref<string[]>([]);
const predict = ref<any>({});
const typeNotification = computed(() => {
  if (props.typeNotify) {
    return props.typeNotify;
  }
  return null;
});

async function getStatusConectividade() {
  const id = Authentication.getters.getUser.value.id ?? 187;
  const dados = await getPredict('LNK', id);

  const investigate = await getInvestigate('LNK', id, '480');
  const data = investigate.data.predict.find((f) => (f?.name ?? f?.NAME) == notify.value.bodyComplete.servico);
  predict.value = data;
  if (predict.value) {
    predict.value['status'] = dados.data.find((f) => f.name == notify.value.bodyComplete.servico).status;
  } else {
    predict.value = {};
  }
}

function abrirChamado() {
  Swal.fire({
    title: 'Sucesso',
    html: 'Ticket aberto com sucesso',
    icon: 'success',
  });
}

onMounted(() => {
  if (props.dados) {
    notify.value = props.dados;
    typeData.value = String(notify.value.bodyComplete.condicao).toUpperCase();
    getStatusConectividade();
  }
});

watch(
  () => props.dados,
  () => {
    notify.value = props.dados;
    typeData.value = String(notify.value.bodyComplete.condicao).toUpperCase();
    getStatusConectividade();
  },
  { deep: true },
);

const __returned__ = { emits, props, notify, typeData, listError, predict, typeNotification, getStatusConectividade, abrirChamado, get AtomAccordion() { return AtomAccordion }, get ArrowCircleIcon() { return ArrowCircleIcon }, get AtomButton() { return AtomButton }, get AtomInput() { return AtomInput }, get ArrowIconGreen() { return ArrowIconGreen }, get ArrowIconBlue() { return ArrowIconBlue } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})