<template>
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="56" height="56" rx="8" fill="white"/>
<rect width="56" height="56" rx="8" fill="#FF5C00" fill-opacity="0.1"/>
<path d="M23.8797 28.07C23.8797 27.66 24.2197 27.32 24.6297 27.32H30.1097V18.86C30.0997 18.38 29.7197 18 29.2397 18C23.3497 18 19.2397 22.11 19.2397 28C19.2397 33.89 23.3497 38 29.2397 38C29.7097 38 30.0997 37.62 30.0997 37.14V28.81H24.6297C24.2097 28.82 23.8797 28.48 23.8797 28.07Z" fill="#FF5C00"/>
<path d="M36.5397 27.54L33.6997 24.69C33.4097 24.4 32.9297 24.4 32.6397 24.69C32.3497 24.98 32.3497 25.46 32.6397 25.75L34.1997 27.31H30.0997V28.81L34.1897 28.81L32.6297 30.37C32.3397 30.66 32.3397 31.14 32.6297 31.43C32.7797 31.58 32.9697 31.65 33.1597 31.65C33.3497 31.65 33.5397 31.58 33.6897 31.43L36.5297 28.58C36.8297 28.3 36.8297 27.83 36.5397 27.54Z" fill="#FF5C00"/>
</svg>

</template>
<script lang="ts" setup></script>
