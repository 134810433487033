import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import StepActiveIcon from './icons/StepActiveIcon.vue';
import { IStep } from '@/types'
import CompletedStepIcon from './icons/CompletedStepIcon.vue';

interface IProps {
  steps: IStep[];
  currentStep: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomProgressForm',
  props: {
    steps: { type: Array, required: true },
    currentStep: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

_useCssVars(_ctx => ({
  "1e229c5f-steps": (steps.value)
}))

const props = __props;
const steps = ref(props.steps.length)

const __returned__ = { props, steps, StepActiveIcon, CompletedStepIcon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})