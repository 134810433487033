import { defineComponent as _defineComponent } from 'vue'
import { computed, ref, onMounted, watch } from 'vue';
import { getColetoresInstalados } from '@/core/services/Coletores';
import { state } from '@/store/modules/layout';
import { Authentication } from '@/store';
import { format } from 'date-fns';
import { GraphIconColor } from '@/components/atoms';
import { MoleculeTable, MoleculeTitleSetings} from '@/components/molecules';
import { IHeadersTable } from '@/types';
interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CollectorInstalledPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const collectorInstalledPerPage = ref<number>(10);
const filtroCollectorInstalled = ref<string>('');
const collectorsInstalledData = ref<any[]>([]);

const loading = ref<boolean>(false);

const collectorInstalledPage = ref<number>(1);
const collectorsInstalledheaders = ref<IHeadersTable[]>([
  {
    label: 'Status',
    field: 'status',
    type: 'badge',
    tdClass: 'text-center',
    thClass: 'text-center',
  },
  // { label: 'Link/Serviço', field: 'service_name' },
  { label: 'Coletor', field: 'coletor_nome', tdClass: 'text-center', thClass: 'text-center' },
  { label: 'Versão', field: 'coletor_versao', tdClass: 'text-center', thClass: 'text-center' },
  { label: 'Usuário', field: 'usuario_nome', tdClass: 'text-center', thClass: 'text-center' },
  // { label: 'Unidade', field: 'client_unit_name' },
  { label: 'Nome da máquina', field: 'nome_maquina', tdClass: 'text-center', thClass: 'text-center' },
  { label: 'Detalhes do SO', field: 'detalhe_so', tdClass: 'text-center', thClass: 'text-center' },
  // { label: 'Grupo de coleta', field: 'grupo' },
  // { label: 'Qualidade', field: 'qualidade' },
  // { label: 'Disponibilidade', field: 'disponibilidade' },
  { label: 'Última Coleta', field: 'ultima_coleta', tdClass: 'text-center', thClass: 'text-center' },
]);

const paginationTableDataCollection = computed(() => {
  const data = filteredDataCollectorInstalled.value.slice(
    (collectorInstalledPage.value - 1) * collectorInstalledPerPage.value,
    (collectorInstalledPage.value - 1) * collectorInstalledPerPage.value + collectorInstalledPerPage.value
  );
  return data;
});
const filteredDataCollectorInstalled = computed(() => {
  if (filtroCollectorInstalled.value) {
    return collectorsInstalledData.value.filter((f) => {
      const obj = Object.keys(f);
      return obj.some((x) => {
        return String(f[x]).toUpperCase().includes(filtroCollectorInstalled.value.toUpperCase());
      });
    });
  } else {
    return collectorsInstalledData.value;
  }
});

function searching(event: string | undefined) {
  // filter(false);
  filtroCollectorInstalled.value = event ?? '';

  if (event) collectorInstalledPage.value = 1;
}

function loadColetoresInstalados() {
  loading.value = true;
  getColetoresInstalados(Authentication.state.id ?? 37)
    .then((res) => {
      collectorsInstalledData.value = res;
      collectorsInstalledData.value.map((el) => {
        el.ultima_coleta = format(new Date(el.ultima_coleta), 'dd/MM/yyyy HH:mm:ss');
        el.bgColor = el.status == 'online' ? '#CCFFEE' : '#FFE3E5';
        el.bgColor = el.status == 'online' ? '#15D095' : '#FF0D21';
      });
    })
    .finally(() => {
      loading.value = false;
    });
}
watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'collectorInstalled') {
      loadColetoresInstalados();
    }
  }
);

onMounted(() => {
  loadColetoresInstalados();
});

const __returned__ = { props, collectorInstalledPerPage, filtroCollectorInstalled, collectorsInstalledData, loading, collectorInstalledPage, collectorsInstalledheaders, paginationTableDataCollection, filteredDataCollectorInstalled, searching, loadColetoresInstalados, get state() { return state }, get GraphIconColor() { return GraphIconColor }, get MoleculeTable() { return MoleculeTable }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})