import { defineComponent as _defineComponent } from 'vue'
import { IOptionsSelect } from '@/types';
import { ref, onMounted, watch } from 'vue';
import { state } from '@/store/modules/layout';
import { CalendarIcon, DeleteCircleIcon } from '../atoms';
interface IProps {
  modelValue?: any;
  options?: IOptionsSelect[];
  placeholder?: string;
  label?: string;
  width?: string;
  rounded?: string;
  multiple?: boolean;
  disabled?: boolean;
  isCalendar?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomSelect',
  props: {
    modelValue: { type: null, required: false },
    options: { type: Array, required: false },
    placeholder: { type: String, required: false, default: 'Selecione' },
    label: { type: String, required: false },
    width: { type: String, required: false, default: 'auto' },
    rounded: { type: String, required: false, default: 'pill' },
    multiple: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false },
    isCalendar: { type: Boolean, required: false, default: false }
  },
  emits: ['onChangeDropDownList', 'update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;
const props = __props;
const selectedItem = ref<string | number>();
function handleChangeDropdownList() {
  emit('onChangeDropDownList', selectedItem.value);
}

watch(
  () => selectedItem.value,
  () => {
    emit('update:modelValue', selectedItem.value);
  },
);
watch(
  () => props.modelValue,
  () => {
    selectedItem.value = props.modelValue;
  },
);

watch(
  () => props.options,
  () => {
    if (props.options?.find((x) => x.selected === true)) {
      selectedItem.value = props.options?.find((x) => x.selected === true)?.value;

      emit('onChangeDropDownList', selectedItem.value);
    }
  },
);
onMounted(() => {
  selectedItem.value = props.modelValue ? props.modelValue : props.options?.find((x) => x.selected === true)?.value;
});

const __returned__ = { emit, props, selectedItem, handleChangeDropdownList, get state() { return state }, get CalendarIcon() { return CalendarIcon }, get DeleteCircleIcon() { return DeleteCircleIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})