import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = { class: "d-flex justify-space-between w-100 ms-3 items-center" }
const _hoisted_3 = {
  class: "d-flex align-center",
  style: {"gap":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_AtomProgressForm = _resolveComponent("AtomProgressForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <h1>Equipamentos</h1> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de equipamento' : $setup.type == 'E' ? 'Editando Equipamento' : 'Equipamento',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    (!$setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.items,
                  totalItems: $setup.totalItems,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleEdit($event, true))),
                  onItemClickDelete: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleDelete($event))),
                  headersNotExport: $setup.headersNotImport
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("div", null, [
                        _createVNode($setup["AtomButton"], {
                          background: "white",
                          text: "Manusear colunas",
                          outlined: ""
                        })
                      ]),
                      _createElementVNode("div", _hoisted_3, [
                        _createCommentVNode(" <AtomButton background=\"white\" outlined>\n                <PaperUploadIcon />\n              </AtomButton>\n              <AtomButton background=\"white\" text=\"Excel\" outlined> </AtomButton>\n              <AtomButton prependIcon appendIcon background=\"white\" outlined text=\"Importar/Exportar\">\n                <template #appendIcon>\n                  <ArrowDown3Icon />\n                </template>\n</AtomButton> "),
                        _createVNode($setup["AtomButton"], {
                          text: "Novo equipamento",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => {
                  $setup.clear();
                  $setup.showModalUpload = true;
                })
                        })
                      ])
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleEdit(props, true)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Editar'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page", "headersNotExport"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 1,
          class: "text-sm"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "my-3" }, {
                  default: _withCtx(() => _cache[38] || (_cache[38] = [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", { class: "text-gray-02" }, "Preencha todos os campos corretamente para cadastrar um novo equipamento.")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            ($setup.currentStep === 'Primeiro passo')
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomSelect"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.unidadeId,
                          placeholder: "Selecione",
                          label: "Unidade",
                          modelValue: $setup.data.unidadeId,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.unidadeId) = $event)),
                          options: $setup.unidades
                        }, null, 8 /* PROPS */, ["hasError", "modelValue", "options"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.ip,
                          placeholder: "Digite aqui",
                          label: "IP",
                          modelValue: $setup.data.ip,
                          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.data.ip) = $event))
                        }, null, 8 /* PROPS */, ["hasError", "modelValue"]),
                        _createCommentVNode(" :mask=\"{\n                    mask: 'B.B.B.B',\n                    tokens: {\n                      B: { pattern: /\\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\b/ },\n                    },\n                  }\" ")
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.portSsh,
                          placeholder: "Digite aqui",
                          label: "Porta SSH",
                          modelValue: $setup.data.portSsh,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.data.portSsh) = $event))
                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.portHttps,
                          placeholder: "Digite aqui",
                          label: "Porta HTTPS",
                          modelValue: $setup.data.portHttps,
                          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.data.portHttps) = $event))
                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { mcols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomSelect"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.tipoEquipamentoId,
                          placeholder: "Selecione",
                          label: "Equipamento",
                          modelValue: $setup.data.tipoEquipamentoId,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.data.tipoEquipamentoId) = $event)),
                          options: $setup.tipos
                        }, null, 8 /* PROPS */, ["hasError", "modelValue", "options"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.username,
                          placeholder: "Digite aqui",
                          label: "Usuário",
                          modelValue: $setup.data.username,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.data.username) = $event))
                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          type: "password",
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.passwordHash,
                          placeholder: "Digite aqui",
                          label: "Senha",
                          modelValue: $setup.data.passwordHash,
                          "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.data.passwordHash) = $event))
                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { md: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomButton"], {
                          disabled: $setup.loadSSH || !$setup.data.ip || !$setup.data.portHttps || !$setup.data.username || !$setup.data.passwordHash ? true : false,
                          onClick: _cache[12] || (_cache[12] = ($event: any) => ($setup.testingSsh())),
                          center: "text-center",
                          class: "mx-auto w-100 mt-5 w-100",
                          text: $setup.loadSSH ? 'Aguarde a execução do teste....' : `Testar porta SSH`,
                          background: "primary"
                        }, null, 8 /* PROPS */, ["disabled", "text"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.currentStep === 'Segundo passo')
              ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "SNMP Porta",
                          modelValue: $setup.data.snmpPort,
                          "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.data.snmpPort) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "SNMP Versão",
                          modelValue: $setup.data.snmpVersion,
                          "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.data.snmpVersion) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "SNMP Security Name",
                          modelValue: $setup.data.snmpSecurityName,
                          "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.data.snmpSecurityName) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "SNMP Auth Protocol",
                          modelValue: $setup.data.snmpAuthProtocol,
                          "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.data.snmpAuthProtocol) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "SNMP Auth Pass HASH",
                          modelValue: $setup.data.snmpAuthPassHash,
                          "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.data.snmpAuthPassHash) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "SNMP Priv Protocol",
                          modelValue: $setup.data.snmpPrivProtoco,
                          "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.data.snmpPrivProtoco) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "SNMP Priv Pass HASH",
                          modelValue: $setup.data.snmpPrivPassHash,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.data.snmpPrivPassHash) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "Community",
                          modelValue: $setup.data.community,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.data.community) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_row, { class: "mt-5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[21] || (_cache[21] = ($event: any) => ($setup.handleNew())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.textSaveMobile
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: $setup.handleCancelNew,
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      width: "639px",
      "body-scrool": true,
      onHandleConfirm: $setup.handleNew,
      onHandleCancel: $setup.handleCancelNew,
      title: "Cadastro de equipamentos",
      subtitle: 'Preencha todos os campos corretamente para cadastrar uma novo equipamento',
      show: $setup.showModalUpload,
      "btn-close-text": $setup.textCancelModal,
      "btn-confirm-text": $setup.textSaveModal
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_AtomProgressForm, {
              steps: $setup.steps,
              "current-step": $setup.currentStep
            }, null, 8 /* PROPS */, ["steps", "current-step"]),
            _createVNode(_component_v_container, { class: "text-sm" }, {
              default: _withCtx(() => [
                ($setup.currentStep === 'Primeiro passo')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione uma unidade",
                              label: "Selecione uma unidade",
                              modelValue: $setup.data.unidadeId,
                              "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($setup.data.unidadeId) = $event)),
                              options: $setup.unidades
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "IP",
                              label: "IP",
                              modelValue: $setup.data.ip,
                              "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($setup.data.ip) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"]),
                            _createCommentVNode(" :mask=\"{\n                    mask: 'B.B.B.B',\n                    tokens: {\n                      B: { pattern: /\\b([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\b/ },\n                    },\n                  }\" ")
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Porta HTTPS",
                              label: "Porta HTTPS",
                              modelValue: $setup.data.portHttps,
                              "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($setup.data.portHttps) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Porta SSH",
                              label: "Porta SSH",
                              modelValue: $setup.data.portSsh,
                              "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($setup.data.portSsh) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione um tipo de equipamento",
                              label: "Selecione um tipo de equipamento",
                              modelValue: $setup.data.tipoEquipamentoId,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($setup.data.tipoEquipamentoId) = $event)),
                              options: $setup.tipos
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Usuário",
                              label: "Usuário",
                              modelValue: $setup.data.username,
                              "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (($setup.data.username) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              type: "password",
                              width: "100%",
                              placeholder: "Senha",
                              label: "Senha",
                              modelValue: $setup.data.passwordHash,
                              "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (($setup.data.passwordHash) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              disabled: 
                    $setup.loadSSH || !$setup.data.ip || !$setup.data.portHttps || !$setup.data.username || !$setup.data.passwordHash ? true : false
                  ,
                              onClick: _cache[29] || (_cache[29] = ($event: any) => ($setup.testingSsh())),
                              center: "text-center",
                              class: "mx-auto w-100 mt-5 w-100",
                              text: $setup.loadSSH ? 'Aguarde a execução do teste....' : `Testar porta SSH`,
                              background: "primary"
                            }, null, 8 /* PROPS */, ["disabled", "text"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.currentStep === 'Segundo passo')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "SNMP Porta",
                              label: "SNMP Porta",
                              modelValue: $setup.data.snmpPort,
                              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (($setup.data.snmpPort) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "SNMP Versão",
                              label: "SNMP Versão",
                              modelValue: $setup.data.snmpVersion,
                              "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (($setup.data.snmpVersion) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "SNMP Security Name",
                              label: "SNMP Security Name",
                              modelValue: $setup.data.snmpSecurityName,
                              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => (($setup.data.snmpSecurityName) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "SNMP Auth Protocol",
                              label: "SNMP Auth Protocol",
                              modelValue: $setup.data.snmpAuthProtocol,
                              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => (($setup.data.snmpAuthProtocol) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "SNMP Auth Pass HASH",
                              label: "SNMP Auth Pass HASH",
                              modelValue: $setup.data.snmpAuthPassHash,
                              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => (($setup.data.snmpAuthPassHash) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "SNMP Priv Protocol",
                              label: "SNMP Priv Protocol",
                              modelValue: $setup.data.snmpPrivProtoco,
                              "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => (($setup.data.snmpPrivProtoco) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "SNMP Priv Pass HASH",
                              label: "SNMP Priv Pass HASH",
                              modelValue: $setup.data.snmpPrivPassHash,
                              "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => (($setup.data.snmpPrivPassHash) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Community",
                              label: "Community",
                              modelValue: $setup.data.community,
                              "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => (($setup.data.community) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "btn-close-text", "btn-confirm-text"])
  ], 64 /* STABLE_FRAGMENT */))
}