import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted, watch } from 'vue';
import { AtomSubTitle, AtomTitle } from '@/components/atoms';

interface IProps {
  height: string;
  max?: number;
  hasPercent?: boolean;
  hasPercentTitle?: boolean;
  hasTitle?: boolean;
  margin1?: boolean;
  noValueTitle?: boolean;
  title?: string;
  progressColor?: string;
  colorPercent?: string;
  modelValue?: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomProgressBar',
  props: {
    height: { type: String, required: true, default: '10' },
    max: { type: Number, required: false },
    hasPercent: { type: Boolean, required: false, default: true },
    hasPercentTitle: { type: Boolean, required: false },
    hasTitle: { type: Boolean, required: false, default: false },
    margin1: { type: Boolean, required: false },
    noValueTitle: { type: Boolean, required: false },
    title: { type: String, required: false },
    progressColor: { type: String, required: false },
    colorPercent: { type: String, required: false },
    modelValue: { type: null, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

function formatLang(value: number): string {
  return new Intl.NumberFormat('pt-BR').format(value);
}
// const emit = defineEmits<{
//   (e: 'update:modelValue', value: string | number | null): void;
// }>();
const props = __props;
const progress = ref(0);
onMounted(() => {
  progress.value = props.modelValue;
});

watch(
  () => props.modelValue,
  () => {
    progress.value = props.modelValue;
  }
);
onMounted(() => {
  progress.value = props.modelValue;
});

const __returned__ = { formatLang, props, progress, get AtomSubTitle() { return AtomSubTitle }, get AtomTitle() { return AtomTitle } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})