import { defineComponent as _defineComponent } from 'vue'
import { toRefs, computed } from "vue";
import { BtnRightIcon } from "../atoms";


export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculePagination',
  props: {
  numberOfPages: {
    required: true,
  },
  modelValue: {
    required: true,
    type: Number,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const { numberOfPages, modelValue: currentPage } = toRefs<any>(props);

const emit = __emit;

const pages = computed(() => {
  let numShown = 6;
  numShown = Math.min(numShown, numberOfPages.value);
  let first = currentPage.value - Math.floor(numShown / 2);
  first = Math.max(first, 1);
  first = Math.min(first, numberOfPages.value - numShown + 1);
  return [...Array(numShown)].map((k, i) => i + first);
});

const setCurrentPage = (number: any) => {
  emit("update:modelValue", number);
};

const previous = () => {
  if (currentPage.value === 1) return;
  emit("update:modelValue", currentPage.value - 1);
};

const next = () => {
  if (currentPage.value >= numberOfPages?.value) return;
  emit("update:modelValue", currentPage.value + 1);
};

const __returned__ = { props, numberOfPages, currentPage, emit, pages, setCurrentPage, previous, next, get BtnRightIcon() { return BtnRightIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})