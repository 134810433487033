import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = { class: "d-flex justify-end w-100" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "ms-2" }
const _hoisted_5 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_6 = { class: "mb-7" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "ms-2" }
const _hoisted_9 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de Empresas' : $setup.type == 'E' ? 'Editando Empresas' : 'Empresas',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    _createCommentVNode(" <AtomTitle title=\"Empresas\" /> "),
    (!$setup.addNew && !$setup.manageGroup)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.items,
                  totalItems: $setup.totalRows,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleEdit($event, true))),
                  onItemClickDelete: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleDelete($event)))
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radiusOutlined: "",
                        class: "pa-2",
                        onClick: _withModifiers(($event: any) => ($setup.handleAdd(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["UsersIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createCommentVNode(" <AtomButton background=\"white\" text=\"Gerar relatório mensal\" outlined> </AtomButton> "),
                      _createVNode($setup["AtomButton"], {
                        class: "ms-2",
                        text: "Nova Empresa",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {
              $setup.clear();
            $setup.showModalUpload = true;
            })
                      }),
                      _createCommentVNode(" <div class=\"d-flex\" style=\"gap: 10px\">\n              <AtomButton background=\"white\" outlined>\n                <PaperUploadIcon />\n              </AtomButton>\n\n              <AtomButton :disabled=\"true\" text=\"Importar usuários\" background=\"white\" outlined> </AtomButton>\n\n              <AtomButton text=\"Exportar usuários\" background=\"white\" outlined> </AtomButton>\n\n              <AtomButton text=\"Excel exemplo\" background=\"white\" outlined> </AtomButton>\n\n              <AtomButton prependIcon appendIcon background=\"white\" outlined text=\"Opções\">\n                <template #prependIcon>\n                  <SettingIcon />\n                </template>\n<template #appendIcon>\n                  <ArrowDown3Icon />\n                </template>\n</AtomButton>\n</div> ")
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleEdit(props, true)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Editar'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleAdd(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Gerenciar Grupos'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew && !$setup.manageGroup)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "my-3" }, {
                  default: _withCtx(() => _cache[16] || (_cache[16] = [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", { class: "text-gray-02" }, "Preencha todos os campos corretamente para cadastrar uma nova empresa.")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      hasError: !$setup.data.nome,
                      placeholder: "Digite aqui",
                      label: "Nome da companhia",
                      modelValue: $setup.data.nome,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.nome) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      multiple: "",
                      width: "100%",
                      placeholder: "Digite aqui",
                      label: "Grupos",
                      modelValue: $setup.data.grupoIntLista,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.data.grupoIntLista) = $event)),
                      options: $setup.groups
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, { class: "mt-5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[7] || (_cache[7] = ($event: any) => ($setup.handleAddOrUpdate())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.type == 'C' ? 'Concluir Cadastro' : 'Concluir edição'
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[8] || (_cache[8] = ($event: any) => (($setup.addNew = false), ($setup.type = ''), ($setup.data = {}))),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.manageGroup)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  md: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode($setup["AtomSelect"], {
                        width: "100%",
                        placeholder: "Selecione um ou mais grupos",
                        modelValue: $setup.grupo,
                        "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.grupo) = $event)),
                        options: $setup.groups,
                        multiple: true
                      }, null, 8 /* PROPS */, ["modelValue", "options"]),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode($setup["AtomButton"], {
                          class: "px-2",
                          onClick: $setup.setGrupo
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["PlusIcon"], { color: "white" })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { md: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["MoleculeTable"], {
                      headers: $setup.headersGrupo,
                      items: $setup.data.grupoLista,
                      pagination: false
                    }, {
                      actions: _withCtx(({ props }) => [
                        _createElementVNode("div", _hoisted_5, [
                          _createVNode($setup["AtomButton"], {
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDeleteGrupo(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["headers", "items"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: $setup.handleSaveGrupo,
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.type == 'C' ? 'Concluir Cadastro' : 'Concluir Edição'
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[10] || (_cache[10] = () => {
            $setup.manageGroup = false;
            $setup.data = {};
          }
          ),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      onHandleConfirm: $setup.handleAddOrUpdate,
      onHandleCancel: _cache[13] || (_cache[13] = ($event: any) => ($setup.showModalUpload = false)),
      title: "Cadastro de companhias",
      subtitle: 'Preencha todos os campos corretamente para cadastrar uma nova companhia',
      show: $setup.showModalUpload,
      "btn-close-text": "Cancelar",
      "btn-confirm-text": "Registrar"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode($setup["AtomInput"], {
                width: "100%",
                required: "",
                msgError: "Campo obrigatório",
                hasError: !$setup.data.nome,
                placeholder: "Nome da companhia",
                label: "Nome da companhia",
                modelValue: $setup.data.nome,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.data.nome) = $event))
              }, null, 8 /* PROPS */, ["hasError", "modelValue"])
            ]),
            _createVNode($setup["AtomSelect"], {
              multiple: "",
              width: "100%",
              placeholder: "Selecione os grupos dessa companhia",
              label: "Selecione os grupos dessa companhia",
              modelValue: $setup.data.grupoIntLista,
              "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.data.grupoIntLista) = $event)),
              options: $setup.groups
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"]),
    _createVNode($setup["MoleculeModal"], {
      onHandleConfirm: $setup.handleSaveGrupo,
      onHandleCancel: _cache[15] || (_cache[15] = ($event: any) => ($setup.showModalGrupo = false)),
      title: "Gerenciar grupos",
      subtitle: 'Gerencie os grupos que essa empresa está inserida',
      show: $setup.showModalGrupo,
      "btn-close-text": "Cancelar",
      "btn-confirm-text": "Salvar"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, {
                cols: "12",
                md: "12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione um ou mais grupos",
                      label: "Selecione um ou mais grupos",
                      modelValue: $setup.grupo,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.grupo) = $event)),
                      options: $setup.groups,
                      multiple: true
                    }, null, 8 /* PROPS */, ["modelValue", "options"]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode($setup["AtomButton"], {
                        class: "px-2",
                        onClick: $setup.setGrupo
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["PlusIcon"], { color: "white" })
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { md: "12" }, {
                default: _withCtx(() => [
                  _createVNode($setup["MoleculeTable"], {
                    headers: $setup.headersGrupo,
                    items: $setup.data.grupoLista,
                    pagination: false
                  }, {
                    actions: _withCtx(({ props }) => [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode($setup["AtomButton"], {
                          background: "white",
                          radius: "",
                          class: "pa-0",
                          onClick: _withModifiers(($event: any) => ($setup.handleDeleteGrupo(props.row)), ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["DeleteIcon"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["headers", "items"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}