import { defineComponent as _defineComponent } from 'vue'
import {
  AtomButton,
  EditSquareIcon,
  DeleteIcon,
  AtomInput,
  EditIcon,
  AtomInputMoney,
  CalendarIcon,
  AtomSubTitle,
  AtomTitle,
} from '@/components/atoms';
import { MoleculeTable, MoleculeModal } from '@/components/molecules';

import { IHeadersTable } from '@/types';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { format } from 'date-fns';
import { listarGastoExtra, alterarGastoExtra, adicionarGastoExtra, removerGastoExtra } from '@/core/services/FinancialService';

import { Preloader } from '@/store';
import Swal from 'sweetalert2';

export default /*@__PURE__*/_defineComponent({
  __name: 'GastosPage',
  setup(__props, { expose: __expose }) {
  __expose();

const formatNumber = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

const route = useRoute();

const router = useRouter();

const mesAno = String(route.params.mesAno).split('-').reverse().join('/');
const text = ref<string>("");
const maxDate = new Date(
  Number(String(route.params.mesAno).split('-')[0]),
  Number(String(route.params.mesAno).split('-')[1]),
  1
);

maxDate.setDate(-1);
const minDate = new Date(
  Number(String(route.params.mesAno).split('-')[0]),
  Number(String(route.params.mesAno).split('-')[1]) - 1,
  1
);
const id = route.params.id;

const items = ref<any[]>([]);
const perPage = ref<number>(10);
const page = ref<number>(1);
const showAddGastos = ref<boolean>(false);

const headersExtras = ref<IHeadersTable[]>([
  {
    label: 'Data',
    field: 'dataFormat',
  },
  { label: 'Motivo', field: 'motivo' },
  { label: 'valor', field: 'valorFormat' },
  { label: 'Opções', field: 'actions' },
]);

const gastoSelected = ref<any>({});




function handleEdit(payload: any) {
  gastoSelected.value = JSON.parse(JSON.stringify(payload));
  showAddGastos.value = true;
}
function handleDelete(payload: any) {
  Preloader.actions.ActionSetPreloader(true);

  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente remover este Gasto extra?`,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {

    if (ale.isConfirmed) {

      removerGastoExtra(payload.id).then(() => {
        Swal.fire({
          title: "Sucesso",
          icon: "success",
          text: "Gasto Extra deletado com sucesso"
        })
        load();
      }).finally(() => {
        Preloader.actions.ActionSetPreloader(false);
      })
    }

  });
}
function handleAddOrUpdateGasto() {

  Preloader.actions.ActionSetPreloader(true);

  gastoSelected.value.ffLinkId = Number(id);


  if (gastoSelected.value.id) {
    alterarGastoExtra(gastoSelected.value).then(() => {
      Swal.fire({
        title: "Sucesso",
        icon: "success",
        text: "Gasto Extra atualizado com sucesso"
      })
      load();
    })
  }
  else {
    adicionarGastoExtra(gastoSelected.value).then(() => {
      Swal.fire({
        title: "Sucesso",
        icon: "success",
        text: "Gasto Extra adicionado com sucesso"
      })
      load();
    })
  }
  showAddGastos.value = false;
  gastoSelected.value = {};
}

function goFinantial() {
  router.go(-1);
}

function load() {
  Preloader.actions.ActionSetPreloader(true);
  listarGastoExtra(Number(id)).then(f => {
    items.value = f;
    items.value.map(x => {
      const date = new Date(x.data.split('.')[0]);
      x.data = format(date, 'yyyy-MM-dd')
      x.dataFormat = format(date, 'dd/MM/yyyy');
      x.valorFormat = formatNumber.format(x.valor);
    })
  }).finally(() => {
    Preloader.actions.ActionSetPreloader(false);
  });
}
onMounted(async () => {
  document.title = 'Gastos';

  text.value = String(route.query.text);

  load();
});

const __returned__ = { formatNumber, route, router, mesAno, text, maxDate, minDate, id, items, perPage, page, showAddGastos, headersExtras, gastoSelected, handleEdit, handleDelete, handleAddOrUpdateGasto, goFinantial, load, get AtomButton() { return AtomButton }, get EditSquareIcon() { return EditSquareIcon }, get DeleteIcon() { return DeleteIcon }, get AtomInput() { return AtomInput }, get EditIcon() { return EditIcon }, get AtomInputMoney() { return AtomInputMoney }, get CalendarIcon() { return CalendarIcon }, get AtomSubTitle() { return AtomSubTitle }, get AtomTitle() { return AtomTitle }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get format() { return format } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})