import { defineComponent as _defineComponent } from 'vue'

import { AtomButton, BtnChevronLeft, BtnChevronRight } from '@/components/atoms';


interface IProps {
  items: any[]
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeCarrousel',
  props: {
    items: { type: Array, required: true }
  },
  emits: ['prev','next'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;

const props = __props;
function handlePrev() {
  emit('prev');
}
function handleNext() {
  emit('next');
}

const __returned__ = { emit, props, handlePrev, handleNext, get AtomButton() { return AtomButton }, get BtnChevronLeft() { return BtnChevronLeft }, get BtnChevronRight() { return BtnChevronRight } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})