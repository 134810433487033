import { defineComponent as _defineComponent } from 'vue'
import {
  AtomButton,
  DeleteIcon,
  EditSquareIcon,
  AtomInput,
  WorkIcon,
  ActivityIcon,
  AtomSelect,
  PlusIcon,
} from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { IHeadersTable, IOptionsSelect } from '@/types';
import { getCompanhiaAll } from '@/core/services/CompanhiasService';
import { getServicosAll } from '@/core/services/ServicoService';
import { getGruposAll, postGrupo, putGrupo } from '@/core/services/GruposService';
import { Companhia, Grupos, Servicos } from '@/core/models';
import Swal from 'sweetalert2';
import { Preloader } from '@/store';
import { ref, onMounted, watch } from 'vue';
import { state } from '@/store/modules/layout';

interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'GroupsPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const perPage = ref<number>(10);
const page = ref<number>(1);
const totalItems = ref<number>(0);
const term = ref<string>('');
const manageGroup = ref<boolean>(false);
const manageService = ref<boolean>(false);
const showModalUpload = ref<boolean>(false);
const showModalCompany = ref<boolean>(false);
const showModalService = ref<boolean>(false);
const data = ref<Grupos>({});
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Nome', field: 'nome' },
  { label: 'Opções', field: 'actions' },
]);
const headersCompany = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Empresa', field: 'nome' },
  { label: 'Opções', field: 'actions' },
]);

const headersService = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Link/Serviço', field: 'nome' },
  { label: 'Tipo', field: 'tipo' },
  { label: 'Destino', field: 'destino' },
  { label: 'Opções', field: 'actions' },
]);
const company = ref<number[]>();
const service = ref<number[]>();
const companys = ref<Companhia[]>([]);
const servicoss = ref<Servicos[]>([]);
const items = ref<any[]>([]);
const empresas = ref<IOptionsSelect[]>([]);
const servicos = ref<IOptionsSelect[]>([]);
const sortable = ref<any>();
const addNew = ref<boolean>(false);
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}
function handleEdit(payload: Grupos, isApp: boolean = false) {
  console.log(payload, 'Edit');
  type.value = 'E';
  data.value = JSON.parse(JSON.stringify(payload));

  if (isApp) {
    addNew.value = true;
  } else {
    showModalUpload.value = true;
  }
}
function handleAdd(payload) {
  console.log(payload, 'Add');

  data.value = JSON.parse(JSON.stringify(payload));

  if (state.isMobile) manageService.value = true;
  else showModalService.value = true;
}
function handleCompany(payload) {
  data.value = JSON.parse(JSON.stringify(payload));

  if (state.isMobile) manageGroup.value = true;
  else showModalCompany.value = true;
}
function clear() {
  data.value = {};
  data.value.id = 0;
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function setCompany() {
  companys.value
    .filter((f) => company.value?.find((ff) => f.id == ff))
    .map((x) => {
      data.value.companhiaDTOLista?.push({ id: x.id, nome: x.nome });
    });

  company.value = [];
}
function handleDeleteCompany(company: Companhia) {
  data.value.companhiaDTOLista = data.value.companhiaDTOLista?.filter((f) => f.id != company.id);
}

function handleSaveCompany() {
  const dados: Grupos = JSON.parse(JSON.stringify(data.value));

  if (!dados.companhiaIntLista) dados.companhiaIntLista = [];
  dados.companhiaIntLista = dados.companhiaDTOLista?.map((f) => f.id);
  dados.companhiaDTOLista = undefined;
  Preloader.actions.ActionSetPreloader(true);
  putGrupo(dados)
    .then(() => {
      // swall
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: `Gestão de empresas  atualizadas com sucesso!`,
      });
      loadData();
      showModalCompany.value = false;
      manageGroup.value = false;
    })
    .catch((err) => {
      console.log(err);
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu algum erro',
      });
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function setService() {
  servicoss.value
    .filter((f) => service.value?.find((ff) => f.id == ff))
    .map((x) => {
      data.value.servicoDTOLista?.push({
        tipo: x.type,
        destino: x.target,
        id: x.id,
        nome: x.nome,
      });
    });

  service.value = [];
}
function handleDeleteService(service) {
  data.value.servicoDTOLista = data.value.servicoDTOLista?.filter((f) => f.id != service.id);
}

function handleSaveService() {
  const dados: Grupos = JSON.parse(JSON.stringify(data.value));

  if (!dados.companhiaIntLista) dados.companhiaIntLista = [];
  dados.servicoIntLista = dados.servicoDTOLista?.map((f) => f.id);
  dados.servicoDTOLista = undefined;
  Preloader.actions.ActionSetPreloader(true);
  putGrupo(dados)
    .then(() => {
      // swall
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: `Gestão de serviços atualizados com sucesso!`,
      });
      loadData();
      showModalService.value = false;
      manageService.value = false;
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function searching(event: string | undefined) {
  console.log('aqui foi', event);
  page.value = 1;
  term.value = String(event);
}
function loadServicos() {
  getServicosAll(0, 99999999).then((r) => {
    servicos.value = r.lista
      .filter((f) => f.archived == 0)
      .map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    servicoss.value = r.lista.filter((f) => f.archived == 0);
  });
}
function loadEmpresa() {
  getCompanhiaAll(0, 99999999).then((r) => {
    empresas.value = r.lista
      .filter((f) => f.archived == 0)
      .map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    companys.value = r.lista.filter((f) => f.archived == 0);
  });
}

function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);
  if (!data.value.id) {
    data.value.archived = 0;
    data.value.id = undefined;
    postGrupo(data.value)
      .then(() => {
        // Swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Grupo ${data.value.nome} foi criado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    putGrupo(data.value)
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Grupo ${data.value.nome} foi atualizado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}
function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalItems.value = 0;

  let promisse;

  if (sortable.value) {
    promisse = getGruposAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = getGruposAll(page.value - 1, perPage.value, term.value);
  }

  // getGruposAll(page.value - 1, perPage.value, term.value)
  promisse
    .then((resp) => {
      items.value = resp.lista;
    
      totalItems.value = resp.totalItems - items.value.filter((f) => f.archived == 1).length;
    })

    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

onMounted(() => {
  loadEmpresa();
  loadServicos();
  loadData();
});
watch(
  () => page.value,
  () => {
    loadData();
  },
);

watch(
  () => term.value,
  () => {
    loadData();
  },
);

watch(
  () => perPage.value,
  () => {
    loadData();
  },
);

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'groups') {
      page.value = 1;
      loadEmpresa();
      loadServicos();
      loadData();
    }
  },
);

function handleDelete(payload: Companhia) {
  console.log(payload, 'Delete');

  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente deletar o grupo <b>${payload.nome}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);
      payload.archived = 1;
      putGrupo(payload)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              title: 'Sucesso',
              text: `Grupo ${payload.nome} foi deletado com sucesso!`,
              icon: 'success',
            });
          }
          loadData();
        })

        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}

const __returned__ = { props, perPage, page, totalItems, term, manageGroup, manageService, showModalUpload, showModalCompany, showModalService, data, headers, headersCompany, headersService, company, service, companys, servicoss, items, empresas, servicos, sortable, addNew, type, replacement, handleEdit, handleAdd, handleCompany, clear, sort, setCompany, handleDeleteCompany, handleSaveCompany, setService, handleDeleteService, handleSaveService, searching, loadServicos, loadEmpresa, handleAddOrUpdate, loadData, handleDelete, get AtomButton() { return AtomButton }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get AtomInput() { return AtomInput }, get WorkIcon() { return WorkIcon }, get ActivityIcon() { return ActivityIcon }, get AtomSelect() { return AtomSelect }, get PlusIcon() { return PlusIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})