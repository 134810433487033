import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "me-4 d-flex align-center"
}
const _hoisted_3 = {
  key: 1,
  class: "ms-4 d-flex align-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: $props.disabled,
    class: _normalizeClass([$props.btnNotify ? 'btn-notification' : [`bg-${$setup.backgroundColor}`, $props.outlined ? 'btn-outlined' : $props.radius ? 'btn-radius' :  $props.radiusOutlined ? 'btn-radiusOutlined' : ''], "d-flex align-center"])
  }, [
    ($props.prependIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "prependIcon", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("p", {
      class: _normalizeClass([$props.center == 'text-center' ? `mx-auto` : $props.center, "d-flex align-center"])
    }, [
      ($props.IconText)
        ? _renderSlot(_ctx.$slots, "IconText", { key: 0 }, undefined, true)
        : _createCommentVNode("v-if", true),
      _cache[0] || (_cache[0] = _createTextVNode()),
      _createElementVNode("span", {
        class: _normalizeClass($props.IconText ? 'mr-2' : '')
      }, _toDisplayString($props.text), 3 /* TEXT, CLASS */),
      ($props.IconText)
        ? _renderSlot(_ctx.$slots, "IconTextEnd", { key: 1 }, undefined, true)
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    ($props.appendIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "appendIcon", {}, undefined, true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 10 /* CLASS, PROPS */, _hoisted_1))
}