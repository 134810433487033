import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["fill"]
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", {
    xmlns: "http://www.w3.org/2000/svg",
    width: "56",
    height: "56",
    viewBox: "0 0 56 56",
    fill: $setup.props.colorBackground || 'none'
  }, [
    _createCommentVNode(" <rect width=\"56\"  height=\"56\" rx=\"8\" :fill=\"props.colorBackground || 'none'\" fill-opacity=\"0.1\" /> "),
    _createElementVNode("path", {
      d: "M22.67 30H20C18.9 30 18 30.9 18 32V37C18 37.55 18.45 38 19 38H22.67C23.22 38 23.67 37.55 23.67 37V31C23.67 30.45 23.22 30 22.67 30Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M29.33 26H26.66C25.56 26 24.66 26.9 24.66 28V37C24.66 37.55 25.11 38 25.66 38H30.33C30.88 38 31.33 37.55 31.33 37V28C31.33 26.9 30.44 26 29.33 26Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_3),
    _createElementVNode("path", {
      d: "M36 33H33.33C32.78 33 32.33 33.45 32.33 34V37C32.33 37.55 32.78 38 33.33 38H37C37.55 38 38 37.55 38 37V35C38 33.9 37.1 33 36 33Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_4),
    _createElementVNode("path", {
      d: "M31.01 20.85C31.32 20.54 31.44 20.17 31.34 19.85C31.24 19.53 30.93 19.3 30.49 19.23L29.53 19.07C29.49 19.07 29.4 19 29.38 18.96L28.85 17.9C28.45 17.09 27.54 17.09 27.14 17.9L26.61 18.96C26.6 19 26.51 19.07 26.47 19.07L25.51 19.23C25.07 19.3 24.77 19.53 24.66 19.85C24.56 20.17 24.68 20.54 24.99 20.85L25.73 21.6C25.77 21.63 25.8 21.75 25.79 21.79L25.58 22.71C25.42 23.4 25.68 23.71 25.85 23.83C26.02 23.95 26.39 24.11 27 23.75L27.9 23.22C27.94 23.19 28.07 23.19 28.11 23.22L29 23.75C29.28 23.92 29.51 23.97 29.69 23.97C29.9 23.97 30.05 23.89 30.14 23.83C30.31 23.71 30.57 23.4 30.41 22.71L30.2 21.79C30.19 21.74 30.22 21.63 30.26 21.6L31.01 20.85Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_5)
  ], 8 /* PROPS */, _hoisted_1))
}