<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="white" />
    <rect width="48" height="48" rx="24" fill="#FF5C00" fill-opacity="0.1" />
    <path
      d="M32.9998 34.0002H14.9998C14.5898 34.0002 14.2498 33.6602 14.2498 33.2502C14.2498 32.8402 14.5898 32.5002 14.9998 32.5002H32.9998C33.4098 32.5002 33.7498 32.8402 33.7498 33.2502C33.7498 33.6602 33.4098 34.0002 32.9998 34.0002Z"
      fill="#FF5C00"
    />
    <path
      d="M31.0198 15.4802C29.0798 13.5402 27.1798 13.4902 25.1898 15.4802L23.9798 16.6902C23.8798 16.7902 23.8398 16.9502 23.8798 17.0902C24.6398 19.7402 26.7598 21.8602 29.4098 22.6202C29.4498 22.6302 29.4898 22.6402 29.5298 22.6402C29.6398 22.6402 29.7398 22.6002 29.8198 22.5202L31.0198 21.3102C32.0098 20.3302 32.4898 19.3802 32.4898 18.4202C32.4998 17.4302 32.0198 16.4702 31.0198 15.4802Z"
      fill="#FF5C00"
    />
    <path
      d="M27.6098 23.5302C27.3198 23.3902 27.0398 23.2502 26.7698 23.0902C26.5498 22.9602 26.3398 22.8202 26.1298 22.6702C25.9598 22.5602 25.7598 22.4002 25.5698 22.2402C25.5498 22.2302 25.4798 22.1702 25.3998 22.0902C25.0698 21.8102 24.6998 21.4502 24.3698 21.0502C24.3398 21.0302 24.2898 20.9602 24.2198 20.8702C24.1198 20.7502 23.9498 20.5502 23.7998 20.3202C23.6798 20.1702 23.5398 19.9502 23.4098 19.7302C23.2498 19.4602 23.1098 19.1902 22.9698 18.9102C22.9486 18.8648 22.9281 18.8196 22.9082 18.7747C22.7606 18.4414 22.326 18.3439 22.0682 18.6017L16.3398 24.3302C16.2098 24.4602 16.0898 24.7102 16.0598 24.8802L15.5198 28.7102C15.4198 29.3902 15.6098 30.0302 16.0298 30.4602C16.3898 30.8102 16.8898 31.0002 17.4298 31.0002C17.5498 31.0002 17.6698 30.9902 17.7898 30.9702L21.6298 30.4302C21.8098 30.4002 22.0598 30.2802 22.1798 30.1502L27.9011 24.4288C28.1606 24.1693 28.0627 23.7238 27.7251 23.5798C27.6871 23.5636 27.6487 23.547 27.6098 23.5302Z"
      fill="#FF5C00"
    />
  </svg>
</template>
