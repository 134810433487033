import { defineComponent as _defineComponent } from 'vue'
import { watch, computed } from 'vue';
import { MoleculeCard } from '@/components/molecules';
import { AtomProgressBar, AtomToogleButton } from '@/components/atoms';
import VueHighcharts from 'vue3-highcharts';
import { IDataChart, IOptionsSelect, IProgress, IStep, IToggleButon } from '@/types';
import { state } from '@/store/modules/layout';

interface IProps {
  title: string;
  legend?: boolean;
  isSteps: boolean;
  data: Array<IDataChart>;
  type: string;
  textX?: string;
  textY?: string;
  progress?: Array<IProgress>;
  hasToggle?: boolean;
  buttons?: IToggleButon[];
  hasDropdownList?: boolean;
  optionsDropDown?: IOptionsSelect[];
  colorSeries?: string;
  height?: number;
  steps?: IStep[]
  currentStep?: string
  loading?: boolean
  isCalendarSelect?: boolean
  textEmpty?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsChartSaltos',
  props: {
    title: { type: String, required: true, default: '' },
    legend: { type: Boolean, required: false, default: true },
    isSteps: { type: Boolean, required: true, default: false },
    data: { type: Array, required: true },
    type: { type: String, required: true },
    textX: { type: String, required: false },
    textY: { type: String, required: false },
    progress: { type: Array, required: false },
    hasToggle: { type: Boolean, required: false, default: false },
    buttons: { type: Array, required: false },
    hasDropdownList: { type: Boolean, required: false },
    optionsDropDown: { type: Array, required: false },
    colorSeries: { type: String, required: false, default: '#000' },
    height: { type: Number, required: false },
    steps: { type: Array, required: false },
    currentStep: { type: String, required: false },
    loading: { type: Boolean, required: false },
    isCalendarSelect: { type: Boolean, required: false, default: false },
    textEmpty: { type: String, required: false }
  },
  emits: ['handleToggle', 'onChangeDropDownList'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;
// const chartOptions = {
//   chart: {
//     type: props.type,
//   },
//   title: {
//     text: '',
//   },
//   xAxis: {
//     categories: props.data.map((f) => f.title),
//   },
//   yAxis: {
//     title: {
//       text: props.textY,
//     },
//   },
//   plotOptions: {
//     series: {
//       borderRadius: 18,
//     },
//   },
//   series: [
//     {
//       name: props.textX,
//       data: props.data.map((f) => f.value),
//     },
//   ],
// };
let chartOptions = {};
changeChartOptions();
function changeChartOptions() {
  chartOptions = {
    chart: {
      type: props.type,
      height: props.height,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: props.data.map((f) => f.title),
    },
    yAxis: {
      title: {
        text: props.textY,
      },
    },
    plotOptions: {
      series: {
        borderRadius: 18,
      },
    },
    series: [
      {
        showInLegend: props.legend,
        name: props.textX,
        data: props.data.map((f) => f.value),
        color: props.colorSeries,
      },
    ],
  };
}
function handleToogleChange($event) {
  emit('handleToggle', $event);
}
function handleChangeDropdownList(event) {
  emit('onChangeDropDownList', event);
}

const isEmpty = computed(() => {
  return (props.steps ?? []).length == 0;
});

watch(
  () => props.data,
  () => {
    changeChartOptions();
  }
);

const __returned__ = { props, emit, get chartOptions() { return chartOptions }, set chartOptions(v) { chartOptions = v }, changeChartOptions, handleToogleChange, handleChangeDropdownList, isEmpty, get MoleculeCard() { return MoleculeCard }, get AtomProgressBar() { return AtomProgressBar }, get AtomToogleButton() { return AtomToogleButton }, get VueHighcharts() { return VueHighcharts }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})