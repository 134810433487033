import { createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["MoleculeTitleSetings"], {
      class: "my-5",
      addBtn: true,
      title: "Análise coletor saltos",
      prependIcon: ""
    }, {
      prependIcon: _withCtx(() => [
        _createVNode($setup["GraphIconColor"], { class: "mt-2" })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["MoleculeCard"], {
      title: "Descubra qual caminho seus serviços fazem até chegarem em você",
      "text-tooltip": "Descubra o caminho e saltos dos seus links/serviços selecionando a unidade e o link/serviço desejado. Explore a latência, o jitter e a perda de pacotes em cada salto, além de detalhes do host, como IP, localização e coordenadas geográficas. Otimize sua conectividade identificando pontos de congestionamento ou falhas na rede para uma melhor experiência de uso."
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "px-3" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              md: "5"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  label: "Escolha a unidade",
                  options: $setup.units,
                  modelValue: $setup.escolha.unit,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.escolha.unit) = $event)),
                  rounded: "lg",
                  onOnChangeDropDownList: $setup.handleSelect
                }, null, 8 /* PROPS */, ["options", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "5"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  rounded: "lg",
                  label: "Agora o serviço",
                  options: $setup.services,
                  disabled: !$setup.escolha.unit,
                  modelValue: $setup.escolha.service,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.escolha.service) = $event))
                }, null, 8 /* PROPS */, ["options", "disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "12",
              md: "2"
            }, {
              default: _withCtx(() => [
                (!$setup.state.isMobile && $setup.btnreplacement && $setup.escolha.unit && $setup.escolha.service)
                  ? (_openBlock(), _createBlock($setup["AtomButton"], {
                      key: 0,
                      text: "Resetar",
                      background: "secondary",
                      class: "mt-5 w-100",
                      center: "text-center",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => {
            $setup.reset();
            $setup.btnreplacement = false;
          })
                    }))
                  : _createCommentVNode("v-if", true),
                (!$setup.state.isMobile && !$setup.btnreplacement)
                  ? (_openBlock(), _createBlock($setup["AtomButton"], {
                      key: 1,
                      center: "text-center",
                      text: "Iniciar",
                      disabled: !($setup.escolha.unit && $setup.escolha.service),
                      background: "primary",
                      class: "mt-5 w-100",
                      onClick: _cache[3] || (_cache[3] = ($event: any) => {
            $setup.buscar();
            $setup.btnreplacement = true;
          })
                    }, null, 8 /* PROPS */, ["disabled"]))
                  : _createCommentVNode("v-if", true),
                ($setup.state.isMobile && $setup.btnreplacement && $setup.escolha.unit && $setup.escolha.service)
                  ? (_openBlock(), _createBlock($setup["AtomButton"], {
                      key: 2,
                      center: "text-center",
                      onClick: _cache[4] || (_cache[4] = ($event: any) => {
            $setup.reset();
            $setup.btnreplacement = false;
          }),
                      class: "mx-auto w-100 py-3 rounded-pill",
                      background: "secondary ",
                      text: 'Resetar'
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.state.isMobile && !$setup.btnreplacement)
                  ? (_openBlock(), _createBlock($setup["AtomButton"], {
                      key: 3,
                      center: "text-center",
                      disabled: !($setup.escolha.unit && $setup.escolha.service),
                      background: "secondary ",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => {
            $setup.buscar();
            $setup.btnreplacement = true;
          }),
                      class: "mx-auto w-100 py-3 rounded-pill",
                      text: 'Iniciar'
                    }, null, 8 /* PROPS */, ["disabled"]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            (!$setup.showSaltos)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "12",
                  class: "text-center",
                  "align-self": "center"
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createElementVNode("div", { class: "quadrado" }, [
                      _createElementVNode("p", { class: "text-center" }, [
                        _createTextVNode(" Bem vindo!"),
                        _createElementVNode("br"),
                        _createTextVNode(" Vamos começar a ver o seu trajeto? Basta clicar nos saltos e você consegue acompanhar todo o caminho e ainda analisar alguns dados como: "),
                        _createElementVNode("br"),
                        _createTextVNode(" - Média de latência, sendo o tempo que um sinal ou pacote de informações leva para chegar até seu destino,um exemplo é o tempo que você demora para acessar um site."),
                        _createElementVNode("br"),
                        _createTextVNode(" - Média de Jitter, é variação da latência, por exemplo, se ontem você demorou 5 segundos para acessar um site e hoje você demora 6 segundos, o jitter será esse 1 segundo de diferença;"),
                        _createElementVNode("br"),
                        _createTextVNode(" - Perda de pacotes, os pacotes de dados muitas vezes podem se perder no caminho até o destinatário, causando interrupções na conexão, lentidão nos serviços e até perda total de conectividade na rede."),
                        _createElementVNode("br"),
                        _createTextVNode(" - Host: IP do Host analisado por salto."),
                        _createElementVNode("br"),
                        _createTextVNode(" - Localização: Informa onde está sendo analisado o serviço daquele host por salto.. "),
                        _createElementVNode("br"),
                        _createTextVNode(" - Latitude e Longitude: A latitude e a longitude permitem a localização do host por salto. ")
                      ])
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.loading)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  col: "12"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_progress_linear, {
                      indeterminate: "",
                      color: "rgb(255, 92, 0)",
                      height: "10"
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.showSaltos && $setup.steps.length)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "12",
                  class: "mt-8"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomProgressSaltos"], {
                      steps: $setup.steps,
                      "current-step": $setup.currentStep,
                      isLarge: ""
                    }, null, 8 /* PROPS */, ["steps", "current-step"])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.showSaltos && !$setup.steps.length)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 3,
                  cols: "12"
                }, {
                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                    _createElementVNode("div", { class: "emptyText pa-4" }, [
                      _createElementVNode("h5", null, "Não há dados para essa consulta")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}