import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-tootltip" }
const _hoisted_2 = { class: "d-flex justify-space-between" }
const _hoisted_3 = {
  style: {"width":"230px"},
  class: "mr-1"
}
const _hoisted_4 = { style: {"width":"230px"} }
const _hoisted_5 = { class: "d-flex align-center" }
const _hoisted_6 = { class: "flex-1" }
const _hoisted_7 = { class: "d-flex align-center" }
const _hoisted_8 = { class: "flex-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[9] || (_cache[9] = _createElementVNode("h1", null, "Paulista1", -1 /* HOISTED */)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[4] || (_cache[4] = _createElementVNode("h4", null, [
          _createTextVNode("Qualidade: "),
          _createElementVNode("span", { class: "text-white" }, "64% ")
        ], -1 /* HOISTED */)),
        _createVNode($setup["OrganismsChart"], {
          noCard: "",
          title: "Média disponibilidade dos serviços",
          type: "line",
          legend: false,
          data: $setup.props.dataCharGeral,
          "color-series": "#0000",
          height: 80,
          backgroundColor: "#212529"
        }, null, 8 /* PROPS */, ["data"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _cache[5] || (_cache[5] = _createElementVNode("h4", null, [
          _createTextVNode("Disponibilidade: "),
          _createElementVNode("span", { class: "text-white" }, "92%")
        ], -1 /* HOISTED */)),
        _createVNode($setup["OrganismsChart"], {
          noCard: "",
          title: "Média disponibilidade dos serviços",
          type: "line",
          legend: false,
          data: $setup.props.dataCharGeral,
          "color-series": "#0000",
          height: 80,
          backgroundColor: "#212529"
        }, null, 8 /* PROPS */, ["data"])
      ])
    ]),
    _createElementVNode("div", null, [
      _cache[8] || (_cache[8] = _createStaticVNode("<div class=\"d-flex justify-center\" data-v-e4350124><p class=\"text-gray text-xs mr-4\" data-v-e4350124><span class=\"dot bg-gray\" data-v-e4350124></span> Download</p><p class=\"text-gray text-xs\" data-v-e4350124><span class=\"dot bg-orange\" data-v-e4350124></span> Upload</p></div>", 1)),
      _createElementVNode("div", _hoisted_5, [
        _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-gray text-xs title-progress" }, "LNK-VIVO-GHD", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_6, [
          _createVNode($setup["AtomProgressBar"], {
            margin1: "",
            "progress-color": "#adb5bd",
            colorPercent: "#fff",
            modelValue: $setup.progressUpload,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.progressUpload) = $event))
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["AtomProgressBar"], {
            margin1: "",
            "progress-color": "#ff5c00",
            colorPercent: "#fff",
            modelValue: $setup.progressUpload,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.progressUpload) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "text-gray text-xs title-progress" }, "MUNDIVOX", -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_8, [
          _createVNode($setup["AtomProgressBar"], {
            margin1: "",
            "progress-color": "#adb5bd",
            colorPercent: "#fff",
            modelValue: $setup.progressUpload,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.progressUpload) = $event))
          }, null, 8 /* PROPS */, ["modelValue"]),
          _createVNode($setup["AtomProgressBar"], {
            margin1: "",
            "progress-color": "#ff5c00",
            colorPercent: "#fff",
            modelValue: $setup.progressUpload,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.progressUpload) = $event))
          }, null, 8 /* PROPS */, ["modelValue"])
        ])
      ])
    ])
  ]))
}