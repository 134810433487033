import { defineComponent as _defineComponent } from 'vue'
import { ref, computed, watch, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { AtomToogleButton, HomeIcon2, AtomSelect, AtomSubTitle } from '@/components/atoms';
import { MoleculeDiagnostic, MoleculeTabs } from '@/components/molecules';
import { OrganismsMapCharts, OrganismsChart } from '@/components/organisms';
import { getters } from '@/store/modules/layout';
import { formatLang } from '@/utils/formatNumber';
import { FiltersUnit, Preloader } from '@/store';
import { Authentication } from '@/store/index';
import { getGrafico, getPredict } from '@/core/services/PredictService';
import {
  linksPorStatusConectividade,
  servicosPorStatusConectividade,
  qualidadeDisponibilidadePorlink,
  qualidadeDisponibilidadePorServico,
  perdaPacotesGrafico,
  graficoConsumoInternet,
  geolocalizacao,
  pontosPorHoraGrafico,
  // pontosPorHoraGrafico,
} from '@/core/services/GraficoService';
import { state } from '@/store/modules/layout';

import { IDataChart, IDataMapChart, IItemDiagnostic, IOptionsSelect, IToggleButon } from '@/types';
import { onMounted } from 'vue';
import { getTempoReal, getTempoRealColetor, getTempoRealUsuarios } from '@/core/services/MonitorTempoRealService';
interface IProps {
  roda?: boolean;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OverView',
  props: {
    roda: { type: Boolean, required: false, default: false }
  },
  emits: ['realtime', 'redirect'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const router = useRouter();
const emit = __emit;

const props = __props;
// const citySelected = ref<any>()
const toogleTotaisPredict = ref<string>('atual');
const itemsDiagnostics = ref<any[]>([]);
const toogleTotais = ref<string>('');
const totalizadores = ref<any[]>([]);
const toggleMarcado = ref<string>('');
const perdaTotalizadores = ref<any>();
const loadingScoreConectividade = ref<boolean>(true);
const optionConsumo = ref<string>('minutos');
const optionPerda = ref<string>('minutos');

const optionsLinksOrServices = ref<IOptionsSelect[]>([]);

const optionsList = computed((): IToggleButon[] => {
  return toogleTotaisPredict.value == 'atual'
    ? [
        { value: 'minutos', text: '30 Minutos', selected: true },
        { value: 'horas', text: '1 Hora' },
        { value: 'dias', text: 'Dia atual' },
      ]
    : [
        { value: 'minutos', text: '+ 30 Minutos', selected: true },
        { value: 'horas', text: '+ 1 Hora' },
        { value: 'dias', text: '+ 1 Dia' },
      ];
});
const optionsListPacotes = computed((): IToggleButon[] => {
  return toogleTotaisPredict.value == 'atual'
    ? [
        { value: 'minutos', text: '35 Minutos', selected: true },
        { value: 'horas', text: '1 Hora' },
      ]
    : [
        { value: 'minutos', text: ' 35 Minutos', selected: true },
        { value: 'horas', text: '+ 1 Hora' },
      ];
});
const optionsListConsumo = computed((): IToggleButon[] => {
  return toogleTotaisPredict.value == 'atual'
    ? [
        { value: 'minutos', text: '+ 30 Minutos', selected: true },
        { value: 'horas', text: '+ 1 Hora' },
      ]
    : [
        { value: 'minutos', text: '+ 30 Minutos', selected: true },
        { value: 'horas', text: '+ 1 Hora' },
      ];
});
const buttonsToggle = ref([
  {
    text: 'Links',
    value: 'porLink',
  },
  {
    text: 'Serviços',
    value: 'porServico',
  },
]);
const tabsPrevisibilidade = ref<any[]>([
  {
    label: 'Links',
    text: 'Links',
    value: 'Links',
  },
  {
    label: 'Serviços',
    text: 'Serviços',
    value: 'Serviços',
  },
]);

const linksOrServices = ref<number[]>([]);
const tabPrevisibilidade = ref(tabsPrevisibilidade.value[0].value);
const intevalGraficos = ref<any>();
const loaderChartGeral = ref<boolean>(true);
const dadosGraficoGeralBruto = ref<any[]>([]);
// const dataCharGeral = ref<IDataChartMore[] | IDataChart[]>([]);
const consumoTotalizadores = ref<any>({});
const loadingGeo = ref<boolean>(false);
const dataMaps = ref<IDataMapChart[]>([]);
const pontosPorHoraScoreConectividade = ref<number>();
const buttonsTogglePrevi = ref<IToggleButon[]>([
  { text: 'Qualidade', value: 'Qualidade', bgColor: '#FF5C00', textColor: '#fff' },
  { text: 'Disponibilidade', value: 'Disponibilidade' },
  // { text: 'Previsibilidade', value: 'predict', bgColor: '#212529', textColor: '#fff' },
]);
const buttonsTogglePredict = ref([
  {
    text: 'Atual',
    value: 'atual',
    bgColor: '#FF5C00',
    textColor: '#fff',
  },
  {
    text: 'Previsão',
    value: 'predict',
    bgColor: '#212529',
    textColor: '#fff',
    tooltip: `Por Regra, adotamos uma nova abordagem para prever problemas de links e/ou serviços de forma mais precisa e confiável, treinando cada modelo exclusivamente com dados específicos. Isso preserva o comportamento típico de cada link ou serviço de internet, resultando em previsões mais seguras e precisas.

A inteligência artificial (IA) utilizada é baseada em machine learning, permitindo que os modelos aprendam e se adaptem aos dados ao longo do tempo. Isso torna os modelos mais eficazes em reconhecer padrões e tendências, ajudando a identificar potenciais problemas antes que ocorram, promovendo uma gestão de rede mais proativa e eficiente.

Entretanto, esses modelos preditivos não conseguem identificar variações fora do padrão, como cortes de luz, interrupções inesperadas ou problemas de hardware. Esses eventos são considerados excepcionais (outliers) e não podem ser previstos com precisão pelos modelos analíticos. Em resumo, a nova abordagem proporciona uma predição em tempo real mais confiável e ajustada às necessidades específicas de cada link ou serviço, melhorando a gestão da rede.`,
  },
]);

const itemsDiagnosticsAll = ref<any[]>([
  {
    icon: 'ShieldDoneIcon',
    isPositive: true,
    item: 'Saudáveis',
    value: 0,
    nFloat: 0,
    color: '#15D095',
    id: '0',
    data: {},
    tooltipsText:
      'Indica que o link ou serviço tem baixa ou nenhuma perda de pacotes, e alta qualidade (jitter, latência e perda de pacotes baixa ou nula.). Por Regra, para que o estado seja considerado "saudável", a média de qualidade e disponibilidade precisa ser maior que 50%. Ou seja, é uma Métrica que indica a proporção de links/serviços classificados como "saudáveis" em relação ao total de links e de serviços.',
    tooltipsTextPrev:
      'A analise preditiva indicará que o link ou serviço terá baixa ou nenhuma perda de pacotes, e alta qualidade (jitter, latência e perda de pacotes baixa ou nula.). Por Regra, é uma Métrica que indica a proporção sobre como será classificado o estado "saudáveis" em relação ao total de links e de serviços para os próximos periodos de: +30 minutos, +3 horas e +8 horas.',
    text: 'Quando a Qualidade e a Disponibilidade estão acima de 50%',
    loading: true,
  },
  {
    icon: 'DangerTriangleIcon',
    isPositive: true,
    item: 'Instáveis',
    value: 0,
    nFloat: 0,
    color: '#FFE70D',
    id: '1',
    data: {},
    tooltipsText:
      'Indica que há algum link ou serviço com perda de pacotes frequente, recente, acima de 10%. Por Regra, para considerar uma estado instável, a média de qualidade deve ser maior ou igual a 50%, mas a média de disponibilidade deve ser menor que 50%. Ou seja, é uma Métrica que indica a proporção de serviços classificados como "instáveis" em relação ao total de links e serviços.',
    tooltipsTextPrev:
      'A analise preditiva indicará que o link ou serviço terá baixa ou nenhuma perda de pacotes, e alta qualidade (jitter, latência e perda de pacotes baixa ou nula.). Por Regra, é uma Métrica que indica a proporção sobre como será classificado o estado "instavél" em relação ao total de links e de serviços para os próximos periodos de: +30 minutos, +3 horas e +8 horas.',
    text: 'Quando a Qualidade está acima de 50% a Disponibilidade está abaixo de 50%',
    loading: true,
  },
  {
    icon: 'ArrowDownCircleIcon',
    isPositive: false,
    item: 'Degradados',
    value: 0,
    nFloat: 0,
    color: '#FF9E0D',
    id: '2',
    data: {},
    tooltipsText:
      'Indica que há algum link ou serviço com variações de qualidade (jitter ou latência), frequente e recente, acima dos padrões definidos para o serviço. Por Regra, para que o estado seja considerado "degradado", a média de qualidade deve ser menor que 50%, mas a média de disponibilidade deve ser maior ou igual a 50%. Ou seja, é uma Métrica que indica a proporção de serviços classificados como "degradados" em relação ao total de links e serviços.',
    tooltipsTextPrev:
      'A analise preditiva indicará que o link ou serviço terá baixa ou nenhuma perda de pacotes, e alta qualidade (jitter, latência e perda de pacotes baixa ou nula.). Por Regra, é uma Métrica que indica a proporção sobre como será classificado o estado "degradado" em relação ao total de links e de serviços para os próximos periodos de: +30 minutos, +3 horas e +8 horas',
    text: 'Quando a Qualidade está abaixo de 50% a Disponibilidade está acima de 50%',
    loading: true,
  },
  {
    icon: 'ShieldFailIcon',
    isPositive: false,
    item: 'Problemáticos',
    value: 0,
    nFloat: 0,
    color: '#FF0D21',
    id: '3',
    data: {},
    tooltipsText:
      'Indica que há algum link ou serviço com alta latência, jitter e perda de pacotes. Por Regra, para que o estado seja considerado "problemático", a média de qualidade e a média de disponibilidade devem ser ambas menores que 50%. Ou seja, é uma Métrica que indica a proporção de serviços classificados como "problemáticos" em relação ao total de links e serviços.',
    tooltipsTextPrev:
      'A analise preditiva indicará que o link ou serviço terá baixa ou nenhuma perda de pacotes, e alta qualidade (jitter, latência e perda de pacotes baixa ou nula.). Por Regra, é uma Métrica que indica a proporção sobre como será classificado o estado "problemático" em relação ao total de links e de serviços para os próximos periodos de: +30 minutos, +3 horas e +8 horas.',
    text: 'Quando a Qualidade e a Disponibilidade estão abaixo de 50%',
    loading: true,
  },
  {
    icon: 'ClockIcon',
    isPositive: true,
    item: 'Monitor Realtime',
    value: 0,
    nFloat: 0,
    doisValores: true,
    id: '4',
    label: ['Links/Serviços', 'Coletores/Máquinas'],
    data: {},
    tooltipsText:
      'Exibe o monitoramento em tempo real dos status de links e serviços online e offline, nome do coletor, unidade, nome do host e detalhes do S.O.',
    tooltipsTextPrev:
      'Exibe o monitoramento em tempo real dos status de links e serviços online e offline, nome do coletor, unidade, nome do host e detalhes do S.O.',
    legends: ['ONLINE', 'OFFLINE'],
    colors: ['#5cb85c', '#bb2124 '],
    loading: true,
  },
  {
    icon: 'ClockIcon',
    isPositive: true,
    item: 'Monitor Unidades',
    value: 0,
    nFloat: 0,
    doisValores: true,
    label: ['Unidades', 'Usuários Online'],
    id: '4',
    data: {},
    tooltipsText:
      'Exibe o monitoramento em tempo real dos status de links e serviços online e offline, nome do coletor, unidade, nome do host e detalhes do S.O.',
    tooltipsTextPrev:
      'Exibe o monitoramento em tempo real dos status de links e serviços online e offline, nome do coletor, unidade, nome do host e detalhes do S.O.',
    legends: ['ONLINE', 'OFFLINE'],
    colors: ['#5cb85c', '#bb2124 '],
    loading: true,
  },
  // {
  //   icon: 'ClockIcon',
  //   isPositive: true,
  //   item: 'Pontos por hora',
  //   value: 0,
  //   // nFloat: 0,
  //   id: '4',
  //   data: {},
  //   loading: true,
  // },
]);

const activeColorGeo = computed(() => {
  const color = toogleTotaisPredict.value === 'atual' ? '#FF9D66' : '#292E36';
  return color;
});

const dataChartPerdaComputado = computed(() => {
  let dados = [];
  if (optionPerda.value == 'minutos') {
    dados = (perdaTotalizadores.value?.perdaMinLista ?? []).map((x) => {
      return { title: `${x.insert_hour}:${x.insert_time}h`, value: x.perc_error };
    });
  } else if (optionPerda.value == 'horas') {
    dados = (perdaTotalizadores.value?.perdaHoraLista ?? []).map((x) => {
      return { title: `${x.insert_hour}:00h`, value: x.perc_error };
    });
  }

  return [
    {
      color: activeColor.value,
      name: 'Perda de Pacotes',
      data: dados,
    },
  ];
});

const activeColor = computed(() => {
  const color = toogleTotaisPredict.value === 'atual' ? '#FF5C00' : '#292E36';
  return color;
});

const dataChartComputado = computed(() => {
  let dadosDownload: any = [];
  let dadosUpload = [];

  if (optionConsumo.value == 'minutos') {
    dadosDownload = (consumoTotalizadores.value?.porMinuto?.consumoDownloadLista ?? []).map((x) => {
      return {
        title: `${x.unidadeTempo}h`,
        value: x.mbps,
      };
    });
    dadosUpload = (consumoTotalizadores.value?.porMinuto?.consumoUploadLista ?? []).map((x) => {
      return {
        title: `${x.unidadeTempo}h`,
        value: x.mbps,
      };
    });
  } else if (optionConsumo.value == 'horas') {
    dadosDownload = (consumoTotalizadores.value?.porHora?.consumoDownloadLista ?? []).map((x) => {
      return {
        title: `${x.unidadeTempo}h`,
        value: x.mbps,
      };
    });
    dadosUpload = (consumoTotalizadores.value?.porHora?.consumoUploadLista ?? []).map((x) => {
      return {
        title: `${x.unidadeTempo}h`,
        value: x.mbps,
      };
    });
  }

  return [
    {
      color: '#212529',
      name: 'Download',
      data: dadosDownload,
    },
    {
      color: '#FF5C00',
      name: 'Upload',
      data: dadosUpload,
    },
  ];
});

function handleClickCard(payload: IItemDiagnostic) {
  if (payload.item == 'Monitor Realtime' || payload.item == 'Monitor Unidades') {
    emit('redirect', true);
    return;
  }

  if (toogleTotaisPredict.value == 'atual') {
    router.push({ name: 'Investigação', params: { dado: payload.item, item: toogleTotais.value } });
  } else {
    router.push({ name: 'Investigação Predição', params: { dado: payload.item, item: toogleTotais.value } });
  }
}

function regexValidarY() {
  const numero = Number(this.value ?? this.y);
  const percentualFormatado = numero.toString().replace(/^(\d+(\.\d+)?)$/, '$1%');
  return percentualFormatado;
}

async function handleMonitorealTime() {
  const dataColetor = await getTempoRealColetor();
  const dados = await getTempoReal(
    Number(Authentication.getters.getUser.value.id),
    toogleTotais.value == 'porLink' ? 'LNK' : 'SRV',
  );
  dataColetor.map((x) => delete x.archived);
  let index = itemsDiagnostics.value.findIndex((f) => f.item == 'Monitor Realtime');

  if (index >= 0) {
    itemsDiagnostics.value[index].value = dados.filter((f) => f.monitor == 'ON-Line').length;
    itemsDiagnostics.value[index].nFloat = dados.filter((f) => f.monitor == 'OFFLine').length;
    itemsDiagnostics.value[index].value2 = dados
      .filter((f) => f.monitor == 'ON-Line')
      .map((x) => x.nomeColetor)
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      }).length;
    itemsDiagnostics.value[index].nFloat2 = dados
      .filter((f) => f.monitor == 'OFFLine')
      .map((x) => x.nomeColetor)
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      }).length;
    itemsDiagnostics.value[index].data = dados;
    itemsDiagnostics.value[index].loading = false;
    emit('realtime', { dados: dados, coletores: dataColetor });

    // Voltar aqui
    const dadosUsuarios = await getTempoRealUsuarios(Number(Authentication.getters.getUser.value.id));
    index = itemsDiagnostics.value.findIndex((f) => f.item == 'Monitor Unidades');
    itemsDiagnostics.value[index].value = dados
      .filter((f) => f.monitor == 'ON-Line')
      .map((x) => x.nomeUnidade)
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      }).length;

    itemsDiagnostics.value[index].nFloat = dados
      .filter((f) => f.monitor == 'OFFLine')
      .map((x) => x.nomeUnidade)
      .filter((value, index, array) => {
        return array.indexOf(value) === index;
      }).length;
    itemsDiagnostics.value[index].value2 = dadosUsuarios;
    itemsDiagnostics.value[index].data = dadosUsuarios;
    itemsDiagnostics.value[index].loading = false;
  }
}

async function handleToogleBtnAdpt(item: string, hasFazLoadd?: boolean) {
  toogleTotais.value = item;
  if (hasFazLoadd == undefined) hasFazLoadd = true;

  itemsDiagnostics.value.map((x) => {
    if (x.item != 'Pontos por hora' && x.item != 'Monitor Realtime' && x.item != 'Monitor Unidades') x.loading = true;
  });
  handleMonitorealTime();
  if (toogleTotaisPredict.value == 'atual') {
    await handleToogleBtn(item, hasFazLoadd);
  } else {
    await handleToogleBtnPredict(item, hasFazLoadd);
  }
}

async function qualidadeDisponibilidadePorlinkService() {
  loaderChartGeral.value = true;
  const id = Authentication.state.id ?? 187;
  dadosGraficoGeralBruto.value = await qualidadeDisponibilidadePorlink(6, id, FiltersUnit.getters.filters.value);
  optionsLinksOrServices.value = [];
  optionsLinksOrServices.value = dadosGraficoGeralBruto.value
    .map((x) => x.link)
    .filter((value, index, array) => {
      return array.indexOf(value) === index;
    })
    .map((x, index) => {
      return { text: x, value: index + 1 } as IOptionsSelect;
    });

  loaderChartGeral.value = false;
}

async function qualidadeDisponibilidadePorServicoService() {
  loaderChartGeral.value = true;
  const id = Authentication.state.id ?? 187;
  dadosGraficoGeralBruto.value = await qualidadeDisponibilidadePorServico(6, id, FiltersUnit.getters.filters.value);
  optionsLinksOrServices.value = [];
  optionsLinksOrServices.value = dadosGraficoGeralBruto.value
    .map((x) => x.servico)
    .filter((value, index, array) => {
      return array.indexOf(value) === index;
    })
    .map((x, index) => {
      return { text: x, value: index + 1 } as IOptionsSelect;
    });
  loaderChartGeral.value = false;
}

function perdaPacotes() {
  perdaPacotesGrafico(Authentication.state.id as number, FiltersUnit.getters.filters.value).then((f) => {
    perdaTotalizadores.value = f;
  });
}

async function qualidadeDisponibilidadePredict() {
  loaderChartGeral.value = true;
  dadosGraficoGeralBruto.value = [];
  const id = Authentication.state.id ?? 187;
  const dados = await getGrafico(tabPrevisibilidade.value == 'Links' ? 'LNK' : 'INT', id);
  if (dados.status == '200') {
    const dadosInsert: any[] = dados.data.map((x) => {
      return {
        insert_time: x.TIME,
        name: x.client_name,
        quality: x.QUALITY,
        availability: x.AVAILABILITY,
      };
    });

    // dados.data.map((x) => {
    //   return { insert_time: x.TIME, quality: x.QUALITY, availability: x.AVAILABILITY };
    // });

    // Object.keys(dados.data).map((x) => {
    //   const obj = {
    //     insert_time: x,
    //     quality: dados.data[x]['QUALITY'],
    //     availability: dados.data[x]['AVAILABILITY'],
    //   };
    //   dadosInsert.push(obj);
    // });

    dadosGraficoGeralBruto.value = dadosInsert;
    // optionsLinksOrServices.value = [];
    // optionsLinksOrServices.value = dadosGraficoGeralBruto.value
    //   .map((x) => x.link)
    //   .filter((value, index, array) => {
    //     return array.indexOf(value) === index;
    //   })
    //   .map((x, index) => {
    //     return { text: x, value: index + 1 } as IOptionsSelect;
    //   });
  } else {
    dadosGraficoGeralBruto.value = [];
  }
}

function consumoDeInternet() {
  graficoConsumoInternet(Authentication.state.id as number, FiltersUnit.getters.filters.value).then((f) => {
    consumoTotalizadores.value = f;
  });
}

const dataCharAreaScoreConectividade = ref<IDataChart[]>([
  { title: '', value: 0 },
  { title: '', value: 0 },
]);

function pontosPorHoraAction() {
  const id = Authentication.state.id ?? 187;
  loadingScoreConectividade.value = true;
  pontosPorHoraGrafico(id, FiltersUnit.getters.filters.value)
    .then((f) => {
      dataCharAreaScoreConectividade.value = f.horasSCLista.map((f) => {
        return { title: `${f.hora}h`, value: Number(f.pontosPorHora) } as IDataChart;
      });
      pontosPorHoraScoreConectividade.value = f.pontosPorHora;

      // itemsDiagnostics.value[index].value = f.pontosPorHora;
      // itemsDiagnostics.value[index].data = f;
      // itemsDiagnostics.value[index].loading = false;
      loadingScoreConectividade.value = false;
    })
    .catch(() => {
      dataCharAreaScoreConectividade.value = [
        { title: '', value: 0 },
        { title: '', value: 0 },
      ];
      loadingScoreConectividade.value = false;

      // itemsDiagnostics.value[index].value = 0;
      // itemsDiagnostics.value[index].data = {};
      // itemsDiagnostics.value[index].loading = false;
    });
}

function setarIntervalos() {
  intevalGraficos.value = setInterval(() => {
    if (toggleMarcado.value == 'predict') {
      qualidadeDisponibilidadePredict();
    } else {
      if (tabPrevisibilidade.value == 'Links') {
        qualidadeDisponibilidadePorlinkService();
      } else {
        qualidadeDisponibilidadePorServicoService();
      }
      perdaPacotes();
      consumoDeInternet();
      pontosPorHoraAction();
      geo();
    }

    handleToogleAtualPredict(toogleTotaisPredict.value, false);
    handleMonitorealTime();
  }, 60000);
}

async function geo() {
  try {
    loadingGeo.value = true;
    const geo = await geolocalizacao(FiltersUnit.getters.filters.value);
    dataMaps.value = geo.map((x) => {
      return {
        uf: String(x.uf).toUpperCase(),
        cidade: String(x.cidade).toUpperCase(),
        value: Number(x.quantidade),
        lat: Number(x.lat),
        lng: Number(x.lng),
      };
    });
  } finally {
    loadingGeo.value = false;
  }
}

const dataChartGeralOptimize = computed(() => {
  const option = tabPrevisibilidade.value == 'Links' ? 'link' : 'servico';
  if (toggleMarcado.value == 'Qualidade') {
    const dados = optionsLinksOrServices.value
      .filter((f) => {
        return !!(linksOrServices.value ?? []).find((x) => x == f.value);
      })
      .map((x) => {
        return {
          color: '',
          name: x.text,
          data: dadosGraficoGeralBruto.value
            .filter((f) => f[option] == x.text)
            .map((xx) => {
              return { title: `${xx.insert_time ?? xx.hour_time}:00h`, value: xx.quality };
            }),
        };
      });

    let titles: string[] = [];
    dados.map((x) => {
      titles = [...titles, ...x.data.map((x) => x.title)];
    });

    titles = titles.sort();

    titles = titles.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    dados.map((x) => {
      const data: any[] = [];
      titles.map((y) => {
        const apx = x.data.find((f) => f.title == y && f.title != null);
        if (!apx) {
          data.push({
            title: y,
            value: 0,
          });
        } else {
          data.push(JSON.parse(JSON.stringify(apx)));
        }
      });

      x.data = data;
    });
    return dados;
  } else if (toggleMarcado.value == 'Disponibilidade') {
    const dados = optionsLinksOrServices.value
      .filter((f) => {
        return !!(linksOrServices.value ?? []).find((x) => x == f.value);
      })
      .map((x) => {
        return {
          color: '',
          name: x.text,
          data: dadosGraficoGeralBruto.value
            .filter((f) => f[option] == x.text)
            .map((xx) => {
              return { title: `${xx.insert_time ?? xx.hour_time}:00h`, value: xx.availability };
            }),
        };
      });

    let titles: string[] = [];
    dados.map((x) => {
      titles = [...titles, ...x.data.map((x) => x.title)];
    });

    titles = titles.sort();
    titles = titles.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    dados.map((x) => {
      const data: any[] = [];
      titles.map((y) => {
        const apx = x.data.find((f) => f.title == y && f.title != null);
        if (!apx) {
          data.push({
            title: y,
            value: 0,
          });
        } else {
          data.push(JSON.parse(JSON.stringify(apx)));
        }
      });

      x.data = data;
    });
    return dados;
  } else {
    let titles: string[] = [];
    dadosGraficoGeralBruto.value.map((x) => {
      titles = [...titles, x.name];
    });

    titles = titles.sort();
    titles = titles.filter((value, index, array) => {
      return array.indexOf(value) === index;
    });
    // dadosGraficoGeralBruto.value.map((x) => {
    //   const data: any[] = [];
    //   titles.map((y) => {
    //     const apx = x.data.find((f) => f.name == y && f.title != null);
    //     if (!apx) {
    //       data.push({
    //         title: y,
    //         value: 0,
    //       });
    //     } else {
    //       data.push(JSON.parse(JSON.stringify(apx)));
    //     }
    //   });
    // });

    return [
      ...titles.map((x) => {
        return {
          color: '',
          name: `Disponibilidade ${x}`,
          data: dadosGraficoGeralBruto.value
            .filter((f) => f.name == x)
            .map((xx) => {
              return { title: `${xx.insert_time}h`, value: xx.availability };
            }),
        };
      }),
      ...titles.map((x) => {
        return {
          color: '',
          name: `Qualidade ${x}`,
          data: dadosGraficoGeralBruto.value
            .filter((f) => f.name == x)
            .map((xx) => {
              return { title: `${xx.insert_time}h`, value: xx.quality };
            }),
        };
      }),
    ];
  }
});

async function togglePrevisivilidade(evt: string) {
  toggleMarcado.value = evt;
  try {
    if (tabPrevisibilidade.value == 'Links') {
      clearInterval(intevalGraficos.value);
      // Preloader.actions.ActionSetPreloader(true);
      if (evt !== 'predict') {
        if (tabPrevisibilidade.value == 'Links') {
          await qualidadeDisponibilidadePorlinkService();
        } else {
          await qualidadeDisponibilidadePorServicoService();
        }
      }

      // if (evt == 'Qualidade') {
      //   dataCharGeral.value = [
      //     {
      //       color: activeColor.value,
      //       name: 'Qualidade',
      //       data: dadosGraficoGeralBruto.value.map((x) => {
      //         return { title: `${x.insert_time}:00h`, value: x.quality };
      //       }),
      //     },
      //   ];
      // } else if (evt == 'Disponibilidade') {
      //   dataCharGeral.value = [
      //     {
      //       color: activeColor.value,
      //       name: 'Disponibilidade',
      //       data: dadosGraficoGeralBruto.value.map((x) => {
      //         return { title: `${x.insert_time}:00h`, value: x.availability };
      //       }),
      //     },
      //   ];
      // } else
      if (evt == 'predict') {
        await qualidadeDisponibilidadePredict();
        // dataCharGeral.value = [
        //   {
        //     color: activeColor.value,
        //     name: 'Disponibilidade',
        //     data: dadosGraficoGeralBruto.value.map((x) => {
        //       return { title: `${x.insert_time}h`, value: x.availability };
        //     }),
        //   },
        //   {
        //     color: '#FF5C00',
        //     name: 'Qualidade',
        //     data: dadosGraficoGeralBruto.value.map((x) => {
        //       return { title: `${x.insert_time}h`, value: x.quality };
        //     }),
        //   },
        // ];
      }
      loaderChartGeral.value = false;
      setarIntervalos();
      // Preloader.actions.ActionSetPreloader(false);
    } else {
      if (evt !== 'predict') {
        if (tabPrevisibilidade.value == 'Links') {
          await qualidadeDisponibilidadePorlinkService();
        } else {
          await qualidadeDisponibilidadePorServicoService();
        }
      }
      if (evt == 'Qualidade') {
        clearInterval(intevalGraficos.value);

        // dataCharGeral.value = [
        //   {
        //     color: activeColor.value,
        //     name: 'Qualidade',
        //     data: dadosGraficoGeralBruto.value.map((x) => {
        //       return { title: `${x.hour_time}:00h`, value: x.quality };
        //     }),
        //   },
        // ];
      } else if (evt == 'Disponibilidade') {
        clearInterval(intevalGraficos.value);
        // dataCharGeral.value = [
        //   {
        //     color: activeColor.value,
        //     name: 'Disponibilidade',
        //     data: dadosGraficoGeralBruto.value.map((x) => {
        //       return { title: `${x.hour_time}h`, value: x.availability };
        //     }),
        //   },
        // ];
      } else if (evt == 'predict') {
        await qualidadeDisponibilidadePredict();
        // dataCharGeral.value = [
        //   {
        //     color: '#212529',
        //     name: 'Qualidade',
        //     data: dadosGraficoGeralBruto.value.map((x) => {
        //       return { title: `${x.insert_time}h`, value: x.quality };
        //     }),
        //   },
        //   {
        //     color: '#FF5C00',
        //     name: 'Disponibilidade',
        //     data: dadosGraficoGeralBruto.value.map((x) => {
        //       return { title: `${x.insert_time}h`, value: x.availability };
        //     }),
        //   },
        // ];
      }
      loaderChartGeral.value = false;
      setarIntervalos();
    }
  } catch (error) {
    // Preloader.actions.ActionSetPreloader(false);
  }
}

function handleToogleAtualPredict(item: string, hasFazLoadd?: boolean) {
  toogleTotaisPredict.value = item;

  if (hasFazLoadd == undefined) hasFazLoadd = true;
  if (item == 'atual') {
    itemsDiagnostics.value = JSON.parse(JSON.stringify(itemsDiagnosticsAll.value));

    handleToogleBtn(toogleTotais.value, true);
    togglePrevisivilidade('Qualidade');
  } else {
    itemsDiagnostics.value = JSON.parse(
      JSON.stringify(
        itemsDiagnosticsAll.value.filter(
          (f) => f.item != 'Pontos por hora' && f.item != 'Monitor Realtime' && f.item != 'Monitor Unidades',
        ),
      ),
    );
    handleToogleBtnPredict(toogleTotais.value, true);

    togglePrevisivilidade('predict');
  }
}

async function handleToogleBtnPredict(item: string, hasFazLoadd?: boolean) {
  toogleTotais.value = item;
  if (hasFazLoadd == undefined) hasFazLoadd = true;

  itemsDiagnostics.value.map((x) => {
    if (x.item != 'Pontos por hora' && x.item != 'Monitor Realtime' && x.item != 'Monitor Unidades') x.loading = true;
  });
  await getStatusConectividade();
  totalizadores.value.map((f) => {
    if (f.status == 'saudavel') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Saudáveis');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    } else if (f.status == 'instavel') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Instáveis');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    } else if (f.status == 'degradado') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Degradados');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    } else if (f.status == 'problematico') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Problemáticos');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    }
  });
  // if (hasFazLoadd) {
  //   Preloader.actions.ActionSetPreloader(false);
  // }
}

async function getStatusConectividade() {
  const id = Authentication.state.id ?? 187;
  const dados = await getPredict(toogleTotais.value == 'porLink' ? 'LNK' : 'INT', id);
  totalizadores.value.map((f) => {
    f.total = 0;
    f.percentual = 0;
  });

  const upData: any[] = dados.data;

  upData.map((f) => {
    const idx = totalizadores.value.findIndex((x) => f.status == x.status);
    totalizadores.value[idx].total = (totalizadores.value[idx].total ?? 0) + 1;
    totalizadores.value[idx].percentual = Math.round((f.availability + f.quality) / 2);
  });
}

async function getServicosPorStatusConectividade() {
  const id = Authentication.state.id ?? 187;
  totalizadores.value = await servicosPorStatusConectividade(6, id, FiltersUnit.getters.filters.value);
}

async function handleToogleBtn(item: string, hasFazLoadd?: boolean) {
  toogleTotais.value = item;
  if (hasFazLoadd == undefined) hasFazLoadd = true;

  itemsDiagnostics.value.map((x) => {
    if (x.item != 'Pontos por hora' && x.item != 'Monitor Realtime' && x.item != 'Monitor Unidades') x.loading = true;
  });
  if (item == 'porLink') {
    await getLinksPorStatusConectividade();
  } else {
    await getServicosPorStatusConectividade();
  }

  totalizadores.value.map((f) => {
    if (f.status == 'saudavel') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Saudáveis');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    } else if (f.status == 'instavel') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Instáveis');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    } else if (f.status == 'degradado') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Degradados');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    } else if (f.status == 'problematico') {
      const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Problemáticos');
      itemsDiagnostics.value[idx].value = f.total;
      itemsDiagnostics.value[idx].nFloat = `${
        f.percentual && f.percentual != 'NaN'
          ? Number.isNaN(formatLang(f.percentual))
            ? 0
            : formatLang(f.percentual)
          : 0
      }%`;
      itemsDiagnostics.value[idx].data = f;
      itemsDiagnostics.value[idx].loading = false;
    }
  });
}
async function getLinksPorStatusConectividade() {
  const id = Authentication.state.id ?? 187;
  totalizadores.value = await linksPorStatusConectividade(6, id, FiltersUnit.getters.filters.value);
}
watch(
  () => tabPrevisibilidade.value,
  () => {
    togglePrevisivilidade(toggleMarcado.value);
  },
);

watch(
  () => props.roda,
  () => {
    if (props.roda) {
      Preloader.actions.ActionSetPreloader(true);
      handleMonitorealTime().then(() => {
        Preloader.actions.ActionSetPreloader(false);
      });
    }
  },
);
watch(
  () => optionsLinksOrServices.value,
  () => {
    linksOrServices.value = [];
    optionsLinksOrServices.value.map((x, index) => {
      if (linksOrServices.value.length < 6) {
        linksOrServices.value.push(index + 1);
      }
    });
  },
);

watch(
  FiltersUnit.getters.filters,
  () => {
    handleToogleBtn(toogleTotais.value, true);
    perdaPacotes();
    pontosPorHoraAction();
    consumoDeInternet();
    geo();
    if (toggleMarcado.value == 'predict') {
      qualidadeDisponibilidadePredict();
    } else {
      if (tabPrevisibilidade.value == 'Links') {
        qualidadeDisponibilidadePorlinkService();
      } else {
        qualidadeDisponibilidadePorServicoService();
      }
    }
  },
  { deep: true },
);
onMounted(() => {
  document.title = 'Dashboard';
  toogleTotais.value = 'porLink';
  toogleTotaisPredict.value = 'atual';
  // handleToogleAtualPredict(toogleTotaisPredict.value, true);
  // Preloader.actions.ActionSetPreloader(true);

  // toggleMarcado.value = 'Qualidade';

  // qualidadeDisponibilidadePorlinkService();
  perdaPacotes();
  pontosPorHoraAction();
  // setarIntervalos();
  consumoDeInternet();
  geo();
});

onUnmounted(() => {
  clearInterval(intevalGraficos.value);
});


const __returned__ = { router, emit, props, toogleTotaisPredict, itemsDiagnostics, toogleTotais, totalizadores, toggleMarcado, perdaTotalizadores, loadingScoreConectividade, optionConsumo, optionPerda, optionsLinksOrServices, optionsList, optionsListPacotes, optionsListConsumo, buttonsToggle, tabsPrevisibilidade, linksOrServices, tabPrevisibilidade, intevalGraficos, loaderChartGeral, dadosGraficoGeralBruto, consumoTotalizadores, loadingGeo, dataMaps, pontosPorHoraScoreConectividade, buttonsTogglePrevi, buttonsTogglePredict, itemsDiagnosticsAll, activeColorGeo, dataChartPerdaComputado, activeColor, dataChartComputado, handleClickCard, regexValidarY, handleMonitorealTime, handleToogleBtnAdpt, qualidadeDisponibilidadePorlinkService, qualidadeDisponibilidadePorServicoService, perdaPacotes, qualidadeDisponibilidadePredict, consumoDeInternet, dataCharAreaScoreConectividade, pontosPorHoraAction, setarIntervalos, geo, dataChartGeralOptimize, togglePrevisivilidade, handleToogleAtualPredict, handleToogleBtnPredict, getStatusConectividade, getServicosPorStatusConectividade, handleToogleBtn, getLinksPorStatusConectividade, get AtomToogleButton() { return AtomToogleButton }, get HomeIcon2() { return HomeIcon2 }, get AtomSelect() { return AtomSelect }, get AtomSubTitle() { return AtomSubTitle }, get MoleculeDiagnostic() { return MoleculeDiagnostic }, get MoleculeTabs() { return MoleculeTabs }, get OrganismsMapCharts() { return OrganismsMapCharts }, get OrganismsChart() { return OrganismsChart }, get getters() { return getters }, get FiltersUnit() { return FiltersUnit }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})