import { defineComponent as _defineComponent } from 'vue'
import { OrganismsQualityChart, OrganismsProgressCircle, OrganismsEconomy } from '@/components/organisms';
import { MoleculeTableSimple } from '@/components/molecules';
// import InformacoesFinanceiras from './components/InformacoesFinanceiras.vue';
import { IDataChart, IDataChartMore, ITab } from '@/types';
// IDataMapChart
// import { state } from '../../store/modules/layout';
import { ref, onMounted, watch, onUnmounted, computed } from 'vue';
// import OverView from './components/OverView.vue';
// import MonitorRealtime from './components/MonitorRealtime.vue';
import { Authentication, Preloader } from '@/store/index';
import {
  graficoConsumoInternet,
  linksPorStatusConectividade,
  perdaPacotesGrafico,
  // pontosPorHoraGrafico,
  qualidadeDisponibilidadePorlink,
  qualidadeDisponibilidadePorServico,
  servicosPorStatusConectividade,
  servicosCriticos as servicesCriticos,
  percentualLinkServicoOffOn,
  listarEconomiaLink,
  totaisColetores,
  // geolocalizacao,
} from '@/core/services/GraficoService';

import { getGrafico } from '@/core/services/PredictService';
import { formatLang } from '@/utils/formatNumber';
// import InformacoesGerenciais from './components/InformacoesGerenciais.vue';
import { FiltersUnit } from '@/store';

import lodash from 'lodash';
// const rodarMonitor = ref<boolean>(false);
// const dataMaps = ref<IDataMapChart[]>([]);
// const loadingGeo = ref<boolean>(false);


export default /*@__PURE__*/_defineComponent({
  __name: 'homePage',
  setup(__props, { expose: __expose }) {
  __expose();

// import { MoleculeTabs, MoleculeTabWindow, MoleculeTabItem } from '@/components/molecules';
const dataCharGeral = ref<IDataChartMore[] | IDataChart[]>([]);

const economy = ref({
  tooltip: 'Baixar relatório',
  title: 'Economia identificada',
  data: [],
});
const progressQualidade = ref({
  notPercentual: true,
  tooltip: 'Mudar para coletores',
  title: 'Monitoramento - s',
  value: 80,
});

const dataQualidade = ref({
  tooltip: 'Mudar para serviços',
  title: 'Qualidade - Serviços',
  series: [],
});

function handleEconomy() {
  console.log('Baixar relatorio de economia');
}

function handleMonitorar() {
  if (progressQualidade.value.title == 'Monitoramento - Links & Serviços') {
    totaisColetores(Authentication.state.id as number, FiltersUnit.getters.filters.value).then((f) => {
      progressQualidade.value = {
        notPercentual: true,
        tooltip: 'Mudar para Links & Serviços',
        title: 'Monitoramento - Coletores',
        value: f,
      };
    });
  } else {
    percentualLinkServicoOffOn(Authentication.state.id as number, FiltersUnit.getters.filters.value).then((f) => {
      progressQualidade.value = {
        notPercentual: false,
        tooltip: 'Mudar para Coletores',
        title: 'Monitoramento - Links & Serviços',
        value: f.find((x) => x.monitor == 'ON-Line')?.percentual ?? 0,
      };
    });
  }
}

async function handleQualityChart() {
  const id = Authentication.state.id as number;

  if (dataQualidade.value.title == 'Qualidade - Links') {
    const data = await qualidadeDisponibilidadePorServico(6, id, FiltersUnit.getters.filters.value);
    console.log(data);

    const groupedByservice = lodash
      .chain(data)
      .groupBy('servico') // Agrupa os dados por 'link'
      .mapValues((items) => ({
        service: items[0].servico,
        qualities: items.map((item) => ({
          quality: item.quality,
          insert_time: item.insert_time,
          day_time: item.day_time,
        })),
      }))
      .values()
      .value();

    console.log(groupedByservice);
    dataQualidade.value = {
      tooltip: 'Mudar para links',
      title: 'Qualidade - Serviços',
      series: groupedByservice.map((f) => {
        return {
          name: f.service,
          data: f.qualities.map((x) => [`${x.insert_time}:00`, x.quality]),
        };
      }), //[
    };
  } else {
    const data = await qualidadeDisponibilidadePorlink(6, id, FiltersUnit.getters.filters.value);

    const groupedByLink = lodash
      .chain(data)
      .groupBy('link') // Agrupa os dados por 'link'
      .mapValues((items) => ({
        link: items[0].link,
        qualities: items.map((item) => ({
          quality: item.quality,
          insert_time: item.insert_time,
          day_time: item.day_time,
        })),
      }))
      .values()
      .value();

    console.log(groupedByLink);
    dataQualidade.value = {
      tooltip: 'Mudar para serviços',
      title: 'Qualidade - Links',
      series: groupedByLink.map((f) => {
        return {
          name: f.link,
          data: f.qualities.map((x) => [`${x.insert_time}:00`, x.quality]),
        };
      }),
    };
  }
}

const columnsLink = ref([
  { label: 'Link', field: 'link' },
  {
    label: 'Status',
    field: 'statusFormat',
    rowColor: 'var(--color-success)',
    fieldExtra: {
      field: 'percentual',
      color: 'var(--color-success)',
      bgColor: '#00B88D10',
      fnBgColor: (row) => {
        return row.status == 'problematico'
          ? '#FDD7D9'
          : row.status == 'instavel'
            ? '#FFEECC'
            : row.status == 'saudavel'
              ? '#00B88D10'
              : '#FFE7CA';
      },
      fnColor: (row) => {
        return row.status == 'problematico'
          ? 'var(--color-danger)'
          : row.status == 'instavel'
            ? 'var(--color-warning)'
            : row.status == 'saudavel'
              ? 'var(--color-success)'
              : 'var(--color-subTitle)';
      },
    },
    fnRowColor: (row) => {
      return row.status == 'problematico'
        ? 'var(--color-danger)'
        : row.status == 'instavel'
          ? 'var(--color-warning)'
          : row.status == 'saudavel'
            ? 'var(--color-success)'
            : 'var(--color-subTitle)';
    },
  },
]);
const columnsService = ref([
  { label: 'Serviços Críticos', field: 'serviconome' },
  // {
  //   label: 'Link recomendado',
  //   field: 'link',
  //   fieldExtra: {
  //     field: 'percent',
  //     color: 'var(--color-success)',
  //     bgColor: '#00B88D10',
  //   },
  // },
]);
const rowsServices = ref<any>([]);
const rowsLink = ref<any>([]);
// function handleLoadMonitor(event) {
//   rodarMonitor.value = event;
//   setTimeout(() => (rodarMonitor.value = !rodarMonitor.value), 0);
// }
const tabsPrevisibilidade = ref<ITab[]>([
  {
    label: 'Links',
    value: 'Links',
    tooltips: '',
  },
  {
    label: 'Serviços / Links',
    value: 'Serviços',
    tooltips: '',
  },
]);
const investigarOffOn = ref<boolean>(false);

const dadosRealtime = ref<any[]>([]);
const troca = ref<boolean>(false);
watch(
  () => dadosRealtime.value,
  () => {
    investigarOffOn.value = true;
  },
  { deep: true },
);
watch(
  () => troca.value,
  () => {
    investigarOffOn.value = true;
  },
  { deep: true },
);
// const tabs = computed(() => {
//   const data = [
//     {
//       label: 'Observabilidade',
//       value: 'Observabilidade',
//       tooltips:
//         'A tela mostra a saúde dos links e serviços com base na média das últimas 8 horas, oferecendo uma visão atual e preditiva da qualidade e disponibilidade. Já nas telas de investigação, os usuários analisam detalhadamente a qualidade e disponibilidade dos links e serviços, com métricas como jitter, latência e perda de pacotes, em amostragens últimos de 30 minutos, 3 horas e últimas 8 horas.',
//     },
//     {
//       label: 'Informações Financeiras',
//       value: 'financeiras',
//       tooltips:
//         'Aqui você pode observar e conferir o seu custo provisionado para o mês e o desconto preditivo (previsto), levando em conta o SLA entregue versus o que foi prometido pelo provedor de internet. Contendo também o Desconto Atual, Custo Atual e Disponibilidade do link de internet.',
//     },
//   ];
//   if (investigarOffOn.value)
//     data.push({
//       label: 'Monitoramento em tempo real',
//       value: 'realtime',
//       tooltips:
//         'Por Regra, a funcionalidade faz o monitoramento em tempo real verifica se os componentes estão funcionando corretamente. Ela exibe informações como se estão online ou offline, o nome da máquina, o serviço ou link sendo monitorado, entre outros dados coletados e exibidos. Essa verificação é feita em um total de até 2 minutos para garantir que as informações sejam atualizadas regularmente e obtenha performance. Ou seja, essa Nova Funcionalidade de Monitoramento em Tempo Real é um assistente que verifica constantemente ( até 30 segundos de coleta e exibe em até 2 minutos) se os status estão Online e/ou Offline referente a: Maquina de Origem, do Link e/ou Serviço, do Coletor, da Ultima coleta, a Versão do Coletor, o Tipo de Serviço e Link Cadastrado, a Unidade coletada, a Interface Analisada, o Equipamento Analisado e o Detalhe daquele S.O.',
//     });

//   return data;
// });

const tabPrevisibilidade = ref(tabsPrevisibilidade.value[0].value);

const toggleMarcado = ref<string>('');
// const tab = ref(tabs.value[0].value);
// const itemsDiagnosticsAll = ref<any[]>([
//   {
//     icon: 'ShieldDoneIcon',
//     isPositive: true,
//     item: 'Saudáveis',
//     value: 0,
//     nFloat: 0,
//     color: '#15D095',
//     id: '0',
//     data: {},
//     text: 'Quando a Qualidade e a Disponibilidade estão acima de 50%',
//     loading: true,
//   },
//   {
//     icon: 'DangerTriangleIcon',
//     isPositive: true,
//     item: 'Instáveis',
//     value: 0,
//     nFloat: 0,
//     color: '#FFE70D',
//     id: '1',
//     data: {},
//     text: 'Quando a Qualidade está acima de 50% a Disponibilidade está abaixo de 50%',
//     loading: true,
//   },
//   {
//     icon: 'ArrowDownCircleIcon',
//     isPositive: false,
//     item: 'Degradados',
//     value: 0,
//     nFloat: 0,
//     color: '#FF9E0D',
//     id: '2',
//     data: {},
//     text: 'Quando a Qualidade está abaixo de 50% a Disponibilidade está acima de 50%',
//     loading: true,
//   },
//   {
//     icon: 'ShieldFailIcon',
//     isPositive: false,
//     item: 'Problemáticos',
//     value: 0,
//     nFloat: 0,
//     color: '#FF0D21',
//     id: '3',
//     data: {},
//     text: 'Quando a Qualidade e a Disponibilidade estão abaixo de 50%',
//     loading: true,
//   },
//   // {
//   //   icon: 'ClockIcon',
//   //   isPositive: true,
//   //   item: 'Pontos por hora',
//   //   value: 0,
//   //   // nFloat: 0,
//   //   id: '4',
//   //   data: {},
//   //   loading: true,
//   // },
// ]);

const itemsDiagnostics = ref<any[]>([]);

const activeColor = computed(() => {
  const color = toogleTotaisPredict.value === 'atual' ? '#FF5C00' : '#292E36';
  return color;
});

const intevalGraficos = ref<any>();

const dadosGraficoGeralBruto = ref<any[]>([]);

// const dataCharAreaScoreConectividade = ref<IDataChart[]>([
//   { title: '', value: 0 },
//   { title: '', value: 0 },
// ]);

// const pontosPorHoraScoreConectividade = ref<number>();
// const loadingScoreConectividade = ref<boolean>(true);

const loaderChartGeral = ref<boolean>(true);
const toogleTotais = ref<string>('');

const toogleTotaisPredict = ref<string>('atual');

const totalizadores = ref<any[]>([]);

const perdaTotalizadores = ref<any>();
const consumoTotalizadores = ref<any>({});

function consumoDeInternet() {
  graficoConsumoInternet(Authentication.state.id as number, FiltersUnit.getters.filters.value).then((f) => {
    consumoTotalizadores.value = f;
  });
}
function perdaPacotes() {
  perdaPacotesGrafico(Authentication.state.id as number, FiltersUnit.getters.filters.value).then((f) => {
    perdaTotalizadores.value = f;
  });
}
// function pontosPorHoraAction() {
//   const index = itemsDiagnostics.value.findIndex((f) => f.item == 'Pontos por hora');
//   itemsDiagnostics.value[index].loading = true;
//   const id = Authentication.state.id ;
//   loadingScoreConectividade.value = true;
//   pontosPorHoraGrafico(id, FiltersUnit.getters.filters.value)
//     .then((f) => {
//       dataCharAreaScoreConectividade.value = f.horasSCLista.map((f) => {
//         return { title: `${f.hora}h`, value: Number(f.pontosPorHora) } as IDataChart;
//       });
//       pontosPorHoraScoreConectividade.value = f.pontosPorHora;

//       itemsDiagnostics.value[index].value = f.pontosPorHora;
//       itemsDiagnostics.value[index].data = f;
//       itemsDiagnostics.value[index].loading = false;
//       loadingScoreConectividade.value = false;
//     })
//     .catch(() => {
//       dataCharAreaScoreConectividade.value = [
//         { title: '', value: 0 },
//         { title: '', value: 0 },
//       ];
//       loadingScoreConectividade.value = false;

//       if(index >=0){
//         itemsDiagnostics.value[index].value = 0;
//         itemsDiagnostics.value[index].data = {};
//         itemsDiagnostics.value[index].loading = false;
//       }

//     });
// }
async function handleToogleBtn(item: string, hasFazLoadd?: boolean) {
  try {
    toogleTotais.value = item;
    if (hasFazLoadd == undefined) hasFazLoadd = true;

    itemsDiagnostics.value.map((x) => {
      if (x.item != 'Pontos por hora') x.loading = true;
    });
    if (item == 'porLink') {
      await getLinksPorStatusConectividade();
    } else {
      await getServicosPorStatusConectividade();
    }

    totalizadores.value.map((f) => {
      if (f.status == 'saudavel') {
        const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Saudáveis');
        itemsDiagnostics.value[idx].value = f.total;
        itemsDiagnostics.value[idx].nFloat = `${
          f.percentual && f.percentual != 'NaN'
            ? Number.isNaN(formatLang(f.percentual))
              ? 0
              : formatLang(f.percentual)
            : 0
        }%`;
        itemsDiagnostics.value[idx].data = f;
        itemsDiagnostics.value[idx].loading = false;
      } else if (f.status == 'instavel') {
        const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Instáveis');
        itemsDiagnostics.value[idx].value = f.total;
        itemsDiagnostics.value[idx].nFloat = `${
          f.percentual && f.percentual != 'NaN'
            ? Number.isNaN(formatLang(f.percentual))
              ? 0
              : formatLang(f.percentual)
            : 0
        }%`;
        itemsDiagnostics.value[idx].data = f;
        itemsDiagnostics.value[idx].loading = false;
      } else if (f.status == 'degradado') {
        const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Degradados');
        itemsDiagnostics.value[idx].value = f.total;
        itemsDiagnostics.value[idx].nFloat = `${
          f.percentual && f.percentual != 'NaN'
            ? Number.isNaN(formatLang(f.percentual))
              ? 0
              : formatLang(f.percentual)
            : 0
        }%`;
        itemsDiagnostics.value[idx].data = f;
        itemsDiagnostics.value[idx].loading = false;
      } else if (f.status == 'problematico') {
        const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Problemáticos');
        itemsDiagnostics.value[idx].value = f.total;
        itemsDiagnostics.value[idx].nFloat = `${
          f.percentual && f.percentual != 'NaN'
            ? Number.isNaN(formatLang(f.percentual))
              ? 0
              : formatLang(f.percentual)
            : 0
        }%`;
        itemsDiagnostics.value[idx].data = f;
        itemsDiagnostics.value[idx].loading = false;
      }
    });
    // if (hasFazLoadd) {
    //   Preloader.actions.ActionSetPreloader(false);
    // }
  } finally {
    Preloader.actions.ActionSetPreloader(false);
  }
}

// async function handleToogleBtnPredict(item: string, hasFazLoadd?: boolean) {
//   try {
//     toogleTotais.value = item;
//     if (hasFazLoadd == undefined) hasFazLoadd = true;

//     itemsDiagnostics.value.map((x) => {
//       if (x.item != 'Pontos por hora') x.loading = true;
//     });
//     await getStatusConectividade();
//     totalizadores.value.map((f) => {
//       if (f.status == 'saudavel') {
//         const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Saudáveis');
//         itemsDiagnostics.value[idx].value = f.total;
//         itemsDiagnostics.value[idx].nFloat = `${
//           f.percentual && f.percentual != 'NaN'
//             ? Number.isNaN(formatLang(f.percentual))
//               ? 0
//               : formatLang(f.percentual)
//             : 0
//         }%`;
//         itemsDiagnostics.value[idx].data = f;
//         itemsDiagnostics.value[idx].loading = false;
//       } else if (f.status == 'instavel') {
//         const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Instáveis');
//         itemsDiagnostics.value[idx].value = f.total;
//         itemsDiagnostics.value[idx].nFloat = `${
//           f.percentual && f.percentual != 'NaN'
//             ? Number.isNaN(formatLang(f.percentual))
//               ? 0
//               : formatLang(f.percentual)
//             : 0
//         }%`;
//         itemsDiagnostics.value[idx].data = f;
//         itemsDiagnostics.value[idx].loading = false;
//       } else if (f.status == 'degradado') {
//         const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Degradados');
//         itemsDiagnostics.value[idx].value = f.total;
//         itemsDiagnostics.value[idx].nFloat = `${
//           f.percentual && f.percentual != 'NaN'
//             ? Number.isNaN(formatLang(f.percentual))
//               ? 0
//               : formatLang(f.percentual)
//             : 0
//         }%`;
//         itemsDiagnostics.value[idx].data = f;
//         itemsDiagnostics.value[idx].loading = false;
//       } else if (f.status == 'problematico') {
//         const idx = itemsDiagnostics.value.findIndex((f) => f.item == 'Problemáticos');
//         itemsDiagnostics.value[idx].value = f.total;
//         itemsDiagnostics.value[idx].nFloat = `${
//           f.percentual && f.percentual != 'NaN'
//             ? Number.isNaN(formatLang(f.percentual))
//               ? 0
//               : formatLang(f.percentual)
//             : 0
//         }%`;
//         itemsDiagnostics.value[idx].data = f;
//         itemsDiagnostics.value[idx].loading = false;
//       }
//     });
//     // if (hasFazLoadd) {
//     //   Preloader.actions.ActionSetPreloader(false);
//     // }
//   } finally {
//     Preloader.actions.ActionSetPreloader(false);
//   }
// }

// async function handleToogleAtualPredict(item: string, hasFazLoadd?: boolean) {
//   try {
//     toogleTotaisPredict.value = item;

//     if (hasFazLoadd == undefined) hasFazLoadd = true;
//     if (item == 'atual') {
//       itemsDiagnostics.value = JSON.parse(JSON.stringify(itemsDiagnosticsAll.value));

//       handleToogleBtn(toogleTotais.value, true);
//     } else {
//       itemsDiagnostics.value = JSON.parse(
//         JSON.stringify(itemsDiagnosticsAll.value.filter((f) => f.item != 'Pontos por hora')),
//       );
//       handleToogleBtnPredict(toogleTotais.value, true);

//       togglePrevisivilidade('predict');
//     }
//   } catch (error) {
//     console.log(error);
//   }
// }
async function qualidadeDisponibilidadePredict() {
  loaderChartGeral.value = true;
  const id = Authentication.state.id;
  const dados = await getGrafico(tabPrevisibilidade.value == 'Links' ? 'LNK' : 'INT', id);
  if (dados.status == '200') {
    const dadosInsert: any[] = [];
    Object.keys(dados.data).map((x) => {
      const obj = {
        insert_time: x,
        quality: dados.data[x]['QUALITY'],
        availability: dados.data[x]['AVAILABILITY'],
      };
      dadosInsert.push(obj);
    });

    dadosGraficoGeralBruto.value = dadosInsert;
  } else {
    dadosGraficoGeralBruto.value = [];
  }
}
async function qualidadeDisponibilidadePorlinkService() {
  loaderChartGeral.value = true;
  const id = Authentication.state.id;
  dadosGraficoGeralBruto.value = await qualidadeDisponibilidadePorlink(6, id, FiltersUnit.getters.filters.value);
  // togglePrevisivilidade(toggleMarcado.value);
}

async function qualidadeDisponibilidadePorServicoService() {
  loaderChartGeral.value = true;
  const id = Authentication.state.id;
  dadosGraficoGeralBruto.value = await qualidadeDisponibilidadePorServico(6, id, FiltersUnit.getters.filters.value);
  // togglePrevisivilidade(toggleMarcado.value);
}

function servicosCriticos() {
  servicesCriticos(FiltersUnit.getters.filters.value).then((f) => {
    rowsServices.value = f;
  });
}
function linksStatus() {
  const id = Authentication.state.id as number;
  linksPorStatusConectividade(8, id, FiltersUnit.getters.filters.value).then((f) => {
    rowsLink.value = [];

    f.map((x) => {
      x.listaDados.map((y) => {
        const obj: any = {
          link: y.service_name,
          status: x.status,
          percentual: `${x.percentual}%`, //x.percentual,

          statusFormat:
            x.status == 'problematico'
              ? 'Problemático'
              : x.status == 'instavel'
                ? 'Instável'
                : x.status == 'degradado'
                  ? 'Degradado'
                  : 'Saudável',
        };
        rowsLink.value.push(obj);
      });
    });
    console.log(rowsLink.value);
  });
}
function loadEconomy() {
  listarEconomiaLink(FiltersUnit.getters.filters.value).then((f) => {
    economy.value.data = f.map((x) => {
      return {
        title: x.nomeLink,
        value: x.economia_total,
      };
    });
  });
}

onMounted(async () => {
  document.title = 'Dashboard';
  toogleTotais.value = 'porLink';
  toogleTotaisPredict.value = 'atual';
  linksStatus();
  servicosCriticos();
  handleQualityChart();
  handleMonitorar();
  loadEconomy();
  /* handleToogleAtualPredict(toogleTotaisPredict.value, true);

  // Preloader.actions.ActionSetPreloader(true);

  toggleMarcado.value = 'Qualidade';

  qualidadeDisponibilidadePorlinkService();
  perdaPacotes();
  // pontosPorHoraAction();
  setarIntervalos();
  consumoDeInternet();
  geo();
  */
});

onUnmounted(() => {
  clearInterval(intevalGraficos.value);
});

watch(
  () => FiltersUnit.getters.filters.value,
  () => {
    linksStatus();
    servicosCriticos();
    handleQualityChart();
    handleMonitorar();
    loadEconomy();
  },
  { deep: true },
);

// async function geo() {
//   try {
//     loadingGeo.value = true;
//     const geo = await geolocalizacao(FiltersUnit.getters.filters.value);
//     dataMaps.value = geo.map((x) => {
//       return { uf: String(x.uf).toLowerCase(), value: Number(x.quantidade), lat: Number(x.lat), lng: Number(x.lng) };
//     });
//   } finally {
//     loadingGeo.value = false;
//   }
// }

// async function getStatusConectividade() {
//   const id = Authentication.state.id ;
//   const dados = await getPredict(toogleTotais.value == 'porLink' ? 'LNK' : 'INT', id);
//   // let tot: any[] = [];
//   // let todos: any[] = [];
//   // let todosStatus: any[] = [];
//   // Object.keys(dados.data).map((f) => {
//   //   const da = Object.keys(dados.data[f]).map((x) => {
//   //     return dados.data[f][x] as number;
//   //   });
//   //   if (da.length) {
//   //     let max = Math.max.apply(Math, da);
//   //     let status = '';
//   //     Object.keys(dados.data[f]).map((x) => {
//   //       if (dados.data[f][x] == max) {
//   //         status = x;
//   //       }
//   //       todosStatus.push({ status: x, percentual: dados.data[f][x] });
//   //     });
//   //     tot.push({ percentual: max, status: status });
//   //   }
//   // });
//   // const status = todosStatus.filter((value, index, array) => {
//   //   return array.findIndex((f) => f.status == value.status) === index;
//   // });
//   totalizadores.value.map((f) => {
//     f.total = 0;
//     f.percentual = 0;
//   });
//   dados.data?.map((f) => {
//     const idx = totalizadores.value.findIndex((x) => f.status == x.status);
//     totalizadores.value[idx].total = (totalizadores.value[idx].total ?? 0) + 1;
//     totalizadores.value[idx].percentual = Math.round((f.availability + f.quality) / 2);
//   });
// }
async function getLinksPorStatusConectividade() {
  const id = Authentication.state.id;
  totalizadores.value = await linksPorStatusConectividade(8, id, FiltersUnit.getters.filters.value);
}

async function getServicosPorStatusConectividade() {
  const id = Authentication.state.id;
  totalizadores.value = await servicosPorStatusConectividade(8, id, FiltersUnit.getters.filters.value);
}

// function setarIntervalos() {
//   intevalGraficos.value = setInterval(() => {
//     if (toggleMarcado.value == 'predict') {
//       qualidadeDisponibilidadePredict();
//     } else {
//       if (tabPrevisibilidade.value == 'Links') {
//         qualidadeDisponibilidadePorlinkService();
//       } else {
//         qualidadeDisponibilidadePorServicoService();
//       }
//     }
//     // togglePrevisivilidade(toggleMarcado.value);

//     handleToogleAtualPredict(toogleTotaisPredict.value, false);
//     // handleToogleBtn(toogleTotais.value, false);
//     perdaPacotes();
//     consumoDeInternet();
//     // pontosPorHoraAction();
//     geo();
//   }, 180000);
// }

async function togglePrevisivilidade(evt: string) {
  toggleMarcado.value = evt;
  try {
    if (tabPrevisibilidade.value == 'Links') {
      clearInterval(intevalGraficos.value);
      // Preloader.actions.ActionSetPreloader(true);
      if (evt !== 'predict') {
        if (tabPrevisibilidade.value == 'Links') {
          await qualidadeDisponibilidadePorlinkService();
        } else {
          await qualidadeDisponibilidadePorServicoService();
        }
      }

      if (evt == 'Qualidade') {
        dataCharGeral.value = [
          {
            color: activeColor.value,
            name: 'Qualidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.insert_time}:00h`, value: x.quality };
            }),
          },
        ];
      } else if (evt == 'Disponibilidade') {
        dataCharGeral.value = [
          {
            color: activeColor.value,
            name: 'Disponibilidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.insert_time}:00h`, value: x.availability };
            }),
          },
        ];
      } else if (evt == 'predict') {
        await qualidadeDisponibilidadePredict();
        dataCharGeral.value = [
          {
            color: activeColor.value,
            name: 'Disponibilidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.insert_time}h`, value: x.availability };
            }),
          },
          {
            color: '#FF5C00',
            name: 'Qualidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.insert_time}h`, value: x.quality };
            }),
          },
        ];
      }
      loaderChartGeral.value = false;
      // setarIntervalos();
      // Preloader.actions.ActionSetPreloader(false);
    } else {
      if (evt !== 'predict') {
        if (tabPrevisibilidade.value == 'Links') {
          await qualidadeDisponibilidadePorlinkService();
        } else {
          await qualidadeDisponibilidadePorServicoService();
        }
      }
      if (evt == 'Qualidade') {
        clearInterval(intevalGraficos.value);

        dataCharGeral.value = [
          {
            color: activeColor.value,
            name: 'Qualidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.hour_time}:00h`, value: x.quality };
            }),
          },
        ];
      } else if (evt == 'Disponibilidade') {
        clearInterval(intevalGraficos.value);
        dataCharGeral.value = [
          {
            color: activeColor.value,
            name: 'Disponibilidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.hour_time}h`, value: x.availability };
            }),
          },
        ];
      } else if (evt == 'predict') {
        await qualidadeDisponibilidadePredict();
        dataCharGeral.value = [
          {
            color: '#212529',
            name: 'Qualidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.insert_time}h`, value: x.quality };
            }),
          },
          {
            color: '#FF5C00',
            name: 'Disponibilidade',
            data: dadosGraficoGeralBruto.value.map((x) => {
              return { title: `${x.insert_time}h`, value: x.availability };
            }),
          },
        ];
      }
      loaderChartGeral.value = false;
      // setarIntervalos();
    }
  } catch (error) {
    // Preloader.actions.ActionSetPreloader(false);
  }
}

watch(
  () => tabPrevisibilidade.value,
  async () => {
    try {
      // Preloader.actions.ActionSetPreloader(true);
      togglePrevisivilidade('qualidade');
      // await togglePrevisivilidade(toggleMarcado.value);
    } finally {
      // Preloader.actions.ActionSetPreloader(false);
    }
  },
);

watch(
  FiltersUnit.getters.filters.value,
  () => {
    if (FiltersUnit.getters.filters.value.length) return;
    handleToogleBtn(toogleTotais.value, true);
    perdaPacotes();
    // pontosPorHoraAction();
    consumoDeInternet();
    geo();
    if (toggleMarcado.value == 'predict') {
      qualidadeDisponibilidadePredict();
    } else {
      if (tabPrevisibilidade.value == 'Links') {
        qualidadeDisponibilidadePorlinkService();
      } else {
        qualidadeDisponibilidadePorServicoService();
      }
    }
  },
  { deep: true },
);

const __returned__ = { dataCharGeral, economy, progressQualidade, dataQualidade, handleEconomy, handleMonitorar, handleQualityChart, columnsLink, columnsService, rowsServices, rowsLink, tabsPrevisibilidade, investigarOffOn, dadosRealtime, troca, tabPrevisibilidade, toggleMarcado, itemsDiagnostics, activeColor, intevalGraficos, dadosGraficoGeralBruto, loaderChartGeral, toogleTotais, toogleTotaisPredict, totalizadores, perdaTotalizadores, consumoTotalizadores, consumoDeInternet, perdaPacotes, handleToogleBtn, qualidadeDisponibilidadePredict, qualidadeDisponibilidadePorlinkService, qualidadeDisponibilidadePorServicoService, servicosCriticos, linksStatus, loadEconomy, getLinksPorStatusConectividade, getServicosPorStatusConectividade, togglePrevisivilidade, get OrganismsQualityChart() { return OrganismsQualityChart }, get OrganismsProgressCircle() { return OrganismsProgressCircle }, get OrganismsEconomy() { return OrganismsEconomy }, get MoleculeTableSimple() { return MoleculeTableSimple } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})