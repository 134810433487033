import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M9.43231 14.6216C9.57805 14.781 9.784 14.8718 10 14.8718C10.216 14.8718 10.4219 14.781 10.5677 14.6216L14.6703 10.1345C14.9569 9.82092 14.9352 9.33436 14.6216 9.04769C14.3081 8.76103 13.8215 8.78277 13.5349 9.09631L10.7692 12.1212V0.769231C10.7692 0.3444 10.4248 0 10 0C9.57518 0 9.23077 0.3444 9.23077 0.769231V12.1212L6.46513 9.09631C6.17846 8.78277 5.6919 8.76103 5.37836 9.04769C5.06482 9.33436 5.04308 9.82092 5.32974 10.1345L9.43231 14.6216Z",
      fill: $setup.props.color || '#292E36'
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M18.4615 13.0769C18.4615 12.6521 18.8059 12.3077 19.2308 12.3077C19.6556 12.3077 20 12.6521 20 13.0769V13.1332C20 14.5359 20 15.6665 19.8805 16.5557C19.7564 17.4789 19.4908 18.2561 18.8735 18.8734C18.2561 19.4909 17.4788 19.7564 16.5556 19.8805C15.6664 20 14.5359 20 13.1332 20H6.86677C5.4641 20 4.33354 20 3.44431 19.8805C2.52113 19.7564 1.7439 19.4909 1.12646 18.8734C0.50913 18.2561 0.243591 17.4789 0.119488 16.5557C0 15.6665 0 14.5359 0 13.1332V13.0769C0 12.6521 0.34441 12.3077 0.76923 12.3077C1.19405 12.3077 1.53846 12.6521 1.53846 13.0769C1.53846 14.5491 1.5401 15.5759 1.64421 16.3507C1.74544 17.1033 1.93046 17.5017 2.21436 17.7856C2.49826 18.0695 2.89672 18.2546 3.64933 18.3558C4.4241 18.4599 5.45087 18.4615 6.92308 18.4615H13.0769C14.5491 18.4615 15.5759 18.4599 16.3506 18.3558C17.1032 18.2546 17.5018 18.0695 17.7856 17.7856C18.0695 17.5017 18.2546 17.1033 18.3557 16.3507C18.4599 15.5759 18.4615 14.5491 18.4615 13.0769Z",
      fill: $setup.props.color || '#292E36'
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}