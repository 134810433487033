import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "table-simple" }
const _hoisted_2 = { class: "d-flex justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("table", _hoisted_1, [
      _createElementVNode("thead", null, [
        _createElementVNode("tr", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.columns, (column, index) => {
            return (_openBlock(), _createElementBlock("th", {
              key: `${index}-head`,
              align: "left",
              class: "px-4 py-2 ft-bold"
            }, _toDisplayString(column.label), 1 /* TEXT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.rows, (row, index) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: `${index}-body`
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column, adx) => {
              return (_openBlock(), _createElementBlock("td", {
                key: `${adx}-ro`,
                class: "px-4 py-2 subtitle",
                style: _normalizeStyle({
              color: column.fnRowColor
                ? column.fnRowColor(row)
                : column.rowColor
                  ? column.rowColor
                  : 'var(--color-subTitle)',
            })
              }, [
                _createElementVNode("div", _hoisted_2, [
                  _createTextVNode(_toDisplayString(row[column.field]) + " ", 1 /* TEXT */),
                  (column.fieldExtra && row[column.fieldExtra.field])
                    ? (_openBlock(), _createBlock($setup["AtomBadge"], {
                        key: 0,
                        text: row[column.fieldExtra.field],
                        color: column.fieldExtra.fnColor ? column.fieldExtra.fnColor(row) : column.fieldExtra.color,
                        "bg-color": column.fieldExtra.fnBgColor ? column.fieldExtra.fnBgColor(row) : column.fieldExtra.bgColor
                      }, null, 8 /* PROPS */, ["text", "color", "bg-color"]))
                    : _createCommentVNode("v-if", true)
                ])
              ], 4 /* STYLE */))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ])
  ]))
}