import { createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, withKeys as _withKeys, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center justify-space-between mb-5 pb-2" }
const _hoisted_2 = { class: "mx-auto position-relative area-foto" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "edit-foto",
  for: "inputfile"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("h3", { style: {"color":"var(--color-title)"} }, "Editar perfil", -1 /* HOISTED */)),
      _createVNode(_component_v_btn, {
        variant: "text",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, {
        default: _withCtx(() => [
          _createVNode($setup["DeletexIcon"], {
            color: "var(--color-title)",
            size: "14"
          })
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: $setup.fileBase64,
        class: "rounded-profile",
        crossorigin: "anonymous"
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("label", _hoisted_4, [
        _createVNode($setup["EditPencil"])
      ])
    ]),
    _createCommentVNode(" <label class=\"text-profile text-center my-2 cursor-pointer\"\n    ><strong>Alterar foto de perfil</strong> "),
    _createElementVNode("input", {
      id: "inputfile",
      ref: "inputfile",
      type: "file",
      accept: ".jpg, .jpeg, .png, .gif",
      class: "sr-only",
      onChange: $setup.changeImg
    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */),
    _createCommentVNode(" </label> "),
    _createCommentVNode(" <label class=\"cursor-pointer position-relative d-inline-block label-img\">\n      <div class=\"edit-img\">\n        <v-icon aria-hidden=\"false\" color=\"white\">\n          mdi-pencil\n        </v-icon>\n      </div>\n      <v-avatar color=\"grey\" size=\"150\" rounded=\"0\">\n        <v-img cover :src=\"fileBase64\"></v-img>\n      </v-avatar>\n      <input ref=\"inputfile\" type=\"file\" class=\"sr-only\" @change=\"changeImg\" />\n    </label> "),
    _createCommentVNode(" <v-row class=\"ms-2\">\n    <v-col>\n      <AtomInput\n        prependIcon\n        :hasError=\"listError.includes('email')\"\n        label=\"Email*\"\n        placeholder=\"exemplo@email.com\"\n        v-model=\"email\"\n        :color=\"`#D6DBE1`\"\n        :disabled=\"true\"\n        class=\"mb-5\"\n      >\n        <template #prependIcon>\n          <MessageIcon :color=\"'#D6DBE1'\" />\n        </template>\n      </AtomInput>\n    </v-col>\n    <v-col>\n      <AtomInput\n        appendIcon\n        prependIcon\n        :type=\"'text'\"\n        :background-color=\"`#F6F7F9`\"\n        :hasError=\"listError.includes('nome')\"\n        @keydown.enter=\"handleSave\"\n        label=\"Nome\"\n        v-model=\"nome\"\n      >\n        <template #prependIcon>\n          <UserFullIcon />\n        </template>\n        <template #appendIcon>\n          <XIcon class=\"cursor-pointer\" @click=\"editName\" />\n        </template>\n      </AtomInput>\n    </v-col>\n    <v-col>\n      <AtomInput\n        prependIcon\n        :type=\"'password'\"\n        :color=\"`#697B8E`\"\n        class=\"border-input\"\n        :borderColor=\"'grey-lighten-2'\"\n        :placeholder=\"`Senha...`\"\n        :hasError=\"listError.includes('senha')\"\n        label=\"Senha:\"\n        v-model=\"senha\"\n      >\n        <template #prependIcon>\n          <LockIcon :color=\"`#697B8E`\" />\n        </template>\n      </AtomInput>\n    </v-col>\n    <v-col>\n      <AtomInput\n        prependIcon\n        :type=\"'password'\"\n        :color=\"`#697B8E`\"\n        :placeholder=\"`Confirmar senha...`\"\n        :hasError=\"listError.includes('confirmSenha')\"\n        label=\"Confirmação Senha:\"\n        v-model=\"confirmSenha\"\n      >\n        <template #prependIcon>\n          <LockIcon :color=\"`#697B8E`\" />\n        </template>\n      </AtomInput>\n    </v-col>\n  </v-row> "),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, null, {
          default: _withCtx(() => [
            _cache[4] || (_cache[4] = _createElementVNode("label", { class: "mb-1 mt-5" }, "Modo de exebição:", -1 /* HOISTED */)),
            _createVNode($setup["AtomToogleButton"], {
              select: $setup.darkMode ? 'dark' : 'light',
              width: "100%",
              buttons: $setup.buttonsToggleTheme,
              onHandleToggle: $setup.handleThemeChange
            }, null, 8 /* PROPS */, ["select", "buttons"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          class: "position-relative"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["AtomInput"], {
              height: "56px",
              type: 'text',
              hasError: $setup.listError.includes('nome'),
              "show-clear": "",
              onKeydown: _withKeys($setup.handleSave, ["enter"]),
              onClear: $setup.editName,
              label: "Nome:",
              modelValue: $setup.nome,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.nome) = $event)),
              width: "100%"
            }, null, 8 /* PROPS */, ["hasError", "modelValue"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "text-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('close'))),
              density: "default",
              "base-color": "var(--primary-nav-10)",
              size: "56",
              variant: "flat",
              class: "w-100",
              style: {"color":"var(--primary-nav)","font-weight":"600"}
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Voltar ")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { class: "text-center" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_btn, {
              onClick: $setup.handleSave,
              density: "default",
              size: "56",
              variant: "flat",
              color: "primary",
              class: "w-100",
              style: {"font-weight":"600"}
            }, {
              default: _withCtx(() => _cache[6] || (_cache[6] = [
                _createTextVNode(" Salvar ")
              ])),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}