import { defineComponent as _defineComponent } from 'vue'
import { AtomButton, PaperUploadIcon, AtomMonthPicker, DolarIcon, AtomInput } from '@/components/atoms';
import { MoleculeTable, MoleculeUploadInfo, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';

import { IHeadersTable, IMonthYear } from '@/types';
import { ref, computed, onMounted, watch } from 'vue';

import { format } from 'date-fns';

// import ModalGastos from './components/ModalGastos.vue';

import { useRouter } from 'vue-router';
import { FiltersUnit, Preloader } from '@/store';
import {
  listarDadosFinanceiros,
  gerarDadosFinanceiros,
  uploadNF,
  downloadNF,
  alterarDadosFinanceiros,
} from '@/core/services/FinancialService';
import Swal from 'sweetalert2';

export default /*@__PURE__*/_defineComponent({
  __name: 'FinancialPage',
  setup(__props, { expose: __expose }) {
  __expose();

const dateRange = ref<IMonthYear>({
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
});

onMounted(() => {
  // loadData();
  filter();
});

const router = useRouter();
const perPage = ref<number>(10);
const page = ref<number>(1);
// const updateRowValues = ref<any>({});

const filteredItems = computed(() => {
  const list = [...items.value];
  return list;
});

const paginationTableData = computed(() => {
  return filteredItems.value.slice((page.value - 1) * perPage.value, (page.value - 1) * perPage.value + perPage.value);
});

const titleModal = computed(() => {
  let text = '';
  if (paramUpload.value.toLowerCase() === 'notas') {
    text = 'Upload de Notas Fiscais';
  }
  if (paramUpload.value.toLowerCase() === 'comprovantes') {
    text = 'Upload de Comprovantes';
  }
  return text;
});

const subTitleModal = computed(() => {
  let text = '';
  if (paramUpload.value.toLowerCase() === 'notas') {
    text = 'Faça o upload dos arquivos referentes a nota fiscal e comprovante';
  }
  if (paramUpload.value.toLowerCase() === 'comprovantes') {
    text = 'Upload de Comprovantes';
  }
  return text;
});

const showModalUpload = ref<boolean>();
const selectedRow = ref();
const headers = ref<IHeadersTable[]>([
  {
    label: 'Cliente',
    field: 'clienteNome',
  },
  { label: 'Unidade', field: 'unidadeNome' },
  { label: 'Link', field: 'linkNome' },
  {
    label: 'Valor pago',
    field: 'valorPagoFormat',
    mode: 'money',
    update: 'valorPago',
    extra: true,
    fieldExtra: 'valorExtraFormat',
    classExtra: 'text-gray-01',
  },
  { label: 'Valor previsto', field: 'valorPrevistoFormat' },
  {
    label: 'Forma de pagamento',
    field: 'formaPagamento',
    mode: 'select',
    update: 'formaPagamento',
    options: [
      { text: 'Cartão', value: 'Cartão' },
      { text: 'PIX', value: 'PIX' },
      { text: 'TED', value: 'TED' },
      { text: 'DOC', value: 'DOC' },
      { text: 'Boleto', value: 'Boleto' },
    ],
  },
  { label: 'Data de vencimento', field: 'dataVencimentoFormat', mode: 'date', update: 'dataVencimento' },
  { label: 'Data de pagamento', field: 'dataPagamentoFormat', mode: 'date', update: 'dataPagamento' },
  {
    label: 'Status',
    field: 'status',
    mode: 'select',
    update: 'status',
    options: [
      { text: 'Pago', value: 'Pago' },
      { text: 'Pendente', value: 'Pendente' },
    ],
  },
]);

const items = ref<any[]>([]);
const itemsAll = ref<any[]>([]);
const paramUpload = ref<string>('');
const filters = ref<boolean>(false);
const selected = ref<any>();
const formatNumber = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });
function handleUpload(param?: string) {
  paramUpload.value = param || '';
  showModalUpload.value = true;
}
function handleUploadFinal(event) {
  Preloader.actions.ActionSetPreloader(true);
  uploadNF(selected.value.fflinkId, event.file)
    .then(() => {
      Swal.fire({
        text: 'NF importada com sucesso',
        icon: 'success',
        title: 'Sucesso',
      });
      filter();
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function handleDownload() {
  Preloader.actions.ActionSetPreloader(true);
  downloadNF(selected.value.fflinkId)
    .then((f) => {
      let file;
      let filename = '';
      if (f.includes('PDF')) {
        file = new Blob([f], { type: 'application/pdf' });
        filename = `NF_${selected.value.id}.pdf`;
      } else {
        file = new Blob([f], { type: 'text/plain' });
        filename = `NF_${selected.value.id}.txt`;
      }
      const a = document.createElement('a');
      a.target = '_blank';
      a.download = filename;
      a.href = URL.createObjectURL(file);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}
function handleClickRow(payload) {
  selectedRow.value = payload.row.id;
  selected.value = payload.row;
  filters.value = true;
}

// function loadData() {
//   filter();
// }
function gerarDados() {
  Preloader.actions.ActionSetPreloader(true);
  gerarDadosFinanceiros(
    Number(dateRange.value?.month) + 1,
    Number(dateRange.value?.year),
    FiltersUnit.getters.filters.value
  )
    .then((x) => {
      x.map((y) => {
        // y.id = index + 1;
        y.id = y.fflinkId;
        y.valorPrevistoFormat = formatNumber.format(y.valorPrevisto);
        y.valorPagoFormat = formatNumber.format(y.valorPago);
        if (y.dataPagamento) {
          const data = y.dataPagamento.split('.')[0].split(' ').join('T');
          y.dataPagamentoFormat = format(new Date(data), 'dd/MM/yyyy');
        }

        if (y.dataVencimento) {
          data = y.dataVencimento.split('.')[0].split(' ').join('T');
          y.dataVencimentoFormat = format(new Date(data), 'dd/MM/yyyy');
        }
      });
      itemsAll.value = JSON.parse(JSON.stringify(x));
      items.value = JSON.parse(JSON.stringify(x));
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function filter(loading = true) {
  if (loading) Preloader.actions.ActionSetPreloader(true);

  listarDadosFinanceiros(
    Number(dateRange.value?.month) + 1,
    Number(dateRange.value?.year),
    FiltersUnit.getters.filters.value
  )
    .then((x) => {
      x.map((y) => {
        // y.id = index + 1;
        y.id = y.fflinkId;
        y.valorPrevistoFormat = formatNumber.format(y.valorPrevisto);
        y.valorPagoFormat = formatNumber.format(y.valorPago);
        y.valorExtraFormat = formatNumber.format(y.totalExtra);

        if (y.dataPagamento) {
          const data = JSON.parse(JSON.stringify(y.dataPagamento.split('.')[0].split(' ').join('T')));
          y.dataPagamentoFormat = format(new Date(data), 'dd/MM/yyyy');
          y.dataPagamento = format(new Date(data), 'yyyy-MM-dd');
        }

        if (y.dataVencimento) {
          const data = JSON.parse(JSON.stringify(y.dataVencimento.split('.')[0].split(' ').join('T')));
          y.dataVencimentoFormat = format(new Date(data), 'dd/MM/yyyy');
          y.dataVencimento = format(new Date(data), 'yyyy-MM-dd');
        }

        y.valorPago = y.valorPago ?? 0;
      });

      itemsAll.value = JSON.parse(JSON.stringify(x));

      items.value = JSON.parse(JSON.stringify(x));
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });

  // if (loading) {
  //   setTimeout(() => {
  //     const year = dateRange.value?.year;
  //     const month = dateRange.value?.month;
  //     const dados =
  //       localStorage.getItem('financial') != null ? JSON.parse(String(localStorage.getItem('financial'))) || [] : [];
  //     items.value = dados;
  //     if (!!year && !!month) {
  //       items.value = items.value.filter(
  //         (f) => new Date(f.dataPagamento).getMonth() == month && new Date(f.dataPagamento).getFullYear() == year
  //       );
  //     }
  //     Preloader.actions.ActionSetPreloader(false);
  //   }, 1000);
  // } else {
  //   const year = dateRange.value?.year;
  //   const month = dateRange.value?.month;
  //   const dados =
  //     localStorage.getItem('financial') != null ? JSON.parse(String(localStorage.getItem('financial'))) || [] : [];
  //   items.value = dados;
  //   if (!!year && !!month) {
  //     items.value = items.value.filter(
  //       (f) => new Date(f.dataPagamento).getMonth() == month && new Date(f.dataPagamento).getFullYear() == year
  //     );
  //   }
  // }
}

function updateRow(event: any) {
  if (event.dataPagamento) {
    try {
      const datPart = event.dataPagamento.split('-');
      event.dataPagamento = format(
        new Date(Number(datPart[0]), Number(datPart[1]) - 1, Number(datPart[2])),
        'yyyy-MM-dd'
      );
      event.dataPagamentoFormat = format(
        new Date(Number(datPart[0]), Number(datPart[1]) - 1, Number(datPart[2])),
        'dd/MM/yyyy'
      );
    } catch (error) {
      console.log(error);
    }
  }

  if (event.valorPago) {
    try {
      const datPart = Number(event.valorPago);
      event.valorPago = datPart;
      event.valorPagoFormat = formatNumber.format(datPart);
    } catch (error) {
      console.log(error);
    }
  }

  Object.keys(event).map((key) => {
    selected.value[key] = event[key];
  });
}
function searching(event: string | undefined) {
  filter(false);
  if (event) {
    items.value = itemsAll.value.filter(
      (f) =>
        String(f.cliente).toUpperCase().includes(event.toUpperCase()) ||
        String(f.unidade).toUpperCase().includes(event.toUpperCase()) ||
        String(f.link).toUpperCase().includes(event.toUpperCase())
    );
  }
}
function save() {
  // const dados =
  //   localStorage.getItem('financial') != null ? JSON.parse(String(localStorage.getItem('financial'))) || [] : [];

  // let index = (dados as any[]).findIndex((f) => f.id == selected.value.id);

  // dados[index] = { ...dados[index], ...selected.value };

  // localStorage.setItem('financial', JSON.stringify(dados));

  Preloader.actions.ActionSetPreloader(true);
  alterarDadosFinanceiros(selected.value)
    .then(() => {
      Swal.fire({
        title: 'Sucesso',
        icon: 'success',
        text: 'Dados foram salvos com sucesso',
      });
      filter();

      filters.value = false;
      selectedRow.value = undefined;
      selected.value = {};
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function clearFilterDate() {
  dateRange.value = {};
}

function goGastos() {
  router.push({
    name: 'Gastos',
    params: {
      mesAno: `${dateRange.value.year}-${String(Number(dateRange.value.month) + 1).padStart(2, '0')}`,
      id: selected.value.id,
    },
    query: {
      text: `${selected.value.clienteNome} - ${selected.value.unidadeNome} - ${selected.value.linkNome}`,
    },
  });
}

watch(
  FiltersUnit.getters.filters.value,
  () => {
    filter();
  },
  { deep: true }
);

const __returned__ = { dateRange, router, perPage, page, filteredItems, paginationTableData, titleModal, subTitleModal, showModalUpload, selectedRow, headers, items, itemsAll, paramUpload, filters, selected, formatNumber, handleUpload, handleUploadFinal, handleDownload, handleClickRow, gerarDados, filter, updateRow, searching, save, clearFilterDate, goGastos, get AtomButton() { return AtomButton }, get PaperUploadIcon() { return PaperUploadIcon }, get AtomMonthPicker() { return AtomMonthPicker }, get DolarIcon() { return DolarIcon }, get AtomInput() { return AtomInput }, get MoleculeTable() { return MoleculeTable }, get MoleculeUploadInfo() { return MoleculeUploadInfo }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})