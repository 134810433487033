import { defineComponent as _defineComponent } from 'vue'
import {
  UserFullIcon,
  AtomButton,
  AtomInput,
  AtomSelect,
  DeleteIcon,
  EditSquareIcon,
  PlusIcon,
  InfoSquareIcon,
  AtomTooltip,
} from '@/components/atoms';
import AtomProgressForm from '@/components/atoms/AtomProgressForm.vue';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import * as ServiceColetores from '@/core/services/Coletores';
import { getGruposAll } from '@/core/services/GruposService';
import { getUnidadeAll } from '@/core/services/UnitsService';
import { getServicosAll } from '@/core/services/ServicoService';
import { getEquipamentoAll } from '@/core/services/EquipamentoService';
import { zeroTouch, downloadLinuxMac, downloadWin } from '@/core/services/UserService';
import { getInterfaceDispositivosAll } from '@/core/services/InterfaceDispositivosService';

import { IHeadersTable, IOptionsSelect } from '@/types';
import { ref, onMounted, computed, watch } from 'vue';
import { Preloader } from '@/store';
import Swal from 'sweetalert2';
import { Coletor, Equipamento, InterfaceDispositivos } from '@/core/models';
import { InterfaceDispositivoLisum } from '@/core/models/Coletor';

interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CollectorsPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const steps = ref([
  { label: 'Informações primárias', completed: false },
  { label: 'Config. testes do collector', completed: false },
  { label: 'Interface de link', completed: false },
]);
const currentStep = ref(steps.value[0].label);
const data = ref<Coletor>({ interfaceDispositivoLista: [], connServiceLista: [] });
const perPage = ref<number>(10);
const page = ref<number>(1);
const showModal = ref<boolean>(false);
const showModalZero = ref<boolean>(false);
const testeColetor = ref<any>({});
const optionsGrupos = ref<IOptionsSelect[]>([]);
const optionsLinks = ref<IOptionsSelect[]>([]);
const interfaces = ref<InterfaceDispositivos[]>([]);
const optionsEquipamentos = ref<IOptionsSelect[]>([]);
const optionsUnidades = ref<IOptionsSelect[]>([]);
const optionsServicos = ref<IOptionsSelect[]>([]);
const dadoZero = ref<any>({});
const interfaceDispositivoLista = ref<any>();
const type = ref<string>();
const headersInterfaceLink = ref<IHeadersTable[]>([
  {
    label: 'Interface',
    field: 'nome',
  },
  { label: 'Link', field: 'internetLinkDescricao' },
  { label: 'Equipamento', field: 'equipamentoDescricao' },
  { label: 'Internet link', field: 'internetLinkDescricao' },
  { label: 'Opções', field: 'actions' },
]);

const headersCollectors = ref<IHeadersTable[]>([
  {
    label: 'Unidade',
    field: 'unidadeNome',
  },
  { label: 'Link/Serviço', field: 'servicoNome' },
  { label: 'Origem', field: 'origem' },
  { label: 'Tipo/ip', field: 'equipamentoNome' },
  { label: 'Opções', field: 'actions' },
]);

const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Nome', field: 'nome' },
  { label: 'Versão', field: 'versao' },
  { label: 'Config Contagem', field: 'configCounter' },
  { label: 'Opções', field: 'actions' },
]);

const items = ref<Coletor[]>([]);
const sortable = ref<any>();

const textSaveModal = computed(() => {
  const isLast = steps.value[steps.value.length - 1].label === currentStep.value;
  return isLast ? 'Salvar' : 'Avançar';
});
const textCancelModal = computed(() => {
  const isLast = steps.value[0].label === currentStep.value;
  return isLast ? 'Cancelar' : 'Voltar';
});
const addNew = ref<boolean>(false);
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}
const tabelaUsers = ref<IOptionsSelect[]>([]);

const idColetorZero = ref<any>();
function ZeroTouch(item: any) {
  idColetorZero.value = item.id;
  showModalZero.value = true;
  zeroTouch(item.id).then((r: any[]) => {
    tabelaUsers.value = r.map((f) => {
      return { value: f.usuarioid, text: f.nomeUsuario } as IOptionsSelect;
    });
  });
}
function DowloadZip(item) {
  Preloader.actions.ActionSetPreloader(true);
  downloadLinuxMac(dadoZero.value.usuarioid, item)
    .then((f) => {
      const file = new Blob([f], {
        type: 'application/octet-stream',
      });
      const filename = `go_connect_agent_${dadoZero.value.usuarioid}.zip`;
      const a = document.createElement('a');
      a.target = '_blank';
      a.download = filename;
      a.href = URL.createObjectURL(file);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .finally(() => {
      showModalZero.value = false;
      Preloader.actions.ActionSetPreloader(false);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Download concluído com sucesso!',
      });
    });
}
function DowloadZipWin(item) {
  Preloader.actions.ActionSetPreloader(true);
  downloadWin(dadoZero.value.usuarioid, item)
    .then((f) => {
      const file = new Blob([f], {
        type: 'application/octet-stream',
      });
      const filename = `go_connect_agent_${dadoZero.value.usuarioid}.bat`;
      const a = document.createElement('a');
      a.target = '_blank';
      a.download = filename;
      a.href = URL.createObjectURL(file);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    })
    .finally(() => {
      showModalZero.value = false;
      Preloader.actions.ActionSetPreloader(false);
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: 'Download concluído com sucesso!',
      });
    });
}

function clear() {
  data.value.nome = '';
  data.value.versao = '';
  data.value.configCounter = 1;
  data.value.interfaceDispositivoLista = [];
  data.value.connServiceLista = [];
  data.value.id = 0;
  currentStep.value = 'Informações primárias';
}
function setColetor() {
  // if (!testeColetor.value.equipamentoId) {

  //   Swal.fire({
  //     icon: "warning",
  //     title: "Atenção",
  //     text: "Equipamento é obrigatório"
  //   });

  //   return;
  // }

  if (!testeColetor.value.unidadeId) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Unidade é obrigatório',
    });

    return;
  }

  if (!testeColetor.value.servicoId) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Serviço é obrigatório',
    });

    return;
  }

  if (!testeColetor.value.grupoId) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Grupo é obrigatório',
    });

    return;
  }

  if (!testeColetor.value.grupoId) {
    Swal.fire({
      icon: 'warning',
      title: 'Atenção',
      text: 'Grupo é obrigatório',
    });

    return;
  }
  // if (!testeColetor.value.origem) {
  //   Swal.fire({
  //     icon: "warning",
  //     title: "Atenção",
  //     text: "Origem é obrigatório"
  //   });

  //   return;
  // }
  const coletorLabels = {
    equipamentoNome: optionsEquipamentos.value.find((x: any) => x.value === testeColetor.value.equipamentoId)?.text,
    unidadeNome: optionsUnidades.value.find((x: any) => x.value === testeColetor.value.unidadeId)?.text,
    grupoNome: optionsGrupos.value.find((x: any) => x.value === testeColetor.value.grupoId)?.text,
    servicoNome: optionsServicos.value.find((x: any) => x.value === testeColetor.value.servicoId)?.text,
  };

  testeColetor.value.origem = testeColetor.value.origem ?? 0;

  data.value.connServiceLista.push({
    ...testeColetor.value,
    ...coletorLabels,
    archived: 0,
    constJitter: 1,
    constLatency: 1,
    isLocal: 0,
    sensibilityFactor: 1,
    status: 'INSERIDO',
    ref: String(data.value.connServiceLista.length),
  });
  testeColetor.value = {};
}
function setLink() {
  const item = interfaces.value.find((f) => f.id == interfaceDispositivoLista.value);
  data.value.interfaceDispositivoLista.push({ ...item } as InterfaceDispositivoLisum);
  interfaceDispositivoLista.value = null;
}

function getLinks() {
  getInterfaceDispositivosAll(0, 9999999).then((r) => {
    const items = (r._embedded.InterfaceDispositivosCollection as InterfaceDispositivos[])
      .filter((f) => f.archived == 0)
      .map((f) => ({ text: f.internetLinkDescricao, value: f.id, ...f }) as IOptionsSelect);

    interfaces.value = (r._embedded.InterfaceDispositivosCollection as InterfaceDispositivos[]).filter(
      (f) => f.archived == 0,
    );
    optionsLinks.value = items;
  });
}
function getGrupos() {
  getGruposAll(0, 9999999, term.value).then((r) => {
    const items = r.lista.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    optionsGrupos.value = items;
  });
}
function getUnidades() {
  getUnidadeAll(0, 9999999, term.value).then((r) => {
    const items = r.lista.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    optionsUnidades.value = items;
  });
}
function getEquipamentos() {
  getEquipamentoAll(0, 99999999).then((r) => {
    const items = (r.lista as Equipamento[])
      .filter((f) => f.archived == 0)
      .map((f) => ({ text: `${f.id} - ${f.ip}`, value: f.id }) as IOptionsSelect);
    optionsEquipamentos.value = items;
  });
}
function getServicos() {
  getServicosAll(0, 99999999, term.value).then((r) => {
    const items = r.lista.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    optionsServicos.value = items;
  });
}

function handleEdit(payload: Coletor, isApp: boolean = false) {
  console.log(payload, 'Edit');
  data.value = { ...payload };
  currentStep.value = 'Informações primárias';

  type.value = 'E';
  if (isApp) {
    addNew.value = true;
  } else {
    showModal.value = true;
  }
}
function handleDelete(payload) {
  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente inativar este coletor?`,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      sendDelete(payload);
    }
  });
}

function sendDelete(payload) {
  console.log(payload, 'Delete');
  try {
    payload.archived = 1;
    ServiceColetores.putColetores(payload).then(() => {
      Swal.fire({
        title: 'Sucesso',
        text: `Coletor ${payload.nome} foi arquivado com sucesso!`,
        icon: 'success',
      });
      load();
    });
  } catch (err) {
    console.log(err);
    Swal.fire({
      icon: 'error',
      title: 'Ocorreu algum erro',
    });
  }
}
function handleDeleteColetor(payload) {
  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente retira esta Configuração?`,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      payload.status = 'DELETADO';
      if (payload.id) {
        const idx = data.value.connServiceLista.findIndex((x) => x.id === payload.id);
        data.value.connServiceLista[idx] = payload;
      } else {
        const idx = data.value.connServiceLista.findIndex((x) => x.ref === payload.ref);
        data.value.connServiceLista[idx] = payload;
      }
    }
  });
}
function handleDeleteLink(payload) {
  data.value.interfaceDispositivoLista = data.value.interfaceDispositivoLista.filter((x) => {
    if (payload.ref) {
      return x.ref !== payload.ref;
    } else {
      return x.id !== payload.id;
    }
  });
}

const totalRows = ref(0);
const term = ref<string>('');

function load() {
  Preloader.actions.ActionSetPreloader(true);
  totalRows.value = 0;

  let promisse;

  if (sortable.value) {
    promisse = ServiceColetores.getColetoresAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = ServiceColetores.getColetoresAll(page.value - 1, perPage.value, term.value);
  }

  promisse
    .then((resp) => {
      items.value = resp.lista;
      items.value.map((f) => {
        if (f.connServiceLista.length) {
          f.connServiceLista.map((x) => {
            x.status = 'MANTIDO';
          });
        }
      });
      totalRows.value = resp.totalItems;
      console.log(resp);
    })

    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  load();
}
function handleNew() {
  const idx = steps.value.findIndex((x) => x.label === currentStep.value);

  if (idx !== -1 && steps.value[idx + 1]) {
    steps.value[idx].completed = true;
    currentStep.value = steps.value[idx + 1].label;
  }

  if (steps.value.length - 1 === idx) {
    const body = { ...data.value, cloudStatus: 0, versao: '1.0', archived: 0 };
    body.interfaceDispositivoLista = body.interfaceDispositivoLista.map((x) => {
      return { id: x.id };
    });

    for (const iterator of body.connServiceLista) {
      if (iterator.archived == 1) iterator.status = 'DELETADO';
    }

    Preloader.actions.ActionSetPreloader(true);
    if (body.id) {
      ServiceColetores.putColetores(body)
        .then(() => {
          load();
          showModal.value = false;
          Swal.fire({
            title: 'Sucesso',
            text: `Coletor ${data.value.nome} foi atualizado com sucesso!`,
            icon: 'success',
          });
        })
        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    } else {
      body.id = undefined;
      ServiceColetores.postColetores(body)
        .then(() => {
          Swal.fire({
            title: 'Sucesso',
            text: `Coletor ${data.value.nome} foi criada com sucesso!`,
            icon: 'success',
          });
          load();
          showModal.value = false;
        })
        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  }
}
function handleCancelNew(event) {
  if ((event ?? '') == 'fecha') showModal.value = false;
  const idx = steps.value.findIndex((x) => x.label === currentStep.value);

  if (idx !== -1 && steps.value[idx - 1]) {
    steps.value[idx - 1].completed = false;
    currentStep.value = steps.value[idx - 1].label;
  } else {
    showModal.value = false;
  }
}
function handleCancelNewZero() {
  showModalZero.value = false;
  // if ((event ?? '') == 'fecha') showModalZero.value = false;
}

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'collectors') {
      load();
    }
  },
);
watch(
  () => sortable.value,
  () => {
    load();
  },
);
watch(
  () => perPage.value,
  () => {
    load();
  },
);
watch(
  () => page.value,
  () => {
    load();
  },
);

watch(
  () => term.value,
  () => {
    load();
  },
);

onMounted(() => {
  load();
  getGrupos();
  getUnidades();
  getLinks();
  getEquipamentos();
  getServicos();
});

function searching(event: string | undefined) {
  term.value = String(event);
}

const __returned__ = { props, steps, currentStep, data, perPage, page, showModal, showModalZero, testeColetor, optionsGrupos, optionsLinks, interfaces, optionsEquipamentos, optionsUnidades, optionsServicos, dadoZero, interfaceDispositivoLista, type, headersInterfaceLink, headersCollectors, headers, items, sortable, textSaveModal, textCancelModal, addNew, replacement, tabelaUsers, idColetorZero, ZeroTouch, DowloadZip, DowloadZipWin, clear, setColetor, setLink, getLinks, getGrupos, getUnidades, getEquipamentos, getServicos, handleEdit, handleDelete, sendDelete, handleDeleteColetor, handleDeleteLink, totalRows, term, load, sort, handleNew, handleCancelNew, handleCancelNewZero, searching, get UserFullIcon() { return UserFullIcon }, get AtomButton() { return AtomButton }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get PlusIcon() { return PlusIcon }, get InfoSquareIcon() { return InfoSquareIcon }, get AtomTooltip() { return AtomTooltip }, AtomProgressForm, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})