import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted } from 'vue';
import {
  AtomAccordion,
  AtomSelect,
  ArrowCircleIcon,
  // rectangleColorIcon,
  // rectangleIcon,
  AtomInput,
} from '@/components/atoms';
import { IOptionsSelect /*IStep*/ } from '@/types';
import {
  postTriggers,
  putTriggers,
  listaGatilhos,
  listaOrdem,
  listaIntervaoNotificacao,
} from '@/core/services/TriggersNotification';
import { getCompanhiaAll } from '@/core/services/CompanhiasService';
import { getUnidadeAll } from '@/core/services/UnitsService';
import { Authentication, Preloader } from '@/store';
// import { GatilhoNotificacao } from '@/core/models';
import Swal from 'sweetalert2';
import { computed } from 'vue';
import { watch } from 'vue';
import { filtrosServicosPorUnidade } from '@/core/services/GraficoService';
// getServicosAll
// import { dadosPingMinuto } from '@/core/services/GraficoService';
// import {Grupos } from '@/core/models';
interface IProps {
  id?: any;
  stepTri?: boolean;
  confirma?: any;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TriggerModal',
  props: {
    id: { type: null, required: false },
    stepTri: { type: Boolean, required: false },
    confirma: { type: null, required: false }
  },
  emits: ['closeModal', 'load', 'create'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emits = __emit;
const flow = ref<boolean>(false);
// const jitterLatencia = ref<boolean>(false);
const listError = ref<string[]>([]);

const trigger = ref<string>();
const TypeNot = ref<string>();
const optionsSelect = ref<any[]>([
  'Jitter',
  'Latencia',
  'Jitter & Latencia',
  'Indisponibilidade / Conectividade do Link',
  'Indisponibilidade',
  'Conectividade do Link',
]);
const optionsSelectTime = ref<IOptionsSelect[]>([
  { text: '15 minutos', value: '_15MINUTOS' },
  { text: '30 minutos', value: '_30MINUTOS' },
  { text: '45 minutos', value: '_45MINUTOS' },
]);
const optionsSelectNotification = ref<any[]>(['1', '2', '3']);
// const groups = ref<IOptionsSelect[]>([]);
const notification1 = ref<boolean>(false);
const notification2 = ref<boolean>(false);
const notification3 = ref<boolean>(false);
const dados = ref<any>({});
// const step = ref(1);
const companhias = ref<IOptionsSelect[]>([]);
const unidades = ref<IOptionsSelect[]>([]);
const links = ref<IOptionsSelect[]>([]);
// const jitter = ref<IOptionsSelect[]>([]);
const jitter = ref<IOptionsSelect[]>([
  // { text: 'Maior Igual', value: '>=' },
  { text: 'Igual', value: '=' },
  // { text: 'Menor Igual', value: '<=' },
  { text: 'Maior', value: '>' },
  { text: 'Menor', value: '<' },
  // { text: 'Diferente', value: '!=' },
]);
const TypeNotification = ref<IOptionsSelect[]>([
  { text: 'Contínua', value: 'CONTINUA' },
  { text: 'Periódica', value: 'PERIODICA' },
]);

const optionsCurrentState = ref<IOptionsSelect[]>([
  { text: 'Saudável', value: 'saudavel' },
  { text: 'Instável', value: 'instavel' },
  { text: 'Degradado', value: 'degradado' },
  { text: 'Problemático', value: 'problematico' },
]);
function edit() {
  Preloader.actions.ActionSetPreloader(false);
  if (props.id.id) {
    // dados.value = JSON.parse(JSON.stringify(props.id));

    const dataApx = JSON.parse(JSON.stringify(props.id));

    TypeNot.value = dataApx.tipoNotificacaoEnum;
    trigger.value = dataApx.condicaoGatilhoEnum;

    if (dataApx.unidade) {
      dados.value.company = unidades.value.find((f) => f.value == dataApx.unidade?.id).companhiaId;
      dados.value.unidade = dataApx.unidade?.id;
    }
    setTimeout(() => {
      if (dataApx.servico) dados.value.links = dataApx.servico.id;
    }, 100);
    dados.value.tituloNotificacao = dataApx.tituloNotificacao;
    dados.value.status = dataApx.archived == 0;
    dataApx.regraNotificacaoLista.map((x, index) => {
      if (index == 0) {
        dados.value.typeNotifications = x.ordemNotificacaoEnum;
        dados.value.timeFirstNotification = x.intervaloTempoNotificaEnum;
        dados.value.operador1 = x.operadorLogico;
        dados.value.ValorRegra1 = x.valor;
      } else if (index == 1) {
        dados.value.timeSecondNotification = x.intervaloTempoNotificaEnum;
        dados.value.operador2 = x.operadorLogico;
        dados.value.ValorRegra2 = x.valor;
      } else {
        dados.value.timeThirdNotification = x.intervaloTempoNotificaEnum;
        dados.value.operador3 = x.operadorLogico;
        dados.value.ValorRegra3 = x.valor;
      }
    });
  }
}
const computedOptionsCurrentState = computed(() => {
  let items = [];

  if (dados.value.currentQualityAvailability1 === 'saudavel') {
    items = [
      { text: 'Instável', value: 'instavel' },
      { text: 'Degradado', value: 'degradado' },
      { text: 'Problemático', value: 'problematico' },
    ];
  }
  if (dados.value.currentQualityAvailability1 === 'instavel') {
    items = [
      { text: 'Degradado', value: 'degradado' },
      { text: 'Problemático', value: 'problematico' },
    ];
  }
  if (
    dados.value.currentQualityAvailability1 === 'degradado' ||
    dados.value.currentQualityAvailability1 === 'problematico'
  ) {
    items = [{ text: 'Problemático', value: 'problematico' }];
  }

  return items;
});
const computedOptionsCurrentState2 = computed(() => {
  let items = [];
  if (dados.value.currentQualityAvailability2 === 'saudavel') {
    items = [
      { text: 'Instável', value: 'instavel' },
      { text: 'Degradado', value: 'degradado' },
      { text: 'Problemático', value: 'problematico' },
    ];
  }
  if (dados.value.currentQualityAvailability2 === 'instavel') {
    items = [
      { text: 'Degradado', value: 'degradado' },
      { text: 'Problemático', value: 'problematico' },
    ];
  }
  if (
    dados.value.currentQualityAvailability2 === 'degradado' ||
    dados.value.currentQualityAvailability2 === 'problematico'
  ) {
    items = [{ text: 'Problemático', value: 'problematico' }];
  }

  return items;
});
const computedOptionsCurrentState3 = computed(() => {
  let items = [];
  if (dados.value.currentQualityAvailability3 === 'saudavel') {
    items = [
      { text: 'Instável', value: 'instavel' },
      { text: 'Degradado', value: 'degradado' },
      { text: 'Problemático', value: 'problematico' },
    ];
  }
  if (dados.value.currentQualityAvailability3 === 'instavel') {
    items = [
      { text: 'Degradado', value: 'degradado' },
      { text: 'Problemático', value: 'problematico' },
    ];
  }
  if (
    dados.value.currentQualityAvailability3 === 'degradado' ||
    dados.value.currentQualityAvailability3 === 'problematico'
  ) {
    items = [{ text: 'Problemático', value: 'problematico' }];
  }

  return items;
});

const compudetUnidades = computed(() => {
  const options = unidades.value.filter((x) => x.companhiaId === dados.value.company);
  return options;
});

const props = __props;

// function handleBackJittler() {
//   jitterLatencia.value = false;
//   flow.value = false;

//   if (step.value === 1) {
//     emits('closeModal');
//     trigger.value = '';
//   } else {
//     step.value -= 1;
//   }
// }
// function handleJittlerNext() {
//   jitterLatencia.value = true;
//   flow.value = true;

//   step.value += 1;
// }

function loadData() {
  getCompanhiaAll(0, 99999999).then((r) => {
    companhias.value = r.lista.map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
  });
}

function loadUnit() {
  getUnidadeAll(0, 99999999).then((r) => {
    unidades.value = r.lista.map((f) => ({ companhiaId: f.companhiaId, text: f.nome, value: f.id }) as IOptionsSelect);

    edit();
  });
}
// function Jitter() {
//   jitter.value = steps.value.map((f) => f.jitter as IOptionsSelect);
// }
// function Latencia() {
//   latencia.value = steps.value.map((f) => f.latencia as IOptionsSelect);
// }
const linksCmputed = computed(() => {
  return links.value; // dados.value.unidade ? .filter((f) => f.dado.unidade.id == dados.value.unidade) : links.value;
});

watch(
  () => dados.value.unidade,
  () => {
    if (dados.value.unidade)
    filtrosServicosPorUnidade(dados.value.unidade).then((r) => {
        links.value = r.map((f) => {
          return {
            text: `${f.id}- ${f.nome}`,
            value: f.id,
            dado: f,
          } as IOptionsSelect;
        });
      });
  },
);

function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);
  const body: any = {
    id: props.id?.id,
    tipoNotificacaoEnum:  TypeNot.value,
    tituloNotificacao: dados.value.tituloNotificacao,
    condicaoGatilhoEnum: (trigger.value ?? '').toUpperCase(),
    unidade: {
      id: dados.value.unidade,
    },
    servico: {
      id: dados.value.links,
    },
    archived: dados.value.status ? 0 : 1,
    regraNotificacaoLista: [
      {
        ordemNotificacaoEnum: 'PRIMEIRA',
        intervaloTempoNotificaEnum: String(dados.value.timeFirstNotification).toUpperCase(),
        operadorLogico: dados.value.operador1,
        valor: dados.value.ValorRegra1,
      },
    ],
    usuario: Authentication.getters.getUser.value.id,
  };

  if (dados.value.operador2 && dados.value.timeSecondNotification && dados.value.ValorRegra2)
    body.regraNotificacaoLista.push({
      ordemNotificacaoEnum: 'SEGUNDA',
      intervaloTempoNotificaEnum: String(dados.value.timeSecondNotification).toUpperCase(),
      operadorLogico: dados.value.operador2,
      valor: dados.value.ValorRegra2,
    });

  if (dados.value.operador3 && dados.value.timeThirdNotification && dados.value.ValorRegra3)
    body.regraNotificacaoLista.push({
      ordemNotificacaoEnum: 'TERCEIRA',
      intervaloTempoNotificaEnum: String(dados.value.timeThirdNotification).toUpperCase(),
      operadorLogico: dados.value.operador3,
      valor: dados.value.ValorRegra3,
    });

  if (!props.id.id) {
    postTriggers(body)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Gatilho criado com sucesso!`,
        });
        emits('closeModal');
        emits('load', true);
      })
      .finally(() => {
        trigger.value = '';
        dados.value = {};
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    putTriggers(body)
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Gatilho atualizado com sucesso!!`,
        });
        emits('closeModal');
        emits('load', true);
      })
      .finally(() => {
        trigger.value = '';
        dados.value = {};
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}

watch(
  () => dados.value.currentQualityAvailability1,
  () => {
    if (dados.value.currentQualityAvailability1 === 'saudavel') {
      dados.value['futureQualityAvailability1'] = ['instavel', 'degradado', 'problematico'];
    }
    if (dados.value.currentQualityAvailability1 === 'instavel') {
      dados.value['futureQualityAvailability1'] = ['degradado', 'problematico'];
    }
    if (dados.value.currentQualityAvailability1 === 'degradado') {
      dados.value['futureQualityAvailability1'] = ['problematico'];
    }
    if (
      dados.value.currentQualityAvailability1 === 'problematico' ||
      dados.value.currentQualityAvailability1 === 'problematico'
    ) {
      dados.value['futureQualityAvailability1'] = ['problematico'];
    }
  },
);
watch(
  () => dados.value.currentQualityAvailability2,
  () => {
    if (dados.value.currentQualityAvailability2 === 'saudavel') {
      dados.value['futureQualityAvailability2'] = ['instavel', 'degradado', 'problematico'];
    }
    if (dados.value.currentQualityAvailability2 === 'instavel') {
      dados.value['futureQualityAvailability2'] = ['degradado', 'problematico'];
    }
    if (dados.value.currentQualityAvailability2 === 'degradado') {
      dados.value['futureQualityAvailability2'] = ['problematico'];
    }
    if (
      dados.value.currentQualityAvailability2 === 'problematico' ||
      dados.value.currentQualityAvailability2 === 'problematico'
    ) {
      dados.value['futureQualityAvailability2'] = ['problematico'];
    }
  },
);
watch(
  () => dados.value.currentQualityAvailability3,
  () => {
    if (dados.value.currentQualityAvailability3 === 'saudavel') {
      dados.value['futureQualityAvailability3'] = ['instavel', 'degradado', 'problematico'];
    }
    if (dados.value.currentQualityAvailability3 === 'instavel') {
      dados.value['futureQualityAvailability3'] = ['degradado', 'problematico'];
    }
    if (dados.value.currentQualityAvailability3 === 'degradado') {
      dados.value['futureQualityAvailability3'] = ['problematico'];
    }
    if (
      dados.value.currentQualityAvailability3 === 'problematico' ||
      dados.value.currentQualityAvailability3 === 'problematico'
    ) {
      dados.value['futureQualityAvailability3'] = ['problematico'];
    }
  },
);

watch(
  () => props.id,
  () => {
    const dataApx = JSON.parse(JSON.stringify(props.id));

    trigger.value = dataApx.condicaoGatilhoEnum;

    if (dataApx.unidade) {
      dados.value.company = unidades.value.find((f) => f.value == dataApx.unidade.id).companhiaId;
      dados.value.unidade = dataApx.unidade?.id;
    }
    TypeNot.value = dataApx.tipoNotificacaoEnum;
    setTimeout(() => {
      if (dataApx.servico) dados.value.links = dataApx.servico.id;
    }, 100);
    dados.value.status = dataApx.archived == 0;

    dados.value.tituloNotificacao = dataApx.tituloNotificacao;
    dataApx.regraNotificacaoLista.map((x, index) => {
      if (index == 0) {
        dados.value.typeNotifications = x.ordemNotificacaoEnum;
        dados.value.timeFirstNotification = x.intervaloTempoNotificaEnum;
        dados.value.operador1 = x.operadorLogico;
        dados.value.ValorRegra1 = x.valor;
      } else if (index == 1) {
        dados.value.timeSecondNotification = x.intervaloTempoNotificaEnum;
        dados.value.operador2 = x.operadorLogico;
        dados.value.ValorRegra2 = x.valor;
      } else {
        dados.value.timeThirdNotification = x.intervaloTempoNotificaEnum;
        dados.value.operador3 = x.operadorLogico;
        dados.value.ValorRegra3 = x.valor;
      }
    });
  },
  { deep: true },
);
watch(
  () => props.confirma,
  () => handleAddOrUpdate(),
);
// watch(
//   () => dados.value.links,
//   () => {
//     if (trigger.value?.toLowerCase().includes('jitter') || trigger.value?.toLowerCase().includes('latencia')) {
//       dadosPingMinuto(dados.value.links).then((f) => {
//         if ((f ?? []).length) {
//           if (trigger.value?.toLowerCase().includes('jitter')) {
//             dados.value.jitter = f[f.length - 1].jitter;
//           }

//           if (trigger.value?.toLowerCase().includes('latencia')) {
//             dados.value.latencia = f[f.length - 1].latencia;
//           }
//         }
//       });
//     }
//   },
// );
async function loadListas() {
  optionsSelect.value = (await listaGatilhos()).map((x) => {
    return { text: x.label, value: x.stringEnum };
  });
  optionsSelectNotification.value = (await listaOrdem()).map((x) => {
    return { text: x.label, value: x.stringEnum };
  });
  optionsSelectTime.value = (await listaIntervaoNotificacao()).map((x) => {
    return { text: x.label, value: x.stringEnum };
  });
}

onMounted(() => {
  Preloader.actions.ActionSetPreloader(true);
  loadListas();
  loadData();
  // loadLink();
  loadUnit();

  // Jitter();
  // Latencia();
});

const __returned__ = { emits, flow, listError, trigger, TypeNot, optionsSelect, optionsSelectTime, optionsSelectNotification, notification1, notification2, notification3, dados, companhias, unidades, links, jitter, TypeNotification, optionsCurrentState, edit, computedOptionsCurrentState, computedOptionsCurrentState2, computedOptionsCurrentState3, compudetUnidades, props, loadData, loadUnit, linksCmputed, handleAddOrUpdate, loadListas, get AtomAccordion() { return AtomAccordion }, get AtomSelect() { return AtomSelect }, get ArrowCircleIcon() { return ArrowCircleIcon }, get AtomInput() { return AtomInput } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})