import { createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-between"
}
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "d-flex pa-3 justify-end w-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_footer = _resolveComponent("v-footer")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    modelValue: $setup.dialog,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dialog) = $event)),
    persistent: "",
    width: $setup.props.width,
    height: $setup.props.height
  }, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent($setup.props.el), {
        class: "modal-area",
        onSubmit: _withModifiers($setup.handleConfirm, ["prevent"]),
        style: _normalizeStyle(`height: ${$setup.props.height}`)
      }, {
        default: _withCtx(() => [
          (!$props.hideHeader)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: _normalizeClass(`${$props.hideDividerHeader ? 'px-8 pt-8' : 'pa-8'} w-100`)
                }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode($setup["AtomTitle"], {
                      title: $setup.props.title
                    }, null, 8 /* PROPS */, ["title"]),
                    _renderSlot(_ctx.$slots, "step", {}, undefined, true)
                  ]),
                  (!!$setup.props.subtitle)
                    ? (_openBlock(), _createBlock($setup["AtomSubTitle"], {
                        key: 0,
                        text: $setup.props.subtitle || '',
                        class: "ma-0"
                      }, null, 8 /* PROPS */, ["text"]))
                    : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_v_btn, {
                    variant: "text",
                    icon: "",
                    dark: "",
                    onClick: $setup.close,
                    class: "ml-auto"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_icon, { color: "var(--primary-nav)" }, {
                        default: _withCtx(() => _cache[2] || (_cache[2] = [
                          _createTextVNode("mdi-close")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ]))
            : _createCommentVNode("v-if", true),
          (!$props.hideHeader && !$props.hideDividerHeader)
            ? (_openBlock(), _createBlock(_component_v_divider, { key: 1 }))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            class: _normalizeClass($setup.props.bodyScrool? 'body-modal-scroll':'body-modal'),
            style: _normalizeStyle({ padding: `${$setup.props.paddingY ? $setup.props.paddingY : '32px'} 32px` })
          }, [
            _renderSlot(_ctx.$slots, "body", {}, undefined, true)
          ], 6 /* CLASS, STYLE */),
          (!$props.hideFooter)
            ? (_openBlock(), _createBlock(_component_v_footer, {
                key: 2,
                class: "d-flex justify-end pa-0 pa-8 rounded-xl bg-transparent"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_btn, {
                    onClick: $setup.handleCancel,
                    density: "default",
                    "base-color": "var(--primary-nav-10)",
                    size: "56",
                    variant: "flat",
                    style: {"color":"var(--primary-nav)","font-weight":"600"},
                    height: "56",
                    class: "btn-footer mr-4"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString($props.btnCloseText), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (!$props.btnHide)
                    ? (_openBlock(), _createBlock(_component_v_btn, {
                        key: 0,
                        density: "default",
                        size: "56",
                        variant: "flat",
                        color: "primary",
                        style: {"font-weight":"600"},
                        height: "56",
                        onClick: _cache[0] || (_cache[0] = 
            () => {
              $setup.props.el !== 'form' ? $setup.handleConfirm() : '';
            }
          ),
                        type: "submit",
                        class: "btn-footer"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString($props.btnConfirmText), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }))
                    : _createCommentVNode("v-if", true)
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      }, 40 /* PROPS, NEED_HYDRATION */, ["style"]))
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["modelValue", "width", "height"]))
}