import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = {
  width: "100",
  height: "100",
  viewBox: "0 0 100 100",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<circle cx=\"50\" cy=\"50\" r=\"50\" fill=\"url(#paint0_linear_2001_83764)\"></circle><path d=\"M55.0812 27.7031C60.651 27.7031 65.1935 32.1678 65.1935 37.6645V48.4111H51.4974C50.5158 48.4111 49.7397 49.1739 49.7397 50.1386C49.7397 51.0809 50.5158 51.8662 51.4974 51.8662H65.1935V62.5904C65.1935 68.0871 60.651 72.5742 55.0356 72.5742H43.9188C38.3262 72.5742 33.7837 68.1095 33.7837 62.6128V37.6869C33.7837 32.1678 38.3491 27.7031 43.9417 27.7031H55.0812ZM70.8926 42.3988C71.5657 41.7033 72.665 41.7033 73.3381 42.3764L79.8893 48.9051C80.2258 49.2417 80.4053 49.6679 80.4053 50.1391C80.4053 50.5878 80.2258 51.0365 79.8893 51.3506L73.3381 57.8793C73.0016 58.2159 72.5529 58.3954 72.1266 58.3954C71.6779 58.3954 71.2292 58.2159 70.8926 57.8793C70.2196 57.2063 70.2196 56.1069 70.8926 55.4339L74.4823 51.8666L65.1935 51.8662V48.4111L74.4823 48.4116L70.8926 44.8443C70.2196 44.1712 70.2196 43.0719 70.8926 42.3988Z\" fill=\"white\"></path><defs><linearGradient id=\"paint0_linear_2001_83764\" x1=\"45\" y1=\"45\" x2=\"107.692\" y2=\"116.704\" gradientUnits=\"userSpaceOnUse\"><stop stop-color=\"#FF5C00\"></stop><stop offset=\"1\" stop-color=\"#ff9b62\"></stop></linearGradient></defs>", 3)
  ])))
}