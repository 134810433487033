import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
  import Draggable from 'vuedraggable';
  import * as comps from '@/components/atoms';
  import { AtomCheckBox } from '@/components/atoms';
  import { IHeadersTable } from '@/types'
  
  interface IProps {
    modelValue: IHeadersTable[];
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeReorder',
  props: {
    modelValue: { type: Array, required: true }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

  const props = __props
  
  const emit = __emit
  
  const value = computed({
    get(): IHeadersTable[] {
      return props.modelValue
    },
    set(value) {
      emit('update:modelValue', value)
    }
  })
  
  function handleChange(item) {
    if (item.moved) {
      let newIndex = item.moved.newIndex + 1
      const oldIndex = item.moved.oldIndex + 1
      newIndex = newIndex + (newIndex > oldIndex ? .1 : -.1)
      value.value[oldIndex - 1].orderItem = newIndex
      value.value.sort((a: any, b: any) => (a.orderItem > b.orderItem) ? 1 : -1)
      value.value.map((option: any, index: number) => option.orderItem = index + 1)
    }
    console.log(item)
  }
  
const __returned__ = { props, emit, value, handleChange, get Draggable() { return Draggable }, get comps() { return comps }, get AtomCheckBox() { return AtomCheckBox } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})