import { createApp } from "vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { vMaska } from 'maska'

import VCalendar from 'v-calendar';
import App from "./App.vue";
import money from 'v-money3'
import FloatingVue from 'floating-vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import Draggable from 'vuedraggable';

import './styles/global.css'
import 'v-calendar/dist/style.css';
import 'floating-vue/dist/style.css'
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";


const app = createApp(App);

if (process.env.VUE_APP_ENVIRONMENT !== 'development') {
    Sentry.init({
        app,
        environment: process.env.VUE_APP_ENVIRONMENT,
        release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({
                tracingOrigins: ['localhost', process.env.VUE_APP_API_URL, /^\//],
            }),
        ],
        debug: process.env.VUE_APP_ENVIRONMENT !== 'production',
        tracesSampleRate: process.env.VUE_APP_ENVIRONMENT === 'production' ? 0.2 : 1,
        tracingOptions: {
            trackComponents: true,
        },
        // Vue specific
        logErrors: process.env.VUE_APP_ENVIRONMENT === 'production' ? false : true,
        attachProps: true,
        attachStacktrace: true,
    });
}

app.use(router);
app.use(vuetify);
app.directive('maska', vMaska);
app.use(FloatingVue);
app.use(money);
app.use(VCalendar);
app.component('VueDatePicker', VueDatePicker).component('Draggable', Draggable);
app.mount("#app");

