import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, mergeProps as _mergeProps, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "d-flex flex-column h-100" }
const _hoisted_2 = { class: "flex-1 d-flex flex-column align-center" }
const _hoisted_3 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_4 = { type: "button" }
const _hoisted_5 = { class: "d-flex align-center justify-center" }
const _hoisted_6 = {
  key: 0,
  class: "name-logo"
}
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_group = _resolveComponent("v-list-group")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_v_navigation_drawer, {
    class: "bg-surface pe-2 transition-all",
    rail: "",
    style: _normalizeStyle({ minWidth: !$setup.state.isMobile ? $setup.state.menuRecolhido ? '120px' : '305px' : '100%', paddingLeft: '24px!important', paddingRight: '24px!important' })
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_v_list, { class: "w-100" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list_item, { class: "mb-1 w-100 area-expandir" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(`my-0 d-flex ${$setup.state.menuRecolhido ? 'justify-center' : 'justify-space-between'}`)
                  }, [
                    (!$setup.state.menuRecolhido)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, _cache[21] || (_cache[21] = [
                          _createElementVNode("span", { class: "bol" }, null, -1 /* HOISTED */),
                          _createElementVNode("span", { class: "bol" }, null, -1 /* HOISTED */),
                          _createElementVNode("span", { class: "bol" }, null, -1 /* HOISTED */)
                        ])))
                      : _createCommentVNode("v-if", true),
                    _withDirectives((_openBlock(), _createElementBlock("button", _hoisted_4, [
                      _createVNode($setup["IconExpandir"], {
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.state.menuRecolhido = !$setup.state.menuRecolhido)),
                        color: "var(--primary-nav)",
                        class: _normalizeClass(["transition-all", {
                    'rotate-invert': !$setup.state.menuRecolhido,
                  }])
                      }, null, 8 /* PROPS */, ["class"])
                    ])), [
                      [_directive_tooltip, $setup.state.menuRecolhido ? 'Expandir' : 'Retrair']
                    ])
                  ], 2 /* CLASS */)
                ]),
                _: 1 /* STABLE */
              }),
              ($setup.state.isMobile)
                ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_col, {
                        cols: "12",
                        "align-self": "center",
                        class: "text-end"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("button", {
                            class: "pa-5",
                            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.actions.handleToggleMenu && $setup.actions.handleToggleMenu(...args)))
                          }, [
                            _createVNode($setup["DobbleLeftIcon"], { color: "var(--primary-nav)" })
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, {
                        "align-self": "center",
                        cols: "2",
                        class: "text-end ms-3 px-0",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.showModalProfile = true))
                      }, {
                        default: _withCtx(() => [
                          ($setup.state.isMobile)
                            ? (_openBlock(), _createBlock(_component_v_avatar, _mergeProps({ key: 0 }, $setup.props, {
                                color: "grey",
                                size: "60",
                                rounded: "5",
                                class: "me-5 cursor-pointer"
                              }), {
                                default: _withCtx(() => [
                                  _createVNode(_component_v_img, {
                                    cover: "",
                                    src: $setup.Authentication.getters.getUser.value.fotoBase64
                                  }, null, 8 /* PROPS */, ["src"])
                                ]),
                                _: 1 /* STABLE */
                              }, 16 /* FULL_PROPS */))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_v_col, {
                        cols: "9",
                        "align-self": "center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["AtomTitle"], {
                            classes: ['text-sm'],
                            noMargin: "",
                            title: `${$setup.Authentication.state.nome}`
                          }, null, 8 /* PROPS */, ["title"]),
                          _createVNode($setup["AtomSubTitle"], {
                            classes: ['text-gray-01 text-xs'],
                            noMargin: "",
                            text: $setup.getFunction()
                          }, null, 8 /* PROPS */, ["text"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }))
                : (_openBlock(), _createBlock(_component_v_list_item, {
                    key: 1,
                    class: "d-flex w-100 justify-center mb-5 bg-hover"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode($setup["IconLogo"]),
                        (!$setup.state.menuRecolhido)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_6, "GoConnect."))
                          : _createCommentVNode("v-if", true)
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })),
              _createVNode(_component_v_divider)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_v_list, {
            density: "compact",
            class: "w-100"
          }, {
            default: _withCtx(() => [
              (!$setup.state.isMobile)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                    onMouseenter: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleHover('Home'))),
                    onMouseleave: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleHover('')))
                  }, [
                    _createVNode(_component_v_list_item, {
                      class: _normalizeClass(["py-5", {
                'active': $setup.route.name === 'Home'
              }]),
                      to: { name: 'Home' },
                      "active-color": "var(--color-gray-06)"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode($setup["GraphIcon"], {
                          color: $setup.route.name === 'Home' || $setup.hover === 'Home' ? 'var(--primary-nav)' : ''
                        }, null, 8 /* PROPS */, ["color"])
                      ]),
                      default: _withCtx(() => [
                        (!$setup.state.menuRecolhido)
                          ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                              key: 0,
                              class: _normalizeClass({
                'pr-2': !$setup.state.menuRecolhido
              })
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Home' || $setup.hover === 'Home' ? 'text-primary-nav' : 'text-gray-01']])
                                }, "Dashboard", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ], 34 /* CLASS, NEED_HYDRATION */)), [
                    [_directive_tooltip, 'Dashboard']
                  ])
                : _createCommentVNode("v-if", true),
              ($setup.state.isMobile)
                ? (_openBlock(), _createBlock(_component_v_list_group, { key: 1 }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                        onMouseenter: _cache[5] || (_cache[5] = ($event: any) => ($setup.handleHover('Home'))),
                        onMouseleave: _cache[6] || (_cache[6] = ($event: any) => ($setup.handleHover('')))
                      }, [
                        _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                          prepend: _withCtx(() => [
                            _createVNode($setup["GraphIcon"], {
                              color: $setup.route.name === 'Home' || $setup.hover === 'Home' ? 'var(--primary-nav)' : ''
                            }, null, 8 /* PROPS */, ["color"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Configurações' || $setup.hover === 'Configurações' ? 'text-primary-nav' : 'text-gray-01']])
                                }, " Início ", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                      ], 34 /* CLASS, NEED_HYDRATION */)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.routesHome, (subSettings) => {
                        return (_openBlock(), _createElementBlock("button", {
                          key: subSettings.label,
                          class: "d-flex align-center ms-5 my-4 px-5",
                          onClick: ($event: any) => ($setup.handleRouterPush(subSettings.nameRoute))
                        }, [
                          _cache[22] || (_cache[22] = _createElementVNode("div", { class: "rounded-full dot" }, null, -1 /* HOISTED */)),
                          _createElementVNode("p", {
                            class: _normalizeClass(["text-sm ms-2", [$setup.route.name === subSettings.nameRoute ? 'text-primary-nav' : 'text-gray-01']])
                          }, _toDisplayString(subSettings.label), 3 /* TEXT, CLASS */)
                        ], 8 /* PROPS */, _hoisted_7))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              ($setup.hasRecurso('Agentes') && !$setup.state.isMobile)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 2,
                    class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                    onMouseenter: _cache[7] || (_cache[7] = ($event: any) => ($setup.handleHover('Home'))),
                    onMouseleave: _cache[8] || (_cache[8] = ($event: any) => ($setup.handleHover('')))
                  }, [
                    _createVNode(_component_v_list_item, {
                      class: _normalizeClass(["py-5", {
                'active': $setup.route.name === 'Agentes'
              }]),
                      to: { name: 'Agentes' },
                      "active-color": "var(--color-gray-06)"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode($setup["IconAnalisys"], {
                          color: $setup.route.name === 'Agentes' || $setup.hover === 'Agentes' ? 'var(--primary-nav)' : ''
                        }, null, 8 /* PROPS */, ["color"])
                      ]),
                      default: _withCtx(() => [
                        (!$setup.state.menuRecolhido)
                          ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                              key: 0,
                              class: _normalizeClass({
                'pr-2': !$setup.state.menuRecolhido
              })
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Agentes' || $setup.hover === 'Agentes' ? 'text-primary-nav' : 'text-gray-01']])
                                }, " Análises Gerais ", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ], 34 /* CLASS, NEED_HYDRATION */)), [
                    [_directive_tooltip, 'Análises Gerais']
                  ])
                : _createCommentVNode("v-if", true),
              ($setup.state.isMobile)
                ? (_openBlock(), _createBlock(_component_v_list_group, { key: 3 }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                        onMouseenter: _cache[9] || (_cache[9] = ($event: any) => ($setup.handleHover('Agentes'))),
                        onMouseleave: _cache[10] || (_cache[10] = ($event: any) => ($setup.handleHover('')))
                      }, [
                        _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                          prepend: _withCtx(() => [
                            _createVNode($setup["IconAnalisys"], {
                              color: $setup.route.name === 'Agentes' || $setup.hover === 'Agentes' ? 'var(--primary-nav)' : ''
                            }, null, 8 /* PROPS */, ["color"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Agentes' || $setup.hover === 'Agentes' ? 'text-primary-nav' : 'text-gray-01']])
                                }, " Análises Gerais ", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                      ], 34 /* CLASS, NEED_HYDRATION */)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.routesAnalitics, (subSettings) => {
                        return (_openBlock(), _createElementBlock("button", {
                          key: subSettings.label,
                          class: "d-flex align-center ms-5 my-4 px-5",
                          onClick: ($event: any) => ($setup.handleRouterPushAnalitics(subSettings.value))
                        }, [
                          _cache[23] || (_cache[23] = _createElementVNode("div", { class: "rounded-full dot" }, null, -1 /* HOISTED */)),
                          _createElementVNode("p", {
                            class: _normalizeClass(["text-sm ms-2", [$setup.route.name === subSettings.nameRoute ? 'text-primary-nav' : 'text-gray-01']])
                          }, _toDisplayString(subSettings.label), 3 /* TEXT, CLASS */)
                        ], 8 /* PROPS */, _hoisted_8))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(" <v-list-item class=\"py-5\" :class}\" :to=\"{ name: 'Quadrante' }\" active-color=\"var(--color-gray-06)\"\n          'active': route.name === 'Quadrante'}\" :to=\"{ name: 'Quadrante' }\" active-color=\"var(--color-gray-06)\"\n            v-if=\"hasRecurso('quadrante')\">\n            <template #prepend>\n              <CategoryIcon :color=\"route.name === 'Quadrante' ? 'var(--primary-nav)' : ''\" />\n            </template>\n            <v-list-item-title>\n              <p class=\"ms-5 text-gray-01 text-sm\" :class=\"[route.name === 'Quadrante' ? '' : '']\">\n                Quadrante\n              </p>\n            </v-list-item-title>\n          </v-list-item> "),
              ($setup.hasRecurso('Financeiro'))
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 4,
                    class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                    onMouseenter: _cache[11] || (_cache[11] = ($event: any) => ($setup.handleHover('Agentes'))),
                    onMouseleave: _cache[12] || (_cache[12] = ($event: any) => ($setup.handleHover('')))
                  }, [
                    _createVNode(_component_v_list_item, {
                      class: _normalizeClass(["py-5", {
                'active': $setup.route.name === 'Financeiro'
              }]),
                      to: { name: 'Financeiro' },
                      "active-color": "var(--color-gray-06)"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode($setup["ChartIcon"], {
                          color: $setup.route.name === 'Financeiro' || $setup.hover === 'Financeiro' ? 'var(--primary-nav)' : ''
                        }, null, 8 /* PROPS */, ["color"])
                      ]),
                      default: _withCtx(() => [
                        (!$setup.state.menuRecolhido)
                          ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                              key: 0,
                              class: _normalizeClass({
                'pr-2': !$setup.state.menuRecolhido
              })
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Financeiro' || $setup.hover === 'Financeiro'  ? 'text-primary-nav' : 'text-gray-01']])
                                }, " Gestão financeira ", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ], 34 /* CLASS, NEED_HYDRATION */)), [
                    [_directive_tooltip, 'Gestão financeira']
                  ])
                : _createCommentVNode("v-if", true),
              ($setup.hasRecurso('relatorios'))
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 5,
                    class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                    onMouseenter: _cache[13] || (_cache[13] = ($event: any) => ($setup.handleHover('Relatórios'))),
                    onMouseleave: _cache[14] || (_cache[14] = ($event: any) => ($setup.handleHover('')))
                  }, [
                    _createVNode(_component_v_list_item, {
                      class: _normalizeClass(["py-5", {
                'active': $setup.route.name === 'Relatórios'
              }]),
                      to: { name: 'Relatórios' },
                      "active-color": "var(--color-gray-06)"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode($setup["RelatoryIcon"], {
                          color: $setup.route.name === 'Relatórios' || $setup.hover === 'Relatórios' ? 'var(--primary-nav)' : ''
                        }, null, 8 /* PROPS */, ["color"])
                      ]),
                      default: _withCtx(() => [
                        (!$setup.state.menuRecolhido)
                          ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                              key: 0,
                              class: _normalizeClass({
                'pr-2': !$setup.state.menuRecolhido
              })
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Relatórios' || $setup.hover === 'Relatórios'  ? 'text-primary-nav' : 'text-gray-01']])
                                }, " Relatórios ", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ], 34 /* CLASS, NEED_HYDRATION */)), [
                    [_directive_tooltip, 'Relatórios']
                  ])
                : _createCommentVNode("v-if", true),
              ($setup.hasRecurso('usuarios'))
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 6,
                    class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                    onMouseenter: _cache[15] || (_cache[15] = ($event: any) => ($setup.handleHover('Usuários'))),
                    onMouseleave: _cache[16] || (_cache[16] = ($event: any) => ($setup.handleHover('')))
                  }, [
                    _createVNode(_component_v_list_item, {
                      class: _normalizeClass(["py-5", {
                'active': $setup.route.name === 'Usuários'
              }]),
                      to: { name: 'Usuários' },
                      "active-color": "var(--color-gray-06)"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode($setup["ProfileIcon"], {
                          colorInherit: "",
                          color: $setup.route.name === 'Usuários' || $setup.hover === 'Usuários' ? 'var(--primary-nav)' : ''
                        }, null, 8 /* PROPS */, ["color"])
                      ]),
                      default: _withCtx(() => [
                        (!$setup.state.menuRecolhido)
                          ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                              key: 0,
                              class: _normalizeClass({
                'pr-2': !$setup.state.menuRecolhido
              })
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Usuários' || $setup.hover === 'Usuários' ? 'text-primary-nav' : 'text-gray-01']])
                                }, "Usuários", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ], 34 /* CLASS, NEED_HYDRATION */)), [
                    [_directive_tooltip, 'Usuários']
                  ])
                : _createCommentVNode("v-if", true),
              ($setup.hasRecurso('configuracoes') && !$setup.state.isMobile)
                ? _withDirectives((_openBlock(), _createElementBlock("div", {
                    key: 7,
                    class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                    onMouseenter: _cache[17] || (_cache[17] = ($event: any) => ($setup.handleHover('Configurações'))),
                    onMouseleave: _cache[18] || (_cache[18] = ($event: any) => ($setup.handleHover('')))
                  }, [
                    _createVNode(_component_v_list_item, {
                      class: _normalizeClass(["py-5", {
                'active': $setup.route.name === 'Configurações'
              }]),
                      to: { name: 'Configurações' },
                      "active-color": "var(--color-gray-06)"
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode($setup["SettingIcon"], {
                          color: $setup.route.name === 'Configurações' || $setup.hover === 'Configurações' ? 'var(--primary-nav)' : ''
                        }, null, 8 /* PROPS */, ["color"])
                      ]),
                      default: _withCtx(() => [
                        (!$setup.state.menuRecolhido)
                          ? (_openBlock(), _createBlock(_component_v_list_item_title, {
                              key: 0,
                              class: _normalizeClass({
                'pr-2': !$setup.state.menuRecolhido
              })
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Configurações' || $setup.hover === 'Configurações' ? 'text-primary-nav' : 'text-gray-01']])
                                }, " Configurações ", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["class"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ], 34 /* CLASS, NEED_HYDRATION */)), [
                    [_directive_tooltip, 'Configurações']
                  ])
                : _createCommentVNode("v-if", true),
              ($setup.state.isMobile && $setup.hasRecurso('configuracoes'))
                ? (_openBlock(), _createBlock(_component_v_list_group, { key: 8 }, {
                    activator: _withCtx(({ props }) => [
                      _createElementVNode("div", {
                        class: _normalizeClass(`d-flex ${$setup.state.menuRecolhido ? 'justify-center' : ''} px-0 my-0`),
                        onMouseenter: _cache[19] || (_cache[19] = ($event: any) => ($setup.handleHover('Configurações'))),
                        onMouseleave: _cache[20] || (_cache[20] = ($event: any) => ($setup.handleHover('')))
                      }, [
                        _createVNode(_component_v_list_item, _normalizeProps(_guardReactiveProps(props)), {
                          prepend: _withCtx(() => [
                            _createVNode($setup["SettingIcon"], {
                              color: $setup.route.name === 'Configurações' || $setup.hover === 'Configurações' ? 'var(--primary-nav)' : ''
                            }, null, 8 /* PROPS */, ["color"])
                          ]),
                          default: _withCtx(() => [
                            _createVNode(_component_v_list_item_title, null, {
                              default: _withCtx(() => [
                                _createElementVNode("p", {
                                  class: _normalizeClass(["ms-5 text-sm", [$setup.route.name === 'Configurações' || $setup.hover === 'Configurações' ? 'text-primary-nav' : 'text-gray-01']])
                                }, " Configurações ", 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                      ], 34 /* CLASS, NEED_HYDRATION */)
                    ]),
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.state.routesSettings, (subSettings) => {
                        return (_openBlock(), _createElementBlock("button", {
                          key: subSettings.label,
                          class: "d-flex align-center ms-5 my-4 px-5",
                          onClick: ($event: any) => ($setup.handleRouterPushSettings(subSettings.value))
                        }, [
                          _cache[24] || (_cache[24] = _createElementVNode("div", { class: "rounded-full dot" }, null, -1 /* HOISTED */)),
                          _createElementVNode("p", {
                            class: _normalizeClass(["text-gray-01 text-sm ms-2", [$setup.route.query.page === subSettings.value ? '' : '']])
                          }, _toDisplayString(subSettings.label), 3 /* TEXT, CLASS */)
                        ], 8 /* PROPS */, _hoisted_9))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 1 /* STABLE */
                  }))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["style"]))
}