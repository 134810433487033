import { defineComponent as _defineComponent } from 'vue'
import { LoaderIcon, LoginPartTopIcon, LoginPartBottomIcon, LogoGoIcon, LogoGoOrangeIcon } from '@/components/atoms';
import { state } from '@/store/modules/layout';
import { Preloader } from '@/store';

export default /*@__PURE__*/_defineComponent({
  __name: 'AtomPreloader',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { get LoaderIcon() { return LoaderIcon }, get LoginPartTopIcon() { return LoginPartTopIcon }, get LoginPartBottomIcon() { return LoginPartBottomIcon }, get LogoGoIcon() { return LogoGoIcon }, get LogoGoOrangeIcon() { return LogoGoOrangeIcon }, get state() { return state }, get Preloader() { return Preloader } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})