import { createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-end mt-4 pr-3"
}
const _hoisted_2 = {
  class: "d-flex align-center w-100 justify-space-between pa-3 check-class",
  style: {"height":"40px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { style: {} }
const _hoisted_6 = { class: "d-flex justify-end" }
const _hoisted_7 = { class: "num-destac badge ml-3" }
const _hoisted_8 = { class: "d-flex justify-end" }
const _hoisted_9 = { class: "num-destac badge ml-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["MoleculeTitleSetings"], {
      class: "my-5",
      addBtn: true,
      title: "Quadrante",
      prependIcon: ""
    }, {
      prependIcon: _withCtx(() => [
        _createVNode($setup["GraphIconColor"], { class: "mt-2" })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: "3",
          class: "px-3"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["AtomToogleButton"], {
              buttons: $setup.buttonsTogglePredict ?? [],
              onHandleToggle: _cache[0] || (_cache[0] = 
          (e) => {
            $setup.mediaDispoServicoTab = e;
            $setup.mediaQualiServicoTab = e;
          }
        ),
              class: "my-5 me-5"
            }, null, 8 /* PROPS */, ["buttons"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "3",
          class: "px-3"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["AtomToogleButton"], {
              buttons: $setup.buttonsCharts,
              onHandleToggle: $setup.handleAlterToogle,
              class: "my-5 me-5",
              select: $setup.filterQuadratEscolha
            }, null, 8 /* PROPS */, ["buttons", "select"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["OrganismsChartQuadrant"], {
      loading: $setup.loadingQuadrant,
      title: `Relatório geral${!$setup.relatorioInit ? '' : ` - ${$setup.relatorioInit}`}`,
      type: "column",
      legend: false,
      data: $setup.dataCharArea,
      "color-series": "#FF5C00",
      hasTabs: "",
      height: 333,
      dataset: $setup.dataSetFiltrado,
      labels: $setup.labels,
      "title-y": "Disponibilidade",
      "title-x": "Qualidade",
      "max-y": 120,
      "max-x": 120,
      id: $setup.idChartQuadrant,
      "has-filters": "",
      "text-tooltip": 
      $setup.mediaDispoServicoTab == 'atual'
        ? 'O relatório mostra a evolução em movimento da qualidade x disponibilidade em um quadrante mágico de Gartner pra coletores, unidades e serviços durante um determinado período de tempo.'
        : 'O relatório mostra a predição  em movimento da qualidade x disponibilidade em um quadrante mágico de Gartner pra serviços durante um  período de 5 horas.'
    
    }, {
      tabs: _withCtx(() => [
        (!$setup.state.isMobile)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "text-center ms-10"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.legends, (legend) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    class: "text-center my-auto",
                    cols: "6",
                    md: "3",
                    key: legend.label
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["AtomLegend"], {
                        text: legend.label,
                        "bg-color": legend.color,
                        class: "mr-5"
                      }, null, 8 /* PROPS */, ["text", "bg-color"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" <div class=\"d-flex justify-end w-100\" style=\"gap: 10px\">\n      \n      </div> ")
      ]),
      tabsMobile: _withCtx(() => [
        ($setup.state.isMobile)
          ? (_openBlock(), _createBlock(_component_v_row, {
              key: 0,
              class: "text-center ms-10"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.legends, (legend) => {
                  return (_openBlock(), _createBlock(_component_v_col, {
                    class: "text-center my-auto",
                    cols: "6",
                    md: "3",
                    key: legend.label
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["AtomLegend"], {
                        text: legend.label,
                        "bg-color": legend.color,
                        class: "mr-5"
                      }, null, 8 /* PROPS */, ["text", "bg-color"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" <div class=\"d-flex justify-end w-100\" style=\"gap: 10px\">\n      \n      </div> ")
      ]),
      filters: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: _normalizeClass($setup.state.isMobile ? 'mt-0 px-3' : 'mt-4 pr-3')
          }, [
            (!$setup.state.isMobile)
              ? (_openBlock(), _createBlock($setup["AtomInput"], {
                  key: 0,
                  prependIcon: "",
                  noBgPrependIcon: "",
                  noBorderPrependIcon: "",
                  placeholder: "Pesquisar",
                  modelValue: $setup.search,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search) = $event)),
                  width: "100%",
                  borderColor: "#D6DBE180",
                  class: "w-100"
                }, {
                  prependIcon: _withCtx(() => [
                    _createVNode($setup["SearchIcon"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"]))
              : _createCommentVNode("v-if", true),
            ($setup.state.isMobile)
              ? (_openBlock(), _createBlock($setup["AtomInput"], {
                  key: 1,
                  prependIcon: "",
                  placeholder: "Pesquisar",
                  modelValue: $setup.search,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.search) = $event)),
                  width: "100%"
                }, {
                  prependIcon: _withCtx(() => [
                    _createVNode($setup["SearchIconMobile"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */),
          (!$setup.state.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", null, _toDisplayString($setup.filtradosQuadrant.filter((f) => f.selected == true).length == $setup.filtradosQuadrant.length
                  ? 'Desmarcar'
                  : 'Marcar') + " todos ", 1 /* TEXT */),
                  _createVNode($setup["AtomCheckBox"], {
                    classes: "checkbox-class",
                    onChange: $setup.handleFoceCheckAll
                  })
                ])
              ]))
            : _createCommentVNode("v-if", true),
          _createElementVNode("div", {
            style: {"max-height":"600px","overflow":"auto"},
            class: _normalizeClass($setup.state.isMobile ? 'px-3' : 'mt-4 pr-3')
          }, [
            ($setup.state.isMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filtradosQuadrant, (filter) => {
                    return (_openBlock(), _createBlock($setup["AtomCheckBox"], {
                      class: _normalizeClass('deep-purple'),
                      key: filter.value,
                      label: filter.text,
                      checked: filter.selected,
                      onChange: ($event: any) => ($setup.handleFoceCheck(filter))
                    }, null, 8 /* PROPS */, ["label", "checked", "onChange"]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true),
            (!$setup.state.isMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filtradosQuadrant, (filter) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: filter.value,
                      class: "d-flex align-center w-100 justify-space-between pa-3 mb-4 check-class",
                      style: {"height":"40px"}
                    }, [
                      _createElementVNode("p", null, _toDisplayString(filter.text), 1 /* TEXT */),
                      _createVNode($setup["AtomCheckBox"], {
                        classes: "checkbox-class",
                        checked: filter.selected,
                        onChange: ($event: any) => ($setup.handleFoceCheck(filter))
                      }, null, 8 /* PROPS */, ["checked", "onChange"])
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ]))
              : _createCommentVNode("v-if", true)
          ], 2 /* CLASS */)
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["loading", "title", "data", "dataset", "labels", "id", "text-tooltip"]),
    (!($setup.filterQuadratEscolha == 'units' || $setup.filterQuadratEscolha == 'collector'))
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          class: "mt-5"
        }, {
          default: _withCtx(() => [
            ($setup.mediaQualiServicoTab == 'atual')
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "12",
                  md: "4",
                  class: "mb-3"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode($setup["OrganismsPolarChart"], {
                        loading: $setup.loadingCaminho,
                        title: `Conectividade por tipo de ${$setup.filterQuadratEscolha == 'links' ? 'link' : 'serviço'}`,
                        data: $setup.dataPolar,
                        labels: $setup.legendPolarChart,
                        "text-tooltip": "O gráfico mostra a média atual de conectividade de seus serviços agrupados de acordo com sua categoria.\n- 𝐒𝐞𝐫𝐯𝐢𝐜̧𝐨𝐬 Analisados:\n𝐋𝐢𝐧𝐤 (LNK): é analisado a conectividade do serviço fornecido pelo provedor para acessarmos a internet;\n𝐈𝐧𝐭𝐞𝐫𝐧𝐞𝐭 (INT): analisamos a situação de conexão dos serviços utilizados na internet por exemplo o Google.\n𝐒𝐞𝐫𝐯𝐢𝐜̧𝐨 E𝐱𝐭𝐞𝐫𝐧𝐨 (SVE): é analisado os serviços que são utilizados por sua empresa, mas não ficam locados dentro da mesma, por exemplo o Cisco Webex.\n𝐒𝐞𝐫𝐯𝐢𝐜̧𝐨 I𝐧𝐭𝐞𝐫𝐧𝐨 (SVI): analisamos como está a o nível de qualidade e disponibilidade nos serviços que estão dentro de sua empresa,por exemplo o DNS.\n𝐍𝐮𝐯𝐞𝐦 (SVN): é analisado os serviços de armazenamento em nuvem, possui seus arquivos, mas a gerencia não é sua, como por exemplo o Google Hangouts."
                      }, null, 8 /* PROPS */, ["loading", "title", "data", "labels"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (!($setup.filterQuadratEscolha == 'units' || $setup.filterQuadratEscolha == 'collector'))
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode($setup["OrganismsChart"], {
                        title: `Média qualidade dos ${$setup.filterQuadratEscolha == 'links' ? 'links' : 'serviços'}`,
                        type: "line",
                        legend: true,
                        "height-card": `655px`,
                        data: $setup.dataCharGeralQuali,
                        "color-series": "#FF5C0020",
                        loading: $setup.loadingQualidade,
                        "text-tooltip": 
            $setup.mediaDispoServicoTab == 'atual'
              ? 'O gráfico mostra a evolução da qualidade média de seus serviços.'
              : 'O gráfico mostra previsão da qualidade para as próximas 5 horas.'
          
                      }, {
                        topfreeArea: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createVNode($setup["AtomSubTitle"], {
                              class: "pt-5",
                              text: "Próximos:"
                            }),
                            _createElementVNode("p", _hoisted_7, [
                              _createVNode($setup["AtomToogleButton"], {
                                id: "qualidadeServicos",
                                width: "172px",
                                buttons: $setup.optionsListQualidade,
                                onHandleToggle: $setup.handleMediaQualidadeChange,
                                select: $setup.filterMediaQualidade
                              }, null, 8 /* PROPS */, ["buttons", "select"])
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["title", "data", "loading", "text-tooltip"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            (!($setup.filterQuadratEscolha == 'units' || $setup.filterQuadratEscolha == 'collector'))
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 2,
                  cols: "12",
                  md: "4"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createVNode($setup["OrganismsChart"], {
                        title: `Média disponibilidade dos ${$setup.filterQuadratEscolha == 'links' ? 'links' : 'serviços'}`,
                        type: "line",
                        legend: true,
                        "height-card": `655px`,
                        data: $setup.dataCharGeralDispo,
                        "color-series": "#FF5C0020",
                        onHandleToggle: $setup.toggleMediaDisponibilidade,
                        loading: $setup.loadingDisponibilidade,
                        "text-tooltip": 
            $setup.mediaDispoServicoTab == 'atual'
              ? 'O gráfico mostra a evolução da disponibilidade média de seus serviços.'
              : 'O gráfico mostra previsão da disponibilidade para as próximas 5 horas.'
          
                      }, {
                        topfreeArea: _withCtx(() => [
                          _createElementVNode("div", _hoisted_8, [
                            _createVNode($setup["AtomSubTitle"], {
                              class: "pt-5",
                              text: "Próximos:"
                            }),
                            _createElementVNode("p", _hoisted_9, [
                              _createVNode($setup["AtomToogleButton"], {
                                width: "172px",
                                buttons: $setup.optionsListDisponibilidade,
                                onHandleToggle: $setup.handleMediaDisponibilidadeChange,
                                select: $setup.filterMediaDisponibilidade
                              }, null, 8 /* PROPS */, ["buttons", "select"])
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["title", "data", "loading", "text-tooltip"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}