import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "56",
  height: "56",
  viewBox: "0 0 56 56",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]
const _hoisted_5 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      width: "56",
      height: "56",
      rx: "8",
      fill: $setup.props.colorBackground || 'none',
      "fill-opacity": "0.1"
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M27.25 24.08V26.94L26.24 26.59C25.73 26.41 25.42 26.24 25.42 25.37C25.42 24.66 25.95 24.08 26.6 24.08H27.25Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_3),
    _createElementVNode("path", {
      d: "M30.58 30.63C30.58 31.34 30.05 31.92 29.4 31.92H28.75V29.06L29.76 29.41C30.27 29.59 30.58 29.76 30.58 30.63Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_4),
    _createElementVNode("path", {
      d: "M32.19 18H23.81C20.17 18 18 20.17 18 23.81V32.19C18 35.83 20.17 38 23.81 38H32.19C35.83 38 38 35.83 38 32.19V23.81C38 20.17 35.83 18 32.19 18ZM30.26 28C31.04 28.27 32.08 28.84 32.08 30.63C32.08 32.17 30.88 33.42 29.4 33.42H28.75V34C28.75 34.41 28.41 34.75 28 34.75C27.59 34.75 27.25 34.41 27.25 34V33.42H26.89C25.25 33.42 23.92 32.03 23.92 30.33C23.92 29.92 24.25 29.58 24.67 29.58C25.08 29.58 25.42 29.92 25.42 30.33C25.42 31.21 26.08 31.92 26.89 31.92H27.25V28.53L25.74 28C24.96 27.73 23.92 27.16 23.92 25.37C23.92 23.83 25.12 22.58 26.6 22.58H27.25V22C27.25 21.59 27.59 21.25 28 21.25C28.41 21.25 28.75 21.59 28.75 22V22.58H29.11C30.75 22.58 32.08 23.97 32.08 25.67C32.08 26.08 31.75 26.42 31.33 26.42C30.92 26.42 30.58 26.08 30.58 25.67C30.58 24.79 29.92 24.08 29.11 24.08H28.75V27.47L30.26 28Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_5)
  ]))
}