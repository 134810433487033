import { defineComponent as _defineComponent } from 'vue'
import {
SettingsColorIcon,
PlusElipseIcon,
lineIcon,
} from '@/components/atoms';




import { ref } from 'vue';



interface IProps {
title: string;
el?: any;
addBtn ?: boolean;
prependIcon ?: boolean;

}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeTitleSetings',
  props: {
    title: { type: String, required: true },
    el: { type: null, required: false },
    addBtn: { type: Boolean, required: false },
    prependIcon: { type: Boolean, required: false }
  },
  emits: ['handleCancel', 'handleConfirm'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const action = ref<boolean>(false);
const emit = __emit;
function handleConfirm() {
  emit('handleConfirm');
  
}


const __returned__ = { props, action, emit, handleConfirm, get SettingsColorIcon() { return SettingsColorIcon }, get PlusElipseIcon() { return PlusElipseIcon }, get lineIcon() { return lineIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})