import { defineComponent as _defineComponent } from 'vue'
import { ref, onMounted, watch } from 'vue';
import { state } from '@/store/modules/layout';
import { MoleculeCard, MoleculeTitleSetings } from '@/components/molecules';
import { FiltersUnit } from '@/store';
import { AtomSelect, AtomButton, AtomProgressSaltos, GraphIconColor } from '@/components/atoms';
import { IOptionsSelect, IStep } from '@/types';

import { getUnidadeColetoresInstalados } from '@/core/services/UnitsService';
import { getServicoPorUnidade } from '@/core/services/ServicoService';
import { coletorSaltos } from '@/core/services/GraficoService';
interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'JumpCollectorAnaliysisPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const units = ref<IOptionsSelect[]>([]);
const steps = ref<IStep[]>([
  { label: 'Salto 1', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 2', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 3', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 4', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 5', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 6', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 7', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 8', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 9', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 10', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 11', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 12', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 13', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 14', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 15', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
  { label: 'Salto 16', completed: true, latencia: '3 ms', jitter: '0 ms', perda: '66' },
]);
const showSaltos = ref<boolean>(false);
const loading = ref<boolean>(false);
const currentStep = ref('');
const btnreplacement = ref<boolean>(false);
const unitsAll = ref<any[]>([]);
const services = ref<IOptionsSelect[]>([]);
const servicesAll = ref<any[]>([]);
const escolha = ref<any>({});

function loadUnits() {
  getUnidadeColetoresInstalados().then((f) => {
    unitsAll.value = f.filter((ff) => ff.archived == 0);
    units.value = f.filter((ff) => ff.archived == 0);

    if (FiltersUnit.getters.filters.value.length) {
      units.value = units.value.filter((f) => FiltersUnit.getters.filters.value.find((x) => x == f.id));
    }

    units.value = units.value.map((ff) => {
      return { value: ff.id, text: ff.nome } as IOptionsSelect;
    });
  });
}
// Authentication.state.id as number, FiltersUnit.getters.filters.value
function loadServices() {
  if (escolha.value.unit) {
    getServicoPorUnidade(escolha.value.unit).then((f) => {
      servicesAll.value = f;
      services.value = servicesAll.value.map((ff) => {
        return { value: ff.id, text: ff.nome } as IOptionsSelect;
      });
    });
  }
}

function handleSelect(event) {
  escolha.value.unit = event;
  loadServices();
}

function reset() {
  escolha.value.unit = '';
  escolha.value.service = '';
  showSaltos.value = false;
}
function buscar() {
  loading.value = true;

  coletorSaltos(escolha.value.unit, escolha.value.service).then((f) => {
    if (f != 'Sem dados!') {
      steps.value = f.map((x, index) => {
        return {
          label: `Salto ${index + 1}`,
          completed: true,
          latencia: `${x.avgLatency} ms`,
          jitter: `${x.avgJitter} ms`,
          perda: x.packetLoss,
          host: x.host,
          location: x.location,
          ...x,
        };
      });
    } else {
      steps.value = [];
    }

    loading.value = false;
    showSaltos.value = true;
  });
}
onMounted(() => {
  loadUnits();
  // document.title = 'Análises Gerais';
  // loadServices();
});

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab != 'jump_collector') {
      //   clearInterval(interval.value);
      //   clearInterval(intervalQuad.value);

      escolha.value = {};
    } else {
      console.log('entrou');
    }
  },
);
watch(
  FiltersUnit.getters.filters,
  () => {

    loadUnits();
  },
  { deep: true },
);

const __returned__ = { props, units, steps, showSaltos, loading, currentStep, btnreplacement, unitsAll, services, servicesAll, escolha, loadUnits, loadServices, handleSelect, reset, buscar, get state() { return state }, get MoleculeCard() { return MoleculeCard }, get MoleculeTitleSetings() { return MoleculeTitleSetings }, get AtomSelect() { return AtomSelect }, get AtomButton() { return AtomButton }, get AtomProgressSaltos() { return AtomProgressSaltos }, get GraphIconColor() { return GraphIconColor } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})