import { defineComponent as _defineComponent } from 'vue'
import { ArrowDown3Icon, AtomButton, InfoSquareIcon } from '@/components/atoms';
import { MoleculeTable, MoleculeInfos } from '@/components/molecules';
import { IHeadersTable } from '@/types';
import { ref, onMounted, computed } from 'vue';

import { findUsers } from '@/core/services/AuthService';
import { Preloader } from '@/store';

export default /*@__PURE__*/_defineComponent({
  __name: 'InvestigationFinance',
  setup(__props, { expose: __expose }) {
  __expose();

const perPage = ref<number>(10);
const page = ref<number>(1);
const showInfos = ref<boolean>(false);
const headers = ref<IHeadersTable[]>([
  { label: 'Nome da operadora ', field: 'nome' },
  { label: 'Detalhes do link', field: 'link' },
  { label: 'Unidade', field: 'unidade' },
  { label: 'Custo provisionado', field: 'custo_provisionado' },
  {
    label: 'Relatório de SLA',
    field: 'relaoório_de_sla',
  },
  // { label: 'Relatório de SLA', field: 'actions' },
]);
const items = ref<any[]>([
  {
    nome: 'VOUE',
    link: 'LNK_VOUE_ITAJUBA',
    unidade: 'DEV',
    custo_provisionado: 'R$1.699,00',
  },
]);

const itemsCusto = ref<any[]>([
  {
    valor: 'R$ 121.000,78',
    descricao: 'Custo provisionado',
    id: '0',
  },
  {
    valor: 'R$ 29.000,82',
    descricao: 'Desconto previsto',
    id: '1',
  },
  {
    valor: 'R$ 00.000,82',
    descricao: 'Custo atual',
    id: '2',
  },
  {
    valor: 'R$ 00.000,73',
    descricao: 'Previsão de custo',
    id: '3',
  },
  {
    valor: '99%',
    descricao: 'Acordo de serviço',
    id: '3',
  },
  {
    valor: '100%',
    descricao: 'Disponibilidade',
    id: '3',
  },
]);
const infos = ref<string[]>([
  'Clique sobre um link na tabela para visualizar suas informações específicas no segundo card',
  'Os valores listados abaixo são referentes ao mês corrente',
  'Aguardando filtro de um link específico',
  'Erro, valor indisponível ou não informado no cadastro ao recuperar o dado esperado',
  'Custo provisionado: Esse campo vai usar o valor atual cadastrado na gerencia financeira, caso este valor não esteja preenchido para o mês corrente, será utilizado o custo provisionado',
  'Marcação laranja claro na tabela indica qual link está filtrado no momento',
  'Para limpar o filtro do card, clique sobre o icone limpeza de filtro no canto superior da tela',
]);
async function loadUser() {
  try {
    Preloader.actions.ActionSetPreloader(true);
    items.value = await findUsers();
  } finally {
    Preloader.actions.ActionSetPreloader(false);
  }
}

const paginationTableData = computed((): any[] => {
  const data = items.value.slice((page.value - 1) * perPage.value, (page.value - 1) * perPage.value + perPage.value);
  return data;
});

onMounted(() => {
  loadUser();
});

const __returned__ = { perPage, page, showInfos, headers, items, itemsCusto, infos, loadUser, paginationTableData, get ArrowDown3Icon() { return ArrowDown3Icon }, get AtomButton() { return AtomButton }, get InfoSquareIcon() { return InfoSquareIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeInfos() { return MoleculeInfos } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})