import { createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "d-flex align-center justify-space-between cursor-pointer item-drag" }
const _hoisted_2 = { class: "d-flex" }
const _hoisted_3 = { class: "font-500 text-sm mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["Draggable"], {
    "model-value": $setup.value,
    "item-key": "field",
    onChange: $setup.handleChange
  }, {
    item: _withCtx(({ element }) => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode($setup["AtomCheckBox"], {
            "no-margin": "",
            modelValue: element.checked,
            "onUpdate:modelValue": ($event: any) => ((element.checked) = $event),
            checked: element.checked
          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "checked"]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(element.label), 1 /* TEXT */)
        ]),
        (_openBlock(), _createBlock(_resolveDynamicComponent($setup.comps['ColsIcon'])))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model-value"]))
}