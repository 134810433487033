import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "56",
  height: "56",
  viewBox: "0 0 56 56",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      width: "56",
      height: "56",
      rx: "8",
      fill: $setup.props.colorBackground || 'none',
      "fill-opacity": "0.1"
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M36.1 25.2201C34.29 25.2201 33.55 23.9401 34.45 22.3701C34.97 21.4601 34.66 20.3001 33.75 19.7801L32.02 18.7901C31.23 18.3201 30.21 18.6001 29.74 19.3901L29.63 19.5801C28.73 21.1501 27.25 21.1501 26.34 19.5801L26.23 19.3901C25.78 18.6001 24.76 18.3201 23.97 18.7901L22.24 19.7801C21.33 20.3001 21.02 21.4701 21.54 22.3801C22.45 23.9401 21.71 25.2201 19.9 25.2201C18.86 25.2201 18 26.0701 18 27.1201V28.8801C18 29.9201 18.85 30.7801 19.9 30.7801C21.71 30.7801 22.45 32.0601 21.54 33.6301C21.02 34.5401 21.33 35.7001 22.24 36.2201L23.97 37.2101C24.76 37.6801 25.78 37.4001 26.25 36.6101L26.36 36.4201C27.26 34.8501 28.74 34.8501 29.65 36.4201L29.76 36.6101C30.23 37.4001 31.25 37.6801 32.04 37.2101L33.77 36.2201C34.68 35.7001 34.99 34.5301 34.47 33.6301C33.56 32.0601 34.3 30.7801 36.11 30.7801C37.15 30.7801 38.01 29.9301 38.01 28.8801V27.1201C38 26.0801 37.15 25.2201 36.1 25.2201ZM28 31.2501C26.21 31.2501 24.75 29.7901 24.75 28.0001C24.75 26.2101 26.21 24.7501 28 24.7501C29.79 24.7501 31.25 26.2101 31.25 28.0001C31.25 29.7901 29.79 31.2501 28 31.2501Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}