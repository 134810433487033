import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = { class: "d-flex align-center justify-space-between w-100 ms-3" }
const _hoisted_3 = {
  class: "d-flex align-center",
  style: {"gap":"10px"}
}
const _hoisted_4 = { class: "draggable-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col_12 = _resolveComponent("v-col-12")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_AtomProgressForm = _resolveComponent("AtomProgressForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Unidades\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de Unidades' : $setup.type == 'E' ? 'Editando unidade' : 'Unidades',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    (!$setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              cols: "12",
              class: "border"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.items,
                  totalItems: $setup.totalItems,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleEdit($event, true))),
                  onItemClickDelete: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleDelete($event)))
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      _createCommentVNode("<AtomButton background=\"white\" radius class=\"pa-0\" @click.stop=\"handleAdd(props.row)\">\n              <WorkIcon />\n            </AtomButton> "),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _cache[60] || (_cache[60] = _createElementVNode("div", null, [
                        _createCommentVNode(" <AtomButton background=\"white\" text=\"Manusear colunas\" outlined> </AtomButton> ")
                      ], -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_3, [
                        _createCommentVNode(" <AtomButton background=\"white\" outlined>\n                <PaperUploadIcon />\n              </AtomButton>\n              <AtomButton background=\"white\" text=\"Excel\" outlined>\n              </AtomButton>\n              <AtomButton prependIcon appendIcon background=\"white\" outlined text=\"Importar/Exportar\">\n                <template #appendIcon>\n                  <ArrowDown3Icon />\n                </template>\n</AtomButton>\n<AtomButton background=\"white\" text=\"Gerar relatório mensal\" outlined>\n</AtomButton> "),
                        _createVNode($setup["AtomButton"], {
                          text: "Nova Unidade",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => {
                $setup.showModalUpload = true;
              $setup.clear();
              })
                        }),
                        _createCommentVNode(" <AtomButton text=\"Configuração colunas\" @click=\"showModalColunas = true\"> </AtomButton> "),
                        _createCommentVNode(" <div class=\"d-flex\" style=\"gap: 10px\">\n                <AtomButton background=\"white\" outlined>\n                  <PaperUploadIcon />\n                </AtomButton>\n                <AtomButton :disabled=\"true\" text=\"Importar usuários\" background=\"white\" outlined> </AtomButton>\n                <AtomButton text=\"Exportar usuários\" background=\"white\" outlined> </AtomButton>\n                <AtomButton text=\"Excel exemplo\" background=\"white\" outlined> </AtomButton>\n                <AtomButton prependIcon appendIcon background=\"white\" outlined text=\"Opções\">\n                  <template #prependIcon>\n                    <SettingIcon />\n                  </template>\n                  <template #appendIcon>\n                    <ArrowDown3Icon />\n                  </template>\n                </AtomButton>\n              </div> ")
                      ])
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleEdit(props, true)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Editar'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 1,
          class: "text-sm"
        }, {
          default: _withCtx(() => [
            ($setup.currentStep === 'Endereço')
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "my-3"
                    }, {
                      default: _withCtx(() => _cache[61] || (_cache[61] = [
                        _createElementVNode("ul", null, [
                          _createElementVNode("li", { class: "text-gray-02" }, "Preencha todos os campos corretamente para cadastrar uma nova unidade.")
                        ], -1 /* HOISTED */)
                      ])),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomSelect"], {
                          width: "100%",
                          class: "p-0",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.pais,
                          placeholder: "Selecione um país",
                          label: "País",
                          modelValue: $setup.data.pais,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.pais) = $event)),
                          options: $setup.Country
                        }, null, 8 /* PROPS */, ["hasError", "modelValue", "options"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    ($setup.data.pais == 'Brasil')
                      ? (_openBlock(), _createBlock(_component_v_col_12, {
                          key: 0,
                          class: "px-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.nome,
                                      placeholder: "Nome da unidade",
                                      label: "Nome da unidade",
                                      modelValue: $setup.data.nome,
                                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.data.nome) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.cep,
                                      placeholder: "CEP",
                                      label: "CEP",
                                      modelValue: $setup.data.cep,
                                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.data.cep) = $event)),
                                      mask: '##.###-###',
                                      onChange: $setup.buscaCep
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.logradouro,
                                      placeholder: "Logradouro",
                                      label: "Logradouro",
                                      modelValue: $setup.data.logradouro,
                                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.data.logradouro) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.numero,
                                      placeholder: "Número",
                                      label: "Número",
                                      modelValue: $setup.data.numero,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.data.numero) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.neighborhood,
                                      placeholder: "Bairro",
                                      label: "Bairro",
                                      modelValue: $setup.data.neighborhood,
                                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.data.neighborhood) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.cidade,
                                      placeholder: "Cidade",
                                      label: "Cidade",
                                      modelValue: $setup.data.cidade,
                                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.data.cidade) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomSelect"], {
                                      width: "100%",
                                      placeholder: "Estado",
                                      label: "Estado",
                                      modelValue: $setup.data.uf,
                                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.data.uf) = $event)),
                                      options: $setup.ufs
                                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    ($setup.data.pais == 'Estados Unidos')
                      ? (_openBlock(), _createBlock(_component_v_col_12, {
                          key: 1,
                          class: "px-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.nome,
                                      placeholder: "Nome da unidade",
                                      label: "Nome da unidade",
                                      modelValue: $setup.data.nome,
                                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.data.nome) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.logradouro,
                                      placeholder: "Endereço",
                                      label: "Endereço",
                                      modelValue: $setup.data.logradouro,
                                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.data.logradouro) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.cidade,
                                      placeholder: "Cidade",
                                      label: "Cidade",
                                      modelValue: $setup.data.cidade,
                                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.data.cidade) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.uf,
                                      placeholder: "Estado",
                                      label: "Estado",
                                      modelValue: $setup.data.uf,
                                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.data.uf) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.cep,
                                      placeholder: "ZIP code",
                                      label: "ZIP code",
                                      modelValue: $setup.data.cep,
                                      "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.data.cep) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true),
                    ($setup.data.pais == 'China')
                      ? (_openBlock(), _createBlock(_component_v_col_12, {
                          key: 2,
                          class: "px-2"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.nome,
                                      placeholder: "Nome da unidade",
                                      label: "Nome da unidade",
                                      modelValue: $setup.data.nome,
                                      "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.data.nome) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.logradouro,
                                      placeholder: "Endereço 1",
                                      label: "Endereço 1",
                                      modelValue: $setup.data.logradouro,
                                      "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.data.logradouro) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.logradouro2,
                                      placeholder: "Endereço 2",
                                      label: "Endereço 2",
                                      modelValue: $setup.data.logradouro2,
                                      "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.data.logradouro2) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.distrito,
                                      placeholder: "Distrito",
                                      label: "Distrito",
                                      modelValue: $setup.data.distrito,
                                      "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.data.distrito) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.cidade,
                                      placeholder: "Cidade",
                                      label: "Cidade",
                                      modelValue: $setup.data.cidade,
                                      "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($setup.data.cidade) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.provincia,
                                      placeholder: "Província",
                                      maxlength: "60",
                                      label: "Província",
                                      modelValue: $setup.data.provincia,
                                      "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($setup.data.provincia) = $event)),
                                      onChange: $setup.changeNumber
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomInput"], {
                                      width: "100%",
                                      required: "",
                                      msgError: "Campo obrigatório",
                                      hasError: !$setup.data.cep,
                                      placeholder: "Cód. postal",
                                      label: "Código postal",
                                      modelValue: $setup.data.cep,
                                      "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($setup.data.cep) = $event))
                                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.currentStep === 'Outras informações')
              ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Latitude",
                          modelValue: $setup.data.latitude,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($setup.data.latitude) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Longitude",
                          modelValue: $setup.data.longitude,
                          "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($setup.data.longitude) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomSelect"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          hasError: !$setup.data.companhiaId,
                          placeholder: "Selecione uma empresa",
                          modelValue: $setup.data.companhiaId,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (($setup.data.companhiaId) = $event)),
                          options: $setup.companhias
                        }, null, 8 /* PROPS */, ["hasError", "modelValue", "options"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomSelect"], {
                          width: "100%",
                          placeholder: "Usuários dessa unidade",
                          modelValue: $setup.data.usuarioIntLista,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (($setup.data.usuarioIntLista) = $event)),
                          options: $setup.usuarios,
                          multiple: ""
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[29] || (_cache[29] = ($event: any) => ($setup.handleNew())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.textSaveMobile
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: $setup.handleCancelNew,
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      width: "639px",
      bodyScrool: true,
      onHandleConfirm: $setup.handleNew,
      onHandleCancel: $setup.handleCancelNew,
      title: "Cadastro de unidades",
      subtitle: 'Preencha todos os campos corretamente para cadastrar uma nova unidade',
      show: $setup.showModalUpload,
      "btn-close-text": $setup.textCancelModal,
      "btn-confirm-text": $setup.textSaveModal
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_AtomProgressForm, {
              steps: $setup.steps,
              "current-step": $setup.currentStep
            }, null, 8 /* PROPS */, ["steps", "current-step"]),
            _createVNode(_component_v_container, { class: "text-sm" }, {
              default: _withCtx(() => [
                ($setup.currentStep === 'Endereço')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              class: "p-0",
                              required: "",
                              msgError: "Campo obrigatório",
                              hasError: !$setup.data.pais,
                              placeholder: "Selecione um país",
                              label: "País",
                              modelValue: $setup.data.pais,
                              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (($setup.data.pais) = $event)),
                              options: $setup.Country
                            }, null, 8 /* PROPS */, ["hasError", "modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        ($setup.data.pais == 'Brasil')
                          ? (_openBlock(), _createBlock(_component_v_col_12, {
                              key: 0,
                              class: "px-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { md: "8" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.nome,
                                          placeholder: "Nome da unidade",
                                          label: "Nome da unidade",
                                          modelValue: $setup.data.nome,
                                          "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (($setup.data.nome) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "4" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.cep,
                                          placeholder: "CEP",
                                          label: "CEP",
                                          modelValue: $setup.data.cep,
                                          "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => (($setup.data.cep) = $event)),
                                          mask: '##.###-###',
                                          onChange: $setup.buscaCep
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "8" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.logradouro,
                                          placeholder: "Logradouro",
                                          label: "Logradouro",
                                          modelValue: $setup.data.logradouro,
                                          "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => (($setup.data.logradouro) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "4" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.numero,
                                          placeholder: "Número",
                                          label: "Número",
                                          modelValue: $setup.data.numero,
                                          "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => (($setup.data.numero) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "4" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.neighborhood,
                                          placeholder: "Bairro",
                                          label: "Bairro",
                                          modelValue: $setup.data.neighborhood,
                                          "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => (($setup.data.neighborhood) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "4" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.cidade,
                                          placeholder: "Cidade",
                                          label: "Cidade",
                                          modelValue: $setup.data.cidade,
                                          "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => (($setup.data.cidade) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "4" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomSelect"], {
                                          width: "100%",
                                          placeholder: "Estado",
                                          label: "Estado",
                                          modelValue: $setup.data.uf,
                                          "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => (($setup.data.uf) = $event)),
                                          options: $setup.ufs
                                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            }))
                          : _createCommentVNode("v-if", true),
                        ($setup.data.pais == 'Estados Unidos')
                          ? (_openBlock(), _createBlock(_component_v_col_12, {
                              key: 1,
                              class: "px-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.nome,
                                          placeholder: "Nome da unidade",
                                          label: "Nome da unidade",
                                          modelValue: $setup.data.nome,
                                          "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => (($setup.data.nome) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.logradouro,
                                          placeholder: "Endereço",
                                          label: "Endereço",
                                          modelValue: $setup.data.logradouro,
                                          "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => (($setup.data.logradouro) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.cidade,
                                          placeholder: "Cidade",
                                          label: "Cidade",
                                          modelValue: $setup.data.cidade,
                                          "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => (($setup.data.cidade) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "4" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.uf,
                                          placeholder: "Estado",
                                          label: "Estado",
                                          modelValue: $setup.data.uf,
                                          "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => (($setup.data.uf) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "2" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.cep,
                                          placeholder: "ZIP code",
                                          label: "ZIP code",
                                          modelValue: $setup.data.cep,
                                          "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => (($setup.data.cep) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            }))
                          : _createCommentVNode("v-if", true),
                        ($setup.data.pais == 'China')
                          ? (_openBlock(), _createBlock(_component_v_col_12, {
                              key: 2,
                              class: "px-2"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_row, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.nome,
                                          placeholder: "Nome da unidade",
                                          label: "Nome da unidade",
                                          modelValue: $setup.data.nome,
                                          "onUpdate:modelValue": _cache[43] || (_cache[43] = ($event: any) => (($setup.data.nome) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.logradouro,
                                          placeholder: "Endereço 1",
                                          label: "Endereço 1",
                                          modelValue: $setup.data.logradouro,
                                          "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => (($setup.data.logradouro) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.logradouro2,
                                          placeholder: "Endereço 2",
                                          label: "Endereço 2",
                                          modelValue: $setup.data.logradouro2,
                                          "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => (($setup.data.logradouro2) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { cols: "12" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.distrito,
                                          placeholder: "Distrito",
                                          label: "Distrito",
                                          modelValue: $setup.data.distrito,
                                          "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => (($setup.data.distrito) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "6" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.cidade,
                                          placeholder: "Cidade",
                                          label: "Cidade",
                                          modelValue: $setup.data.cidade,
                                          "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => (($setup.data.cidade) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "3" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.provincia,
                                          placeholder: "Província",
                                          maxlength: "60",
                                          label: "Província",
                                          modelValue: $setup.data.provincia,
                                          "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => (($setup.data.provincia) = $event)),
                                          onChange: $setup.changeNumber
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    }),
                                    _createVNode(_component_v_col, { md: "3" }, {
                                      default: _withCtx(() => [
                                        _createVNode($setup["AtomInput"], {
                                          width: "100%",
                                          required: "",
                                          msgError: "Campo obrigatório",
                                          hasError: !$setup.data.cep,
                                          placeholder: "Cód. postal",
                                          label: "Código postal",
                                          modelValue: $setup.data.cep,
                                          "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => (($setup.data.cep) = $event))
                                        }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                                      ]),
                                      _: 1 /* STABLE */
                                    })
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            }))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.currentStep === 'Outras informações')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Latitude",
                              label: "Latitude",
                              modelValue: $setup.data.latitude,
                              "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => (($setup.data.latitude) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Longitude",
                              label: "Longitude",
                              modelValue: $setup.data.longitude,
                              "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => (($setup.data.longitude) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createCommentVNode(" <AtomInput\n                  width=\"100%\"\n                  required\n                  msgError=\"Campo obrigatório\"\n                  :hasError=\"!data.nome\"\n                  placeholder=\"Selecione uma empresa\"\n                  v-model=\"data.nome\"\n                >\n                </AtomInput> "),
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              hasError: !$setup.data.companhiaId,
                              placeholder: "Selecione uma empresa",
                              label: "Selecione uma empresa",
                              modelValue: $setup.data.companhiaId,
                              "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => (($setup.data.companhiaId) = $event)),
                              options: $setup.companhias
                            }, null, 8 /* PROPS */, ["hasError", "modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Usuários dessa unidade",
                              label: "Usuários dessa unidade",
                              modelValue: $setup.data.usuarioIntLista,
                              "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => (($setup.data.usuarioIntLista) = $event)),
                              options: $setup.usuarios,
                              multiple: ""
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "btn-close-text", "btn-confirm-text"]),
    _createVNode($setup["MoleculeModal"], {
      width: "639px",
      onHandleConfirm: $setup.handleCancelColunas,
      onHandleCancel: $setup.handleCancelColunas,
      title: "Manusear colunas",
      subtitle: 'Selecione as colunas que você quer ver nessa tabela',
      show: $setup.showModalColunas,
      "btn-close-text": $setup.textCancelModal,
      "btn-confirm-text": 'Concluir',
      paddingY: "0"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_v_container, { class: "text-sm" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { md: "5" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomCheckBox"], {
                      modelValue: $setup.data.numero,
                      "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => (($setup.data.numero) = $event)),
                      label: "Número"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createVNode($setup["AtomCheckBox"], {
                      modelValue: $setup.data.neighborhood,
                      "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => (($setup.data.neighborhood) = $event)),
                      label: "Bairro"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createVNode($setup["AtomCheckBox"], {
                      modelValue: $setup.data.cep,
                      "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => (($setup.data.cep) = $event)),
                      label: "CEP"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createVNode($setup["AtomCheckBox"], {
                      modelValue: $setup.data.companhiaId,
                      "onUpdate:modelValue": _cache[57] || (_cache[57] = ($event: any) => (($setup.data.companhiaId) = $event)),
                      label: "Empresa"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createVNode($setup["AtomCheckBox"], {
                      modelValue: $setup.data.latitude,
                      "onUpdate:modelValue": _cache[58] || (_cache[58] = ($event: any) => (($setup.data.latitude) = $event)),
                      label: "Latitude"
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createVNode($setup["AtomCheckBox"], {
                      modelValue: $setup.data.longitude,
                      "onUpdate:modelValue": _cache[59] || (_cache[59] = ($event: any) => (($setup.data.longitude) = $event)),
                      label: "Longitude"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  md: "7",
                  class: "border-s"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["Container"], {
                      onDrop: $setup.onDrop,
                      "get-ghost-parent": $setup.getGhostParent,
                      "remove-on-drop-out": true,
                      onDropReady: _ctx.onDropReady
                    }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.listItems, (element) => {
                          return (_openBlock(), _createBlock($setup["Draggable"], {
                            key: element.placeholder
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_4, [
                                _createVNode($setup["AtomInput"], {
                                  prependIcon: "",
                                  noBgPrependIcon: "",
                                  "no-border-prepend-icon": "",
                                  placeholder: element.placeholder,
                                  modelValue: element.value,
                                  "onUpdate:modelValue": ($event: any) => ((element.value) = $event),
                                  width: "100%"
                                }, {
                                  prependIcon: _withCtx(() => [
                                    _createVNode($setup["ColsIcon"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["placeholder", "modelValue", "onUpdate:modelValue"])
                              ])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        }), 128 /* KEYED_FRAGMENT */))
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["onDropReady"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "btn-close-text"])
  ], 64 /* STABLE_FRAGMENT */))
}