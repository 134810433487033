import { defineComponent as _defineComponent } from 'vue'
import { IDataset, IOptions } from '@/types';
import { onMounted, ref, computed } from 'vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Scatter } from 'vue-chartjs';
import { Chart as ChartJS, LinearScale, PointElement, LineElement, Tooltip, Legend, CategoryScale } from 'chart.js';
interface IProps {
  dataset: IDataset[];
  options?: IOptions;
  labels?: string[];
  maxY?: number;
  maxX?: number;
  titleY?: string;
  titleX?: string;
  id: string;
}
// const isVisible = ref<boolean>(false);

export default /*@__PURE__*/_defineComponent({
  __name: 'AtomChartQuadrant',
  props: {
    dataset: { type: Array, required: true },
    options: { type: Object, required: false },
    labels: { type: Array, required: false },
    maxY: { type: Number, required: false },
    maxX: { type: Number, required: false },
    titleY: { type: String, required: false },
    titleX: { type: String, required: false },
    id: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend, ChartDataLabels, CategoryScale);

const props = __props;
// const emit = defineEmits(['onTootipUp', 'onTootipDown']);
// const chart = ref<Chart>();
// const canvas = ref(null);
// const div = ref(null);
const dataSetRef = ref<IDataset[]>([]);
// const wait = (timeMs: number) => {
//   return new Promise((resolve) => {
//     setTimeout(resolve, timeMs);
//   });
// };
const data = computed(() => {
  return {
    datasets: props.dataset,
  };
});
const options = computed(() => {
  return {
    // interaction: {
    //   mode: "nearest",
    //   intersect: true
    // },
    plugins: {
      // annotation: {
      //   annotations: {
      //     problematico: {
      //       borderCapStyle: "round",
      //       type: 'label',
      //       xValue: 25,
      //       yValue: 25,
      //       // backgroundColor: 'rgba(245,245,245)',
      //       content: ['PROBLEMÁTICO'],
      //       font: {
      //         size: 18
      //       }
      //     },
      //     saudavel: {
      //       borderCapStyle: "round",
      //       type: 'label',
      //       xValue: 75,
      //       yValue: 75,
      //       // backgroundColor: 'rgba(245,245,245)',
      //       content: ['SAUDÁVEL'],
      //       font: {
      //         size: 18
      //       }
      //     },
      //     instavel: {
      //       borderCapStyle: "round",
      //       type: 'label',
      //       xValue: 75,
      //       yValue: 25,
      //       // backgroundColor: 'rgba(245,245,245)',
      //       content: ['INSTÁVEL'],
      //       font: {
      //         size: 18
      //       }
      //     },
      //     degradado: {
      //       borderCapStyle: "round",
      //       type: 'label',
      //       xValue: 25,
      //       yValue: 75,
      //       // backgroundColor: 'rgba(245,245,245)',
      //       content: ['DEGRADADO'],
      //       font: {
      //         size: 18
      //       }
      //     }

      //   }
      // }
      tooltip: {
        // Disable the on-canvas tooltip
        enabled: false,
        external: function (context) {
          // Tooltip Element
          let tooltipEl = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            // tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }
          let innerHtml = '';
          // Set Text
          if (tooltipModel.body) {
            const tableRoot = tooltipEl.querySelector('table') as HTMLElement;
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(getBody);

            innerHtml = '<thead>';

            titleLines.forEach(function (title) {
              innerHtml += '<tr><th>' + title + '</th></tr>';
            });
            innerHtml = `${innerHtml}</thead><tbody>`;

            bodyLines.forEach(function (body, i) {
              const colors = tooltipModel.labelColors[i];
              let style = 'background:' + colors.backgroundColor;
              style += '; border-color:' + colors.borderColor;
              style += '; border-width: 2px';
              const span = '<span style="' + style + '">' + body + '</span>';
              innerHtml += '<tr><td>' + span + '</td></tr>';
            });
            innerHtml += '</tbody>';

            if (tableRoot) tableRoot.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          // tooltipEl.style.font = bodyFont.string;
          tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
          tooltipEl.style.pointerEvents = 'none';

          let htmlTootip = '';

          console.log(context.tooltip.dataPoints);
          context.tooltip.dataPoints.map((f, index) => {
            const { listaDispQualidade } = f.dataset['dado'];

            if (listaDispQualidade) {
              console.log('listaDispQualidade', listaDispQualidade);
              htmlTootip += `
                 <div class="container-tootltip">
    <h1>${f.dataset.label}</h1>

    <div class="d-flex justify-space-between">
      <div style="width: 230px" class="mr-1">
        <h4>Qualidade: <span class="text-white">${f.parsed.x}% </span></h4>
        
        ${
          listaDispQualidade && listaDispQualidade.length
            ? `<canvas height="60px" width="100%" id="canvas-qualidade-${index}"></canvas>`
            : ''
        } 
      </div>
      <div style="width: 230px">
        <h4>Disponibilidade: <span class="text-white">${f.parsed.y}%</span></h4>
       ${
         listaDispQualidade && listaDispQualidade.length
           ? `<canvas height="60px" width="100%" id="canvas-disponibilidade-${index}"></canvas>`
           : ''
       } 
      </div>
    </div>

    <div>

`;

              if (f.dataset['dado']?.listaDownUp && f.dataset['dado']?.listaDownUp.length) {
                htmlTootip += `   <div class="d-flex justify-center">
        <p class="text-gray text-xs mr-4"><span class="dot bg-gray"></span> Download</p>
        <p class="text-gray text-xs"><span class="dot bg-orange"></span> Upload</p>
      </div> `;
                f.dataset['dado'].listaDownUp.map((x) => {
                  htmlTootip += `    <div class="d-flex align-center mb-2">
<p class="text-gray text-xs title-progress">${String(x.service_name).toUpperCase()}</p>
<div class="flex-1">
<div class="d-flex align-center">
<div class="progress-container download"></div>
<p class="percent-value">${x.percenti}%</p>
</div>
<div class="d-flex align-center">
<div class="progress-container upload"></div>
<p class="percent-value">${x.percentu}%</p>
</div>
</div>
</div>`;
                });
              }

              htmlTootip += `
    </div>
  </div>
              `;
            }
          });

          tooltipEl.innerHTML = htmlTootip;

          context.tooltip.dataPoints.map((f, index) => {
            const { listaDispQualidade } = f.dataset['dado'];
            if (!listaDispQualidade) return;

            setTimeout(() => {
              const dataQualidade = {
                labels: listaDispQualidade.map((x) => `${x.hour_time}:00`),
                datasets: [
                  {
                    label: 'Qualidade',
                    data: listaDispQualidade.map((x) => x.media_qualidade),
                    borderColor: '#FF5C00',
                  },
                ],
              };

              const dataDisponibilidade = {
                labels: listaDispQualidade.map((x) => `${x.hour_time}:00`),
                datasets: [
                  {
                    label: 'Disponibilidade',
                    data: listaDispQualidade.map((x) => x.media_disponibilidade),
                    borderColor: '#FF5C00',
                  },
                ],
              };

              const ctx = document.getElementById(`canvas-qualidade-${index}`).getContext('2d');
              new ChartJS(ctx, {
                type: 'line',
                data: dataQualidade,
                options: {
                  backgroundColor: '#FF5C00',
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      max: 100,
                      min: 0,
                      title: {
                        text: 'Valor (%)',
                        display: true,
                      },
                      ticks: {
                        stepSize: 50,
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                          console.log(index);
                          console.log(ticks);
                          return `${value}%`;
                        },
                      },
                    },
                    x: {
                      max: 100,
                      min: 0,
                    },
                  },
                },
              });

              const ctxDispo = document.getElementById(`canvas-disponibilidade-${index}`).getContext('2d');
              new ChartJS(ctxDispo, {
                type: 'line',
                data: dataDisponibilidade,
                options: {
                  backgroundColor: '#FF5C00',
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      max: 100,
                      min: 0,
                      title: {
                        text: 'Valor (%)',
                        display: true,
                      },
                      ticks: {
                        stepSize: 50,
                        // Include a dollar sign in the ticks
                        callback: function (value, index, ticks) {
                          console.log(index);
                          console.log(ticks);
                          return `${value}%`;
                        },
                      },
                    },
                  },
                },
              });
            }, 100);
          });
        },
      },
      datalabels: {
        align: 'bottom',
        formatter(context) {
          return context.dataset?.label ?? '';
        },
      },
      legend: {
        display: false,
      },
    },
    responsive: true,
    scales: {
      y: {
        max: props.maxY,
        min: 0,
        ticks: {
          // Include a dollar sign in the ticks
          // callback: function (value, index, ticks) {
          //   return '';
          // },
        },
        title: {
          text: props.titleY,
          display: true,
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
          color: (context) => {
            return Math.round(context.scale.ticks.length / 2) == context.index + 1 ? '#000' : '#fff';
          },
        },
      },
      x: {
        max: props.maxX,
        min: 0,
        ticks: {
          // Include a dollar sign in the ticks
          // callback: function (value, index, ticks) {
          //   return '';
          // },
        },
        title: {
          text: props.titleX,
          display: true,
          font: {
            size: 16,
          },
        },
        grid: {
          display: true,
          color: (context) => {
            return Math.round(context.scale.ticks.length / 2) == context.index + 1 ? '#000' : '#fff';
          },
        },
      },
    },
  };
});
// function renderChart() {
//   // (div.value as HTMLElement).innerHTML = `<canvas id="${props.id}" height="80px" width="100%" ref="canvas"></canvas>`;
//   const elementoCanvas = document.createElement('canvas');
//   elementoCanvas.id = props.id;
//   // elementoCanvas.style.height = "80px";
//   elementoCanvas.style.width = '100%';
//   if (div.value) {
//     div.value.innerHTML = '';
//     div.value.appendChild(elementoCanvas);
//   }

//   // if (chart.value) {
//   //   // chart.value.clear();
//   //   // chart.value.destroy();
//   // }
//   const dad = document.getElementById(elementoCanvas.id) as HTMLElement;

//   dad.style.width = '100%';
//   if (!dad) return;

//   chart.value = new Chart(dad.getContext('2d'), {
//     plugins: [ChartDataLabels],
//     type: 'scatter',
//     data: {
//       datasets: props.dataset,
//     },
//     options: {
//       // interaction: {
//       //   mode: "nearest",
//       //   intersect: true
//       // },
//       plugins: {
//         // annotation: {
//         //   annotations: {
//         //     problematico: {
//         //       borderCapStyle: "round",
//         //       type: 'label',
//         //       xValue: 25,
//         //       yValue: 25,
//         //       // backgroundColor: 'rgba(245,245,245)',
//         //       content: ['PROBLEMÁTICO'],
//         //       font: {
//         //         size: 18
//         //       }
//         //     },
//         //     saudavel: {
//         //       borderCapStyle: "round",
//         //       type: 'label',
//         //       xValue: 75,
//         //       yValue: 75,
//         //       // backgroundColor: 'rgba(245,245,245)',
//         //       content: ['SAUDÁVEL'],
//         //       font: {
//         //         size: 18
//         //       }
//         //     },
//         //     instavel: {
//         //       borderCapStyle: "round",
//         //       type: 'label',
//         //       xValue: 75,
//         //       yValue: 25,
//         //       // backgroundColor: 'rgba(245,245,245)',
//         //       content: ['INSTÁVEL'],
//         //       font: {
//         //         size: 18
//         //       }
//         //     },
//         //     degradado: {
//         //       borderCapStyle: "round",
//         //       type: 'label',
//         //       xValue: 25,
//         //       yValue: 75,
//         //       // backgroundColor: 'rgba(245,245,245)',
//         //       content: ['DEGRADADO'],
//         //       font: {
//         //         size: 18
//         //       }
//         //     }

//         //   }
//         // }
//         tooltip: {
//           // Disable the on-canvas tooltip
//           enabled: false,
//           external: function (context) {
//             // Tooltip Element
//             let tooltipEl = document.getElementById('chartjs-tooltip');

//             // Create element on first render
//             if (!tooltipEl) {
//               tooltipEl = document.createElement('div');
//               tooltipEl.id = 'chartjs-tooltip';
//               // tooltipEl.innerHTML = '<table></table>';
//               document.body.appendChild(tooltipEl);
//             }

//             // Hide if no tooltip
//             const tooltipModel = context.tooltip;
//             if (tooltipModel.opacity === 0) {
//               tooltipEl.style.opacity = 0;
//               return;
//             }

//             // Set caret Position
//             tooltipEl.classList.remove('above', 'below', 'no-transform');
//             if (tooltipModel.yAlign) {
//               tooltipEl.classList.add(tooltipModel.yAlign);
//             } else {
//               tooltipEl.classList.add('no-transform');
//             }

//             function getBody(bodyItem) {
//               return bodyItem.lines;
//             }

//             // Set Text
//             if (tooltipModel.body) {
//               const titleLines = tooltipModel.title || [];
//               const bodyLines = tooltipModel.body.map(getBody);

//               let innerHtml = '<thead>';

//               titleLines.forEach(function (title) {
//                 innerHtml += '<tr><th>' + title + '</th></tr>';
//               });
//               innerHtml += '</thead><tbody>';

//               bodyLines.forEach(function (body, i) {
//                 const colors = tooltipModel.labelColors[i];
//                 let style = 'background:' + colors.backgroundColor;
//                 style += '; border-color:' + colors.borderColor;
//                 style += '; border-width: 2px';
//                 const span = '<span style="' + style + '">' + body + '</span>';
//                 innerHtml += '<tr><td>' + span + '</td></tr>';
//               });
//               innerHtml += '</tbody>';

//               let tableRoot = tooltipEl.querySelector('table') as HTMLElement;
//               // tableRoot.innerHTML = innerHtml;
//             }

//             const position = context.chart.canvas.getBoundingClientRect();
//             // const bodyFont = Chart.helpers.toFont(tooltipModel.options.bodyFont);

//             // Display, position, and set styles for font
//             tooltipEl.style.opacity = 1;
//             tooltipEl.style.position = 'absolute';
//             tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
//             tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
//             // tooltipEl.style.font = bodyFont.string;
//             tooltipEl.style.padding = tooltipModel.padding + 'px ' + tooltipModel.padding + 'px';
//             tooltipEl.style.pointerEvents = 'none';

//             let htmlTootip = '';

//             context.tooltip.dataPoints.map((f, index) => {
//               htmlTootip += `
//                  <div class="container-tootltip">
//     <h1>${f.dataset.label}</h1>

//     <div class="d-flex justify-space-between">
//       <div style="width: 230px" class="mr-1">
//         <h4>Qualidade: <span class="text-white">${f.parsed.x}% </span></h4>
//         <canvas height="60px" width="100%" id="canvas-qualidade-${index}"></canvas>
//       </div>
//       <div style="width: 230px">
//         <h4>Disponibilidade: <span class="text-white">${f.parsed.y}%</span></h4>
//         <canvas height="60px" width="100%" id="canvas-disponibilidade-${index}"></canvas>
//       </div>
//     </div>

//     <div>

// `;

//               if (f.dataset['dado']?.listaDownUp && f.dataset['dado']?.listaDownUp.length) {
//                 htmlTootip += `   <div class="d-flex justify-center">
//         <p class="text-gray text-xs mr-4"><span class="dot bg-gray"></span> Download</p>
//         <p class="text-gray text-xs"><span class="dot bg-orange"></span> Upload</p>
//       </div> `;
//                 f.dataset['dado'].listaDownUp.map((x) => {
//                   htmlTootip += `    <div class="d-flex align-center mb-2">
// <p class="text-gray text-xs title-progress">${String(x.service_name).toUpperCase()}</p>
// <div class="flex-1">
// <div class="d-flex align-center">
// <div class="progress-container download"></div>
// <p class="percent-value">${x.percenti}%</p>
// </div>
// <div class="d-flex align-center">
// <div class="progress-container upload"></div>
// <p class="percent-value">${x.percentu}%</p>
// </div>
// </div>
// </div>`;
//                 });
//               }

//               htmlTootip += `
//     </div>
//   </div>
//               `;
//             });

//             tooltipEl.innerHTML = htmlTootip;

//             context.tooltip.dataPoints.map((f, index) => {
//               let { listaDispQualidade } = f.dataset['dado'];
//               setTimeout(() => {
//                 var dataQualidade = {
//                   labels: listaDispQualidade.map((x) => `${x.hour_time}:00`),
//                   datasets: [
//                     {
//                       label: 'Qualidade',
//                       data: listaDispQualidade.map((x) => x.media_qualidade),
//                       borderColor: '#FF5C00',
//                     },
//                   ],
//                 };

//                 var dataDisponibilidade = {
//                   labels: listaDispQualidade.map((x) => `${x.hour_time}:00`),
//                   datasets: [
//                     {
//                       label: 'Disponibilidade',
//                       data: listaDispQualidade.map((x) => x.media_disponibilidade),
//                       borderColor: '#FF5C00',
//                     },
//                   ],
//                 };

//                 var ctx = document.getElementById(`canvas-qualidade-${index}`).getContext('2d');
//                 var myNewChart = new Chart(ctx, {
//                   type: 'line',
//                   data: dataQualidade,
//                   options: {
//                     backgroundColor: '#FF5C00',
//                     plugins: {
//                       legend: {
//                         display: false,
//                       },
//                     },
//                     scales: {
//                       y: {
//                         max: 100,
//                         min: 0,
//                         title: {
//                           text: 'Valor (%)',
//                           display: true,
//                         },
//                         ticks: {
//                           stepSize: 50,
//                           // Include a dollar sign in the ticks
//                           callback: function (value, index, ticks) {
//                             return `${value}%`;
//                           },
//                         },
//                       },
//                       x: {
//                         max: 100,
//                         min: 0,
//                       },
//                     },
//                   },
//                 });

//                 var ctxDispo = document.getElementById(`canvas-disponibilidade-${index}`).getContext('2d');
//                 var dispponibilidade = new Chart(ctxDispo, {
//                   type: 'line',
//                   data: dataDisponibilidade,
//                   options: {
//                     backgroundColor: '#FF5C00',
//                     plugins: {
//                       legend: {
//                         display: false,
//                       },
//                     },
//                     scales: {
//                       y: {
//                         max: 100,
//                         min: 0,
//                         title: {
//                           text: 'Valor (%)',
//                           display: true,
//                         },
//                         ticks: {
//                           stepSize: 50,
//                           // Include a dollar sign in the ticks
//                           callback: function (value, index, ticks) {
//                             return `${value}%`;
//                           },
//                         },
//                       },
//                     },
//                   },
//                 });
//               }, 100);
//             });
//           },
//         },
//         datalabels: {
//           align: 'bottom',
//           formatter(value, context) {
//             return context.dataset.label;
//           },
//         },
//         legend: {
//           display: false,
//         },
//       },
//       responsive: true,
//       scales: {
//         y: {
//           max: props.maxY,
//           min: 0,
//           ticks: {
//             // Include a dollar sign in the ticks
//             // callback: function (value, index, ticks) {
//             //   return '';
//             // },
//           },
//           title: {
//             text: props.titleY,
//             display: true,
//             font: {
//               size: 16,
//             },
//           },
//           grid: {
//             display: true,
//             color: (context) => {
//               return Math.round(context.scale.ticks.length / 2) == context.index + 1 ? '#000' : '#fff';
//             },
//           },
//         },
//         x: {
//           max: props.maxX,
//           min: 0,
//           ticks: {
//             // Include a dollar sign in the ticks
//             // callback: function (value, index, ticks) {
//             //   return '';
//             // },
//           },
//           title: {
//             text: props.titleX,
//             display: true,
//             font: {
//               size: 16,
//             },
//           },
//           grid: {
//             display: true,
//             color: (context) => {
//               return Math.round(context.scale.ticks.length / 2) == context.index + 1 ? '#000' : '#fff';
//             },
//           },
//         },
//       },
//     },
//   });

//   isVisible.value = true;
// }

// watch(
//   () => props.dataset,
//   () => {
//     dataSetRef.value = props.dataset;
//     setTimeout(() => {
//       renderChart();
//     }, 200);
//   },
//   { deep: true }
// );
onMounted(() => {
  dataSetRef.value = props.dataset;

  // setTimeout(() => { renderChart(); }, 400);
});

const __returned__ = { props, dataSetRef, data, options, get Scatter() { return Scatter } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})