import { createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withModifiers as _withModifiers, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_3 = { class: "d-flex justify-space-between w-100 ms-3 items-center" }
const _hoisted_4 = {
  class: "d-flex align-center",
  style: {"gap":"10px"}
}
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "ms-2 mt-5" }
const _hoisted_7 = { class: "d-flex" }
const _hoisted_8 = { class: "ms-2 d-flex align-end" }
const _hoisted_9 = { class: "mb-7" }
const _hoisted_10 = { class: "mb-7" }
const _hoisted_11 = { class: "mb-7" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_AtomProgressForm = _resolveComponent("AtomProgressForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de Link' : $setup.type == 'E' ? 'Editando Link' : 'Link',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    _createCommentVNode(" <h1>Link de Internet</h1> "),
    (!$setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            (!$setup.state.isMobile)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "12"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      _cache[58] || (_cache[58] = _createElementVNode("h2", { class: "mx-3" }, "Links de Internet", -1 /* HOISTED */)),
                      _createVNode($setup["AtomTooltip"], {
                        class: "mt-5",
                        text: 'Representam as conexões de internet disponíveis em cada unidade. Incluem detalhes como tipo de conexão, velocidade de banda e configurações de roteamento.'
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["AlertIcon"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.items,
                  totalItems: $setup.totalRows,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleEdit($event, true))),
                  onItemClickDelete: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleDelete($event)))
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _cache[59] || (_cache[59] = _createElementVNode("div", null, [
                        _createCommentVNode(" <AtomButton background=\"white\" text=\"Manusear colunas\" outlined> </AtomButton> ")
                      ], -1 /* HOISTED */)),
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode($setup["AtomButton"], {
                          class: "ms-2",
                          prependIcon: "",
                          text: "Novo link de internet",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => {
                  $setup.clear();
                  $setup.showModalUpload = true;
                })
                        })
                      ])
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleEdit(props, true)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Editar'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "my-3" }, {
                  default: _withCtx(() => _cache[60] || (_cache[60] = [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", { class: "text-gray-02" }, "Preencha todos os campos corretamente para cadastrar um novo Link.")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Tipo de cadastro",
                      modelValue: $setup.data.tipoDeCadastro,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.tipoDeCadastro) = $event)),
                      options: $setup.tipoCadastro,
                      disabled: !!$setup.data.id
                    }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Provedor",
                      modelValue: $setup.data.provedorId,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.data.provedorId) = $event)),
                      options: $setup.provedores
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      type: "number",
                      msgError: "Campo obrigatório",
                      hasError: !$setup.data.download,
                      placeholder: "Digite aqui",
                      label: "Download",
                      modelValue: $setup.data.download,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.data.download) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      type: "number",
                      msgError: "Campo obrigatório",
                      placeholder: "Digite aqui",
                      label: "Upload",
                      modelValue: $setup.data.upload,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.data.upload) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Tecnologia",
                      modelValue: $setup.data.tecnologia,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.data.tecnologia) = $event)),
                      options: $setup.tecnologias
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      type: "date",
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      placeholder: "Digite aqui",
                      label: "Data de vencimento",
                      modelValue: $setup.data.vencimento,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.data.vencimento) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      type: "number",
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      placeholder: "Digite aqui",
                      label: "Valor provisionado",
                      modelValue: $setup.data.valorProvisionado,
                      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.data.valorProvisionado) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      type: "number",
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      placeholder: "Digite aqui",
                      label: "SLA",
                      modelValue: $setup.data.sla,
                      "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.data.sla) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      type: "text",
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      placeholder: "Digite aqui",
                      label: "Contato de suporte",
                      modelValue: $setup.data.contatoSuporte,
                      "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.data.contatoSuporte) = $event)),
                      mask: ['(##) #####-####', '(##) ####-####']
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      type: "number",
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      placeholder: "Digite aqui",
                      label: "SLA problema",
                      modelValue: $setup.data.slaProblem,
                      "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => (($setup.data.slaProblem) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      type: "number",
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      placeholder: "Digite aqui",
                      label: "Horas de serviço",
                      modelValue: $setup.data.horasServico,
                      "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.data.horasServico) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Unidade",
                      modelValue: $setup.data.unidadeId,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.data.unidadeId) = $event)),
                      options: $setup.unidades
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode($setup["AtomSelect"], {
                        width: "100%",
                        placeholder: "Selecione",
                        label: "Gerente",
                        modelValue: $setup.data.gerenteDeContasId,
                        "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.data.gerenteDeContasId) = $event)),
                        options: $setup.gerentes
                      }, null, 8 /* PROPS */, ["modelValue", "options"]),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode($setup["AtomButton"], {
                          class: "px-2",
                          onClick: _cache[18] || (_cache[18] = ($event: any) => {
                $setup.newManager = true;
                $setup.novoGerente = {};
              })
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AddUserIcon"], { color: "white" })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            ($setup.currentStep === 'Serviço do tipo link')
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomSelect"], {
                          width: "100%",
                          placeholder: "Selecione",
                          label: "LINK",
                          modelValue: $setup.data.link,
                          "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.data.link) = $event)),
                          options: [],
                          disabled: true
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          placeholder: "Digite aqui",
                          label: "Nome link",
                          modelValue: $setup.data.nomeLink,
                          "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.data.nomeLink) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          placeholder: "Digite aqui",
                          label: "IP",
                          modelValue: $setup.data.ip,
                          "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.data.ip) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "Latitude",
                          modelValue: $setup.data.latitude,
                          "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($setup.data.latitude) = $event)),
                          type: "number"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          placeholder: "Digite aqui",
                          label: "Longitude",
                          modelValue: $setup.data.longitude,
                          "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($setup.data.longitude) = $event)),
                          type: "number"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          placeholder: "Digite aqui",
                          label: "Valor aceitável de jiter",
                          modelValue: $setup.data.latencia,
                          "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => (($setup.data.latencia) = $event)),
                          type: "number",
                          appendIcon: ""
                        }, {
                          appendIcon: _withCtx(() => _cache[61] || (_cache[61] = [
                            _createTextVNode(" ms ")
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          placeholder: "Digite aqui",
                          label: "Valor aceitável de latência",
                          modelValue: $setup.data.jitter,
                          "onUpdate:modelValue": _cache[25] || (_cache[25] = ($event: any) => (($setup.data.jitter) = $event)),
                          type: "number",
                          appendIcon: ""
                        }, {
                          appendIcon: _withCtx(() => _cache[62] || (_cache[62] = [
                            _createTextVNode(" ms ")
                          ])),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (!$setup.data.id)
                      ? (_openBlock(), _createBlock(_component_v_col, {
                          key: 0,
                          cols: "12"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione",
                              label: "Grupos com esse serviço",
                              multiple: "",
                              modelValue: $setup.data.grupoIntLista,
                              "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => (($setup.data.grupoIntLista) = $event)),
                              options: $setup.grupos,
                              disabled: !!$setup.data.id
                            }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
                          ]),
                          _: 1 /* STABLE */
                        }))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.currentStep === 'Interface do link')
              ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          width: "100%",
                          required: "",
                          msgError: "Campo obrigatório",
                          placeholder: "Digite aqui",
                          label: "Nome interface",
                          modelValue: $setup.data.nomeInterface,
                          "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => (($setup.data.nomeInterface) = $event))
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomSelect"], {
                          width: "100%",
                          placeholder: "Selecione",
                          label: "Equipamento",
                          modelValue: $setup.data.equipamentoId,
                          "onUpdate:modelValue": _cache[28] || (_cache[28] = ($event: any) => (($setup.data.equipamentoId) = $event)),
                          options: $setup.equipamentos
                        }, null, 8 /* PROPS */, ["modelValue", "options"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_row, { class: "mt-5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[29] || (_cache[29] = ($event: any) => ($setup.handleNew())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.textSaveMobile
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: $setup.handleCancelNew,
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      width: "1050px",
      bodyScrool: true,
      onHandleConfirm: $setup.handleNew,
      onHandleCancel: $setup.handleCancelNew,
      title: "Cadastro de links",
      subtitle: 'Preencha todos os campos corretamente para cadastrar um novo link',
      show: $setup.showModalUpload,
      "btn-close-text": $setup.textCancelModal,
      "btn-confirm-text": $setup.textSaveModal
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode(_component_AtomProgressForm, {
              steps: $setup.steps,
              "current-step": $setup.currentStep
            }, null, 8 /* PROPS */, ["steps", "current-step"]),
            _createVNode(_component_v_container, { class: "text-sm px-0" }, {
              default: _withCtx(() => [
                ($setup.currentStep === 'Link de internet')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione um tipo de cadastro",
                              label: "Selecione um tipo de cadastro",
                              modelValue: $setup.data.tipoDeCadastro,
                              "onUpdate:modelValue": _cache[30] || (_cache[30] = ($event: any) => (($setup.data.tipoDeCadastro) = $event)),
                              options: $setup.tipoCadastro,
                              disabled: !!$setup.data.id
                            }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione um provedor",
                              label: "Selecione um provedor",
                              modelValue: $setup.data.provedorId,
                              "onUpdate:modelValue": _cache[31] || (_cache[31] = ($event: any) => (($setup.data.provedorId) = $event)),
                              options: $setup.provedores
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              type: "number",
                              msgError: "Campo obrigatório",
                              hasError: !$setup.data.download,
                              placeholder: "Download",
                              label: "Download",
                              modelValue: $setup.data.download,
                              "onUpdate:modelValue": _cache[32] || (_cache[32] = ($event: any) => (($setup.data.download) = $event))
                            }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              type: "number",
                              msgError: "Campo obrigatório",
                              placeholder: "Upload",
                              label: "Upload",
                              modelValue: $setup.data.upload,
                              "onUpdate:modelValue": _cache[33] || (_cache[33] = ($event: any) => (($setup.data.upload) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione a tecnologia",
                              label: "Selecione a tecnologia",
                              modelValue: $setup.data.tecnologia,
                              "onUpdate:modelValue": _cache[34] || (_cache[34] = ($event: any) => (($setup.data.tecnologia) = $event)),
                              options: $setup.tecnologias
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              type: "date",
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Data de vencimento",
                              label: "Data de vencimento",
                              modelValue: $setup.data.vencimento,
                              "onUpdate:modelValue": _cache[35] || (_cache[35] = ($event: any) => (($setup.data.vencimento) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              type: "number",
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Valor provisionado",
                              label: "Valor provisionado",
                              modelValue: $setup.data.valorProvisionado,
                              "onUpdate:modelValue": _cache[36] || (_cache[36] = ($event: any) => (($setup.data.valorProvisionado) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              type: "number",
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "SLA",
                              label: "SLA",
                              modelValue: $setup.data.sla,
                              "onUpdate:modelValue": _cache[37] || (_cache[37] = ($event: any) => (($setup.data.sla) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              type: "text",
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Contato de suporte",
                              label: "Contato de suporte",
                              modelValue: $setup.data.contatoSuporte,
                              "onUpdate:modelValue": _cache[38] || (_cache[38] = ($event: any) => (($setup.data.contatoSuporte) = $event)),
                              mask: ['(##) #####-####', '(##) ####-####']
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              type: "number",
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "SLA problema",
                              label: "SLA problema",
                              modelValue: $setup.data.slaProblem,
                              "onUpdate:modelValue": _cache[39] || (_cache[39] = ($event: any) => (($setup.data.slaProblem) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              type: "number",
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Horas de serviço",
                              label: "Horas de serviço",
                              modelValue: $setup.data.horasServico,
                              "onUpdate:modelValue": _cache[40] || (_cache[40] = ($event: any) => (($setup.data.horasServico) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione uma unidade",
                              label: "Selecione uma unidade",
                              modelValue: $setup.data.unidadeId,
                              "onUpdate:modelValue": _cache[41] || (_cache[41] = ($event: any) => (($setup.data.unidadeId) = $event)),
                              options: $setup.unidades
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_7, [
                              _createVNode($setup["AtomSelect"], {
                                width: "100%",
                                placeholder: "Selecione um gerente",
                                label: "Selecione um gerente",
                                modelValue: $setup.data.gerenteDeContasId,
                                "onUpdate:modelValue": _cache[42] || (_cache[42] = ($event: any) => (($setup.data.gerenteDeContasId) = $event)),
                                options: $setup.gerentes
                              }, null, 8 /* PROPS */, ["modelValue", "options"]),
                              _createElementVNode("div", _hoisted_8, [
                                _createVNode($setup["AtomButton"], {
                                  class: "px-2 mb-2",
                                  onClick: _cache[43] || (_cache[43] = ($event: any) => {
                        $setup.newManager = true;
                        $setup.novoGerente = {};
                      })
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AddUserIcon"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.currentStep === 'Serviço do tipo link')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "LINK",
                              label: "LINK",
                              modelValue: $setup.data.link,
                              "onUpdate:modelValue": _cache[44] || (_cache[44] = ($event: any) => (($setup.data.link) = $event)),
                              options: [],
                              disabled: true
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Nome link",
                              label: "Nome link",
                              modelValue: $setup.data.nomeLink,
                              "onUpdate:modelValue": _cache[45] || (_cache[45] = ($event: any) => (($setup.data.nomeLink) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "IP",
                              label: "IP",
                              modelValue: $setup.data.ip,
                              "onUpdate:modelValue": _cache[46] || (_cache[46] = ($event: any) => (($setup.data.ip) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Latitude",
                              label: "Latitude",
                              modelValue: $setup.data.latitude,
                              "onUpdate:modelValue": _cache[47] || (_cache[47] = ($event: any) => (($setup.data.latitude) = $event)),
                              type: "number"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              placeholder: "Longitude",
                              label: "Longitude",
                              modelValue: $setup.data.longitude,
                              "onUpdate:modelValue": _cache[48] || (_cache[48] = ($event: any) => (($setup.data.longitude) = $event)),
                              type: "number"
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Valor aceitável de jiter",
                              label: "Valor aceitável de jiter",
                              modelValue: $setup.data.latencia,
                              "onUpdate:modelValue": _cache[49] || (_cache[49] = ($event: any) => (($setup.data.latencia) = $event)),
                              type: "number",
                              appendIcon: ""
                            }, {
                              appendIcon: _withCtx(() => _cache[63] || (_cache[63] = [
                                _createTextVNode(" ms ")
                              ])),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Valor aceitável de latência",
                              label: "Valor aceitável de latência",
                              modelValue: $setup.data.jitter,
                              "onUpdate:modelValue": _cache[50] || (_cache[50] = ($event: any) => (($setup.data.jitter) = $event)),
                              type: "number",
                              appendIcon: ""
                            }, {
                              appendIcon: _withCtx(() => _cache[64] || (_cache[64] = [
                                _createTextVNode(" ms ")
                              ])),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        (!$setup.data.id)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              md: "6"
                            }, {
                              default: _withCtx(() => [
                                _createVNode($setup["AtomSelect"], {
                                  width: "100%",
                                  placeholder: "Grupos com esse serviço",
                                  label: "Grupos com esse serviço",
                                  multiple: "",
                                  modelValue: $setup.data.grupoIntLista,
                                  "onUpdate:modelValue": _cache[51] || (_cache[51] = ($event: any) => (($setup.data.grupoIntLista) = $event)),
                                  options: $setup.grupos,
                                  disabled: !!$setup.data.id
                                }, null, 8 /* PROPS */, ["modelValue", "options", "disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.currentStep === 'Interface do link')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              placeholder: "Nome interface",
                              label: "Nome interface",
                              modelValue: $setup.data.nomeInterface,
                              "onUpdate:modelValue": _cache[52] || (_cache[52] = ($event: any) => (($setup.data.nomeInterface) = $event))
                            }, null, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione um equipamento",
                              label: "Selecione um equipamento",
                              modelValue: $setup.data.equipamentoId,
                              "onUpdate:modelValue": _cache[53] || (_cache[53] = ($event: any) => (($setup.data.equipamentoId) = $event)),
                              options: $setup.equipamentos
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "btn-close-text", "btn-confirm-text"]),
    _createVNode($setup["MoleculeModal"], {
      onHandleConfirm: $setup.addGerenteContas,
      onHandleCancel: _cache[57] || (_cache[57] = ($event: any) => ($setup.newManager = false)),
      title: "Adicionar novo gerente",
      subtitle: 'Cadastre um novo gerente de contas se o mesmo não estiver na base de dados',
      show: $setup.newManager,
      "btn-close-text": "Cancelar",
      "btn-confirm-text": "Registrar"
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_9, [
              _createVNode($setup["AtomInput"], {
                width: "100%",
                required: "",
                msgError: "Campo obrigatório",
                hasError: !$setup.novoGerente.nome,
                placeholder: "Nome",
                label: "Nome",
                modelValue: $setup.novoGerente.nome,
                "onUpdate:modelValue": _cache[54] || (_cache[54] = ($event: any) => (($setup.novoGerente.nome) = $event))
              }, null, 8 /* PROPS */, ["hasError", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode($setup["AtomInput"], {
                width: "100%",
                required: "",
                mask: ['(##) #####-####', '(##) ####-####'],
                msgError: "Campo obrigatório",
                hasError: !$setup.novoGerente.numero,
                placeholder: "Contato",
                label: "Contato",
                modelValue: $setup.novoGerente.numero,
                "onUpdate:modelValue": _cache[55] || (_cache[55] = ($event: any) => (($setup.novoGerente.numero) = $event))
              }, null, 8 /* PROPS */, ["hasError", "modelValue"])
            ]),
            _createElementVNode("div", _hoisted_11, [
              _createVNode($setup["AtomInput"], {
                type: "email",
                width: "100%",
                required: "",
                msgError: "Campo obrigatório",
                hasError: !$setup.novoGerente.email,
                placeholder: "Email",
                label: "Email",
                modelValue: $setup.novoGerente.email,
                "onUpdate:modelValue": _cache[56] || (_cache[56] = ($event: any) => (($setup.novoGerente.email) = $event))
              }, null, 8 /* PROPS */, ["hasError", "modelValue"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}