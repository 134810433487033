import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex flex-row-reverse mt-3" }
const _hoisted_2 = { class: "d-flex justify-end w-100" }
const _hoisted_3 = { class: "d-flex align-center btn-opt" }
const _hoisted_4 = { class: "d-flex flex-row-reverse" }
const _hoisted_5 = {
  key: 1,
  class: "card"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Gerenciar usuários\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      prependIcon: "",
      "add-btn": true,
      title: 'Relátorio'
    }, {
      prependIcon: _withCtx(() => [
        _createVNode($setup["RelatoryIcon"], { class: "mt-2" })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, {
          cols: "12",
          md: $setup.filter.relatory ? 3 : 12
        }, {
          default: _withCtx(() => [
            _createVNode($setup["AtomSelect"], {
              width: "100%",
              placeholder: "Selecione um relatório",
              label: "Selecione o tipo de relatório",
              modelValue: $setup.filter.relatory,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.filter.relatory) = $event)),
              options: $setup.optionsRelatory,
              rounded: "lg"
            }, null, 8 /* PROPS */, ["modelValue", "options"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["md"]),
        ($setup.filter.relatory == 5 || $setup.filter.relatory == 7 || ($setup.filter.relatory >= 12 && $setup.filter.relatory <= 17))
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 0,
              cols: "12",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  width: "100%",
                  placeholder: "Selecione uma unidade",
                  label: "Selecione uma unidade",
                  modelValue: $setup.filter.unidade,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.filter.unidade) = $event)),
                  options: $setup.optionsUnidades,
                  rounded: "lg",
                  onOnChangeDropDownList: $setup.handleSelect
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.filter.relatory >= 12 && $setup.filter.relatory <= 17)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 1,
              cols: "12",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  disabled: $setup.filter.unidade ? false : true,
                  type: 'month',
                  label: "Mês e ano",
                  width: "100%",
                  modelValue: $setup.filter.mouthAndYear,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.filter.mouthAndYear) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        (
        $setup.filter.relatory &&
        ($setup.filter.relatory == 3 ||
          $setup.filter.relatory == 5 ||
          $setup.filter.relatory == 7 ||
          $setup.filter.relatory == 8 ||
          $setup.filter.relatory == 9 ||
          $setup.filter.relatory == 10 ||
          $setup.filter.relatory == 11)
      )
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 2,
              cols: "12",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  width: "100%",
                  placeholder: "Selecione um serviço",
                  label: "Selecione um Serviço",
                  modelValue: $setup.filter.servico,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.filter.servico) = $event)),
                  options: $setup.optionsServicos,
                  rounded: "lg"
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.filter.relatory && $setup.filter.relatory != 1 && $setup.filter.relatory < 12)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 3,
              cols: "12",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  disabled: $setup.filter.relatory ? false : true,
                  width: "100%",
                  placeholder: "Selecione o tempo de busca",
                  label: "Selecione o tempo",
                  modelValue: $setup.filter.Time,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.filter.Time) = $event)),
                  options: $setup.optionTimeServicos,
                  rounded: "lg"
                }, null, 8 /* PROPS */, ["disabled", "modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.filter.relatory && $setup.filter.relatory == 18)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 4,
              cols: "12",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  disabled: $setup.filter.relatory ? false : true,
                  width: "100%",
                  placeholder: "Selecione o tempo de busca",
                  label: "Selecione o tempo",
                  modelValue: $setup.filter.Time,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.filter.Time) = $event)),
                  options: $setup.optionTimeNotification,
                  rounded: "lg"
                }, null, 8 /* PROPS */, ["disabled", "modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createCommentVNode(" <v-col cols=\"12\" md=\"3\" v-if=\"filter.relatory\">\n      <AtomSelect width=\"100%\" placeholder=\"Selecione um coletor\" label=\"Selecione uma coletor\" v-model=\"filter.coletor\"\n        :options=\"optionsColetors\" />\n    </v-col>\n    <v-col cols=\"12\" md=\"3\" v-if=\"filter.relatory\">\n      <AtomSelect width=\"100%\" v-model=\"filter.link\" placeholder=\"Selecione uma Empresa\" label=\"Selecione uma Empresa\"\n        :options=\"optionsCompany\" />\n    </v-col>\n    <v-col cols=\"12\" md=\"3\" v-if=\"filter.relatory\">\n      <AtomSelect width=\"100%\" v-model=\"filter.link\" placeholder=\"Selecione um link\" label=\"Selecione um link\"\n        :options=\"optionsLinks\" />\n    </v-col> "),
        ($setup.filter.relatory)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 5,
              cols: "12",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  width: "100%",
                  placeholder: "Selecione",
                  label: "Método de exportação",
                  options: $setup.format,
                  modelValue: $setup.dataDate.export,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.dataDate.export) = $event)),
                  rounded: "lg"
                }, null, 8 /* PROPS */, ["options", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.filter.relatory == 1)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 6,
              cols: "6",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  type: 'date',
                  disabled: !$setup.dataDate.export ? true : false,
                  label: "Data Início",
                  width: "100%",
                  modelValue: $setup.dataDate.dataIni,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.dataDate.dataIni) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        ($setup.filter.relatory == 1)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 7,
              cols: "6",
              md: "3"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  type: 'date',
                  disabled: !$setup.dataDate.export ? true : false,
                  label: "Data Fim",
                  width: "100%",
                  modelValue: $setup.dataDate.dataFim,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.dataDate.dataFim) = $event))
                }, null, 8 /* PROPS */, ["disabled", "modelValue"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode(_component_v_col, {
          cols: "12",
          md: "12"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode($setup["AtomButton"], {
                prependIcon: "",
                class: "ms-3 px-5 py-3",
                onClick: _cache[9] || (_cache[9] = ($event: any) => ($setup.SearchRelatory())),
                text: "Buscar"
              }, {
                prependIcon: _withCtx(() => [
                  _createVNode($setup["SearchIconMobile"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode($setup["AtomButton"], {
                class: "ms-2 px-11 py-3",
                background: "gray-05",
                text: "Limpar",
                onClick: _cache[10] || (_cache[10] = ($event: any) => ($setup.clear()))
              })
            ])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            ($setup.dataDate.export == '.XLSX' && $setup.items.length != 0)
              ? (_openBlock(), _createBlock($setup["MoleculeTable"], {
                  key: 0,
                  onChangePerPage: _cache[12] || (_cache[12] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[13] || (_cache[13] = (ev) => ($setup.page = ev)),
                  onBlur: _cache[14] || (_cache[14] = ($event: any) => ($setup.term = $event)),
                  filters: "",
                  headers: $setup.headersResumoComputed,
                  items: $setup.paginationTableData,
                  totalItems: $setup.items.length,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  searchFalse: false,
                  searchText: "Aguardando pesquisa de relatórios"
                }, {
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createCommentVNode(" <AtomButton prependIcon @click=\"exportToExcel()\" class=\"ms-2 my-2 py-3\" background=\"gray-05\"\n              text=\"Exportar\">\n              <template #prependIcon>\n                <exportIcon />\n              </template>\n      </AtomButton> "),
                      _createVNode(_component_v_menu, {
                        "close-on-content-click": false,
                        width: 290
                      }, {
                        activator: _withCtx(({ props }) => [
                          _createVNode($setup["AtomButton"], _mergeProps({
                            background: "gray-05",
                            "no-hover": "",
                            class: "ms-2 my-2 py-3"
                          }, props), {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode($setup["ColsIcon"]),
                                _cache[15] || (_cache[15] = _createElementVNode("p", { class: "ms-3" }, "Colunas", -1 /* HOISTED */))
                              ])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_v_list, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_v_list_item, null, {
                                default: _withCtx(() => [
                                  _createVNode($setup["MoleculeReorder"], {
                                    modelValue: $setup.headersResumoComputed,
                                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.headersResumoComputed) = $event))
                                  }, null, 8 /* PROPS */, ["modelValue"])
                                ]),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createCommentVNode(" <AtomButton prependIcon class=\"ms-2 my-2 py-3\" background=\"gray-05\" text=\"Colunas\">\n              <template #prependIcon> <ColsIcon /> </template>\n            </AtomButton> ")
                    ])
                  ]),
                  areaFilterMobile: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode($setup["AtomButton"], {
                        prependIcon: "",
                        class: "ms-2 my-2 py-3",
                        background: "gray-05",
                        text: "Exportar"
                      }, {
                        prependIcon: _withCtx(() => [
                          _createVNode($setup["exportIcon"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode($setup["AtomButton"], {
                        prependIcon: "",
                        class: "ms-2 my-2 py-3",
                        background: "gray-05",
                        text: "Colunas"
                      }, {
                        prependIcon: _withCtx(() => [
                          _createVNode($setup["ColsIcon"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"]))
              : _createCommentVNode("v-if", true),
            ($setup.items.length == 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createVNode($setup["timerIcon"]),
                  _cache[16] || (_cache[16] = _createElementVNode("p", null, "Aguardando pesquisa de relatórios", -1 /* HOISTED */))
                ]))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.dataDate.export == 'PDF' && $setup.items.length != 0)
          ? (_openBlock(), _createBlock(_component_v_col, {
              key: 8,
              cols: "10",
              offset: "1"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["OrganismsChart"], {
                  legend: true,
                  PdfBtn: true,
                  title: `${
          $setup.filter.relatory == 2
            ? 'Tempo total de inatividade por serviço'
            : $setup.filter.relatory == 3
              ? 'Pico de consumo de banda por serviço'
              : $setup.filter.relatory == 4
                ? 'Percentual de tempo de estados por serviço'
                : $setup.filter.relatory == 5
                  ? 'Latência média por unidades'
                  : $setup.filter.relatory == 6
                    ? 'Latência média nos estados por serviço'
                    : $setup.filter.relatory == 7
                      ? 'Disponibilidade geral por unidade'
                      : $setup.filter.relatory == 8
                        ? 'Consumo médio de banda por serviço'
                        : $setup.filter.relatory == 9
                          ? 'Variação de desempenho por serviço'
                          : $setup.filter.relatory == 10
                            ? 'Desempenho por serviço'
                            : $setup.filter.relatory == 11
                              ? 'Comparação de desempenho por serviço'
                              : $setup.filter.relatory == 12
                                ? 'Custo total mensal por link'
                                : $setup.filter.relatory == 13
                                  ? 'Desconto total mensal por link'
                                  : $setup.filter.relatory == 14
                                    ? 'Percentual de variação mensal de custos e descontos por lin'
                                    : $setup.filter.relatory == 15
                                      ? 'Porcentagem de economia mensal por link'
                                      : $setup.filter.relatory == 16
                                        ? 'Variação mensal de descontos por link'
                                        : $setup.filter.relatory == 17
                                          ? 'Variação mensal de custos por link'
                                          : $setup.filter.relatory == 18
                                            ? 'Histórico de notificações por período'
                                            : $setup.filter.relatory == 19
                                              ? 'Desempenho de Internet por Unidade'
                                              : $setup.filter.relatory == 20
                                                ? 'Desempenho de Internet por Serviços/Links'
                                                : $setup.filter.relatory == 21
                                                  ? 'Desempenho de Internet por Coletor'
                                                  : $setup.filter.relatory == 22
                                                    ? 'Média de Velocidade de Internet por Localidade'
                                                    : 'Relatório Geral'
        }`,
                  "has-toggle": $setup.filter.relatory != 1 ? false : true,
                  type: "area",
                  loading: $setup.loaderChartGeral,
                  buttons: $setup.optionsList,
                  loadingColor: $setup.activeColor,
                  data: $setup.itemsChart,
                  onHandleToggle: $setup.toggle
                }, null, 8 /* PROPS */, ["title", "has-toggle", "loading", "buttons", "loadingColor", "data"])
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ], 64 /* STABLE_FRAGMENT */))
}