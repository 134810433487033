import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';

interface IProps {
  text?: string;
  background?: string;
  prependIcon?: boolean;
  outlined?: boolean;
  radiusOutlined?: boolean;
  radius?: boolean;
  appendIcon?: boolean;
  IconText?: boolean;
  disabled?: boolean;
  center?: string;
  btnNotify?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomButton',
  props: {
    text: { type: String, required: false },
    background: { type: String, required: false },
    prependIcon: { type: Boolean, required: false },
    outlined: { type: Boolean, required: false },
    radiusOutlined: { type: Boolean, required: false },
    radius: { type: Boolean, required: false },
    appendIcon: { type: Boolean, required: false },
    IconText: { type: Boolean, required: false },
    disabled: { type: Boolean, required: false, default: false },
    center: { type: String, required: false },
    btnNotify: { type: Boolean, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const backgroundColor = computed(() => {
  return props.background ? props.background : 'primary';
});

const __returned__ = { props, backgroundColor }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})