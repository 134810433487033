import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted, computed } from 'vue';
import { NotificationIcon, BellIcon, AtomButton } from '@/components/atoms';
import { Notification } from '@/store';
import { DangerTriangleFullIcon } from '@/components/atoms';
import { MoleculeModal } from '../molecules';
import { state } from '@/store/modules/layout';
import NotificationModal from '@/pages/notificationmodal/NotificationModal.vue';
import { format } from 'date-fns';

export default /*@__PURE__*/_defineComponent({
  __name: 'AtomNotification',
  setup(__props, { expose: __expose }) {
  __expose();

const showNotification = ref<boolean>(false);
const showModalnotification = ref<boolean>(false);

const notifyAlert = ref<any>();
const typeNotify = computed(() => {
  return notifyAlert.value.ordem.includes('1')
    ? '1'
    : notifyAlert.value.ordem.includes('2')
      ? '2'
      : notifyAlert.value.ordem.includes('3')
        ? '3'
        : '4';
});

function handleClick(notify) {
  showModalnotification.value = true;
  notifyAlert.value = notify;
}

watch(
  () => showNotification.value,
  (nValue: boolean) => {
    if (nValue) {
      Notification.resetNotification();
    }
  },
);
onMounted(() => {
  Notification.getLocalMutedNotification();

  setInterval(() => {
    let data: any = localStorage.getItem('notifyBackgroundMessages');

    if (data) {
      for (const iterator of JSON.parse(data)) {
        Notification.setNotification(iterator);
      }

      data = [];

      localStorage.setItem('notifyBackgroundMessages', JSON.stringify(data));
    }
  }, 6000);
});

const __returned__ = { showNotification, showModalnotification, notifyAlert, typeNotify, handleClick, get NotificationIcon() { return NotificationIcon }, get BellIcon() { return BellIcon }, get AtomButton() { return AtomButton }, get Notification() { return Notification }, get DangerTriangleFullIcon() { return DangerTriangleFullIcon }, get MoleculeModal() { return MoleculeModal }, get state() { return state }, NotificationModal, get format() { return format } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})