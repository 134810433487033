import { defineComponent as _defineComponent } from 'vue'
import { computed } from 'vue';
  import { BarMobileIcon } from '@/components/atoms';
  import { state } from '@/store/modules/layout';
  
  interface IProps {
    title?: string;
    subtitle?: string;
    content?: any;
    sheet?: boolean;
    collapseClick?: boolean;
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeSheetBottom',
  props: {
    title: { type: String, required: false },
    subtitle: { type: String, required: false },
    content: { type: null, required: false },
    sheet: { type: Boolean, required: false },
    collapseClick: { type: Boolean, required: false }
  },
  emits: ['update:sheet'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

  const emit = __emit;
  
  const props = __props;
  
  const localSheet = computed({
    get: () => props.sheet,
    set: (value) => {
      emit('update:sheet', value);
    },
  });
  
//   const closeSheet = () => {
//     localSheet.value = false; // Feche a bottom sheet definindo localSheet como false
//   };
  
const __returned__ = { emit, props, localSheet, get BarMobileIcon() { return BarMobileIcon }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})