import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderSlot as _renderSlot, mergeProps as _mergeProps, withModifiers as _withModifiers, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({'my-2': !$props.noMargin})
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`d-flex align-center justify-space-between cursor-pointer ${$setup.props.cardClass}`),
      onClick: _withModifiers($setup.toggle, ["prevent"])
    }, [
      ($props.title)
        ? (_openBlock(), _createElementBlock("h2", {
            key: 0,
            class: _normalizeClass($setup.props.titleOrange ? 'title-trigger-orange':
       $setup.props.titleGreen ? ' title-trigger-green':
       $setup.props.titleBlue ? ' title-trigger-blue':'')
          }, _toDisplayString($props.title), 3 /* TEXT, CLASS */))
        : _createCommentVNode("v-if", true),
      (!$props.noChevron)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["d-flex ml-3 button", {'rotate-invert': $setup.show}])
          }, [
            _createVNode($setup["ChevronDownIcon"])
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      (!$setup.props.iconDifferent)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: _normalizeClass(["d-flex ml-3 button", {'rotate-invert': $setup.show}])
          }, [
            _renderSlot(_ctx.$slots, "icon", {}, undefined, true)
          ], 2 /* CLASS */))
        : _renderSlot(_ctx.$slots, "titleArea", _mergeProps({
            key: 3,
            show: $setup.show,
            toggle: $setup.toggle
          }, _ctx.$attrs), undefined, true)
    ], 2 /* CLASS */),
    _createVNode(_Transition, {
      name: "accordion",
      onAfterEnter: $setup.leave,
      onEnter: $setup.enter,
      onAfterLeave: $setup.leave,
      onBeforeLeave: $setup.enter,
      persisted: ""
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 512 /* NEED_PATCH */), [
          [_vShow, $setup.show]
        ])
      ]),
      _: 3 /* FORWARDED */
    })
  ], 2 /* CLASS */))
}