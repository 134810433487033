import { defineComponent as _defineComponent } from 'vue'

import CircleProgress from 'vue3-circle-progress';
import { MoleculeCardSimple } from '@/components/molecules';
import { OnlineIcon, OfflineIcon } from '@/components/atoms';
import 'vue3-circle-progress/dist/circle-progress.css';
import { ReorderIcon } from '@/components/atoms';

// Definindo as props para receber os dados dinamicamente

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsProgressCircle',
  props: {
  value: {
    type: Number,
    required: true,
    default: () => 0,
  },
  title: {
    type: String,
    default: '',
  },
  tooltip: {
    type: String,
    default: '',
  },
  notPercent: {
    type: Boolean,
    default: false,
  },
},
  emits: ['clickIconTitle'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const __returned__ = { props, emit, get CircleProgress() { return CircleProgress }, get MoleculeCardSimple() { return MoleculeCardSimple }, get OnlineIcon() { return OnlineIcon }, get OfflineIcon() { return OfflineIcon }, get ReorderIcon() { return ReorderIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})