import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "error-msg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString($setup.props.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", {
      class: _normalizeClass(["input-wapper d-flex", { error: $props.hasError }])
    }, [
      ($props.prependIcon)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([{ 'bg-gray-06': !$props.noBgPrependIcon, 'border-e': !$props.noBorderPrependIcon, error: $props.hasError }, "prependIcon h-100 px-2 d-flex align-center"])
          }, [
            _renderSlot(_ctx.$slots, "prependIcon", {}, undefined, true)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["Money3Component"], _mergeProps({
        required: $setup.props.required,
        class: "input",
        modelValue: $setup.amount,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.amount) = $event))
      }, $setup.config), null, 16 /* FULL_PROPS */, ["required", "modelValue"])
    ], 2 /* CLASS */),
    ($props.hasError && $props.msgError)
      ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString($props.msgError), 1 /* TEXT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}