import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      opacity: "0.2",
      d: "M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M12 10.25C12.41 10.25 12.75 10.59 12.75 11V16C12.75 16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V11C11.25 10.59 11.59 10.25 12 10.25Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_3),
    _createElementVNode("path", {
      d: "M12.92 8.38001C12.87 8.50001 12.8 8.61001 12.71 8.71001C12.61 8.80001 12.5 8.87001 12.38 8.92001C12.14 9.02001 11.86 9.02001 11.62 8.92001C11.5 8.87001 11.39 8.80001 11.29 8.71001C11.2 8.61001 11.13 8.50001 11.08 8.38001C11.03 8.26001 11 8.13001 11 8.00001C11 7.87001 11.03 7.74001 11.08 7.62001C11.13 7.49001 11.2 7.39001 11.29 7.29001C11.39 7.20001 11.5 7.13 11.62 7.08C11.74 7.03 11.87 7 12 7C12.13 7 12.26 7.03 12.38 7.08C12.5 7.13 12.61 7.20001 12.71 7.29001C12.8 7.39001 12.87 7.49001 12.92 7.62001C12.97 7.74001 13 7.87001 13 8.00001C13 8.13001 12.97 8.26001 12.92 8.38001Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_4)
  ]))
}