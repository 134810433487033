import { createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "d-flex justify-center" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = {
  key: 0,
  class: "text-sm font-600 py-4"
}
const _hoisted_5 = {
  key: 2,
  class: "text-sm font-600 py-4"
}
const _hoisted_6 = ["accept"]
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { class: "d-flex justify-center mt-5" }
const _hoisted_9 = { class: "text-center" }
const _hoisted_10 = { class: "mb-5" }
const _hoisted_11 = {
  key: 0,
  class: "text-sm font-600 py-4"
}
const _hoisted_12 = { class: "pt-5" }
const _hoisted_13 = {
  key: 1,
  class: "text-sm font-600 py-4"
}
const _hoisted_14 = ["accept"]
const _hoisted_15 = { class: "d-flex" }
const _hoisted_16 = {
  key: 0,
  class: "text-sm font-600 pt-3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            class: "drop-area d-flex flex-column justify-center",
            onDragover: $setup.dragover,
            onDragleave: $setup.dragleave,
            onDrop: $setup.drop
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode($setup["PaperPlusIcon"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              (!$props.preenchido || !$setup.files)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _cache[2] || (_cache[2] = [
                    _createElementVNode("span", { class: "select" }, "Selecione um arquivo", -1 /* HOISTED */),
                    _createTextVNode(" ou arraste e solte ")
                  ])))
                : _createCommentVNode("v-if", true),
              (!$props.preenchido || !$setup.files)
                ? (_openBlock(), _createBlock($setup["AtomSubTitle"], {
                    key: 1,
                    "no-margin": "",
                    text: $setup.props.text
                  }, null, 8 /* PROPS */, ["text"]))
                : _createCommentVNode("v-if", true),
              ($props.preenchido && $setup.files)
                ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString($setup.files[0].name), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("input", {
              onChange: $setup.changeInput,
              id: "upload",
              type: "file",
              class: "d-sr-only",
              accept: $setup.props.accept
            }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_6)
          ], 32 /* NEED_HYDRATION */)
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile  )
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (!$setup.files)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                class: "drop-area-mobile d-flex flex-column justify-center",
                onDragover: $setup.dragover,
                onDragleave: $setup.dragleave,
                onDrop: $setup.drop
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode($setup["DocumentUploadIcon"])
                ]),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    (!$props.preenchido || !$setup.files)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_11, _cache[3] || (_cache[3] = [
                          _createElementVNode("span", { class: "text-muted" }, "Selecione um arquivo", -1 /* HOISTED */)
                        ])))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _cache[4] || (_cache[4] = _createElementVNode("div", { class: "my-2" }, [
                    _createElementVNode("span", { class: "btn-drop" }, "Procurar")
                  ], -1 /* HOISTED */)),
                  _createElementVNode("div", _hoisted_12, [
                    (!$props.preenchido || !$setup.files)
                      ? (_openBlock(), _createBlock($setup["AtomSubTitle"], {
                          key: 0,
                          text: `Tipo de arquivo: ${$setup.props.text}`
                        }, null, 8 /* PROPS */, ["text"]))
                      : _createCommentVNode("v-if", true),
                    ($props.preenchido && $setup.files)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString($setup.files[0].name), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ])
                ]),
                _createElementVNode("input", {
                  onChange: $setup.changeInput,
                  id: "upload",
                  type: "file",
                  class: "d-sr-only",
                  accept: $setup.props.accept
                }, null, 40 /* PROPS, NEED_HYDRATION */, _hoisted_14)
              ], 32 /* NEED_HYDRATION */))
            : _createCommentVNode("v-if", true),
          ($props.preenchido && $setup.files)
            ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_col, { cols: "10" }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_15, [
                        ($props.preenchido && $setup.files)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString($setup.files[0].name), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true),
                        _createVNode($setup["CheckColor"], { class: "ms-3 mt-3" })
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "2",
                    class: "text-end"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["TrashIcon"], {
                        class: "cursor",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.clear()))
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_v_col, {
                    cols: "12",
                    class: "mt-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["AtomProgressBar"], {
                        progressColor: '#FF5C00',
                        modelValue: $setup.progressBar,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.progressBar) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}