import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import { AtomProgressBar, AtomUpload, PaperIcon, DeletexIcon } from '@/components/atoms';

// interface IProps {
//   title?: string;
// }


export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeUploadInfo',
  emits: ['handleUpload'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const files = ref<any[]>([]);

const emit = __emit;

function handleRemoveFile(index: number) {
  files.value.splice(index, 1);
}
function handleUpload(evt: FileList) {
  // const nFile: any = { ...evt, progressUpload: 0 };
  const newObject = {
    lastModified: evt[0].lastModified,
    lastModifiedDate: evt[0]['lastModifiedDate'],
    name: evt[0].name,
    size: evt[0].size,
    type: evt[0].type,
    progressUpload: 100,
    index: files.value.length,
  };
  emit('handleUpload', { file: evt[0], newObject });

  files.value = [...files.value, { ...newObject }];
  // let interval = setInterval(function () {
  //   files.value[files.value.length - 1].progressUpload += 1;
  //   if (files.value[files.value.length - 1].progressUpload >= 100) {
  //     clearInterval(interval);
  //   }
  // }, 250);
}

const __returned__ = { files, emit, handleRemoveFile, handleUpload, get AtomProgressBar() { return AtomProgressBar }, get AtomUpload() { return AtomUpload }, get PaperIcon() { return PaperIcon }, get DeletexIcon() { return DeletexIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})