import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted } from 'vue';

interface IProps {
  modelValue: string;
}
// const emit = defineEmits(['update:modelValue']);


export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeTabWindow',
  props: {
    modelValue: { type: String, required: true, default: '' }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const tab = ref(props.modelValue)

watch(
  () => props.modelValue,
  () => {
    tab.value = props.modelValue
  }
)
onMounted(() => {
  if (props.modelValue) tab.value = props.modelValue
})

const __returned__ = { props, tab }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})