import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = { class: "d-flex justify-end w-100" }
const _hoisted_3 = { class: "d-flex" }
const _hoisted_4 = { class: "d-flex" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "ms-2 mt-5" }
const _hoisted_7 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_8 = { class: "d-flex" }
const _hoisted_9 = { class: "ms-2 mt-5" }
const _hoisted_10 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Coletores\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastro de Coletores' : $setup.type == 'E' ? 'Editando Coletor' : 'Coletores',
      onHandleConfirm: $setup.replacement
    }, null, 8 /* PROPS */, ["addBtn", "title"]),
    (!$setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: $setup.searching,
                  onHandleSort: $setup.sort,
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.items,
                  totalItems: $setup.totalRows,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  "enable-options": "",
                  onItemClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.handleEdit($event, true))),
                  onItemClickDelete: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleDelete($event)))
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 1,
                            background: "white",
                            radius: "",
                            class: "pa-3 border",
                            onClick: _withModifiers(($event: any) => ($setup.ZeroTouch(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["UserFullIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createCommentVNode(" <AtomButton prependIcon appendIcon outlined background=\"white\" text=\"Download\">\n              <template #prependIcon>\n                <DownloadIcon />\n              </template>\n</AtomButton> "),
                      _createVNode($setup["AtomButton"], {
                        class: "ms-2",
                        text: "Novo Coletor",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {
                $setup.showModal = true;
                $setup.clear();
              })
                      })
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleEdit(props, true)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Editar'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.ZeroTouch(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "0 Touch",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "my-3" }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", { class: "text-gray-02" }, "Preencha todos os campos corretamente para cadastrar uma nova empresa.")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createElementVNode("h3", { class: "text-primary font-s" }, [
                      _createElementVNode("strong", { class: "elipse-num" }, "1"),
                      _createTextVNode(" Informações primárias")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      hasError: !$setup.data.nome,
                      placeholder: "Digite aqui...",
                      label: "Nome do coletor",
                      modelValue: $setup.data.nome,
                      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.data.nome) = $event))
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      width: "100%",
                      required: "",
                      msgError: "Campo obrigatório",
                      hasError: !$setup.data.configCounter,
                      disabled: true,
                      placeholder: "Digite aqui...",
                      label: "Quantidade",
                      modelValue: $setup.data.configCounter,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.data.configCounter) = $event)),
                      modelModifiers: { number: true }
                    }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createElementVNode("h3", { class: "text-primary font-s" }, [
                      _createElementVNode("strong", { class: "elipse-num" }, "2"),
                      _createTextVNode(" Configurações testes do coletor")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Grupo",
                      modelValue: $setup.testeColetor.grupoId,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.testeColetor.grupoId) = $event)),
                      options: $setup.optionsGrupos
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Unidade",
                      modelValue: $setup.testeColetor.unidadeId,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.testeColetor.unidadeId) = $event)),
                      options: $setup.optionsUnidades
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      noBgAppendIcon: "",
                      noBorderPrependIcon: "",
                      "append-icon": "",
                      width: "100%",
                      placeholder: "Digite aqui...",
                      label: "Informe a origem",
                      modelValue: $setup.testeColetor.origem,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => (($setup.testeColetor.origem) = $event))
                    }, {
                      appendIcon: _withCtx(() => [
                        _createVNode($setup["AtomTooltip"], { text: "Utilize este campo para forçar o uso de uma origem específica na coleta. Um uso comum desta opção costuma ser para testes feitos a partir de máquinas Fortigate." }, {
                          default: _withCtx(() => [
                            _createVNode($setup["InfoSquareIcon"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Serviço",
                      modelValue: $setup.testeColetor.servicoId,
                      "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.testeColetor.servicoId) = $event)),
                      options: $setup.optionsServicos
                    }, null, 8 /* PROPS */, ["modelValue", "options"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode($setup["AtomSelect"], {
                        width: "100%",
                        placeholder: "Selecione",
                        label: "Equipamento",
                        modelValue: $setup.testeColetor.equipamentoId,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.testeColetor.equipamentoId) = $event)),
                        options: $setup.optionsEquipamentos
                      }, null, 8 /* PROPS */, ["modelValue", "options"]),
                      _createCommentVNode(" <div class=\"ms-2 mt-5\">\n            <AtomButton class=\"px-2\" @click=\"setColetor\">\n              <PlusIcon color=\"white\" />\n            </AtomButton>\n          </div> ")
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" <v-col  cols=\"12\">\n        <MoleculeTable\n          :headers=\"headersCollectors\"\n          :items=\"data.connServiceLista.filter((f) => f.status != 'DELETADO' && f.archived == 0)\"\n          :pagination=\"false\"\n          :key=\"String(data.connServiceLista.length)\"\n          class=\"text-sm\"\n        >\n          <template #actions=\"{ props }\">\n            <div class=\"d-inline-flex align-center justify-space-between\" style=\"gap: 5px\">\n             <AtomButton background=\"white\" radius class=\"pa-0\" @click.stop=\"handleDeleteColetor(props.row)\">\n                <DeleteIcon />\n              </AtomButton>\n            </div>\n          </template>\n        </MoleculeTable>\n      </v-col> ")
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => _cache[29] || (_cache[29] = [
                    _createElementVNode("h3", { class: "text-primary font-s" }, [
                      _createElementVNode("strong", { class: "elipse-num" }, "3"),
                      _createTextVNode(" Interface de link")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode($setup["AtomSelect"], {
                        width: "100%",
                        placeholder: "Selecione",
                        label: "Interface de link",
                        modelValue: $setup.interfaceDispositivoLista,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.interfaceDispositivoLista) = $event)),
                        options: $setup.optionsLinks
                      }, null, 8 /* PROPS */, ["modelValue", "options"]),
                      _createCommentVNode(" <div class=\"ms-2 mt-5\">\n            <AtomButton class=\"px-2\" @click=\"setLink\">\n              <PlusIcon color=\"white\" />\n            </AtomButton>\n          </div> ")
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createCommentVNode(" <v-col  cols=\"12\">\n        <MoleculeTable :headers=\"headersInterfaceLink\" :items=\"data.interfaceDispositivoLista\" :pagination=\"false\">\n          <template #actions=\"{ props }\">\n            <div class=\"d-inline-flex align-center justify-space-between\" style=\"gap: 5px\">\n             <AtomButton background=\"white\" radius class=\"pa-0\" @click.stop=\"handleDeleteLink(props.row)\">\n                <DeleteIcon />\n              </AtomButton>\n            </div>\n          </template>\n        </MoleculeTable>\n      </v-col> ")
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, { class: "mt-5" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[13] || (_cache[13] = ($event: any) => ($setup.handleNew())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.type == 'C' ? 'Concluir Cadastro' : 'Concluir edição'
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[14] || (_cache[14] = ($event: any) => (($setup.addNew = false), ($setup.type = ''), ($setup.data = {}))),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      width: "1050px",
      bodyScrool: true,
      onHandleConfirm: $setup.handleNew,
      onHandleCancel: $setup.handleCancelNew,
      title: "Cadastro de coletores",
      subtitle: 'Preencha todos os campos corretamente para cadastrar um novo coletor',
      show: $setup.showModal,
      "btn-close-text": $setup.textCancelModal,
      "btn-confirm-text": $setup.textSaveModal
    }, {
      body: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", null, [
            _createVNode($setup["AtomProgressForm"], {
              steps: $setup.steps,
              "current-step": $setup.currentStep
            }, null, 8 /* PROPS */, ["steps", "current-step"]),
            _createVNode(_component_v_container, { class: "text-sm px-0" }, {
              default: _withCtx(() => [
                ($setup.currentStep === 'Informações primárias')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              hasError: !$setup.data.nome,
                              placeholder: "Nome do coletor",
                              label: "Nome do coletor",
                              modelValue: $setup.data.nome,
                              "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => (($setup.data.nome) = $event))
                            }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              width: "100%",
                              required: "",
                              msgError: "Campo obrigatório",
                              hasError: !$setup.data.configCounter,
                              disabled: true,
                              placeholder: "1 (?)",
                              modelValue: $setup.data.configCounter,
                              "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => (($setup.data.configCounter) = $event)),
                              modelModifiers: { number: true },
                              label: "1 (?)"
                            }, null, 8 /* PROPS */, ["hasError", "modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.currentStep === 'Config. testes do collector')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 1 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione um grupo",
                              label: "Selecione um grupo",
                              modelValue: $setup.testeColetor.grupoId,
                              "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => (($setup.testeColetor.grupoId) = $event)),
                              options: $setup.optionsGrupos
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione uma unidade",
                              label: "Selecione uma unidade",
                              modelValue: $setup.testeColetor.unidadeId,
                              "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => (($setup.testeColetor.unidadeId) = $event)),
                              options: $setup.optionsUnidades
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomInput"], {
                              noBgAppendIcon: "",
                              noBorderPrependIcon: "",
                              "append-icon": "",
                              width: "100%",
                              placeholder: "Informe a origem",
                              label: "Informe a origem",
                              modelValue: $setup.testeColetor.origem,
                              "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => (($setup.testeColetor.origem) = $event))
                            }, {
                              appendIcon: _withCtx(() => [
                                _createVNode($setup["AtomTooltip"], { text: "Utilize este campo para forçar o uso de uma origem específica na coleta. Um uso comum desta opção costuma ser para testes feitos a partir de máquinas Fortigate." }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["InfoSquareIcon"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["modelValue"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomSelect"], {
                              width: "100%",
                              placeholder: "Selecione um serviço",
                              label: "Selecione um serviço",
                              modelValue: $setup.testeColetor.servicoId,
                              "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => (($setup.testeColetor.servicoId) = $event)),
                              options: $setup.optionsServicos
                            }, null, 8 /* PROPS */, ["modelValue", "options"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "6" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode($setup["AtomSelect"], {
                                width: "100%",
                                placeholder: "Selecione um equip.",
                                label: "Selecione um equip.",
                                modelValue: $setup.testeColetor.equipamentoId,
                                "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => (($setup.testeColetor.equipamentoId) = $event)),
                                options: $setup.optionsEquipamentos
                              }, null, 8 /* PROPS */, ["modelValue", "options"]),
                              _createElementVNode("div", _hoisted_6, [
                                _createVNode($setup["AtomButton"], {
                                  class: "px-2",
                                  onClick: $setup.setColetor
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["PlusIcon"], { color: "white" })
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            (_openBlock(), _createBlock($setup["MoleculeTable"], {
                              headers: $setup.headersCollectors,
                              items: $setup.data.connServiceLista.filter((f) => f.status != 'DELETADO' && f.archived == 0),
                              pagination: false,
                              key: String($setup.data.connServiceLista.length),
                              class: "text-sm"
                            }, {
                              actions: _withCtx(({ props }) => [
                                _createElementVNode("div", _hoisted_7, [
                                  _createVNode($setup["AtomButton"], {
                                    background: "white",
                                    radius: "",
                                    class: "pa-0",
                                    onClick: _withModifiers(($event: any) => ($setup.handleDeleteColetor(props.row)), ["stop"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode($setup["DeleteIcon"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                ])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["headers", "items"]))
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                ($setup.currentStep === 'Interface de link')
                  ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode($setup["AtomSelect"], {
                                width: "100%",
                                placeholder: "Selecione uma interface de link",
                                label: "Selecione uma interface de link",
                                modelValue: $setup.interfaceDispositivoLista,
                                "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => (($setup.interfaceDispositivoLista) = $event)),
                                options: $setup.optionsLinks
                              }, null, 8 /* PROPS */, ["modelValue", "options"]),
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode($setup["AtomButton"], {
                                  class: "px-2",
                                  onClick: $setup.setLink
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["PlusIcon"], { color: "white" })
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, { md: "12" }, {
                          default: _withCtx(() => [
                            _createVNode($setup["MoleculeTable"], {
                              headers: $setup.headersInterfaceLink,
                              items: $setup.data.interfaceDispositivoLista,
                              pagination: false
                            }, {
                              actions: _withCtx(({ props }) => [
                                _createElementVNode("div", _hoisted_10, [
                                  _createVNode($setup["AtomButton"], {
                                    background: "white",
                                    radius: "",
                                    class: "pa-0",
                                    onClick: _withModifiers(($event: any) => ($setup.handleDeleteLink(props.row)), ["stop"])
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode($setup["DeleteIcon"])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                                ])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["headers", "items"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show", "btn-close-text", "btn-confirm-text"]),
    _createVNode($setup["MoleculeModal"], {
      width: "1050px",
      bodyScrool: true,
      onHandleCancel: $setup.handleCancelNewZero,
      title: "Zero Touch",
      subtitle: 'Selecione um usuário para tornar o cadastro de coletores padrão',
      show: $setup.showModalZero,
      btnHide: true,
      "btn-close-text": 'Fechar',
      "btn-confirm-text": 'Baixar'
    }, {
      body: _withCtx(() => [
        _createVNode(_component_v_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["AtomSelect"], {
                  width: "100%",
                  placeholder: "Selecione um usuário",
                  label: "Selecione um usuário",
                  modelValue: $setup.dadoZero.usuarioid,
                  "onUpdate:modelValue": _cache[23] || (_cache[23] = ($event: any) => (($setup.dadoZero.usuarioid) = $event)),
                  options: $setup.tabelaUsers
                }, null, 8 /* PROPS */, ["modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }),
            ($setup.dadoZero.usuarioid)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[24] || (_cache[24] = ($event: any) => ($setup.DowloadZipWin($setup.idColetorZero))),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Baixar para Windows",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.dadoZero.usuarioid)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  cols: "12",
                  md: "6"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[25] || (_cache[25] = ($event: any) => ($setup.DowloadZip($setup.idColetorZero))),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Baixar para Linux e Mac",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}