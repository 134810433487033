import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted } from 'vue';
import { Money3Component } from 'v-money3';

interface IProps {
  modelValue?: any;
  msgError?: string;
  prefix?: string;
  suffix?: string;
  label?: string;
  decimal?: string;
  min?: number;
  max?: number;
  thousands?: string;
  prependIcon?: boolean;
  required?: boolean;
  width?: string;
  noBgPrependIcon?: boolean;
  noBorderPrependIcon?: boolean;
  hasError?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomInputMoney',
  props: {
    modelValue: { type: null, required: false },
    msgError: { type: String, required: false, default: '' },
    prefix: { type: String, required: false, default: '' },
    suffix: { type: String, required: false, default: '' },
    label: { type: String, required: false },
    decimal: { type: String, required: false, default: ',' },
    min: { type: Number, required: false },
    max: { type: Number, required: false },
    thousands: { type: String, required: false, default: '.' },
    prependIcon: { type: Boolean, required: false },
    required: { type: Boolean, required: false },
    width: { type: String, required: false, default: '346px' },
    noBgPrependIcon: { type: Boolean, required: false, default: false },
    noBorderPrependIcon: { type: Boolean, required: false, default: false },
    hasError: { type: Boolean, required: false }
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

_useCssVars(_ctx => ({
  "7a4989e4-width": (_ctx.width)
}))

const props = __props;

const amount = ref(0);
const config = ref({
  masked: false,
  prefix: props.prefix,
  suffix: props.prefix,
  thousands: props.thousands,
  decimal: props.decimal,
  precision: 2,
  disableNegative: false,
  disabled: false,
  min: props.min,
  max: props.max,
  allowBlank: true,
  // minimumNumberOfCharacters: 0,
});

const emit = __emit;
watch(
  () => amount.value,
  () => {
    emit('update:modelValue', amount.value);
  }
);
onMounted(() => {
  amount.value = props.modelValue ?? 0;
});

const __returned__ = { props, amount, config, emit, get Money3Component() { return Money3Component } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})