import { defineComponent as _defineComponent } from 'vue'


interface IProps {
  title: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeCardSimple',
  props: {
    title: { type: String, required: true }
  },
  emits: ['clickIconTitle'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;

const props = __props;


const __returned__ = { emit, props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})