import { defineComponent as _defineComponent } from 'vue'
import { onMounted, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import { OrganismsBar, OrganismsNavigation } from '@/components/organisms';
import { AtomPreloader } from '@/components/atoms';
import { Authentication } from '@/store';
import { actions, state } from '@/store/modules/layout';
import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
// import { initializeApp } from 'firebase/app';
import { Notification as NotificationMsg } from '@/store';
import { notificacoesInscrever } from '@/core/services/AuthService';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props, { expose: __expose }) {
  __expose();

const paddingLeft = computed(() => {
  return Authentication.getters.getStatusLoggedIn.value ? state.menuRecolhido ? '90px' : '270px' : 0
})

watch(
  () => Authentication.getters.getUser.value,
  () => {
    if (!Authentication.getters.getUser.value.id) return;
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable('PushNotifications');

    if (isPushNotificationsAvailable) {
      const addListeners = async () => {
        await PushNotifications.addListener('registration', async (token) => {
          if (Authentication.getters.getUser.value.papelLista?.find((f) => f.recebeNotificacao) != undefined) {
            const data = await notificacoesInscrever({
              email: Authentication.getters.getUser.value.email,
              deviceToken: token.value,
            });
            console.info('Registration token: ', token.value);
            console.warn('Retorno Register: ', data);
          }
        });

        await PushNotifications.addListener('registrationError', (err) => {
          console.error('Registration error: ', err.error);
        });

        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
          console.log('Push notification received: ', notification);

          const topc = notification?.body ? JSON.parse(notification?.body) : {};
          NotificationMsg.setNotification({
            text: `${topc.title}`,
            id: `push-${NotificationMsg.getNotifications.value.length + 1}`,
            date: new Date(),
            hasButton: !!topc?.ordem,
            ordem: topc?.ordem,
            bodyComplete: topc,
          });
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
      };

      const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
          permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
          throw new Error('User denied permissions!');
        }

        try {
          await PushNotifications.register();
        } catch (error) {
          alert(JSON.stringify(error));
        }
      };

      const getDeliveredNotifications = async () => {
        const notificationList = await PushNotifications.getDeliveredNotifications();
        console.log('delivered notifications', notificationList);
      };

      registerNotifications();
      addListeners();
      getDeliveredNotifications();
    } else {
      initAppGetToken();
    }
  },
  { deep: true },
);

function initAppGetToken() {
  const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
    measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
  };
  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app);
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
    }
  });
  onMessage(messaging, (payload) => {
    const topc = payload.notification?.body ? JSON.parse(payload.notification?.body) : {};
    NotificationMsg.setNotification({
      text: `${topc.title}`,
      id: `fcm-${NotificationMsg.getNotifications.value.length + 1}`,
      date: new Date(),
      hasButton: !!topc?.ordem,
      ordem: topc?.ordem,
      bodyComplete: topc,
    });
  });

  const vapidKey = process.env.VUE_APP_FIREBASE_VAPID_KEY;
  console.log('VAPIDKEY: ', vapidKey);
  getToken(messaging, {
    vapidKey: vapidKey,
  })
    .then(async (currentToken) => {
      if (currentToken) {
        // Send the token to your server and update the UI if necessary
        console.log('TOKEN is: ', currentToken);
        if (Authentication.getters.getUser.value.papelLista?.find((f) => f.recebeNotificacao) != undefined) {
          const data = await notificacoesInscrever({
            email: Authentication.getters.getUser.value.email,
            deviceToken: currentToken,
          });
          console.log(currentToken);
          console.warn('Retorno Register: ', data);
        }
      } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
}

function ResizeObserverCorrect() {
  const debounce = (callback: (...args: any[]) => void, delay: number) => {
    let tid: any;
    return function (...args: any[]) {
      const ctx = self;
      tid && clearTimeout(tid);
      tid = setTimeout(() => {
        callback.apply(ctx, args);
      }, delay);
    };
  };

  const _ = (window as any).ResizeObserver;
  (window as any).ResizeObserver = class ResizeObserver extends _ {
    constructor(callback: (...args: any[]) => void) {
      callback = debounce(callback, 20);
      super(callback);
    }
  };
}
const route = useRoute();
onMounted(() => {
  try {
    if (window.self !== window.top) {
      window.top.location = window.self.location;
    }
  } catch (error) {
    console.error(error);
  }

  ResizeObserverCorrect();
  actions.handleIsMobile();
  window.addEventListener('resize', actions.handleIsMobile);
  Authentication.actions.ActionCheckToken();
});

const title = computed(() => {
  return route?.meta?.title ? String(route.meta.title) : '';
});

const __returned__ = { paddingLeft, initAppGetToken, ResizeObserverCorrect, route, title, get OrganismsBar() { return OrganismsBar }, get OrganismsNavigation() { return OrganismsNavigation }, get AtomPreloader() { return AtomPreloader }, get Authentication() { return Authentication } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})