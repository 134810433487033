import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, withModifiers as _withModifiers, withKeys as _withKeys, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "area-form" }
const _hoisted_2 = { class: "text-sm text-center" }
const _hoisted_3 = {
  key: 0,
  class: "text-forgot cursor-pointer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("form", _hoisted_1, [
    _cache[5] || (_cache[5] = _createElementVNode("img", {
      src: "assets/GoConnect-logo.svg",
      class: "mb-12",
      alt: "logo"
    }, null, -1 /* HOISTED */)),
    _createVNode($setup["AtomTitle"], {
      title: !$setup.state.isMobile ? ' Faça seu login' : 'Bem vindo(a)!'
    }, null, 8 /* PROPS */, ["title"]),
    _createVNode($setup["AtomSubTitle"], {
      text: 
        !$setup.state.isMobile
          ? 'Insira seu email e senha para acessar o dashboard da GoConnect'
          : 'Preencha corretamente os campos abaixo, para acessar a plataforma GoConnect.'
      
    }, null, 8 /* PROPS */, ["text"]),
    _createVNode($setup["AtomInput"], {
      hasError: $setup.listError.includes('email'),
      label: !$setup.state.isMobile ? 'Email*' : 'E-mail',
      placeholder: !$setup.state.isMobile ? 'exemplo@email.com' : 'Digite seu usuário',
      prependIcon: "",
      modelValue: $setup.email,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.email) = $event)),
      class: "mb-5"
    }, {
      prependIcon: _withCtx(() => [
        (!$setup.state.isMobile)
          ? (_openBlock(), _createBlock($setup["MessageIcon"], {
              key: 0,
              color: $setup.listError.includes('email') ? 'var(--color-error)' : ''
            }, null, 8 /* PROPS */, ["color"]))
          : (_openBlock(), _createBlock($setup["ProfileIconLogin"], {
              key: 1,
              color: $setup.listError.includes('email') ? 'var(--color-error)' : ''
            }, null, 8 /* PROPS */, ["color"]))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["hasError", "label", "placeholder", "modelValue"]),
    _createVNode($setup["AtomInput"], {
      type: $setup.inputTypePassword,
      hasError: $setup.listError.includes('password'),
      appendIcon: "",
      prependIcon: "",
      noBgAppendIcon: "",
      onKeydown: _withKeys($setup.handleLogin, ["enter"]),
      label: !$setup.state.isMobile ? 'Senha*' : 'Senha',
      modelValue: $setup.password,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.password) = $event))
    }, {
      appendIcon: _withCtx(() => [
        _createVNode($setup["ShowIcon"], {
          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.inputTypePassword = $setup.inputTypePassword === 'password' ? 'text' : 'password'), ["prevent"])),
          color: $setup.listError.includes('password') ? 'var(--color-error)' : ''
        }, null, 8 /* PROPS */, ["color"])
      ]),
      prependIcon: _withCtx(() => [
        (!$setup.state.isMobile)
          ? (_openBlock(), _createBlock($setup["LockIcon"], { key: 0 }))
          : (_openBlock(), _createBlock($setup["lockLoginIcon"], { key: 1 }))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["type", "hasError", "label", "modelValue"]),
    _cache[6] || (_cache[6] = _createElementVNode("p", { class: "text-forgot text-sm pt-4" }, "Esqueci minha senha", -1 /* HOISTED */)),
    (!$setup.state.isMobile)
      ? (_openBlock(), _createBlock(_component_v_btn, {
          key: 0,
          onClick: $setup.handleLogin,
          variant: "flat",
          color: "primary",
          class: "w-100 my-5"
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Login ")
          ])),
          _: 1 /* STABLE */
        }))
      : (_openBlock(), _createBlock($setup["AtomButton"], {
          key: 1,
          center: "text-center",
          IconText: "",
          onClick: _withModifiers($setup.handleLogin, ["prevent"]),
          class: "mx-auto my-5 w-100 rounded-pill",
          text: 'Login'
        }, {
          IconText: _withCtx(() => [
            _createVNode($setup["LoginIcon"], { class: "pt-1" })
          ]),
          _: 1 /* STABLE */
        })),
    _createCommentVNode(" <p v-if=\"!state.isMobile\" :class=\"!state.isMobile ? 'text-sm' : 'text-sm text-center'\">\n      <span class=\"text-muted\">Não tem uma conta?</span>\n      <a class=\"text-forgot cursor-pointer\"> Crie agora</a>\n    </p> "),
    _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
    _createElementVNode("p", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("span", { class: "text-muted" }, "Versão: ", -1 /* HOISTED */)),
      (!$setup.state.isMobile)
        ? (_openBlock(), _createElementBlock("a", _hoisted_3, _toDisplayString(String($setup.version)), 1 /* TEXT */))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}