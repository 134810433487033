import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center my-5"
}
const _hoisted_2 = { class: "arrow-return" }
const _hoisted_3 = {
  key: 0,
  class: "border border-dashed pa-5 rounded my-6"
}
const _hoisted_4 = {
  key: 1,
  class: "area-btns mb-5 mt-10"
}
const _hoisted_5 = { class: "d-flex justify-space-between pa-5" }
const _hoisted_6 = { class: "num-destac badge mb-md-5 mb-5 ml-md-3" }
const _hoisted_7 = {
  class: "d-flex flex-column",
  style: {"gap":"25px"}
}
const _hoisted_8 = { class: "num-destac badge mb-md-5 mb-5 ml-md-3" }
const _hoisted_9 = { class: "num-destac badge mb-md-5 mb-5 ml-md-3" }
const _hoisted_10 = { class: "num-destac badge ml-3 mb-5" }
const _hoisted_11 = { class: "num-destac badge mb-md-5 mb-5 ml-md-3" }
const _hoisted_12 = { class: "num-destac badge mb-md-5 mb-5 ml-md-3" }
const _hoisted_13 = { class: "num-destac badge mb-md-5 mb-5 ml-md-3" }
const _hoisted_14 = { class: "d-flex flex-column flex-md-row justify-end px-2" }
const _hoisted_15 = { class: "num-destac badge ml-3 mb-5" }
const _hoisted_16 = { class: "num-destac badge mb-md-5 mb-5 my-5 ml-md-3" }
const _hoisted_17 = { class: "d-flex align-center" }
const _hoisted_18 = { class: "num-destac badge ml-3" }
const _hoisted_19 = { class: "num-destac badge mb-md-5 mb-5 ml-md-3" }
const _hoisted_20 = { class: "num-destac badge ml-3 mb-5" }
const _hoisted_21 = { class: "num-destac badge ml-3 mb-5" }
const _hoisted_22 = { class: "num-destac badge ml-3 mb-5" }
const _hoisted_23 = { class: "num-destac badge ml-3 mb-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeft = _resolveComponent("ArrowLeft")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode($setup["ArrowLeftMobileIcon"], {
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.router.go(-1))),
              class: "ms-3 mt-3 scale",
              color: "var(--primary-)"
            })
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("p", { class: "ms-2 font-600 text-primary text-lg" }, "Investigativas", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeTabs"], {
      modelValue: $setup.tab,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.tab) = $event)),
      tabs: $setup.tabs
    }, null, 8 /* PROPS */, ["modelValue", "tabs"]),
    _createVNode($setup["MoleculeTabWindow"], {
      modelValue: $setup.tab,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.tab) = $event)),
      class: _normalizeClass({ border: !$setup.state.isMobile })
    }, {
      default: _withCtx(() => [
        _createVNode($setup["MoleculeTabItem"], { value: "general" }, {
          default: _withCtx(() => [
            ($setup.state.isMobile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("p", {
                    class: "text-center text-sm",
                    style: _normalizeStyle({ color: $setup.diagnostico.color })
                  }, "Links Saudáveis", 4 /* STYLE */),
                  _cache[6] || (_cache[6] = _createElementVNode("ul", null, [
                    _createElementVNode("li", {
                      class: "text-xs",
                      style: {"color":"#697b8e"}
                    }, "Quando a qualidade e a disponibilidade estão acima de 50%.")
                  ], -1 /* HOISTED */))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(_component_v_card, null, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createElementVNode("div", {
                          class: "d-flex justify-items-center cursor-pointer",
                          onClick: $setup.handleReturnDash
                        }, [
                          _createVNode(_component_ArrowLeft),
                          _cache[7] || (_cache[7] = _createElementVNode("button", { class: "ps-2" }, [
                            _createTextVNode("Dashboard / "),
                            _createElementVNode("strong", null, "Investigativas")
                          ], -1 /* HOISTED */))
                        ]),
                        _createElementVNode("p", null, _toDisplayString($setup.getters.time.value), 1 /* TEXT */),
                        _createElementVNode("p", null, [
                          _createElementVNode("span", {
                            style: _normalizeStyle({ color: $setup.diagnostico.color })
                          }, _toDisplayString($setup.diagnostico.item), 5 /* TEXT, STYLE */),
                          _createTextVNode(" → " + _toDisplayString($setup.diagnostico.text), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  })
                ])),
            (!$setup.state.isMobile)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 2 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6",
                      class: "h-auto d-flex flex-column mt-1",
                      style: {"gap":"25px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["MoleculeTable"], {
                          headers: $setup.headersDetails,
                          items: $setup.diagnostico?.data?.listaDados || [],
                          pagination: false,
                          loading: $setup.diagnostico.loading,
                          "fixed-header": true,
                          scroolyn: true,
                          onRowClick: $setup.handleRowClick,
                          "item-select": $setup.investigandoDadoSelecionado,
                          "index-select": $setup.investigandoDadoSelecionado.id,
                          "selected-class": "bg-gray"
                        }, null, 8 /* PROPS */, ["headers", "items", "loading", "item-select", "index-select"]),
                        _createCommentVNode(" <OrganismsChart title=\"Caminho de Conectividade\" type=\"line\" :legend=\"false\" :data=\"caminhoConectividadeChatLine\"\n        is-calendar-select color-series=\"#FF5C0020\" :has-dropdown-list=\"true\" :options-drop-down=\"optionsList\"\n        :height=\"260\" text-empty=\"Selecione um Serviço/Link na tabela para traçar seu caminho de conectividade!\" /> "),
                        (!$setup.isPredict)
                          ? (_openBlock(), _createBlock($setup["OrganismsChartSaltos"], {
                              key: 0,
                              loading: false,
                              isSteps: "",
                              steps: $setup.steps,
                              "current-step": $setup.currentStep,
                              type: "column",
                              "text-x": "x",
                              "text-y": "y7",
                              style: {},
                              title: `Caminho de Conectividade${$setup.labelComplementar}`,
                              data: $setup.dataChar,
                              height: 260,
                              class: "h-100",
                              "text-empty": "Selecione um Serviço/Link na tabela para visualizar seu caminho de conectividade ao longo do tempo!",
                              "text-tooltip": 'Mostra o caminho, através de saltos, que os dados percorrem da origem ao destino. Ajuda a identificar possíveis gargalos ou pontos de falha na rede. Aqui são apresentados apenas os saltos efetuados com sucesso.'
                            }, {
                              bodySteps: _withCtx(() => [
                                _createCommentVNode(" <MoleculeCarrousel :items=\"items\" @next=\"handleNext\" @prev=\"handlePrev\" /> "),
                                _createVNode($setup["AtomProgressSaltos"], {
                                  steps: $setup.steps,
                                  "current-step": $setup.currentStep
                                }, null, 8 /* PROPS */, ["steps", "current-step"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["steps", "current-step", "title", "data"]))
                          : _createCommentVNode("v-if", true),
                        ($setup.isPredict)
                          ? (_openBlock(), _createBlock($setup["OrganismsChart"], {
                              key: 1,
                              title: `Latência${$setup.labelComplementar}`,
                              type: "line",
                              legend: false,
                              data: $setup.latenciaChatLine,
                              "color-series": "#FF5C0020",
                              "is-calendar-select": "",
                              height: 170,
                              "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua latência ao longo do tempo!",
                              "text-tooltip": 'Predição com amostragem em relação ao Latência de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas'
                            }, {
                              topfreeArea: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                                }, [
                                  _createVNode($setup["AtomSubTitle"], {
                                    class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                    text: "período de:"
                                  }, null, 8 /* PROPS */, ["class"]),
                                  _createElementVNode("p", _hoisted_6, [
                                    _createVNode($setup["AtomToogleButton"], {
                                      buttons: $setup.optionsListLatencia,
                                      onHandleToggle: $setup.handleLatenciaChange,
                                      select: $setup.toogleLatencia
                                    }, null, 8 /* PROPS */, ["buttons", "select"])
                                  ])
                                ], 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["title", "data"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_7, [
                          _createVNode($setup["OrganismsChart"], {
                            title: `Jitter${$setup.labelComplementar}`,
                            type: "line",
                            legend: false,
                            data: $setup.jitterChatLine,
                            "color-series": "#FF5C0020",
                            height: 170,
                            "text-empty": "Selecione um Serviço/Link na tabela para visualizar seu jitter ao longo do tempo!",
                            "is-calendar-select": "",
                            "text-tooltip": $setup.isPredict
                ? `Predição com amostragem em relação ao Jitter de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas`
                : 'Mede a variação no atraso entre os pacotes de dados. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
                
                          }, {
                            topfreeArea: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass($setup.state.isMobile
                  ? 'd-flex flex-column flex-md-row justify-start px-2'
                  : 'd-flex flex-column flex-md-row justify-end px-2'
                  )
                              }, [
                                _createVNode($setup["AtomSubTitle"], {
                                  class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                  text: "período de:"
                                }, null, 8 /* PROPS */, ["class"]),
                                _createElementVNode("p", _hoisted_8, [
                                  _createVNode($setup["AtomToogleButton"], {
                                    id: "jitter",
                                    width: "172px",
                                    buttons: $setup.optionsListJitter,
                                    select: $setup.toogleJitter,
                                    onHandleToggle: $setup.handleJiterChange
                                  }, null, 8 /* PROPS */, ["buttons", "select"]),
                                  _createCommentVNode(" ")
                                ])
                              ], 2 /* CLASS */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["title", "data", "text-tooltip"]),
                          (!$setup.isPredict)
                            ? (_openBlock(), _createBlock($setup["OrganismsChart"], {
                                key: 0,
                                title: `Latência${$setup.labelComplementar}`,
                                type: "line",
                                legend: false,
                                data: $setup.latenciaChatLine,
                                "color-series": "#FF5C0020",
                                "is-calendar-select": "",
                                height: 170,
                                "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua latência ao longo do tempo!",
                                "text-tooltip": 'Representa o tempo necessário para um pacote percorrer a rede. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
                              }, {
                                topfreeArea: _withCtx(() => [
                                  _createElementVNode("div", {
                                    class: _normalizeClass($setup.state.isMobile
                  ? 'd-flex flex-column flex-md-row justify-start px-2'
                  : 'd-flex flex-column flex-md-row justify-end px-2'
                  )
                                  }, [
                                    _createVNode($setup["AtomSubTitle"], {
                                      class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                      text: "período de:"
                                    }, null, 8 /* PROPS */, ["class"]),
                                    _createElementVNode("p", _hoisted_9, [
                                      _createVNode($setup["AtomToogleButton"], {
                                        buttons: $setup.optionsListLatencia,
                                        onHandleToggle: $setup.handleLatenciaChange,
                                        select: $setup.toogleLatencia
                                      }, null, 8 /* PROPS */, ["buttons", "select"])
                                    ])
                                  ], 2 /* CLASS */)
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["title", "data"]))
                            : _createCommentVNode("v-if", true),
                          _createVNode($setup["OrganismsChart"], {
                            title: `Perda de Pacote${$setup.labelComplementar}`,
                            type: "line",
                            legend: false,
                            data: $setup.perdaPacoteChatLine,
                            "color-series": "#FF5C0020",
                            height: 170,
                            "is-calendar-select": "",
                            "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua perda de pacote ao longo do tempo!",
                            "text-tooltip": $setup.isPredict
                ? `Predição com amostragem em relação a Perda de Pacotes de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas.`
                : 'Indica a porcentagem de pacotes perdidos durante a transmissão. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
                
                          }, {
                            topfreeArea: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass($setup.state.isMobile
                  ? 'd-flex flex-column flex-md-row justify-start px-2'
                  : 'd-flex flex-column flex-md-row justify-end px-2'
                  )
                              }, [
                                _createVNode($setup["AtomSubTitle"], {
                                  class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                  text: "período de:"
                                }, null, 8 /* PROPS */, ["class"]),
                                _createElementVNode("p", _hoisted_10, [
                                  _createVNode($setup["AtomToogleButton"], {
                                    buttons: $setup.optionsListPerda,
                                    onHandleToggle: $setup.handlePerdaPacoteChange,
                                    select: $setup.tooglePerdaPacote
                                  }, null, 8 /* PROPS */, ["buttons", "select"]),
                                  _createCommentVNode("  ")
                                ])
                              ], 2 /* CLASS */)
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["title", "data", "text-tooltip"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            ($setup.state.isMobile)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 3 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      md: "6",
                      class: "h-auto d-flex flex-column mt-1",
                      style: {"gap":"25px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["MoleculeTable"], {
                          headers: $setup.headersDetails,
                          items: $setup.diagnostico?.data?.listaDados || [],
                          pagination: false,
                          loading: $setup.diagnostico.loading,
                          "fixed-header": true,
                          scroolyn: true,
                          onItemClick: $setup.handleRowClick
                        }, {
                          "content-action": _withCtx(({ props }) => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  class: "text-center"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["AtomButton"], {
                                      center: "text-center",
                                      onClick: _withModifiers(($event: any) => ($setup.handleRowClick(props)), ["stop"]),
                                      class: "mx-auto w-100 rounded-pill",
                                      text: 'Visualizar'
                                    }, null, 8 /* PROPS */, ["onClick"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["headers", "items", "loading"]),
                        _createCommentVNode(" <OrganismsChart title=\"Caminho de Conectividade\" type=\"line\" :legend=\"false\" :data=\"caminhoConectividadeChatLine\"\n        is-calendar-select color-series=\"#FF5C0020\" :has-dropdown-list=\"true\" :options-drop-down=\"optionsList\"\n        :height=\"260\" text-empty=\"Selecione um Serviço/Link na tabela para traçar seu caminho de conectividade!\" /> ")
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        (!$setup.isPredict)
                          ? (_openBlock(), _createBlock($setup["OrganismsChartSaltos"], {
                              key: 0,
                              loading: false,
                              isSteps: "",
                              steps: $setup.steps,
                              "current-step": $setup.currentStep,
                              type: "column",
                              "text-x": "x",
                              "text-y": "y7",
                              style: {},
                              title: `Caminho de Conectividade${$setup.labelComplementar}`,
                              data: $setup.dataChar,
                              height: 260,
                              class: "h-100",
                              "text-empty": "Selecione um Serviço/Link na tabela para visualizar seu caminho de conectividade ao longo do tempo!",
                              "text-tooltip": 'Mostra o caminho, através de saltos, que os dados percorrem da origem ao destino. Ajuda a identificar possíveis gargalos ou pontos de falha na rede. Aqui são apresentados apenas os saltos efetuados com sucesso'
                            }, {
                              bodySteps: _withCtx(() => [
                                _createCommentVNode(" <MoleculeCarrousel :items=\"items\" @next=\"handleNext\" @prev=\"handlePrev\" /> "),
                                _createVNode($setup["AtomProgressSaltos"], {
                                  steps: $setup.steps,
                                  "current-step": $setup.currentStep
                                }, null, 8 /* PROPS */, ["steps", "current-step"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["steps", "current-step", "title", "data"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        ($setup.isPredict)
                          ? (_openBlock(), _createBlock($setup["OrganismsChart"], {
                              key: 0,
                              title: `Latência${$setup.labelComplementar}`,
                              type: "line",
                              legend: false,
                              data: $setup.latenciaChatLine,
                              "color-series": "#FF5C0020",
                              "is-calendar-select": "",
                              height: 170,
                              "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua latência ao longo do tempo!",
                              "text-tooltip": 'Predição com amostragem em relação ao Latência de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas'
                            }, {
                              topfreeArea: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                                }, [
                                  _createVNode($setup["AtomSubTitle"], {
                                    class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                    text: "período de:"
                                  }, null, 8 /* PROPS */, ["class"]),
                                  _createElementVNode("p", _hoisted_11, [
                                    _createVNode($setup["AtomToogleButton"], {
                                      buttons: $setup.optionsListLatencia,
                                      onHandleToggle: $setup.handleLatenciaChange,
                                      select: $setup.toogleLatencia
                                    }, null, 8 /* PROPS */, ["buttons", "select"])
                                  ])
                                ], 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["title", "data"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["OrganismsChart"], {
                          title: `Jitter${$setup.labelComplementar}`,
                          type: "line",
                          legend: false,
                          data: $setup.jitterChatLine,
                          "color-series": "#FF5C0020",
                          height: 170,
                          "text-empty": "Selecione um Serviço/Link na tabela para visualizar seu jitter ao longo do tempo!",
                          "is-calendar-select": "",
                          "text-tooltip": $setup.isPredict
              ? `Predição com amostragem em relação ao Jitter de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas.`
              : 'Mede a variação no atraso entre os pacotes de dados. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
              
                        }, {
                          topfreeArea: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                            }, [
                              _createVNode($setup["AtomSubTitle"], {
                                class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                text: "período de:"
                              }, null, 8 /* PROPS */, ["class"]),
                              _createElementVNode("p", _hoisted_12, [
                                _createVNode($setup["AtomToogleButton"], {
                                  buttons: $setup.optionsListJitter,
                                  onHandleToggle: $setup.handleJiterChange
                                }, null, 8 /* PROPS */, ["buttons"])
                              ])
                            ], 2 /* CLASS */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["title", "data", "text-tooltip"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        (!$setup.isPredict)
                          ? (_openBlock(), _createBlock($setup["OrganismsChart"], {
                              key: 0,
                              title: `Latência${$setup.labelComplementar}`,
                              type: "line",
                              legend: false,
                              data: $setup.latenciaChatLine,
                              "color-series": "#FF5C0020",
                              "is-calendar-select": "",
                              height: 170,
                              "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua latência ao longo do tempo!",
                              "text-tooltip": 'Representa o tempo necessário para um pacote percorrer a rede. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
                            }, {
                              topfreeArea: _withCtx(() => [
                                _createElementVNode("div", {
                                  class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                                }, [
                                  _createVNode($setup["AtomSubTitle"], {
                                    class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                    text: "período de:"
                                  }, null, 8 /* PROPS */, ["class"]),
                                  _createElementVNode("p", _hoisted_13, [
                                    _createVNode($setup["AtomToogleButton"], {
                                      buttons: $setup.optionsListLatencia,
                                      onHandleToggle: $setup.handleLatenciaChange
                                    }, null, 8 /* PROPS */, ["buttons"])
                                  ])
                                ], 2 /* CLASS */)
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["title", "data"]))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createVNode($setup["OrganismsChart"], {
                          title: `Perda de Pacote${$setup.labelComplementar}`,
                          type: "line",
                          legend: false,
                          data: $setup.perdaPacoteChatLine,
                          "color-series": "#FF5C0020",
                          height: 170,
                          "is-calendar-select": "",
                          "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua perda de pacote ao longo do tempo!"
                        }, {
                          topfreeArea: _withCtx(() => [
                            _createElementVNode("div", _hoisted_14, [
                              _createVNode($setup["AtomSubTitle"], {
                                class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                text: "período de:"
                              }, null, 8 /* PROPS */, ["class"]),
                              _createElementVNode("p", _hoisted_15, [
                                _createVNode($setup["AtomToogleButton"], {
                                  buttons: $setup.optionsListPerda,
                                  onHandleToggle: $setup.handlePerdaPacoteChange
                                }, null, 8 /* PROPS */, ["buttons"])
                              ])
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["title", "data"])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["MoleculeTabItem"], { value: "analitics" }, {
          default: _withCtx(() => [
            ($setup.isPredict)
              ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { cols: "12" }, {
                      default: _withCtx(() => [
                        _createElementVNode("p", _hoisted_16, [
                          _createVNode($setup["AtomToogleButton"], {
                            buttons: $setup.optionsGeral,
                            onHandleToggle: $setup.handleGerALChangeAnalitico,
                            select: $setup.toogleGeralAnalitico
                          }, null, 8 /* PROPS */, ["buttons", "select"])
                        ])
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode($setup["MoleculeTable"], {
              onChangePerPage: _cache[2] || (_cache[2] = (ev) => ($setup.perPage = ev)),
              onChangePage: _cache[3] || (_cache[3] = (ev) => ($setup.page = ev)),
              headers: $setup.headersAnalitisc,
              "item-select": $setup.investigandoDadoSelecionadoAnalitico,
              "index-select": $setup.investigandoDadoSelecionadoAnalitico.id,
              items: $setup.analistsDataComputed,
              pagination: true,
              loading: $setup.diagnostico.loading,
              "fixed-header": true,
              scroolyn: true,
              totalItems: $setup.analitsData.length,
              perPage: $setup.perPage,
              page: $setup.page,
              onRowClick: $setup.handleRowClickAnalitico,
              "selected-class": "bg-gray"
            }, {
              "content-action": _withCtx(({ props }) => [
                _createVNode(_component_v_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, {
                      cols: "12",
                      class: "text-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomButton"], {
                          center: "text-center",
                          onClick: _withModifiers(($event: any) => ($setup.handleRowClickAnalitico(props)), ["stop"]),
                          class: "mx-auto w-100 rounded-pill",
                          text: 'Visualizar'
                        }, null, 8 /* PROPS */, ["onClick"])
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["headers", "item-select", "index-select", "items", "loading", "totalItems", "perPage", "page"]),
            _createVNode(_component_v_row, { class: "my-5 mx-md-15" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { "no-gutters": "" }, {
                      default: _withCtx(() => [
                        (!$setup.isPredict)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "12"
                            }, {
                              default: _withCtx(() => [
                                _createVNode($setup["OrganismsChartSaltos"], {
                                  loading: false,
                                  isSteps: "",
                                  steps: $setup.stepsAnalitco,
                                  "current-step": $setup.currentStepAnalitico,
                                  type: "column",
                                  "text-x": "x",
                                  "text-y": "y7",
                                  style: {},
                                  title: `Caminho de Conectividade${$setup.labelComplementarAnalitico}`,
                                  data: $setup.dataCharAnalitico,
                                  height: 260,
                                  class: "h-100",
                                  "text-empty": "Selecione um Serviço/Link na tabela para visualizar seu caminho de conectividade ao longo do tempo!",
                                  "text-tooltip": 'Mostra o caminho, através de saltos, que os dados percorrem da origem ao destino. Ajuda a identificar possíveis gargalos ou pontos de falha na rede. Aqui são apresentados apenas os saltos efetuados com sucesso.'
                                }, {
                                  bodySteps: _withCtx(() => [
                                    _createCommentVNode(" <MoleculeCarrousel :items=\"items\" @next=\"handleNext\" @prev=\"handlePrev\" /> "),
                                    _createVNode($setup["AtomProgressSaltos"], {
                                      steps: $setup.stepsAnalitco,
                                      "current-step": $setup.currentStepAnalitico
                                    }, null, 8 /* PROPS */, ["steps", "current-step"])
                                  ]),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["steps", "current-step", "title", "data"])
                              ]),
                              _: 1 /* STABLE */
                            }))
                          : _createCommentVNode("v-if", true),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          "align-self": "center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_v_row, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_v_col, {
                                  cols: "12",
                                  class: "my-3",
                                  if: "!isPredict"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["OrganismsChart"], {
                                      loadingColor: $setup.activeColor,
                                      title: "Score de Conectividade",
                                      type: "line",
                                      legend: false,
                                      data: $setup.pontosPorHoraArray,
                                      "color-series": "#FF5C00",
                                      height: 166,
                                      "has-dropdown-list": false,
                                      "text-tooltip": "Com este gráfico você consegue acompanhar a evolução da funcionalidade de sua conexão em relação ao dado anterior."
                                    }, {
                                      topfreeArea: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_17, [
                                          _createVNode($setup["AtomSubTitle"], {
                                            "no-margin": "",
                                            text: "Pontos por hora"
                                          }),
                                          _createElementVNode("p", _hoisted_18, _toDisplayString($setup.pontosPorHora), 1 /* TEXT */)
                                        ])
                                      ]),
                                      _: 1 /* STABLE */
                                    }, 8 /* PROPS */, ["loadingColor", "data"])
                                  ]),
                                  _: 1 /* STABLE */
                                }),
                                _createVNode(_component_v_col, { cols: "12" }, {
                                  default: _withCtx(() => [
                                    _createVNode($setup["OrganismsChart"], {
                                      title: `Latência${$setup.labelComplementarAnalitico}`,
                                      "text-x": "x",
                                      "text-y": "y7",
                                      type: "line",
                                      legend: false,
                                      data: $setup.latenciaChatLineAnalitico,
                                      "color-series": "#FF5C0020",
                                      "is-calendar-select": "",
                                      height: 200,
                                      "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua latência ao longo do tempo!",
                                      "text-tooltip": 'Representa o tempo necessário para um pacote percorrer a rede. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
                                    }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                                      (!$setup.isPredict)
                                        ? {
                                            name: "topfreeArea",
                                            fn: _withCtx(() => [
                                              _createElementVNode("div", {
                                                class: _normalizeClass($setup.state.isMobile
                        ? 'd-flex flex-column flex-md-row justify-start px-2'
                        : 'd-flex flex-column flex-md-row justify-end px-2'
                        )
                                              }, [
                                                _createVNode($setup["AtomSubTitle"], {
                                                  class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                                  text: "período de:"
                                                }, null, 8 /* PROPS */, ["class"]),
                                                _createElementVNode("p", _hoisted_19, [
                                                  _createVNode($setup["AtomToogleButton"], {
                                                    buttons: $setup.optionsListLatencia,
                                                    onHandleToggle: $setup.handleLatenciaChangeAnalitico,
                                                    select: $setup.toogleLatenciaAnalitico
                                                  }, null, 8 /* PROPS */, ["buttons", "select"])
                                                ])
                                              ], 2 /* CLASS */)
                                            ]),
                                            key: "0"
                                          }
                                        : undefined
                                    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "data"])
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ]),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["OrganismsChart"], {
                      title: `Jitter${$setup.labelComplementarAnalitico}`,
                      type: "line",
                      legend: false,
                      data: $setup.jitterChatLineAnalitico,
                      "color-series": "#FF5C0020",
                      height: 200,
                      "is-calendar-select": "",
                      "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua perda de pacote ao longo do tempo!",
                      "text-tooltip": $setup.isPredict
              ? `Predição com amostragem em relação a Perda de Pacotes de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas.`
              : 'Indica a porcentagem de pacotes perdidos durante a transmissão. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
              
                    }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                      (!$setup.isPredict)
                        ? {
                            name: "topfreeArea",
                            fn: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                              }, [
                                _createVNode($setup["AtomSubTitle"], {
                                  class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                  text: "período de:"
                                }, null, 8 /* PROPS */, ["class"]),
                                _createElementVNode("p", _hoisted_20, [
                                  _createVNode($setup["AtomToogleButton"], {
                                    buttons: $setup.optionsListJitter,
                                    onHandleToggle: $setup.handleJiterChangeAnalitico,
                                    select: $setup.toogleJitterAnalitico
                                  }, null, 8 /* PROPS */, ["buttons", "select"])
                                ])
                              ], 2 /* CLASS */)
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "data", "text-tooltip"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["OrganismsChart"], {
                      title: `Perda de Pacote${$setup.labelComplementarAnalitico}`,
                      type: "line",
                      legend: false,
                      data: $setup.perdaPacoteChatLineAnalitico,
                      "color-series": "#FF5C0020",
                      height: 200,
                      "is-calendar-select": "",
                      "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua perda de pacote ao longo do tempo!",
                      "text-tooltip": $setup.isPredict
              ? `Predição com amostragem em relação a Perda de Pacotes de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas.`
              : 'Indica a porcentagem de pacotes perdidos durante a transmissão. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
              
                    }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                      (!$setup.isPredict)
                        ? {
                            name: "topfreeArea",
                            fn: _withCtx(() => [
                              _createElementVNode("div", {
                                class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                              }, [
                                _createVNode($setup["AtomSubTitle"], {
                                  class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                  text: "período de:"
                                }, null, 8 /* PROPS */, ["class"]),
                                _createElementVNode("p", _hoisted_21, [
                                  _createVNode($setup["AtomToogleButton"], {
                                    buttons: $setup.optionsListPerda,
                                    onHandleToggle: $setup.handlePerdaPacoteChangeAnalitico,
                                    select: $setup.tooglePerdaPacoteAnalitico
                                  }, null, 8 /* PROPS */, ["buttons", "select"]),
                                  _createCommentVNode("  ")
                                ])
                              ], 2 /* CLASS */)
                            ]),
                            key: "0"
                          }
                        : undefined
                    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["title", "data", "text-tooltip"])
                  ]),
                  _: 1 /* STABLE */
                }),
                (!$setup.isPredict)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      cols: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["OrganismsChart"], {
                          title: `Consumo de Internet Download x Upload ${$setup.labelComplementar}`,
                          type: "line",
                          legend: false,
                          data: $setup.consumoInternetArray,
                          "color-series": "#FF5C0020",
                          height: 200,
                          "is-calendar-select": "",
                          "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua perda de pacote ao longo do tempo!",
                          "text-tooltip": $setup.isPredict
              ? `Predição com amostragem em relação a Perda de Pacotes de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas.`
              : 'Indica a porcentagem de pacotes perdidos durante a transmissão. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
              
                        }, {
                          topfreeArea: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                            }, [
                              _createVNode($setup["AtomSubTitle"], {
                                class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                text: "período de:"
                              }, null, 8 /* PROPS */, ["class"]),
                              _createElementVNode("p", _hoisted_22, [
                                _createVNode($setup["AtomToogleButton"], {
                                  buttons: $setup.options,
                                  onHandleToggle: $setup.handleConsumoInternet,
                                  select: $setup.toogleConsumoInternet
                                }, null, 8 /* PROPS */, ["buttons", "select"]),
                                _createCommentVNode("  ")
                              ])
                            ], 2 /* CLASS */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["title", "data", "text-tooltip"])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                (!$setup.isPredict)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 1,
                      cols: "12"
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["OrganismsChart"], {
                          title: `Estado da CPU ${$setup.labelComplementar}`,
                          type: "line",
                          legend: false,
                          data: $setup.cpuAnalitico,
                          "color-series": "#FF5C0020",
                          height: 200,
                          "is-calendar-select": "",
                          "text-empty": "Selecione um Serviço/Link na tabela para visualizar sua perda de pacote ao longo do tempo!",
                          "text-tooltip": $setup.isPredict
              ? `Predição com amostragem em relação a Perda de Pacotes de links e/ou serviços para os próximos períodos de: +30 minutos, +1 horas e +3 horas.`
              : 'Indica a porcentagem de pacotes perdidos durante a transmissão. Amostragem a cada: 30 Minutos: Fornece uma visão recente da qualidade da conexão. 3 Horas: Oferece uma análise mais detalhada do desempenho ao longo do tempo e 8 Horas: Permite avaliar a estabilidade da conexão em um período mais longo.'
              
                        }, {
                          topfreeArea: _withCtx(() => [
                            _createElementVNode("div", {
                              class: _normalizeClass($setup.state.isMobile
                ? 'd-flex flex-column flex-md-row justify-start px-2'
                : 'd-flex flex-column flex-md-row justify-end px-2'
                )
                            }, [
                              _createVNode($setup["AtomSubTitle"], {
                                class: _normalizeClass($setup.state.isMobile ? 'mx-auto' : 'pt-5'),
                                text: "período de:"
                              }, null, 8 /* PROPS */, ["class"]),
                              _createElementVNode("p", _hoisted_23, [
                                _createVNode($setup["AtomToogleButton"], {
                                  buttons: $setup.options,
                                  onHandleToggle: $setup.handleCpuChangeAnalitico,
                                  select: $setup.toogleCpu
                                }, null, 8 /* PROPS */, ["buttons", "select"]),
                                _createCommentVNode("  ")
                              ])
                            ], 2 /* CLASS */)
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["title", "data", "text-tooltip"])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "class"])
  ], 64 /* STABLE_FRAGMENT */))
}