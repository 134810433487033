import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "pagination-container",
  "aria-label": "row pagination"
}
const _hoisted_2 = {
  key: 0,
  class: "pagination"
}
const _hoisted_3 = ["aria-label", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      ($setup.numberOfPages >= 1)
        ? (_openBlock(), _createElementBlock("ul", _hoisted_2, [
            _createElementVNode("li", {
              class: _normalizeClass(["navigation1", {
          disabled: $setup.currentPage === 1,
        }]),
              "aria-label": "go to previous page",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.previous()))
            }, [
              _createVNode($setup["BtnRightIcon"], { class: "prev" })
            ], 2 /* CLASS */),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.pages, (index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: index,
                "aria-label": 'go to page ' + index,
                class: "page-item",
                onClick: ($event: any) => ($setup.setCurrentPage(index))
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["page-link", {
            'active-page': $setup.currentPage === index,
          }])
                }, _toDisplayString(index), 3 /* TEXT, CLASS */)
              ], 8 /* PROPS */, _hoisted_3))
            }), 128 /* KEYED_FRAGMENT */)),
            _createElementVNode("li", {
              class: _normalizeClass(["navigation", {
          disabled: $setup.currentPage === $setup.numberOfPages || !$setup.numberOfPages,
        }]),
              "aria-label": "go to next page",
              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.next()))
            }, [
              _createVNode($setup["BtnRightIcon"])
            ], 2 /* CLASS */)
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}