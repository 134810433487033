import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "text-primary number-notification"
}
const _hoisted_2 = { class: "bar-l" }
const _hoisted_3 = { class: "text-sm mt-2" }
const _hoisted_4 = { class: "text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list_subheader = _resolveComponent("v-list-subheader")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_menu = _resolveComponent("v-menu")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_v_menu, {
      modelValue: $setup.showNotification,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.showNotification) = $event)),
      "close-on-content-click": false,
      "max-width": "300",
      "max-height": "400",
      class: "notification"
    }, {
      activator: _withCtx(({ props }) => [
        _createVNode(_component_v_btn, _mergeProps({ variant: "text" }, props, {
          icon: "mdi-filter",
          class: "btn-notification position-relative ms-6 me-8"
        }), {
          default: _withCtx(() => [
            _createVNode(_component_v_tooltip, {
              activator: "parent",
              location: "bottom"
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode("Notificações")
              ])),
              _: 1 /* STABLE */
            }),
            ($setup.state.isMobile)
              ? (_openBlock(), _createBlock($setup["BellIcon"], {
                  key: 0,
                  color: "var(--primary-nav)"
                }))
              : _createCommentVNode("v-if", true),
            (!$setup.state.isMobile)
              ? (_openBlock(), _createBlock($setup["NotificationIcon"], {
                  key: 1,
                  color: "var(--primary-nav)"
                }))
              : _createCommentVNode("v-if", true),
            ($setup.Notification.getNumberNotifications.value > 0)
              ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($setup.Notification.getNumberNotifications.value), 1 /* TEXT */))
              : _createCommentVNode("v-if", true)
          ]),
          _: 2 /* DYNAMIC */
        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_v_list, { width: "400" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_list_subheader, { class: "w-100" }, {
              default: _withCtx(() => [
                _cache[8] || (_cache[8] = _createElementVNode("span", { class: "me-3" }, " Notificações ", -1 /* HOISTED */)),
                _createCommentVNode(" <AtomButton btnNotify center=\"text-center\" text=\"Ver mais\"  class=\"h-75\" @click=\"showModalnotification = true\" /> "),
                _createVNode(_component_v_tooltip, {
                  text: !$setup.Notification.getMutedNotification.value ? 'Silenciar notificações' : 'Permitir som de notificações'
                }, {
                  activator: _withCtx(({ props }) => [
                    (!$setup.Notification.getMutedNotification.value)
                      ? (_openBlock(), _createBlock(_component_v_icon, _mergeProps({
                          key: 0,
                          class: "border pa-3"
                        }, props, {
                          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.Notification.setMuted(true)))
                        }), {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createTextVNode("mdi-volume-mute")
                          ])),
                          _: 2 /* DYNAMIC */
                        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */))
                      : (_openBlock(), _createBlock(_component_v_icon, _mergeProps({
                          key: 1,
                          class: "border"
                        }, props, {
                          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.Notification.setMuted(false)))
                        }), {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createTextVNode("mdi-volume-high")
                          ])),
                          _: 2 /* DYNAMIC */
                        }, 1040 /* FULL_PROPS, DYNAMIC_SLOTS */))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["text"])
              ]),
              _: 1 /* STABLE */
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.Notification.notifications.value, (notify) => {
              return (_openBlock(), _createElementBlock(_Fragment, {
                key: notify.id
              }, [
                _createVNode(_component_v_list_item, { class: "pointer" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, { cols: "2" }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_2, [
                              _createVNode($setup["DangerTriangleFullIcon"], { class: "m-extra" })
                            ])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_v_col, {
                          cols: notify.hasButton ? '6' : '10'
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("p", _hoisted_3, _toDisplayString(notify.text), 1 /* TEXT */),
                            _createElementVNode("p", _hoisted_4, _toDisplayString($setup.format(notify.date, 'dd/MM/yyyy HH:mm:ss')), 1 /* TEXT */)
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["cols"]),
                        (notify.hasButton)
                          ? (_openBlock(), _createBlock(_component_v_col, {
                              key: 0,
                              cols: "4"
                            }, {
                              default: _withCtx(() => [
                                _createVNode($setup["AtomButton"], {
                                  btnNotify: "",
                                  center: "text-center",
                                  text: "Ver mais",
                                  class: "h-75",
                                  onClick: ($event: any) => ($setup.handleClick(notify))
                                }, null, 8 /* PROPS */, ["onClick"])
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1024 /* DYNAMIC_SLOTS */))
                          : _createCommentVNode("v-if", true)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */),
                ($setup.Notification.notifications.value.length > 1)
                  ? (_openBlock(), _createBlock(_component_v_divider, { key: 0 }))
                  : _createCommentVNode("v-if", true)
              ], 64 /* STABLE_FRAGMENT */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue"]),
    _createVNode($setup["MoleculeModal"], {
      "padding-y": "32px",
      width: "850px",
      height: "auto",
      title: "Teste",
      showHeader: false,
      hideFooter: true,
      hideHeader: true,
      persistent: false,
      show: $setup.showModalnotification,
      "btn-close-text": "Cancelar",
      onHandleCancel: _cache[4] || (_cache[4] = ($event: any) => ($setup.showModalnotification = false))
    }, {
      body: _withCtx(() => [
        _createVNode($setup["NotificationModal"], {
          typeNotify: $setup.typeNotify,
          dados: $setup.notifyAlert,
          onCloseModal: _cache[3] || (_cache[3] = ($event: any) => ($setup.showModalnotification = !$setup.showModalnotification))
        }, null, 8 /* PROPS */, ["typeNotify", "dados"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}