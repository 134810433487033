import { defineComponent as _defineComponent } from 'vue'
import { watch, computed, ref } from 'vue';
import { MoleculeCard } from '@/components/molecules';
import { AtomToogleButton, AtomSelect } from '@/components/atoms';
import VueHighcharts from 'vue3-highcharts';
import HighCharts from 'highcharts';
import MapCharts from 'highcharts/modules/map';
import brMap from '@highcharts/map-collection/countries/br/br-all.geo.json';
import chMap from '@highcharts/map-collection/countries/cn/cn-all.geo.json';
import usMap from '@highcharts/map-collection/countries/us/us-all.geo.json';
import { IDataMapChart, IOptionsSelect, IToggleButon } from '@/types';
import { MapboxMap, MapboxMarker } from '@studiometa/vue-mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

interface IProps {
  title: string;
  legend: boolean;
  data: Array<IDataMapChart>;
  name?: string;
  colorHover?: string;
  colorLabel?: string;
  height?: number;
  hasToggle?: boolean;
  buttons?: IToggleButon[];
  hasDropdownList?: boolean;
  optionsDropDown?: IOptionsSelect[];
  loading?: boolean;
  textTooltip?: string;
  loadingColor?: string;
  optionMaps?: boolean;
  content?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsMapCharts',
  props: {
    title: { type: String, required: true, default: '' },
    legend: { type: Boolean, required: true, default: false },
    data: { type: Array, required: true },
    name: { type: String, required: false },
    colorHover: { type: String, required: false },
    colorLabel: { type: String, required: false },
    height: { type: Number, required: false },
    hasToggle: { type: Boolean, required: false },
    buttons: { type: Array, required: false },
    hasDropdownList: { type: Boolean, required: false },
    optionsDropDown: { type: Array, required: false },
    loading: { type: Boolean, required: false, default: false },
    textTooltip: { type: String, required: false },
    loadingColor: { type: String, required: false },
    optionMaps: { type: Boolean, required: false, default: false },
    content: { type: Boolean, required: false }
  },
  emits: ['handleToggle', 'onChangeDropDownList'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

MapCharts(HighCharts);
const keyMapBox = computed(() => {
  return process.env.VUE_APP_KEY_MAP_BOX;
});

const countrySelected = ref<any>();
const citySelected = ref<any>();
const municipeSelected = ref<any>();
const City = computed(() => {
  const lista = [];
  props.data.map((f) => {
    lista.push(f.uf);
  });
  return lista;
});
const Country = computed(() => {
  const lista = ['China', 'Brasil', 'Estados Unidos'];
  return lista;
});

const Municipe = computed(() => {
  const lista = [];
  props.data.map((f) => {
    lista.push(f.uf == citySelected.value ? f.cidade : 'Selecione um estado');
  });
  return lista;
});

const latitude = ref<string>();
const longitude = ref<string>();

function changeCity(loc) {
  latitude.value = loc[1];
  longitude.value = loc[2];
}

const mapCenter = computed(() => {
  return props.data.length && !latitude.value && !longitude.value
    ? [props.data[0].lat, props.data[0].lng]
    : props.data.length && latitude.value && longitude.value
      ? [latitude, longitude]
      : [];
});

const props = __props;
const emit = __emit;
let values = props.data.map((f) => {
  return [`br-${f.uf.toLowerCase()}`, f.value];
});

let chartOptions = {};
changeChartOptions();
function changeChartOptions() {
  values = props.data.map((f) => {
    return [`br-${f.uf.toLowerCase()}`, f.value];
  });
  chartOptions = {
    chart: {
      map: countrySelected.value == 'China' ? chMap : countrySelected.value == 'Estados Unidos' ? usMap: brMap,
      legend: true,
      height: props.height,
    },
    legend: {
      enabled: props.legend,
    },

    title: {
      text: '',
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: 'bottom',
      },
    },

    series: [
      {
        name: props.name,
        data: [...values],
        color: props.colorLabel,
        states: {
          hover: props.colorHover
            ? {
                color: props.colorHover,
              }
            : undefined,
        },
        dataLabels: {
          enabled: true,
          color: props.colorLabel,
          // format: '',
        },
      },
    ],
  };
}
function handleToogleChange($event) {
  emit('handleToggle', $event);
}
function handleChangeDropdownList(event) {
  emit('onChangeDropDownList', event);
}

watch(
  () => props.data,
  () => {
    changeChartOptions();
  },
);
watch(
  () => countrySelected.value ,
  () => {
    changeChartOptions();
  },
);

const __returned__ = { keyMapBox, countrySelected, citySelected, municipeSelected, City, Country, Municipe, latitude, longitude, changeCity, mapCenter, props, emit, get values() { return values }, set values(v) { values = v }, get chartOptions() { return chartOptions }, set chartOptions(v) { chartOptions = v }, changeChartOptions, handleToogleChange, handleChangeDropdownList, get MoleculeCard() { return MoleculeCard }, get AtomToogleButton() { return AtomToogleButton }, get AtomSelect() { return AtomSelect }, get VueHighcharts() { return VueHighcharts }, get MapboxMap() { return MapboxMap }, get MapboxMarker() { return MapboxMarker } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})