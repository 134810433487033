import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted } from 'vue';
import { state } from '@/store/modules/layout';

interface IToggleButton {
  text: string;
  value: string;
  textColor?: string;
  bgColor?: string;
}

interface IProps {
  buttons: Array<IToggleButton>;
  select?: string;
  width?: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomToogleButton',
  props: {
    buttons: { type: Array, required: true },
    select: { type: String, required: false },
    width: { type: String, required: false, default: 'auto' }
  },
  emits: ['handleToggle'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;
const toggleBtn = ref('');

watch(
  () => toggleBtn.value,
  () => {
    if (toggleBtn.value !== props.select) {
      emit('handleToggle', toggleBtn.value);
    }
  },
);

watch(
  () => props.select,
  () => {
    if (props.select && toggleBtn.value !== props.select) {
      toggleBtn.value = props.select;
    }
  },
);

onMounted(() => {
  toggleBtn.value = props.select || props.buttons[0]?.value || '';
});

const __returned__ = { props, emit, toggleBtn, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})