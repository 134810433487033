import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { computed, ref, useAttrs } from 'vue';
import omit from '@/utils/omit';
import { state } from '@/store/modules/layout';
import { DangerIcon, DeletexIconCircle } from '@/components/atoms';
import { InputType } from '@/types';

// const { calcFieldSize } = useField()


const __default__ = {
  inheritAttrs: false,
};

interface InputProps {
  modelValue?: any;
  placeholder?: string;
  width?: string;
  bgIcon?: string;
  mask?: string | string[] | any;
  list?: string;
  label?: string;
  msgError?: string;
  type?: InputType;
  maxLength?: number | string;
  minLength?: number | string;
  max?: number | string;
  min?: number | string;
  borderColor?: string;
  height?: string | number;
  filled?: boolean;
  simple?: boolean;
  selectionOnFocus?: boolean;
  noStyle?: boolean;
  prependIcon?: boolean;
  appendIcon?: boolean;
  noBgPrependIcon?: boolean;
  noBorderPrependIcon?: boolean;
  noBgAppendIcon?: boolean;
  disabled?: boolean;
  required?: boolean;
  hasError?: boolean;
  wFull?: boolean;
  borderBottom?: boolean;
  nullable?: boolean;
  trim?: boolean;
  showClear?: boolean;
  classes?: string;
}


export default /*@__PURE__*/_defineComponent({
  ...__default__,
  __name: 'AtomInput',
  props: {
    modelValue: { type: null, required: false, default: '' },
    placeholder: { type: String, required: false },
    width: { type: String, required: false, default: '346px' },
    bgIcon: { type: String, required: false },
    mask: { type: null, required: false, default: '' },
    list: { type: String, required: false },
    label: { type: String, required: false },
    msgError: { type: String, required: false },
    type: { type: String, required: false, default: 'text' },
    maxLength: { type: [Number, String], required: false, default: 126 },
    minLength: { type: [Number, String], required: false },
    max: { type: [Number, String], required: false },
    min: { type: [Number, String], required: false },
    borderColor: { type: String, required: false, default: 'gray-500' },
    height: { type: [String, Number], required: false, default: '48px' },
    filled: { type: Boolean, required: false, default: false },
    simple: { type: Boolean, required: false },
    selectionOnFocus: { type: Boolean, required: false },
    noStyle: { type: Boolean, required: false, default: false },
    prependIcon: { type: Boolean, required: false, default: undefined },
    appendIcon: { type: Boolean, required: false, default: undefined },
    noBgPrependIcon: { type: Boolean, required: false, default: false },
    noBorderPrependIcon: { type: Boolean, required: false, default: false },
    noBgAppendIcon: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
    required: { type: Boolean, required: false },
    hasError: { type: Boolean, required: false },
    wFull: { type: Boolean, required: false, default: false },
    borderBottom: { type: Boolean, required: false },
    nullable: { type: Boolean, required: false },
    trim: { type: Boolean, required: false },
    showClear: { type: Boolean, required: false, default: false },
    classes: { type: String, required: false }
  },
  emits: ["update:modelValue", "click", "keydown", "focus", "update:hint", "clear"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

_useCssVars(_ctx => ({
  "011f406c-width": (_ctx.width),
  "011f406c-height": (_ctx.height)
}))

const props = __props;

const emit = __emit;
// const emit = defineEmits(['click', 'keydown', 'update:modelValue', 'focus', 'update:hint'])

const attrs = useAttrs();

const input = ref<HTMLInputElement | null>(null);

const listeners = computed(() => ({
  input: emitValue,
  keydown: processValue,
  blur: (e: Event) => {
    trimIfEnabled();
    if (typeof attrs.onBlur === 'function') attrs.onBlur(e);
  },
  focus: (e: PointerEvent) => emit('focus', e),
}));

const attributes = computed(() => omit(attrs, 'class'));

// const isStepUpAllowed = computed(() => {
//   return props.disabled === false && (props.max === undefined || parseInt(String(props.modelValue), 10) < props.max);
// });

// const isStepDownAllowed = computed(() => {
//   return props.disabled === false && (props.min === undefined || parseInt(String(props.modelValue), 10) > props.min);
// });

const processValue = (event: KeyboardEvent) => {
  if (!event.key) return;
  //   const key = event.key.toLocaleLowerCase()
  //   const systemKeys = ['meta', 'shift', 'alt', 'backspace', 'delete', 'tab']
  //   const value = (event.target as HTMLInputElement).value

  //   if (props.slug === true) {
  //   }

  //   if (props.dbSafe === true) {
  //   }

  emit('keydown', event);
};

const emitValue = (event: InputEvent) => {
  const value = (event.target as HTMLInputElement).value;

  if (props.nullable === true && value === '') {
    emit('update:modelValue', null);
    return;
  }

  if (props.type === 'number') {
    const parsedNumber = Number(value);

    if (props.modelValue !== parsedNumber) emit('update:modelValue', parsedNumber);
  } else {
    // if (props.slug === true) {
    // }

    // if (props.dbSafe === true) {
    // }

    emit('update:modelValue', value);
  }
};

const trimIfEnabled = () => {
  if (props.modelValue && props.trim && ['string', 'text'].includes(props.type))
    emit('update:modelValue', String(props.modelValue).trim());
};

// const stepUp = () => {
//   if (!input.value) return;
//   if (isStepUpAllowed.value === false) return;
//   input.value.stepUp();
//   if (input.value.value != null) return emit('update:modelValue', Number(input.value.value));
// };

// const stepDown = () => {
//   if (!input.value) return;
//   if (isStepDownAllowed.value === false) return;
//   input.value.stepDown();
//   if (input.value.value) return emit('update:modelValue', Number(input.value.value));
//   else return emit('update:modelValue', props.min || 0);
// };

const __returned__ = { props, emit, attrs, input, listeners, attributes, processValue, emitValue, trimIfEnabled, get state() { return state }, get DangerIcon() { return DangerIcon }, get DeletexIconCircle() { return DeletexIconCircle } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})