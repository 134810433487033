import { createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode($setup["MoleculeTableSimple"], {
              columns: $setup.columnsLink,
              rows: $setup.rowsLink
            }, null, 8 /* PROPS */, ["columns", "rows"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode($setup["MoleculeTableSimple"], {
              columns: $setup.columnsService,
              rows: $setup.rowsServices
            }, null, 8 /* PROPS */, ["columns", "rows"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode($setup["OrganismsQualityChart"], {
              data: $setup.dataQualidade.series,
              title: $setup.dataQualidade.title,
              tooltip: $setup.dataQualidade.tooltip,
              onClickIconTitle: $setup.handleQualityChart
            }, null, 8 /* PROPS */, ["data", "title", "tooltip"])
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_col, { cols: "6" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "h-100" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "6",
                  class: "h-100"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["OrganismsProgressCircle"], {
                      value: $setup.progressQualidade.value,
                      title: $setup.progressQualidade.title,
                      tooltip: $setup.progressQualidade.tooltip,
                      "not-percent": $setup.progressQualidade.notPercentual,
                      onClickIconTitle: $setup.handleMonitorar
                    }, null, 8 /* PROPS */, ["value", "title", "tooltip", "not-percent"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "6",
                  class: "h-100"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["OrganismsEconomy"], {
                      value: $setup.economy.data,
                      title: $setup.economy.title,
                      tooltip: $setup.economy.tooltip,
                      onClickIconTitle: $setup.handleEconomy
                    }, null, 8 /* PROPS */, ["value", "title", "tooltip"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createCommentVNode(" <button @click=\"Notification.incrementNotification\">Teste nova notificação</button> "),
    _createCommentVNode(" <button @click=\"Notification.setNotification({text: `Novo notificação ${Notification.getNotifications.value.length + 1}`, id: String(Notification.getNotifications.value.length + 1)})\">Teste nova notificação</button> "),
    _createCommentVNode(" <MoleculeTabs v-model=\"tab\" :textTooltip=\"true\" :tabs=\"tabs\" v-if=\"!state.isMobile\" />\n    <MoleculeTabWindow v-model=\"tab\" :class=\"{ border: !state.isMobile }\">\n      <MoleculeTabItem value=\"Observabilidade\">\n        <OverView @realtime=\"dadosRealtime = $event\" @redirect=\"tab = 'realtime'\" :roda=\"rodarMonitor\" />\n      </MoleculeTabItem>\n      <MoleculeTabItem value=\"financeiras\">\n        <InformacoesFinanceiras />\n      </MoleculeTabItem>\n      <MoleculeTabItem value=\"realtime\">\n        <MonitorRealtime @redirect=\"tab = 'Observabilidade'\" :dadosRealtime=\"dadosRealtime\" @load=\"handleLoadMonitor\" />\n      </MoleculeTabItem>\n    </MoleculeTabWindow> ")
  ]))
}