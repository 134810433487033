import { AxiosResponse } from 'axios'
import { httpAuth, httpGcn } from '../api'

import { Credential, LoginResponse, User } from '../models'




export async function login(payload: Credential): Promise<LoginResponse> {
  const controller = 'auth/autenticar'
  const { data } = await httpAuth.post<LoginResponse, AxiosResponse>(`${controller}`, payload);
  return data;
}


export async function findUsers(): Promise<User[]> {
  const controller = 'usuario/listar'
  const { data } = await httpAuth.get<User[], AxiosResponse>(`${controller}`,);

  data.map((x) => {
    x.papeis = x.papelLista.map((y) => y.nome).join(', ');
  });
  return data;
}

export async function atualizaDados({ id, nomeUsuario, fileContentBase64, novaSenha, temaescuro }: any) {
  const controller = 'usuario/atualizar-dados'
  const { data } = await httpAuth.post<User[], AxiosResponse>(`${controller}`, { id, nomeUsuario, fileContentBase64, novaSenha, temaescuro });
  return data;
}

export async function criar({ nome, email, fileContentBase64, sistemaToken, papelDTOLista }: any) {
  const controller = 'usuario/adicionar'
  const { data } = await httpAuth.post<User[], AxiosResponse>(`${controller}`, { nome, email, fileContentBase64, sistemaToken, papelDTOLista });
  return data;
}

export async function atualizar({ id, nome, email, fileContentBase64, sistemaToken, papelDTOLista }: any) {
  const controller = 'usuario/atualizar'
  const { data } = await httpAuth.put<User[], AxiosResponse>(`${controller}`, { id, nome, email, fileContentBase64, sistemaToken, papelDTOLista });
  return data;
}

export async function deletar(id: number) {
  const controller = `usuario/excluir?usuarioId=${id}`
  const { data } = await httpAuth.delete<any, AxiosResponse>(`${controller}`);
  return data;
}


export async function listarPapeis() {
  const controller = `papel/listar`
  const { data } = await httpAuth.get<any[], AxiosResponse>(`${controller}`);
  return data;
}



export async function notificacoesInscrever({ email, deviceToken }: any) {
  const controller = 'notificacao/inscrever'
  const { data } = await httpGcn.post<any, AxiosResponse>(`${controller}`, { email, deviceToken });
  return data;
}