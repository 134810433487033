import { defineComponent as _defineComponent } from 'vue'
import { AtomButton, DeleteIcon, EditSquareIcon, AtomInput, AtomSelect } from '@/components/atoms';

import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { IHeadersTable, IOptionsSelect } from '@/types';
import { ref, onMounted, computed, watch } from 'vue';
import Swal from 'sweetalert2';
import { Preloader } from '@/store';
import { getEquipamentoAll, postEquipamento, putEquipamento, testSSH } from '@/core/services/EquipamentoService';

import { Equipamento } from '@/core/models';
import { getTipoEquipamentoAll } from '@/core/services/TipoEquipamentoService';
import { getUnidadeAll } from '@/core/services/UnitsService';
interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'EquipamentPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const steps = ref([
  { label: 'Primeiro passo', completed: false },
  { label: 'Segundo passo', completed: false },
]);
const loadSSH = ref<boolean>(false);
const currentStep = ref(steps.value[0].label);
const totalItems = ref<number>(0);
const term = ref<string>('');
const data = ref<Equipamento>({});
const tipos = ref<IOptionsSelect[]>([]);
const unidades = ref<IOptionsSelect[]>([]);
const perPage = ref<number>(10);
const page = ref<number>(1);
const showModalUpload = ref<boolean>(false);
const sortable = ref<any>();
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Unidade', field: 'unidadeNome' },
  {
    label: 'IP',
    field: 'ip',
    formatFn: (event) => {
      if (event) {
        const password = JSON.parse(JSON.stringify(event));
        const visibleDigits = password.substring(0, 3);
        const hiddenDigits = '*'.repeat(password.length <= 6 ? password.length - 1 : password.length - 6);
        const formattedPassword = visibleDigits + hiddenDigits + password.slice(-1);
        return formattedPassword;
      } else {
        return '';
      }
    },
  },
  { label: 'Porta SSH', field: 'portSsh' },
  { label: 'Porta HTTPS', field: 'portHttps' },
  { label: 'Tipo de equip.', field: 'tipoEquipamentoNome' },
  {
    label: 'Usuário SSH',
    field: 'username',
    formatFn: (event) => {
      if (event) {
        const password = JSON.parse(JSON.stringify(event));
        const hiddenDigits = '*'.repeat(password.length <= 6 ? password.length - 1 : password.length - 6);
        return hiddenDigits;
      } else {
        return '';
      }
    },
  },
  {
    label: 'Senha SSH',
    field: 'passwordHash',
    formatFn: (event) => {
      if (event) {
        const password = JSON.parse(JSON.stringify(event));
        const hiddenDigits = '*'.repeat(password.length <= 6 ? password.length - 1 : password.length - 6);
        return hiddenDigits;
      } else {
        return '';
      }
    },
  },
  { label: 'SNMP Porta', field: 'snmpPort' },
  { label: 'SNMP Versão', field: 'snmpVersion' },
  { label: 'SNMP Sec. Name', field: 'snmpSecurityName' },
  { label: 'SNMP Auth Prot.', field: 'snmpAuthProtocol' },
  // { label: 'SNMP Auth HASH Sen.', field: 'snmpAuthPassHash' },
  { label: 'SNMP Priv Prot.', field: 'snmpAuthProtocol' },
  // { label: 'SNMP Priv HASH Sen.', field: 'snmpPrivPassHash' },
  { label: 'Community', field: 'community' },
  { label: 'Opções', field: 'actions' },
]);
const items = ref<any[]>([]);
const headersNotImport = ref<IHeadersTable[]>([
  {
    label: 'IP',
    field: 'ip',
  },
  {
    label: 'Usuário SSH',
    field: 'username',
  },
  {
    label: 'Senha SSH',
    field: 'passwordHash',
  },
  { label: 'SNMP Porta', field: 'snmpPort' },
  { label: 'SNMP Versão', field: 'snmpVersion' },
  { label: 'Community', field: 'community' },
]);

const textSaveModal = computed(() => {
  const isLast = steps.value[steps.value.length - 1].label === currentStep.value;
  return isLast ? 'Salvar' : 'Avançar';
});

const textSaveMobile = computed(() => {
  const isLast = steps.value[steps.value.length - 1].label === currentStep.value;
  return type.value == 'C' && isLast
    ? 'Concluir cadastro'
    : type.value != 'C' && isLast
      ? 'Concluir edição'
      : 'Avançar';
});
const textCancelModal = computed(() => {
  const isLast = steps.value[0].label === currentStep.value;
  return isLast ? 'Cancelar' : 'Voltar';
});

const addNew = ref<boolean>(false);
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}

function loadTipoEquipamentos() {
  getTipoEquipamentoAll(0, 99999999).then((r) => {
    tipos.value = r.lista.filter((x) => x.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
  });
}
function testingSsh() {
  const dataSSH = {
    host: data.value.ip,
    port: data.value.portSsh,
    user: data.value.username,
    pass: data.value.passwordHash,
  };
  loadSSH.value = true;
  testSSH(dataSSH)
    .then((resp) => {
      Swal.fire({
        icon: 'info',
        title: `Porta: ${data.value.portSsh}`,
        text: `${resp}`,
      });
    })
    .catch((err) => {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: `Atenção`,
        text: `Ocorreu um erro ao validar a requisição`,
      });
    })
    .finally(() => {
      loadSSH.value = false;
    });

  console.log(data.value);
}
function loadUnits() {
  getUnidadeAll(0, 999999999).then((r) => {
    unidades.value = r.lista
      .filter((x) => x.archived == 0)
      .map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
  });
}
function handleEdit(payload: Equipamento, isApp: boolean = false) {
  console.log(payload, 'Edit');

  data.value = JSON.parse(JSON.stringify(payload));
  currentStep.value = 'Primeiro passo';
  type.value = 'E';

  if (isApp) {
    addNew.value = true;
  } else {
    showModalUpload.value = true;
  }
}
function clear() {
  data.value = {};
  data.value.id = 0;
  currentStep.value = 'Primeiro passo';
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function handleDelete(payload: Equipamento) {
  console.log(payload, 'Delete');

  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente arquivar o equipamento <b>${payload.ip}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);
      payload.archived = 1;
      putEquipamento(payload)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              title: 'Sucesso',
              text: `Equipamento ${payload.ip} foi arquivado com sucesso!`,
              icon: 'success',
            });
          }

          loadData();
        })

        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}

function searching(event: string | undefined) {
  page.value = 1;
  term.value = String(event);
  // loadData();
}
function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);
  if (!data.value?.id) {
    data.value.archived = 0;
    data.value.id = undefined;
    postEquipamento(data.value)
      .then(() => {
        // Swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Equipamento ${data.value?.ip} foi criado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    putEquipamento(data.value as Equipamento)
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Equipamento ${data.value?.ip} foi atualizado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}
function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalItems.value = 0;
  let promisse;
  if (sortable.value) {
    promisse = getEquipamentoAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = getEquipamentoAll(page.value - 1, perPage.value, term.value);
  }

  promisse
    .then((resp) => {
      items.value = resp.lista;
      totalItems.value = resp.totalItems - items.value.filter((f) => f.archived == 1).length;
    })

    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function handleNew() {
  const idx = steps.value.findIndex((x) => x.label === currentStep.value);

  if (idx !== -1 && steps.value[idx + 1]) {
    steps.value[idx].completed = true;
    currentStep.value = steps.value[idx + 1].label;
  }

  if (steps.value.length - 1 === idx) {
    handleAddOrUpdate();
  }
}
function handleCancelNew(event) {
  if ((event ?? '') == 'fecha') showModalUpload.value = false;
  const idx = steps.value.findIndex((x) => x.label === currentStep.value);

  if (idx !== -1 && steps.value[idx - 1]) {
    steps.value[idx - 1].completed = false;
    currentStep.value = steps.value[idx - 1].label;
  } else if (!addNew.value) {
    showModalUpload.value = false;
  } else if (addNew.value) {
    addNew.value = false;
    type.value = '';
    data.value = {};
  }
}

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'equipaments') {
      page.value = 1;
      loadTipoEquipamentos();
      loadUnits();
      loadData();
    }
  },
);

watch(
  () => page.value,
  () => {
    loadData();
  },
);

watch(
  () => term.value,
  () => {
    loadData();
  },
);

watch(
  () => perPage.value,
  () => {
    loadData();
  },
);

onMounted(() => {
  loadUnits();
  loadTipoEquipamentos();
  loadData();
});

const __returned__ = { props, steps, loadSSH, currentStep, totalItems, term, data, tipos, unidades, perPage, page, showModalUpload, sortable, headers, items, headersNotImport, textSaveModal, textSaveMobile, textCancelModal, addNew, type, replacement, loadTipoEquipamentos, testingSsh, loadUnits, handleEdit, clear, sort, handleDelete, searching, handleAddOrUpdate, loadData, handleNew, handleCancelNew, get AtomButton() { return AtomButton }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})