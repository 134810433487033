import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-fit d-flex flex-column mx-auto justify-center h-100" }
const _hoisted_2 = { class: "relative w-fit" }
const _hoisted_3 = { class: "area-value" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "d-flex justify-center area-legenda" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock($setup["MoleculeCardSimple"], {
    title: $setup.props.title,
    onClickIconTitle: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('clickIconTitle'))),
    class: "h-100 item-circle"
  }, {
    iconTitle: _withCtx(() => [
      ($props.tooltip)
        ? (_openBlock(), _createBlock(_component_v_tooltip, {
            key: 0,
            activator: "parent",
            location: "top"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.tooltip), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["ReorderIcon"], { color: "var(--color-title)" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", null, _toDisplayString($setup.props.value), 1 /* TEXT */),
            (!$props.notPercent)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, "%"))
              : _createCommentVNode("v-if", true)
          ]),
          _createVNode($setup["CircleProgress"], {
            "fill-color": "var(--primary-)",
            "empty-color": "var(--stroke-)",
            percent: $setup.props.value,
            size: 213,
            "border-bg-width": 23,
            "border-width": 23
          }, null, 8 /* PROPS */, ["percent"])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode($setup["OnlineIcon"], { color: "var(--color-title)" }),
          _createVNode($setup["OfflineIcon"], { color: "var(--color-title)" })
        ])
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}