import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "25",
  viewBox: "0 0 24 25",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      d: "M3 6.35011C3 4.77604 4.27599 3.5 5.85 3.5H19.15C20.7241 3.5 22 4.77604 22 6.35011V7.46315C22 8.21905 21.6997 8.94398 21.1652 9.47848L15.6283 15.0157C15.4501 15.1939 15.35 15.4355 15.35 15.6875V17.9136C15.35 18.6694 15.0497 19.3944 14.5152 19.9289L12.4233 22.0209C11.3999 23.0444 9.65 22.3195 9.65 20.8721V15.6875C9.65 15.4355 9.54991 15.1939 9.37175 15.0157L3.83475 9.47848C3.30027 8.94398 3 8.21905 3 7.46315V6.35011Z",
      fill: $setup.props.color || '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_2)
  ]))
}