import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import { AtomProgressBar } from '../atoms';
import { OrganismsChart } from '@/components/organisms';

interface IProps {
  dataCharGeral: any;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsTooltipChart',
  props: {
    dataCharGeral: { type: null, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const progressUpload = ref(35);

const __returned__ = { props, progressUpload, get AtomProgressBar() { return AtomProgressBar }, get OrganismsChart() { return OrganismsChart } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})