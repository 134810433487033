import { defineComponent as _defineComponent } from 'vue'


interface IProps {
  color: string;
  icon: string;
  size: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomIcon',
  props: {
    color: { type: String, required: true, default: 'warning' },
    icon: { type: String, required: true, default: 'mdi-alert-circle' },
    size: { type: String, required: true, default: 'small' }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;

const __returned__ = { props }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})