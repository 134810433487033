import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "label-mobile"
}
const _hoisted_3 = { class: "d-flex align-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_autocomplete = _resolveComponent("v-autocomplete")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({ width: $setup.props.width }),
    class: "mb-2"
  }, [
    (!$setup.state.isMobile && $setup.props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_1, _toDisplayString($setup.props.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile && $setup.props.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, _toDisplayString($setup.props.label), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_v_autocomplete, {
      class: "bg-teste mt-1",
      "item-title": "text",
      modelValue: $setup.selectedItem,
      "onUpdate:modelValue": [
        _cache[1] || (_cache[1] = ($event: any) => (($setup.selectedItem) = $event)),
        $setup.handleChangeDropdownList
      ],
      density: "comfortable",
      style: {"min-width":"155px"},
      placeholder: $setup.props.placeholder,
      items: $setup.props.options,
      multiple: $props.multiple,
      chips: $props.multiple,
      disabled: $setup.props.disabled,
      "item-value": "value",
      variant: "outlined",
      rounded: $props.rounded,
      "base-color": "var(--stroke-)",
      clearable: ""
    }, _createSlots({
      clear: _withCtx(() => [
        _createVNode($setup["DeleteCircleIcon"], {
          color: "var(--primary-nav)",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.selectedItem = $props.multiple ? [] : null))
        })
      ]),
      _: 2 /* DYNAMIC */
    }, [
      (!$setup.props.isCalendar)
        ? {
            name: "details",
            fn: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode($setup["CalendarIcon"])
              ])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "placeholder", "items", "multiple", "chips", "disabled", "rounded"]),
    _createCommentVNode(" <v-select item-title=\"text\" v-model=\"selectedItem\" density=\"compact\" variant=\"outlined\"\n      :placeholder=\"props.placeholder\" :items=\"props.options\" :multiple=\"multiple\" :chips=\"multiple\"\n      :disabled=\"props.disabled\" item-value=\"value\" @update:modelValue=\"handleChangeDropdownList\">\n      \n    </v-select> ")
  ], 4 /* STYLE */))
}