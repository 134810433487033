import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "56",
  height: "56",
  viewBox: "0 0 56 56",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      width: "56",
      height: "56",
      rx: "8",
      fill: $setup.props.colorBackground || 'none',
      "fill-opacity": "0.1"
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M30.3499 18H25.6499C24.6099 18 23.7599 18.84 23.7599 19.88V20.82C23.7599 21.86 24.5999 22.7 25.6399 22.7H30.3499C31.3899 22.7 32.2299 21.86 32.2299 20.82V19.88C32.2399 18.84 31.3899 18 30.3499 18Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_3),
    _createElementVNode("path", {
      d: "M33.2399 20.82C33.2399 22.41 31.9399 23.71 30.3499 23.71H25.6499C24.0599 23.71 22.7599 22.41 22.7599 20.82C22.7599 20.26 22.1599 19.91 21.6599 20.17C20.2499 20.92 19.2899 22.41 19.2899 24.12V33.53C19.2899 35.99 21.2999 38 23.7599 38H32.2399C34.6999 38 36.7099 35.99 36.7099 33.53V24.12C36.7099 22.41 35.7499 20.92 34.3399 20.17C33.8399 19.91 33.2399 20.26 33.2399 20.82ZM28.3799 32.95H23.9999C23.5899 32.95 23.2499 32.61 23.2499 32.2C23.2499 31.79 23.5899 31.45 23.9999 31.45H28.3799C28.7899 31.45 29.1299 31.79 29.1299 32.2C29.1299 32.61 28.7899 32.95 28.3799 32.95ZM30.9999 28.95H23.9999C23.5899 28.95 23.2499 28.61 23.2499 28.2C23.2499 27.79 23.5899 27.45 23.9999 27.45H30.9999C31.4099 27.45 31.7499 27.79 31.7499 28.2C31.7499 28.61 31.4099 28.95 30.9999 28.95Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_4)
  ]))
}