import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue';
import { AtomSubTitle, AtomTitle } from '../atoms';

interface IProps {
  show: any;
  width?: string;
  height?: string;
  el?: any;
  title: string;
  subtitle?: string;
  btnCloseText?: string;
  btnConfirmText?: string;
  paddingY?: string;
  hideFooter?: boolean;
  btnHide?: boolean;
  hideDividerHeader?: boolean;
  hideHeader?: boolean;
  bodyScrool?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeModal',
  props: {
    show: { type: null, required: true },
    width: { type: String, required: false, default: '509px' },
    height: { type: String, required: false },
    el: { type: null, required: false, default: 'div' },
    title: { type: String, required: true },
    subtitle: { type: String, required: false },
    btnCloseText: { type: String, required: false, default: 'Cancelar' },
    btnConfirmText: { type: String, required: false, default: 'Importar' },
    paddingY: { type: String, required: false },
    hideFooter: { type: Boolean, required: false },
    btnHide: { type: Boolean, required: false },
    hideDividerHeader: { type: Boolean, required: false },
    hideHeader: { type: Boolean, required: false },
    bodyScrool: { type: Boolean, required: false }
  },
  emits: ['handleCancel', 'handleConfirm'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;

const props = __props;

const dialog = ref<boolean>(false);

function handleCancel() {
  emit('handleCancel');
}
function handleConfirm() {
  emit('handleConfirm');
}

function close() {
  emit('handleCancel', 'fecha');
}

watch(
  () => props.show,
  () => (dialog.value = props.show),
);
watch(
  () => dialog.value,
  () => {
    if (!dialog.value) emit('handleCancel', 'fecha');
  },
);

const __returned__ = { emit, props, dialog, handleCancel, handleConfirm, close, get AtomSubTitle() { return AtomSubTitle }, get AtomTitle() { return AtomTitle } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})