import { defineComponent as _defineComponent } from 'vue'
import { ref } from 'vue';
import { watch } from 'vue';
import {
ChevronDownIcon
} from '@/components/atoms';
interface Props {
  title?: string
  noChevron?: boolean
  iconDifferent?: boolean
  initialShow?: boolean
  noMargin?: boolean
  titleOrange?: boolean
  titleGreen?: boolean
  titleBlue?: boolean
  cardClass?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomAccordion',
  props: {
    title: { type: String, required: false, default: '' },
    noChevron: { type: Boolean, required: false, default: false },
    iconDifferent: { type: Boolean, required: false },
    initialShow: { type: Boolean, required: false, default: false },
    noMargin: { type: Boolean, required: false, default: false },
    titleOrange: { type: Boolean, required: false },
    titleGreen: { type: Boolean, required: false },
    titleBlue: { type: Boolean, required: false },
    cardClass: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props
const show = ref<boolean>(props.initialShow)

function toggle() {
  show.value = !show.value
}

function enter(el: any) {
  el.style.height = `${el.scrollHeight}px`
}

function leave(el: any) {
  el.style.height = ''
}

watch(
  () => props.initialShow,
  () => show.value = props.initialShow
)

const __returned__ = { props, show, toggle, enter, leave, get ChevronDownIcon() { return ChevronDownIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})