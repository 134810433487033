import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { ref, computed } from 'vue';
import { AtomButton, BtnChevronLeft, BtnChevronRight } from '@/components/atoms';
import StepActiveIcon from './icons/StepActiveIcon.vue';
import CompletedStepIcon from './icons/CompletedStepIcon.vue';

interface IProps {
  steps: any[];
  currentStep: string;
  isLarge?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomProgressSaltos',
  props: {
    steps: { type: Array, required: true },
    currentStep: { type: String, required: true },
    isLarge: { type: Boolean, required: false, default: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

_useCssVars(_ctx => ({
  "44e1fed6-steps": (steps.value),
  "44e1fed6-fontsize_title": (fontsize_title.value),
  "44e1fed6-sizeIconCheck": (sizeIconCheck.value),
  "44e1fed6-linetop": (linetop.value),
  "44e1fed6-width_cardstep": (width_cardstep.value)
}))

const props = __props;
const steps = ref(props.steps.length);
const areaSaltos = ref<HTMLElement>();

const fontsize_title = computed(() => {
  return props.isLarge ? '24px' : '14px';
});
const widtharea = computed(() => {
  return props.isLarge ? '245px' : '145px';
});
const width_cardstep = computed(() => {
  return props.isLarge ? '230px' : '130px';
});
const sizeIconCheck = computed(() => {
  return props.isLarge ? '44px' : '24px';
});
const linetop = computed(() => {
  return props.isLarge ? '53px' : '43px';
});
function handleRight() {
  if (areaSaltos.value) {
    const nvalue = props.isLarge ? 245 : 135;
    areaSaltos.value.scrollBy({
      left: nvalue,
      behavior: 'smooth',
    });
  }
}
function handleLeft() {
  if (areaSaltos.value) {
    const nvalue = props.isLarge ? -245 : -135;
    areaSaltos.value.scrollBy({
      left: nvalue,
      behavior: 'smooth',
    });
  }
}

const __returned__ = { props, steps, areaSaltos, fontsize_title, widtharea, width_cardstep, sizeIconCheck, linetop, handleRight, handleLeft, get AtomButton() { return AtomButton }, get BtnChevronLeft() { return BtnChevronLeft }, get BtnChevronRight() { return BtnChevronRight }, StepActiveIcon, CompletedStepIcon }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})