import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment, resolveComponent as _resolveComponent, renderList as _renderList, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}
const _hoisted_2 = { class: "quadrado pa-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_divider = _resolveComponent("v-divider")!

  return (_openBlock(), _createBlock($setup["MoleculeCard"], {
    loading: $setup.props.loading,
    textTooltip: 'textTooltip',
    title: $setup.props.title,
    onOnChangeDropDownList: $setup.handleChangeDropdownList,
    "has-dropdown-list": $setup.props.hasDropdownList,
    "options-drop-down": $setup.props.optionsDropDown,
    isCalendarSelect: $setup.props.isCalendarSelect
  }, {
    default: _withCtx(() => [
      ($setup.props.hasToggle && !$setup.isEmpty)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["AtomToogleButton"], {
              buttons: $setup.props.buttons ?? [],
              onHandleToggle: $setup.handleToogleChange
            }, null, 8 /* PROPS */, ["buttons"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.props.isSteps && !$setup.isEmpty)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
            _createCommentVNode(" <AtomProgressForm :steps=\"props.steps || []\" :current-step=\"props.currentStep || ''\" /> "),
            _renderSlot(_ctx.$slots, "bodySteps", {}, undefined, true),
            _createCommentVNode(" <v-divider class=\"mb-6\"></v-divider> ")
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      (!$setup.props.isSteps && !$setup.isEmpty)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            _createVNode($setup["VueHighcharts"], {
              type: "chart",
              options: $setup.chartOptions,
              redrawOnUpdate: true,
              oneToOneUpdate: false,
              animateOnUpdate: true
            }, null, 8 /* PROPS */, ["options"]),
            _createVNode(_component_v_divider, { class: "border-opacity-50 mb-6" }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.progress, (col, index) => {
              return (_openBlock(), _createBlock($setup["AtomProgressBar"], {
                key: String(index),
                modelValue: col.value,
                "progress-color": col.color,
                hasPercent: col.hasPercent,
                hasTitle: !!col.title,
                title: col.title,
                "has-percent-title": col.hasPercentTitle,
                max: col.maxValue
              }, null, 8 /* PROPS */, ["modelValue", "progress-color", "hasPercent", "hasTitle", "title", "has-percent-title", "max"]))
            }), 128 /* KEYED_FRAGMENT */))
          ], 64 /* STABLE_FRAGMENT */))
        : _createCommentVNode("v-if", true),
      ($setup.isEmpty && $setup.props.textEmpty)
        ? (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: _normalizeClass($setup.state.isMobile ? 'px-2' : '')
          }, [
            _createElementVNode("div", _hoisted_2, _toDisplayString($setup.props.textEmpty), 1 /* TEXT */)
          ], 2 /* CLASS */))
        : _createCommentVNode("v-if", true)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading", "title", "has-dropdown-list", "options-drop-down", "isCalendarSelect"]))
}