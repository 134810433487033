// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

//recuperar do localstorage o tema escolhido
const theme = localStorage.getItem('isDarkMode') || 'false';
const isDark = theme === 'true';

const myCustomDarkTheme = {
    dark: isDark,
    colors: {
        background: '#121212',
        surface: '#121212',
        primary: '#FF5C00',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#CF6679',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
};

const myCustomLightTheme = {
    dark: isDark,
    colors: {
        background: '#fafafa',
        surface: '#FFFFFF',
        primary: '#FF5C00',
        'primary-darken-1': '#3700B3',
        secondary: '#212529',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    }
}

export default createVuetify({
    theme: {
        defaultTheme: isDark ? 'myCustomDarkTheme' : 'myCustomLightTheme',
        themes: {
            myCustomLightTheme,
            myCustomDarkTheme,
        },
    },
});


