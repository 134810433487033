import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "56",
  height: "56",
  viewBox: "0 0 56 56",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("rect", {
      width: "56",
      height: "56",
      rx: "8",
      fill: $setup.props.colorBackground || 'none',
      "fill-opacity": "0.1"
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M28.0001 18C25.3801 18 23.2501 20.13 23.2501 22.75C23.2501 25.32 25.2601 27.4 27.8801 27.49C27.9601 27.48 28.0401 27.48 28.1001 27.49C28.1201 27.49 28.1301 27.49 28.1501 27.49C28.1601 27.49 28.1601 27.49 28.1701 27.49C30.7301 27.4 32.7401 25.32 32.7501 22.75C32.7501 20.13 30.6201 18 28.0001 18Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_3),
    _createElementVNode("path", {
      d: "M33.0801 30.15C30.2901 28.29 25.7401 28.29 22.9301 30.15C21.6601 31 20.9601 32.15 20.9601 33.38C20.9601 34.61 21.6601 35.75 22.9201 36.59C24.3201 37.53 26.1601 38 28.0001 38C29.8401 38 31.6801 37.53 33.0801 36.59C34.3401 35.74 35.0401 34.6 35.0401 33.36C35.0301 32.13 34.3401 30.99 33.0801 30.15Z",
      fill: $setup.props.color || '#B2BBC7'
    }, null, 8 /* PROPS */, _hoisted_4)
  ]))
}