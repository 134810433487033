import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted, onUnmounted } from 'vue';
import { MoleculeTable, MoleculeFinanceInfo } from '@/components/molecules';
import { AtomButton, HomeIcon2 } from '@/components/atoms';

import { IHeadersTable } from '@/types';
import { totalFinanceiro, dadosFinanceiro } from '@/core/services/GraficoService';
import { Authentication, FiltersUnit } from '@/store/index';
import { formatNumber } from '@/utils/formatNumber';
import { getDescontPrevist } from '@/core/services/PredictService';
import { state } from '@/store/modules/layout';


export default /*@__PURE__*/_defineComponent({
  __name: 'InformacoesFinanceiras',
  setup(__props, { expose: __expose }) {
  __expose();

const dadosPredict = ref<any>();
const showOrientacao = ref<boolean>(false);
const loadingInfo = ref<boolean>(false);
const financeInfos = ref([
  {
    description: 'Custo provisionado',
    value: '0',
    icon: 'ProvisionedIcon',
  },
  {
    description: 'Desconto previsto',
    value: '0',
    icon: 'DiscountCoinIcon',
  },
]);
const selectedRow = ref();
const showDetails = ref<boolean>();
const dadoFinanceiro = ref<any>({});

const loadingTable = ref<boolean>(false);
const selected = ref<any>({});

const headersDetails = ref<IHeadersTable[]>([
  {
    label: 'Nome da operadora',
    field: 'nomeOperadora',
  },
  { label: 'Detalhes do link', field: 'detalheLink' },
  { label: 'Unidade', field: 'nomeUnidade' },
  { label: 'Custo provísionado', field: 'custoProvisionado' },
  { label: 'SLA', field: 'sla' },
  // { label: 'Relatório de SLA', field: 'actions' },
]);
const totalizadoresFinanceiro = ref<any>({});
const intervalos = ref<any>();

function handleRowClick(payload: any) {
  const options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNum = new Intl.NumberFormat('pt-BR', options);
  console.log('row click', payload);
  selected.value = payload.row;
  selectedRow.value = payload.row.id;
  dadoFinanceiro.value = {};
  dadosFinanceiro(payload.row.internet_links_id).then((f) => {
    dadoFinanceiro.value = f;

    dadoFinanceiro.value.custoAtual = formatNum.format(dadoFinanceiro.value.custoAtual as number);
  });
}
function handleGerar(payload: any) {
  console.log('row click Gerar', payload);
}
const groupBy = function (xs, key) {
  return xs?.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};
async function loadPredict() {
  try {
    loadingInfo.value = true;
    console.log(FiltersUnit.getters.filters.value);
    dadosPredict.value = FiltersUnit.getters.filters.value;
    const id = Authentication.state.id ?? 187;
    const interval = '480';

    const data = await getDescontPrevist('LNK', id, interval);
    let valorTotal = 0;
    if (data.data) {
      const value = data.data
        ?.filter(
          (f) =>
            FiltersUnit.getters.filters.value.length == 0 ||
            FiltersUnit.getters.filters.value.find((x) => x == f.client),
        )
        .sort((a, b) => {
          return a.time - b.time;
        });
      const px = groupBy(value, 'client');

      Object.keys(px)?.map((f) => {
        valorTotal += Number(px[f][px[f].length - 1].prediction);
      });
      financeInfos.value[1].value = Number(valorTotal).toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      });
    }
  } finally {
    loadingInfo.value = false;
  }
}
function loadFinanceiro() {
  loadingInfo.value = true;
  totalizadoresFinanceiro.value = {};
  showDetails.value = false;
  dadoFinanceiro.value = {};
  const options = { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 };
  const formatNum = new Intl.NumberFormat('pt-BR', options);
  totalFinanceiro(Authentication.state.id as number, FiltersUnit.getters.filters.value)
    .then((f) => {
      totalizadoresFinanceiro.value = f;

      console.log(totalizadoresFinanceiro.value);
      financeInfos.value[0].value = formatNumber.format(f.custoProvisionado as number);
      financeInfos.value[0].success = undefined;
      financeInfos.value[0].variant = undefined;
      financeInfos.value[1].value = formatNumber.format(f.descontoEstimado as number);
      financeInfos.value[1].success = undefined;
      financeInfos.value[1].variant = undefined;
      totalizadoresFinanceiro.value.custoProvisionado = formatNum.format(
        totalizadoresFinanceiro.value.custoProvisionado as number,
      );
      totalizadoresFinanceiro.value.descontoEstimado = formatNum.format(
        totalizadoresFinanceiro.value.descontoEstimado as number,
      );
      totalizadoresFinanceiro.value.custoAtual = formatNum.format(totalizadoresFinanceiro.value.custoAtual as number);
      (totalizadoresFinanceiro.value.dadoFinanceiroLinkDTOLista ?? []).map((x, index) => {
        x.id = index + 1;

        x.custoProvisionado = formatNum.format(x.custoProvisionado as number);
        x.custoAtual = formatNum.format(x.custoAtual as number);
        x.descontoEstimado = formatNum.format(x.descontoEstimado as number);
      });
    })
    .finally(() => {
      // loadingInfo.value = false;
    });
}
onMounted(() => {
  loadFinanceiro();
  loadPredict();
  intervalos.value = setInterval(() => {
    loadFinanceiro();
    loadPredict();
  }, 60000);
});

watch(
  FiltersUnit.getters.filters.value,
  () => {
    loadFinanceiro();
    loadPredict();
  },
  { deep: true },
);
onUnmounted(() => {
  clearInterval(intervalos.value);
});

const __returned__ = { dadosPredict, showOrientacao, loadingInfo, financeInfos, selectedRow, showDetails, dadoFinanceiro, loadingTable, selected, headersDetails, totalizadoresFinanceiro, intervalos, handleRowClick, handleGerar, groupBy, loadPredict, loadFinanceiro, get MoleculeTable() { return MoleculeTable }, get MoleculeFinanceInfo() { return MoleculeFinanceInfo }, get AtomButton() { return AtomButton }, get HomeIcon2() { return HomeIcon2 }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})