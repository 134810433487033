import { defineComponent as _defineComponent } from 'vue'

import { MoleculeCard } from '@/components/molecules';
import { AtomToogleButton, AtomChartQuadrant } from '@/components/atoms';
import { IDataset, IOptions, IOptionsSelect, IToggleButon } from '@/types';

interface IProps {
  title: string;
  legend: boolean;
  type: string;
  textX?: string;
  textY?: string;
  label?: string;
  hasToggle?: boolean;
  buttons?: IToggleButon[];
  hasDropdownList?: boolean;
  optionsDropDown?: IOptionsSelect[];
  colorSeries: string;
  height?: number;
  hasTabs?: boolean;
  footer?: boolean;
  dataset: IDataset[];
  options?: IOptions;
  labels?: string[];
  maxY?: number;
  maxX?: number;
  titleY?: string;
  titleX?: string;
  hasFilters?: boolean;
  loading?: boolean;
  id: string;
  textTooltip?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsChartQuadrant',
  props: {
    title: { type: String, required: true, default: '' },
    legend: { type: Boolean, required: true, default: false },
    type: { type: String, required: true },
    textX: { type: String, required: false },
    textY: { type: String, required: false },
    label: { type: String, required: false },
    hasToggle: { type: Boolean, required: false },
    buttons: { type: Array, required: false },
    hasDropdownList: { type: Boolean, required: false },
    optionsDropDown: { type: Array, required: false },
    colorSeries: { type: String, required: true, default: '#000' },
    height: { type: Number, required: false },
    hasTabs: { type: Boolean, required: false, default: false },
    footer: { type: Boolean, required: false },
    dataset: { type: Array, required: true },
    options: { type: Object, required: false },
    labels: { type: Array, required: false },
    maxY: { type: Number, required: false },
    maxX: { type: Number, required: false },
    titleY: { type: String, required: false },
    titleX: { type: String, required: false },
    hasFilters: { type: Boolean, required: false },
    loading: { type: Boolean, required: false },
    id: { type: String, required: true },
    textTooltip: { type: String, required: false }
  },
  emits: ['handleToggle', 'onChangeDropDownList', "onTootipUp", "onTootipDown"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;

function handleToogleChange($event) {
  emit('handleToggle', $event);
}


function onTootipUp($event) {
  emit('onTootipUp', $event);
}

function onTootipDown($event) {
  emit('onTootipDown', $event);
}
function handleChangeDropdownList(event) {
  emit('onChangeDropDownList', event);
}


const __returned__ = { props, emit, handleToogleChange, onTootipUp, onTootipDown, handleChangeDropdownList, get MoleculeCard() { return MoleculeCard }, get AtomToogleButton() { return AtomToogleButton }, get AtomChartQuadrant() { return AtomChartQuadrant } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})