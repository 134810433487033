import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  class: "d-flex flex-column justify-center h-100",
  style: {"gap":"16px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock($setup["MoleculeCardSimple"], {
    title: $setup.props.title,
    onClickIconTitle: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('clickIconTitle'))),
    class: "h-100 item-circle"
  }, {
    iconTitle: _withCtx(() => [
      ($props.tooltip)
        ? (_openBlock(), _createBlock(_component_v_tooltip, {
            key: 0,
            activator: "parent",
            location: "top"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.tooltip), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["DownloadIcon2"], { color: "var(--color-title)" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.value, (col, index) => {
          return (_openBlock(), _createBlock($setup["AtomProgressBar"], {
            key: String(index),
            modelValue: 55,
            "progress-color": '#FF5C00',
            hasPercent: true,
            hasTitle: true,
            title: col.title,
            "has-percent-title": false,
            "no-value-title": "",
            max: 100
          }, null, 8 /* PROPS */, ["title"]))
        }), 128 /* KEYED_FRAGMENT */))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}