import { defineComponent as _defineComponent } from 'vue'
import { AtomButton, DeleteIcon, EditSquareIcon, AtomInput, AtomSelect, UsersIcon, PlusIcon } from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { state } from '@/store/modules/layout';

import { Grupos, Servicos } from '@/core/models';
import { IHeadersTable, IOptionsSelect } from '@/types';
import { ref, onMounted, watch } from 'vue';
import Swal from 'sweetalert2';
import { Preloader } from '@/store';
import { getServicosAll, putServico, postServico } from '@/core/services/ServicoService';
import { getGruposAll } from '@/core/services/GruposService';
interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'ServicesPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const data = ref<Servicos>({});
const grupos = ref<IOptionsSelect[]>([]);
const tipos = ref<IOptionsSelect[]>([
  { text: 'INT - INTERNET', value: 'INT' },
  { text: 'SVI - INTERNO', value: 'SVI' },
  { text: 'SVE - EXTERNO', value: 'SVE' },
  { text: 'SVN - NUVEM', value: 'SVN' },
  { text: 'LNK - LINK', value: 'LNK' },
]);

const props = __props;
const perPage = ref<number>(10);
const page = ref<number>(1);
const showModalUpload = ref<boolean>(false);
const showModaGroups = ref<boolean>(false);
const manageGroup = ref<boolean>(false);
const term = ref<string>('');
const totalRows = ref<number>(0);
const headersGroups = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Grupo', field: 'grupo' },
  { label: 'Opções', field: 'actions' },
]);

const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Tipo', field: 'type' },
  { label: 'Nome', field: 'nome' },
  { label: 'Destino', field: 'target' },
  { label: 'Latitude', field: 'latitude' },
  { label: 'Longitude', field: 'longitude' },
  { label: 'Opções', field: 'actions' },
]);
const items = ref<any[]>([]);
const grupo = ref<number[]>([]);
const gruops = ref<Grupos[]>([]);
const showModalGrupo = ref<boolean>(false);
const sortable = ref<any>();
const headersGrupo = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Grupo', field: 'nome' },
  { label: 'Opções', field: 'actions' },
]);

const addNew = ref<boolean>(false);
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}

function handleGroup(dado: Servicos) {
  console.log(dado, 'Add');
  data.value = dado;
  console.log(data.value);
  if (state.isMobile) manageGroup.value = true;
  else showModalGrupo.value = true;
}
function setGrupo() {
  gruops.value
    .filter((f) => grupo.value?.find((ff) => f.id == ff))
    .map((x) => {
      data.value.grupoDTOLista?.push({ id: x.id, nome: x.nome });
    });

  grupo.value = [];
}
function handleDeleteGrupo(grupo: Grupos) {
  data.value.grupoDTOLista = data.value.grupoDTOLista?.filter((f) => f.id != grupo.id);
}

function handleSaveGrupo() {
  const dados: Servicos = JSON.parse(JSON.stringify(data.value));

  if (!dados.grupos) dados.grupos = [];
  dados.grupoIntLista = dados.grupoDTOLista?.map((f) => f.id);
  dados.grupoDTOLista = undefined;
  Preloader.actions.ActionSetPreloader(true);
  putServico(dados)
    .then(() => {
      // swall
      Swal.fire({
        icon: 'success',
        title: 'Sucesso',
        text: `Gestão de grupos atualizados com sucesso!`,
      });
      loadData();
      showModalGrupo.value = false;
      manageGroup.value = false;
    })
    .catch(() => {
      Swal.fire({
        icon: 'error',
        title: 'Ocorreu algum erro',
      });
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

function handleEdit(payload: Servicos, isApp: boolean = false) {
  data.value = JSON.parse(JSON.stringify(payload));
  type.value = 'E';

  if (isApp) {
    addNew.value = true;
  } else {
    showModalUpload.value = true;
  }
}
function loadGrupos() {
  getGruposAll(0, 999999).then((f) => {
    grupos.value = f.lista.filter((f) => f.archived == 0).map((f) => ({ text: f.nome, value: f.id }) as IOptionsSelect);
    gruops.value = f.lista.filter((f) => f.archived == 0);
  });
}
function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function handleDelete(payload: Servicos) {
  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente arquivar o serviço <b>${payload.nome}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);
      payload.archived = 1;
      putServico(payload)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              icon: 'success',
              title: 'Sucesso',
              text: `Serviço ${payload.nome} foi arquivado com sucesso!`,
            });
          }
          loadData();
        })

        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}

function clear() {
  data.value = {};
  data.value.id = 0;
}

function searching(event: string | undefined) {
  page.value = 1;
  term.value = String(event);
  loadData();
}

function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalRows.value = 0;

  let promisse;

  if (sortable.value) {
    promisse = getServicosAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = getServicosAll(page.value - 1, perPage.value, term.value);
  }
  promisse
    .then((resp) => {
      items.value = resp.lista;
      totalRows.value = resp.totalItems;
    })

    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'services') {
      page.value = 1;
      loadGrupos();
      loadData();
    }
  },
);

onMounted(() => {
  loadGrupos();
  loadData();
});

function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);
  if (data.value.id == 0 || !data.value.id) {
    data.value.id = undefined;
    data.value.archived = 0;
    data.value.constJitter = 0;
    data.value.constLatency = 0;
    data.value.sensibilityFactor = 0;
    postServico(data.value)
      .then(() => {
        // Swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Serviço ${data.value.nome} foi criado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    putServico(data.value)
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Servico ${data.value.nome} foi atualizado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })

      .finally(() => {
        data.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}
watch(
  () => page.value,
  () => {
    loadData();
  },
);

watch(
  () => perPage.value,
  () => {
    loadData();
  },
);

watch(
  () => term.value,
  () => {
    loadData();
  },
);

const __returned__ = { data, grupos, tipos, props, perPage, page, showModalUpload, showModaGroups, manageGroup, term, totalRows, headersGroups, headers, items, grupo, gruops, showModalGrupo, sortable, headersGrupo, addNew, type, replacement, handleGroup, setGrupo, handleDeleteGrupo, handleSaveGrupo, handleEdit, loadGrupos, sort, handleDelete, clear, searching, loadData, handleAddOrUpdate, get AtomButton() { return AtomButton }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get UsersIcon() { return UsersIcon }, get PlusIcon() { return PlusIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})