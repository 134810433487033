import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mb-5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock($setup["MoleculeCard"], {
    loading: $setup.props.loading,
    footer: $setup.props.footer,
    onOnChangeDropDownList: $setup.handleChangeDropdownList,
    "has-dropdown-list": $setup.props.hasDropdownList,
    "options-drop-down": $setup.props.optionsDropDown,
    label: $setup.props.label,
    textTooltip: $setup.props.textTooltip,
    title: $setup.props.title,
    hasTabs: $setup.props.hasTabs
  }, {
    tabs: _withCtx(() => [
      _renderSlot(_ctx.$slots, "tabs")
    ]),
    tabsMobile: _withCtx(() => [
      _renderSlot(_ctx.$slots, "tabsMobile")
    ]),
    default: _withCtx(() => [
      ($setup.props.hasToggle)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["AtomToogleButton"], {
              buttons: $setup.props.buttons ?? [],
              onHandleToggle: $setup.handleToogleChange
            }, null, 8 /* PROPS */, ["buttons"])
          ]))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "topfreeArea"),
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, {
            order: "1",
            "order-md": "0",
            cols: "12",
            md: $props.hasFilters ? '8' : '12'
          }, {
            default: _withCtx(() => [
              _createVNode($setup["AtomChartQuadrant"], {
                dataset: $setup.props.dataset,
                labels: $setup.props.labels,
                "title-x": $setup.props.titleX,
                "title-y": $setup.props.titleY,
                "max-x": $setup.props.maxX,
                "max-y": $setup.props.maxY,
                onOnTootipUp: $setup.onTootipUp,
                onOnTootipDown: $setup.onTootipDown,
                id: $setup.props.id
              }, null, 8 /* PROPS */, ["dataset", "labels", "title-x", "title-y", "max-x", "max-y", "id"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["md"]),
          ($props.hasFilters)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                order: "0",
                "order-md": "1",
                cols: "12",
                md: "4"
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "filters")
                ]),
                _: 3 /* FORWARDED */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      })
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["loading", "footer", "has-dropdown-list", "options-drop-down", "label", "textTooltip", "title", "hasTabs"]))
}