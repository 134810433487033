import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`${$setup.state.isMobile ? 'toggle-container-mobile' : 'toggle-container'}`),
    style: _normalizeStyle({ width: $setup.props.width })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.buttons, (col, index) => {
      return (_openBlock(), _createBlock(_component_v_btn, {
        key: String(index),
        elevation: "0",
        onClick: ($event: any) => ($setup.toggleBtn = col.value),
        class: _normalizeClass(["toggle-btn", { active: $setup.toggleBtn === col.value }]),
        style: _normalizeStyle({
        color: $setup.toggleBtn === col.value ? col.textColor || 'var(--primary-nav)' : 'var(--color-subTitle-icon)',
        backgroundColor: $setup.toggleBtn === col.value ? col.bgColor || 'rgba(255, 140, 0, 0.1)' : 'transparent',
        fontWeight: $setup.toggleBtn === col.value ? '600' : '500',
      })
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(col.text), 1 /* TEXT */)
        ]),
        _: 2 /* DYNAMIC */
      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick", "class", "style"]))
    }), 128 /* KEYED_FRAGMENT */))
  ], 6 /* CLASS, STYLE */))
}