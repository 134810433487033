import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "20",
  height: "20",
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M14.0668 8.33329C11.8834 8.33329 10.1084 10.1083 10.1084 12.2916C10.1084 13.0333 10.3168 13.7333 10.6834 14.3333C11.3668 15.4833 12.6251 16.25 14.0668 16.25C15.5084 16.25 16.7668 15.475 17.4501 14.3333C17.8168 13.7416 18.0251 13.0333 18.0251 12.2916C18.0251 10.1083 16.2584 8.33329 14.0668 8.33329ZM15.5668 13.7666C15.4418 13.8916 15.2834 13.95 15.1251 13.95C14.9668 13.95 14.8084 13.8916 14.6834 13.7666L14.0834 13.1666L13.4584 13.7916C13.3334 13.9166 13.1751 13.975 13.0168 13.975C12.8584 13.975 12.7001 13.9166 12.5751 13.7916C12.3334 13.55 12.3334 13.15 12.5751 12.9083L13.2001 12.2833L12.6001 11.675C12.3584 11.4333 12.3584 11.0333 12.6001 10.7916C12.8418 10.55 13.2418 10.55 13.4834 10.7916L14.0834 11.3916L14.6668 10.8083C14.9084 10.5666 15.3084 10.5666 15.5501 10.8083C15.7918 11.05 15.7918 11.45 15.5501 11.6916L14.9668 12.275L15.5668 12.8833C15.8168 13.125 15.8168 13.525 15.5668 13.7666Z",
      fill: $setup.props.color || '#FF5C00',
      "fill-opacity": "0.1"
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M17.1501 3.34996V5.19996C17.1501 5.87496 16.7334 6.71663 16.3168 7.14163L16.1668 7.27496C16.0501 7.38329 15.8751 7.40829 15.7251 7.35829C15.5584 7.29996 15.3918 7.25829 15.2251 7.21663C14.8584 7.12496 14.4668 7.08329 14.0668 7.08329C11.1918 7.08329 8.85843 9.41663 8.85843 12.2916C8.85843 13.2416 9.11676 14.175 9.60843 14.975C10.0251 15.675 10.6084 16.2583 11.2418 16.65C11.4334 16.775 11.5084 17.0416 11.3418 17.1916C11.2834 17.2416 11.2251 17.2833 11.1668 17.325L10.0001 18.0833C8.91676 18.7583 7.4251 18 7.4251 16.65V12.1916C7.4251 11.6 7.09176 10.8416 6.75843 10.425L3.6001 7.05829C3.18343 6.63329 2.8501 5.87496 2.8501 5.37496V3.43329C2.8501 2.42496 3.6001 1.66663 4.50843 1.66663H15.4918C16.4001 1.66663 17.1501 2.42496 17.1501 3.34996Z",
      fill: $setup.props.color || '#FF5C00',
      "fill-opacity": "0.1"
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}