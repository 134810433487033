import { defineComponent as _defineComponent } from 'vue'
import {
  DeleteIcon,
  RegisterIcon,
  EditSquareIcon,
  AtomButton,
  AtomInput,
  AtomSelect,
  ProfileMobileIcon,
} from '@/components/atoms';
import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { IHeadersTable, IOptionsSelect } from '@/types';
import { ref, onMounted, computed } from 'vue';
import { findUsers, listarPapeis, criar, atualizar, deletar } from '@/core/services/AuthService';
import { User } from '@/core/models';
import { Preloader } from '@/store';
import Swal from 'sweetalert2';

export default /*@__PURE__*/_defineComponent({
  __name: 'UsersPage',
  setup(__props, { expose: __expose }) {
  __expose();

const inputfile = ref<any>(null);
const fileBase64 = ref<any>();
const perPage = ref<number>(10);
const page = ref<number>(1);
const dataUser = ref<any>({});
const showModalUsers = ref<boolean>();
const optionsPapeis = ref<IOptionsSelect[]>([]);
const totalRows = ref<number>(0);
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Nome', field: 'nome' },
  { label: 'Email', field: 'email' },
  // { label: 'Tipo', field: 'type' },
  { label: 'Perfis', field: 'papeis' },
  { label: 'Opções', field: 'actions' },
]);
const items = ref<User[]>([]);
const term = ref<string>('');

const itemsFiltered = computed(() => {
  return term.value
    ? items.value.filter(
        (f) => f.nome.includes(term.value) || f.email.includes(term.value) || f.papeis.includes(term.value),
      )
    : items.value;
});
const itemsComputed = computed(() => {
  const startIndex = (page.value - 1) * perPage.value;
  const endIndex = startIndex + perPage.value;
  return itemsFiltered.value.slice(startIndex, endIndex);
});
const addNew = ref<boolean>(false);
// const sortable = ref<any>();
const type = ref<string>();
function replacement() {
  if (addNew.value == false) {
    addNew.value = true;
    type.value = 'C';
  } else {
    addNew.value = false;
    type.value = '';
  }
}
function clear() {
  dataUser.value = {};
}
// function sort(event) {
//   let typ: any | undefined = {};
//   if (event.type == 'none') {
//     typ = undefined;
//   } else {
//     typ[event.field] = event.type;
//   }
//   sortable.value = typ;
//   loadUser();
// }
function changeImg(event: any) {
  const file = event.target.files[0];
  if (!file) {
    return;
  }
  const reader = new FileReader();
  reader.onload = function () {
    fileBase64.value = reader.result;
    inputfile.value = null;
  };
  reader.readAsDataURL(file);
}
async function loadUser() {
  try {
    Preloader.actions.ActionSetPreloader(true);
    items.value = await findUsers();

    totalRows.value = items.value.length;
  } finally {
    Preloader.actions.ActionSetPreloader(false);
  }
}
async function GetPapeis() {
  try {
    Preloader.actions.ActionSetPreloader(true);
    const data = await listarPapeis();
    optionsPapeis.value = data.map((f) => {
      return { text: f.nome, value: f.id } as IOptionsSelect;
    });
  } finally {
    Preloader.actions.ActionSetPreloader(false);
  }
}
function handleEdit(payload, isApp: boolean = false) {
  console.log(payload, 'Edit');
  dataUser.value = JSON.parse(JSON.stringify(payload));
  fileBase64.value = dataUser.value.fotoBase64;
  dataUser.value.papelDTOLista = dataUser.value.papelLista.map((f) => f.id);

  type.value = 'E';
  if (isApp) {
    addNew.value = true;
  } else {
    showModalUsers.value = true;
  }
}
function handleDelete(payload) {
  console.log(payload, 'Delete');
  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente deletar o usuário <b>${payload.nome}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);
      deletar(payload.id)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              title: 'Sucesso',
              text: `Usuário ${payload.nome} foi deletado com sucesso!`,
              icon: 'success',
            });
          }
          GetPapeis();
          loadUser();
        })
        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}
function handleSave() {
  const dados = JSON.parse(JSON.stringify(dataUser.value));
  Preloader.actions.ActionSetPreloader(true);
  if (dados.id) {
    atualizar({
      ...dados,
      sistemaToken: '$2b$10$077jneVtRzI/EnFTffYb1.z/BXLF2/yD0zB767K44rEbaP54PjM6u',
      fileContentBase64: fileBase64.value,
      papelDTOLista: dados.papelDTOLista.map((f) => {
        return {
          id: f,
        };
      }),
    })
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Usuário atualizado com sucesso!`,
        });
        GetPapeis();
        loadUser();
        showModalUsers.value = false;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu algum erro',
        });
      })
      .finally(() => {
        dataUser.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    criar({
      ...dados,
      sistemaToken: '$2b$10$077jneVtRzI/EnFTffYb1.z/BXLF2/yD0zB767K44rEbaP54PjM6u',
      fileContentBase64: fileBase64.value,
      papelDTOLista: dados.papelDTOLista.map((f) => {
        return {
          id: f,
        };
      }),
    })
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Usuário criado com sucesso!`,
        });
        GetPapeis();
        loadUser();
        showModalUsers.value = false;
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: 'error',
          title: 'Ocorreu algum erro',
        });
      })
      .finally(() => {
        dataUser.value = {};
        addNew.value = false;
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}
onMounted(() => {
  GetPapeis();
  loadUser();
});

const __returned__ = { inputfile, fileBase64, perPage, page, dataUser, showModalUsers, optionsPapeis, totalRows, headers, items, term, itemsFiltered, itemsComputed, addNew, type, replacement, clear, changeImg, loadUser, GetPapeis, handleEdit, handleDelete, handleSave, get DeleteIcon() { return DeleteIcon }, get RegisterIcon() { return RegisterIcon }, get EditSquareIcon() { return EditSquareIcon }, get AtomButton() { return AtomButton }, get AtomInput() { return AtomInput }, get AtomSelect() { return AtomSelect }, get ProfileMobileIcon() { return ProfileMobileIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})