import { renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withKeys as _withKeys, resolveComponent as _resolveComponent, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "responsive"
}
const _hoisted_2 = {
  key: 1,
  class: "responsive"
}
const _hoisted_3 = { key: 1 }
const _hoisted_4 = {
  key: 0,
  class: "text-center"
}
const _hoisted_5 = { class: "row-table-style" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 3 }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "text-center" }
const _hoisted_12 = {
  key: 0,
  class: "d-flex justify-space-between align-center border-t responsive"
}
const _hoisted_13 = { class: "d-flex align-center ml-3 responsive" }
const _hoisted_14 = {
  key: 0,
  class: "responsive"
}
const _hoisted_15 = {
  key: 0,
  class: "responsive"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_VSelect = _resolveComponent("VSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$props.searchFalse)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          ($setup.props.filters)
            ? (_openBlock(), _createBlock($setup["MoleculeFilters"], {
                key: 0,
                modelValue: $setup.search,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.search) = $event)),
                "has-search": $setup.props.searchFilterNone,
                onHandleUpload: $setup.handleUpload,
                onHandleBlur: $setup.handleBlur,
                "has-options": $setup.props.hasOptions
              }, {
                areaFilter: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "areaFilter", {}, undefined, true),
                  _createVNode($setup["AtomButton"], {
                    background: "gray-05",
                    onClick: _withModifiers($setup.exportToExcel, ["prevent"]),
                    class: "ms-2 my-2 py-3"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["exportIcon"]),
                      _cache[7] || (_cache[7] = _createElementVNode("span", { style: {"color":"var(--color-subTitle)"} }, "Exportar", -1 /* HOISTED */))
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 3 /* FORWARDED */
              }, 8 /* PROPS */, ["modelValue", "has-search", "has-options"]))
            : _createCommentVNode("v-if", true)
        ]))
      : _createCommentVNode("v-if", true),
    ($props.searchFalse)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "areaFilter", {}, undefined, true),
          _createVNode($setup["AtomButton"], {
            background: "gray-05",
            onClick: _withModifiers($setup.exportToExcel, ["prevent"]),
            class: "ms-2 my-2 py-3"
          }, {
            default: _withCtx(() => [
              _createVNode($setup["exportIcon"]),
              _cache[8] || (_cache[8] = _createTextVNode("Exportar "))
            ]),
            _: 1 /* STABLE */
          })
        ]))
      : _createCommentVNode("v-if", true),
    (!$setup.props.searchMobile)
      ? (_openBlock(), _createBlock(_component_v_container, {
          key: 2,
          class: "mobile-search"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                (!$props.searchFalse)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      cols: "10"
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["AtomInput"], {
                          prependIcon: "",
                          placeholder: "Pesquisar",
                          onKeyup: _withKeys($setup.handleBlur, ["enter"]),
                          modelValue: $setup.search,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.search) = $event)),
                          width: "100%",
                          onHandleBlur: $setup.handleBlur
                        }, {
                          prependIcon: _withCtx(() => [
                            _createVNode($setup["SearchIcon"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_v_col, { cols: "2" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      background: "gray-05",
                      onClick: _withModifiers($setup.exportToExcel, ["prevent"]),
                      class: "ms-2 my-2 py-3"
                    }, {
                      default: _withCtx(() => [
                        _createVNode($setup["exportIcon"]),
                        _cache[9] || (_cache[9] = _createTextVNode("Exportar "))
                      ]),
                      _: 1 /* STABLE */
                    })
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    ($setup.state.isMobile)
                      ? _renderSlot(_ctx.$slots, "areaFilterMobile", { key: 0 }, undefined, true)
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 3 /* FORWARDED */
                })
              ]),
              _: 3 /* FORWARDED */
            })
          ]),
          _: 3 /* FORWARDED */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode(_component_v_container, {
      fluid: true,
      class: "responsive-mobile"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "bg-header" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.headerMobile, (item) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                cols: "6",
                key: item.label
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.label), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1024 /* DYNAMIC_SLOTS */))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_row, { class: "mt-0" }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              ref: "containerItems",
              class: "rows-space pt-5 w-100 px-2",
              style: {"border-left":"solid 1px var(--stroke-)","border-right":"solid 1px var(--stroke-)","margin-left":"1px","margin-top":"10px"},
              onScroll: $setup.checkLastItemVisibility
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.computedItems, (item, idx) => {
                return (_openBlock(), _createBlock(_component_v_row, {
                  id: `rowitem-${idx}`,
                  key: item,
                  onClick: 
            () => {
              if (!$setup.props.noclickrow) {
                ($setup.transfer = item), ($setup.up = !$setup.up);
              }
            }
          ,
                  class: "color-row"
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.headerMobile, (head) => {
                      return (_openBlock(), _createBlock(_component_v_col, {
                        cols: "6",
                        class: "",
                        key: head.label
                      }, {
                        default: _withCtx(() => [
                          (head.type === 'badge')
                            ? (_openBlock(), _createBlock($setup["AtomBadge"], {
                                key: 0,
                                text: item[head.field],
                                color: head.badgeBgColorFn ? head.badgeBgColorFn(item[head.field]) : item.color,
                                "bg-color": head.badgeColorFn ? head.badgeColorFn(item[head.field]) : item.bgColor
                              }, null, 8 /* PROPS */, ["text", "color", "bg-color"]))
                            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(head.formatFn
                ? head.formatFn(item[head.field]).length > 15 && !head.formatFn(item[head.field]).includes(' ')
                  ? `${head.formatFn(item[head.field]).substring(0, 15)}...`
                  : head.formatFn(item[head.field])
                : typeof item[head.field] == 'string' && item[head.field].length > 15 && !item[head.field].includes(' ')
                  ? `${item[head.field].substring(0, 15)}...`
                  : item[head.field]), 1 /* TEXT */))
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1024 /* DYNAMIC_SLOTS */))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["id", "onClick"]))
              }), 128 /* KEYED_FRAGMENT */)),
              ($setup.computedItems.length === 0)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString($props.textEmpty), 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ], 544 /* NEED_HYDRATION, NEED_PATCH */)
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode(_component_v_row, {
          class: _normalizeClass(["bg-footer position-relative", { 'fade-item': $setup.hasLastItem }])
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "2" }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: _normalizeClass($setup.btnDisabled ? 'elipse-disabled text-center' : 'elipse text-center'),
                  onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.prevColum()))
                }, [
                  _createElementVNode("span", null, [
                    _createVNode($setup["IconChevronLeft"], { class: "mt-2" })
                  ])
                ], 2 /* CLASS */)
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, {
              cols: "8",
              class: "text-center d-flex align-center justify-center"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.totalColumns, (index) => {
                  return (_openBlock(), _createElementBlock("span", {
                    class: _normalizeClass(`me-2 pagination-circle ${$setup.currentPageHeader == index ? 'active-item' : ''}`),
                    key: index
                  }, null, 2 /* CLASS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { cols: "2" }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "elipse text-center",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.proxColum()))
                }, [
                  _createElementVNode("span", null, [
                    _createVNode($setup["IconChevronRight"], { class: "mt-2" })
                  ])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["class"])
      ]),
      _: 1 /* STABLE */
    }),
    _createElementVNode("div", {
      class: _normalizeClass(!$setup.state.isMobile ? 'border-box' : '')
    }, [
      _createVNode($setup["VueGoodTable"], {
        class: "responsive",
        isLoading: $setup.props.loading,
        ref: "table",
        onSortChange: $setup.onSortChange,
        "select-options": {
        enabled: $props.enableOptions,
        selectOnCheckboxOnly: $props.selectOnCheckboxOnly,
        disableSelectInfo: $props.disableSelectInfo,
      },
        onOnSelectedRowsChange: _ctx.selectionChanged,
        styleClass: "vgt-table",
        "row-style-class": $setup.rowStyleClassFn,
        onRowClick: $setup.onRowClick,
        columns: $props.headers,
        rows: $setup.computedItems,
        "max-height": $props.scroolyn ? '500px' : '100vh',
        "fixed-header": true
      }, _createSlots({
        loadingContent: _withCtx(() => [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.LoaderIcon), { class: "ma-auto w-100" }))
        ]),
        "table-row": _withCtx((props) => [
          _createElementVNode("div", _hoisted_5, [
            (props.column.type == 'badge')
              ? (_openBlock(), _createElementBlock("span", _hoisted_6, [
                  ($props.update && !!props.column.mode && $setup.validLine(props.row))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        (props.column.mode == 'money')
                          ? (_openBlock(), _createBlock($setup["AtomInputMoney"], {
                              key: 0,
                              modelValue: $setup.updateValues[props.column.update],
                              "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event)
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                          : _createCommentVNode("v-if", true),
                        (props.column.mode == 'date')
                          ? (_openBlock(), _createBlock($setup["AtomInput"], {
                              key: 1,
                              type: "date",
                              modelValue: $setup.updateValues[props.column.update],
                              "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event)
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                          : _createCommentVNode("v-if", true),
                        (props.column.mode == 'input')
                          ? (_openBlock(), _createBlock($setup["AtomInput"], {
                              key: 2,
                              modelValue: $setup.updateValues[props.column.update],
                              "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event)
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"]))
                          : _createCommentVNode("v-if", true),
                        (props.column.mode == 'select')
                          ? (_openBlock(), _createBlock($setup["AtomSelect"], {
                              key: 3,
                              width: "154px",
                              modelValue: $setup.updateValues[props.column.update],
                              "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event),
                              options: props.column.options
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options"]))
                          : _createCommentVNode("v-if", true)
                      ]))
                    : (_openBlock(), _createBlock($setup["AtomBadge"], {
                        key: 1,
                        text: props.formattedRow[props.column.field],
                        color: 
                props.column.badgeBgColorFn
                  ? props.column.badgeBgColorFn(props.formattedRow[props.column.field])
                  : props.row.color
              ,
                        "bg-color": 
                props.column.badgeColorFn
                  ? props.column.badgeColorFn(props.formattedRow[props.column.field])
                  : props.row.bgColor
              
                      }, null, 8 /* PROPS */, ["text", "color", "bg-color"])),
                  _createCommentVNode(" ")
                ]))
              : (props.column.field == 'status')
                ? _renderSlot(_ctx.$slots, "status", _mergeProps({ key: 1 }, _ctx.$attrs, {
                    props: props.row
                  }), undefined, true)
                : (props.column.field == 'actions')
                  ? _renderSlot(_ctx.$slots, "actions", _mergeProps({ key: 2 }, _ctx.$attrs, { props: props }), undefined, true)
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, [
                      ($props.update && !!props.column.mode && $setup.validLine(props.row))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                            (props.column.mode == 'money')
                              ? (_openBlock(), _createBlock($setup["AtomInputMoney"], {
                                  key: 0,
                                  label: props.column.label,
                                  prependIcon: "",
                                  modelValue: $setup.updateValues[props.column.update],
                                  "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event)
                                }, {
                                  prependIcon: _withCtx(() => _cache[10] || (_cache[10] = [
                                    _createTextVNode(" R$ ")
                                  ])),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "modelValue", "onUpdate:modelValue"]))
                              : _createCommentVNode("v-if", true),
                            (props.column.mode == 'date')
                              ? (_openBlock(), _createBlock($setup["AtomInput"], {
                                  key: 1,
                                  type: "date",
                                  modelValue: $setup.updateValues[props.column.update],
                                  "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event),
                                  label: props.column.label
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label"]))
                              : _createCommentVNode("v-if", true),
                            (props.column.mode == 'input')
                              ? (_openBlock(), _createBlock($setup["AtomInput"], {
                                  key: 2,
                                  modelValue: $setup.updateValues[props.column.update],
                                  "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event),
                                  label: props.column.label
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "label"]))
                              : _createCommentVNode("v-if", true),
                            (props.column.mode == 'select')
                              ? (_openBlock(), _createBlock($setup["AtomSelect"], {
                                  key: 3,
                                  width: "154px",
                                  label: props.column.label,
                                  modelValue: $setup.updateValues[props.column.update],
                                  "onUpdate:modelValue": ($event: any) => (($setup.updateValues[props.column.update]) = $event),
                                  options: props.column.options
                                }, null, 8 /* PROPS */, ["label", "modelValue", "onUpdate:modelValue", "options"]))
                              : _createCommentVNode("v-if", true)
                          ]))
                        : (_openBlock(), _createElementBlock("span", _hoisted_10, _toDisplayString(props.formattedRow[props.column.field]), 1 /* TEXT */))
                    ])),
            (props.column.extra)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 4,
                  class: _normalizeClass(props.column.classExtra)
                }, _toDisplayString(props.row[props.column.fieldExtra]), 3 /* TEXT, CLASS */))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _: 2 /* DYNAMIC */
      }, [
        (!!$props.textEmpty)
          ? {
              name: "emptystate",
              fn: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, _toDisplayString($props.textEmpty), 1 /* TEXT */)
              ]),
              key: "0"
            }
          : undefined
      ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["isLoading", "select-options", "onOnSelectedRowsChange", "columns", "rows", "max-height"]),
      ($setup.props.pagination)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _cache[11] || (_cache[11] = _createElementVNode("p", { class: "responsive" }, "Mostrando", -1 /* HOISTED */)),
              _createVNode(_component_VSelect, {
                "bg-color": "primary",
                modelValue: $setup.perPageTable,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.perPageTable) = $event)),
                class: "mt-5 mx-4 responsive",
                items: [10, 15, 20, 60, 100, 200],
                variant: "solo",
                density: "compact"
              }, null, 8 /* PROPS */, ["modelValue"]),
              _createCommentVNode("  {{ computedItems.length > 0 ? 1 + perPageTable * (page - 1) : 0 }} a {{ computedItems.length + (page - 1) * perPageTable }} "),
              ($setup.props.pagination)
                ? (_openBlock(), _createElementBlock("p", _hoisted_14, " de " + _toDisplayString($setup.props.totalItems) + " itens ", 1 /* TEXT */))
                : _createCommentVNode("v-if", true)
            ]),
            ($setup.props.totalItems != 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createVNode($setup["MoleculePagination"], {
                    class: "pagination-component responsive",
                    modelValue: $setup.pageTable,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.pageTable) = $event)),
                    numberOfPages: $setup.lengthItems
                  }, null, 8 /* PROPS */, ["modelValue", "numberOfPages"])
                ]))
              : _createCommentVNode("v-if", true)
          ]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    _createVNode($setup["MoleculeSheetBottom"], {
      collapseClick: "",
      sheet: $setup.up,
      "onUpdate:sheet": _cache[6] || (_cache[6] = ($event: any) => ($setup.up = $event))
    }, {
      content: _withCtx(() => [
        _renderSlot(_ctx.$slots, "content-action", { props: $setup.transfer }, undefined, true)
      ]),
      _: 3 /* FORWARDED */
    }, 8 /* PROPS */, ["sheet"])
  ], 64 /* STABLE_FRAGMENT */))
}