import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted } from 'vue';
import { AtomTooltip, AlertIcon } from '@/components/atoms';
import { ITab } from '@/types';

interface IProps {
  modelValue: string;
  color?: string;
  textTooltip?: boolean;
  tabs: ITab[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeTabs',
  props: {
    modelValue: { type: String, required: true, default: '' },
    color: { type: String, required: false },
    textTooltip: { type: Boolean, required: false },
    tabs: { type: Array, required: true }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const emit = __emit;

const props = __props;
const tab = ref(props.tabs[0].label);

function updateTab() {
  emit('update:modelValue', tab.value);
}

watch(
  () => tab.value,
  () => {
    updateTab();
  },
);
watch(
  () => props.modelValue,
  () => {
    tab.value = props.modelValue;
  },
);
onMounted(() => {
  if (props.modelValue) tab.value = props.modelValue;
});

const __returned__ = { emit, props, tab, updateTab, get AtomTooltip() { return AtomTooltip }, get AlertIcon() { return AlertIcon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})