import axios from 'axios'
import { Authentication } from '../../store'
import Swal from 'sweetalert2'
import router from "@/router"


const httpAnalitcs = axios.create({
  baseURL: process.env.VUE_APP_API_URL_ANALITICS,
  headers: {
    'Content-Type': 'application/json',
  },
})
const httpAnalitcsVelocimeter = axios.create({
  baseURL: process.env.VUE_APP_API_URL_ANALITICSVELO,
  headers: {
    'Content-Type': 'application/json',
  },
})


httpAnalitcs.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return

  if (Authentication.state.token) {
    config.headers.Authorization = `Bearer ${Authentication.state.token}`
  }

  return config
})

httpAnalitcs.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error?.response?.status === 403) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    } else if (error?.response?.status === 400) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: error.response.data
      });
    } else if (error?.response?.status === 500) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }
    // const { userState, refreshAccessToken, logout } = useAuth()

    // if (error.response.status === 400 && error.response.data.message === 'Invalid refresh token')
    //   logout(userState.value)

    // if (error.response.status === 401 && error.response.data.message === 'Unauthorized') {
    //   try {
    //     await refreshAccessToken(userState.value)
    //     console.log('Refreshing token')
    //   }
    //   catch (e) {
    //     await logout(userState.value)
    //     console.error(e)
    //   }
    // }
    return Promise.reject(error)
  },
)
const httpRelatory = axios.create({
  baseURL: process.env.VUE_APP_API_URL_RELATORY,
  headers: {
    'Content-Type': 'application/json',
  },
})


httpRelatory.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return

  if (Authentication.state.token) {
    config.headers.Authorization = `Bearer ${Authentication.state.token}`
  }

  return config
})

httpRelatory.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 403) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    } else if (error.response.status === 400) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: error.response.data
      });
    } else if (error.response.status === 500) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }

    return Promise.reject(error)
  },
)



const httpPredict = axios.create({
  baseURL: process.env.VUE_APP_API_URL_PREDICT,
  headers: {
    'Content-Type': 'application/json',
  },
})

httpPredict.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return

  if (Authentication.state.token) {
    config.headers.Authorization = `${Authentication.state.token}`
  }

  return config
})

httpPredict.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 403) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    } else if (error.response.status === 400) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: error.response.data
      });
    } else if (error.response.status === 500) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }
    // const { userState, refreshAccessToken, logout } = useAuth()

    // if (error.response.status === 400 && error.response.data.message === 'Invalid refresh token')
    //   logout(userState.value)

    // if (error.response.status === 401 && error.response.data.message === 'Unauthorized') {
    //   try {
    //     await refreshAccessToken(userState.value)
    //     console.log('Refreshing token')
    //   }
    //   catch (e) {
    //     await logout(userState.value)
    //     console.error(e)
    //   }
    // }
    return Promise.reject(error)
  },
)

const httpGcn = axios.create({
  baseURL: process.env.VUE_APP_API_URL_GCN,
  headers: {
    'Content-Type': 'application/json',
  },
})

httpGcn.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return

  if (config.url != 'usuario/listar') {
    if (Authentication.state.token) {
      config.headers.Authorization = `Bearer ${Authentication.state.token}`
    }
  }



  return config
})

httpGcn.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 403) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    } else if (error.response.status === 400) {
      if (error.request.url != 'notificacao/inscrever') {
        Swal.fire({
          icon: "warning",
          title: "Atenção",
          text: error.response.data
        });
      } else {
        console.error(error)
      }
    } else if (error.response.status === 500) {
      if (error.request.url != 'notificacao/inscrever') {

        Swal.fire({
          icon: "error",
          title: "Atenção",
          text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
        });
      } else {
        console.error(error)
      }

    }
    // const { userState, refreshAccessToken, logout } = useAuth()

    // if (error.response.status === 400 && error.response.data.message === 'Invalid refresh token')
    //   logout(userState.value)

    // if (error.response.status === 401 && error.response.data.message === 'Unauthorized') {
    //   try {
    //     await refreshAccessToken(userState.value)
    //     console.log('Refreshing token')
    //   }
    //   catch (e) {
    //     await logout(userState.value)
    //     console.error(e)
    //   }
    // }
    return Promise.reject(error)
  },
)
const httpAuth = axios.create({
  baseURL: process.env.VUE_APP_API_URL_AUTH,
  headers: {
    'Content-Type': 'application/json',
  },
})

httpAuth.interceptors.request.use((config: any) => {
  if (config.headers === undefined)
    return

  if (config.url != 'usuario/listar') {
    if (Authentication.state.token) {
      config.headers.Authorization = `Bearer ${Authentication.state.token}`
    }
  }



  return config
})

httpAuth.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response.status === 403) {

      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: "Sua sessão foi expirada, favor se logar novamente"
      }).then(() => {
        Authentication.actions.logout();
        router.push("/");
      });
    } else if (error.response.status === 400) {
      Swal.fire({
        icon: "warning",
        title: "Atenção",
        text: error.response.data
      });
    } else if (error.response.status === 500) {
      Swal.fire({
        icon: "error",
        title: "Atenção",
        text: "Ocorreu um erro inesperado no sistema, por favor contacte o suporte"
      });
    }
    // const { userState, refreshAccessToken, logout } = useAuth()

    // if (error.response.status === 400 && error.response.data.message === 'Invalid refresh token')
    //   logout(userState.value)

    // if (error.response.status === 401 && error.response.data.message === 'Unauthorized') {
    //   try {
    //     await refreshAccessToken(userState.value)
    //     console.log('Refreshing token')
    //   }
    //   catch (e) {
    //     await logout(userState.value)
    //     console.error(e)
    //   }
    // }
    return Promise.reject(error)
  },
)
export { httpAnalitcs, httpAnalitcsVelocimeter, httpAuth, httpPredict, httpGcn, httpRelatory }