<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
    <g clip-path="url(#clip0_163_1125)">
      <path
        d="M51.9984 28C51.9984 41.2548 41.2536 51.9999 27.9992 51.9999C14.7448 51.9999 4 41.2548 4 28C4 14.7451 14.7448 4 27.9992 4C41.2536 4 51.9984 14.7451 51.9984 28Z"
        fill="white"
      />
      <path
        d="M28 4C14.7462 4 4 14.745 4 28.0007C4 41.2565 14.7462 51.9999 28 51.9999C41.2537 51.9999 51.9999 41.2549 51.9999 28.0007C51.9999 14.7465 41.2553 4 28 4ZM32.5626 41.0379C31.7298 41.4735 30.84 41.8307 29.901 42.0985C29.0128 42.351 28.12 42.5111 27.2334 42.5834C26.0173 42.685 24.8135 42.6235 23.6467 42.4126C19.1641 41.6059 15.2357 38.6164 13.35 34.2768C13.1068 33.7134 12.8959 33.13 12.725 32.5235C12.5819 32.0201 12.4679 31.5167 12.3833 31.0118C11.5782 26.2243 13.35 21.4783 16.8135 18.3687C17.694 17.5759 18.6869 16.8894 19.7737 16.3367C20.5649 15.9319 21.41 15.5993 22.2982 15.3469C23.2372 15.079 24.1824 14.9143 25.1183 14.8481C26.3405 14.7604 27.5489 14.8373 28.7188 15.0621L29.8087 18.8983C28.6326 18.4518 27.3688 18.2148 26.0773 18.2179C25.1352 18.2209 24.1793 18.3502 23.228 18.6212C22.3321 18.8767 21.4947 19.24 20.7281 19.6957C19.6259 20.3515 18.6715 21.1935 17.8926 22.1695C16.1562 24.3477 15.3065 27.1879 15.6852 30.0743C15.7498 30.5808 15.8545 31.0872 15.9977 31.5921C16.1716 32.2033 16.3948 32.7852 16.6642 33.3347C17.888 35.8439 20.0384 37.7066 22.5661 38.6118C23.7406 39.032 24.9967 39.2476 26.279 39.2245C27.1687 39.2091 28.0738 39.0782 28.9697 38.8227C29.9211 38.5533 30.8031 38.1607 31.6051 37.6681L29.3484 29.7295L24.0115 31.2473L23.0802 27.9715L31.6928 25.5238L35.512 38.9612C34.6345 39.7694 33.6478 40.4698 32.5626 41.0379ZM41.3461 30.5869C40.3471 31.8723 39.2264 33.0022 38.0088 33.9736L33.6263 18.7951C37.4669 19.0722 41.2676 20.4515 44.5372 22.99C44.6726 23.0962 44.8081 23.204 44.9389 23.3133C44.2693 25.8825 43.0763 28.3579 41.3461 30.5869Z"
        fill="#FF5C00"
      />
    </g>
    <defs>
      <clipPath id="clip0_163_1125">
        <rect width="56" height="56" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts" setup>

</script>
