import { defineComponent as _defineComponent } from 'vue'
import {
  AtomButton,
  AtomInput,
  DeleteIcon,
  EditSquareIcon,
} from '@/components/atoms';


import { MoleculeTable, MoleculeModal, MoleculeTitleSetings } from '@/components/molecules';
import { TipoEquipamento } from '@/core/models';
import { IHeadersTable } from '@/types';
import { ref, onMounted, watch } from 'vue';
import Swal from 'sweetalert2';
import { Preloader } from '@/store';
import {
  getTipoEquipamentoAll,
  postTipoEquipamento,
  putTipoEquipamento,
} from '@/core/services/TipoEquipamentoService';
interface IProps {
  activeTab: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'TypeOfEquipmentPage',
  props: {
    activeTab: { type: String, required: true }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

const props = __props;
const sortable = ref<any>();
const perPage = ref<number>(10);
const page = ref<number>(1);
const totalItems = ref<number>(0);
const term = ref<string>('');
const showModalUpload = ref<boolean>(false);
const data = ref<TipoEquipamento>({});
const headers = ref<IHeadersTable[]>([
  {
    label: 'Id',
    field: 'id',
  },
  { label: 'Nome', field: 'nome' },
  { label: 'Versão', field: 'versao' },
  { label: 'Opções', field: 'actions' },
]);
const items = ref<any[]>([]);
  const addNew = ref<boolean>(false)
    const type = ref<string>();
  function replacement(){
  if(addNew.value == false){
    addNew.value = true;
    type.value = 'C';
  }else{
    addNew.value = false;
    type.value = '';
  }
}

function handleEdit(payload: TipoEquipamento, isApp:boolean = false) {
 data.value = JSON.parse(JSON.stringify(payload));

 
  type.value = 'E';
  
  if(isApp){
    addNew.value = true;
  } else {
    showModalUpload.value = true;
  }
}
function clear() {
  data.value = {};
  data.value.id = 0;
}

function handleDelete(payload: TipoEquipamento) {
  Swal.fire({
    title: '<strong>Atenção</strong>',
    icon: 'question',
    html: `Deseja realmente arquivar o Tipo equipamento <b>${payload.nome}</b>? `,
    showCloseButton: true,
    showCancelButton: true,
    focusConfirm: false,
    confirmButtonText: 'Sim',
    cancelButtonText: 'Não',
  }).then((ale) => {
    if (ale.isConfirmed) {
      Preloader.actions.ActionSetPreloader(true);

      payload.archived = 1;
      putTipoEquipamento(payload)
        .then((resp) => {
          if (resp) {
            Swal.fire({
              title: 'Sucesso',
              text: `Tipo Equipamento ${payload.nome} foi arquivado com sucesso!`,
              icon: 'success',
            });

            loadData();
          }
        })
        .finally(() => {
          Preloader.actions.ActionSetPreloader(false);
        });
    }
  });
}
function searching(event: string | undefined) {
  page.value = 1;
  term.value = String(event);
  // loadData();
}

function handleAddOrUpdate() {
  Preloader.actions.ActionSetPreloader(true);
  if (data.value?.id == 0) {
    data.value.archived = 0;
    data.value.id = undefined;
    postTipoEquipamento(data.value)
      .then(() => {
        // Swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Tipo de equipamento ${data.value?.nome} foi criado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })
      .finally(() => {
        data.value = {}
        addNew.value = false
        Preloader.actions.ActionSetPreloader(false);
      });
  } else {
    putTipoEquipamento(data.value as TipoEquipamento)
      .then(() => {
        // swall
        Swal.fire({
          icon: 'success',
          title: 'Sucesso',
          text: `Tipo de Equipamento ${data.value?.nome} foi atualizado com sucesso!`,
        });
        loadData();
        showModalUpload.value = false;
      })
      .finally(() => {
        data.value = {}
        addNew.value = false
        Preloader.actions.ActionSetPreloader(false);
      });
  }
}

function sort(event) {
  let typ = {};
  if (event.type == 'none') {
    typ = undefined;
  } else {
    typ[event.field] = event.type;
  }
  sortable.value = typ;
  loadData();
}
function loadData() {
  Preloader.actions.ActionSetPreloader(true);
  totalItems.value = 0;
  let promisse;

  if (sortable.value) {
    promisse = getTipoEquipamentoAll(page.value - 1, perPage.value, term.value, sortable.value);
  } else {
    promisse = getTipoEquipamentoAll(page.value - 1, perPage.value, term.value);
  }
  promisse
    .then((resp) => {
      items.value = resp.lista;
      totalItems.value = resp.totalItems-  items.value.filter(f=>f.archived == 1).length;
    })
    .finally(() => {
      Preloader.actions.ActionSetPreloader(false);
    });
}

watch(
  () => props.activeTab,
  () => {
    if (props.activeTab === 'typeEquipaments') {
      page.value = 1;
      loadData();
    }
  }
);

onMounted(() => {
  loadData();
});

watch(
  () => page.value,
  () => {
    loadData();
  }
);

watch(
  () => term.value,
  () => {
    loadData();
  }
);

watch(
  () => perPage.value,
  () => {
    loadData();
  }
);

const __returned__ = { props, sortable, perPage, page, totalItems, term, showModalUpload, data, headers, items, addNew, type, replacement, handleEdit, clear, handleDelete, searching, handleAddOrUpdate, sort, loadData, get AtomButton() { return AtomButton }, get AtomInput() { return AtomInput }, get DeleteIcon() { return DeleteIcon }, get EditSquareIcon() { return EditSquareIcon }, get MoleculeTable() { return MoleculeTable }, get MoleculeModal() { return MoleculeModal }, get MoleculeTitleSetings() { return MoleculeTitleSetings } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})