import { defineComponent as _defineComponent } from 'vue'
import FormLogin from './components/FormLogin.vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'MeusDados',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { FormLogin }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})