import { defineComponent as _defineComponent } from 'vue'
import { ref, watch } from 'vue';
import {
  PaperPlusIcon,
  AtomSubTitle,
  DocumentUploadIcon,
  AtomProgressBar,
  TrashIcon,
  CheckColor,
} from '@/components/atoms';
import { state } from '@/store/modules/layout';
interface IProps {
  accept: string;
  text: string;
  preenchido: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AtomUpload',
  props: {
    accept: { type: String, required: true, default: '.pdf,.txt' },
    text: { type: String, required: true, default: 'PDF ou TXT' },
    preenchido: { type: Boolean, required: true, default: false }
  },
  emits: ['changeFile'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;
const files = ref<File>();

function changeInput(evt) {
  files.value = evt.target.files;
}

const progressBar = ref<number>(100)

function dragover(event) {
  event.preventDefault();

  event.currentTarget.classList.add('bg-gray-03');
}
function dragleave(event) {
  event.currentTarget.classList.remove('bg-gray-03');
}
function drop(event) {
  event.preventDefault();
  files.value = event.dataTransfer.files;
  event.currentTarget.classList.remove('bg-gray-03');
}
function clear(){
  files.value = undefined;
}

watch(
  () => files.value,
  () => {
    emit('changeFile', files.value);
  }
);

const __returned__ = { props, emit, files, changeInput, progressBar, dragover, dragleave, drop, clear, get PaperPlusIcon() { return PaperPlusIcon }, get AtomSubTitle() { return AtomSubTitle }, get DocumentUploadIcon() { return DocumentUploadIcon }, get AtomProgressBar() { return AtomProgressBar }, get TrashIcon() { return TrashIcon }, get CheckColor() { return CheckColor }, get state() { return state } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})