import { createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "56",
  height: "56",
  viewBox: "0 0 56 56",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]
const _hoisted_4 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <svg width=\"56\" height=\"56\" viewBox=\"0 0 56 56\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n    <path\n      d=\"M8.95607 4.9595C9.4462 4.86776 9.93762 5.11248 10.1515 5.55479C10.2047 5.63505 10.2473 5.72164 10.2782 5.81245C10.4141 7.90929 10.559 9.97059 10.6948 12.0319C10.6917 12.2462 10.7254 12.4594 10.7944 12.6627C10.9569 13.0627 11.3614 13.3165 11.7997 13.2936L18.4558 12.8671L18.5011 12.8848L18.6226 12.8936C18.8632 12.9244 19.0875 13.0342 19.2579 13.207C19.4566 13.4087 19.5655 13.6795 19.5608 13.9599C19.2984 17.782 16.4962 20.9755 12.6828 21.7982C8.86938 22.621 4.96017 20.8754 3.08778 17.5139C2.53722 16.5457 2.1893 15.4794 2.06445 14.3775C2.01603 14.051 1.99483 13.7212 2.00106 13.3913C2.01368 9.32706 4.90728 5.81907 8.95607 4.9595ZM12.9135 2.00082C17.4843 2.13765 21.3044 5.4558 21.9967 9.89063C22.0011 9.91711 22.0011 9.94411 21.9967 9.97059L21.9955 10.0958C21.9804 10.2613 21.9125 10.4191 21.7996 10.5458C21.6586 10.7043 21.459 10.801 21.2451 10.8147L13.5656 11.3211L13.4391 11.3236C13.2294 11.3134 13.0284 11.2319 12.8718 11.0909C12.6839 10.9218 12.5774 10.6828 12.5785 10.4326L12.0623 2.88932V2.76493C12.0717 2.55278 12.1667 2.353 12.3264 2.20966C12.4861 2.06632 12.6973 1.99119 12.9135 2.00082Z\"\n      :fill=\"props.color || '#ADB5BD'\"\n    />\n  </svg> "),
    (_openBlock(), _createElementBlock("svg", _hoisted_1, [
      _createElementVNode("rect", {
        width: "56",
        height: "56",
        rx: "8",
        fill: $setup.props.colorBackground || 'none',
        "fill-opacity": "0.1"
      }, null, 8 /* PROPS */, _hoisted_2),
      _createElementVNode("path", {
        d: "M37.6696 22.95C37.0296 20.78 35.2196 18.97 33.0496 18.33C31.3996 17.85 30.2596 17.89 29.4696 18.48C28.5196 19.19 28.4096 20.47 28.4096 21.38V23.87C28.4096 26.33 29.5296 27.58 31.7296 27.58H34.5996C35.4996 27.58 36.7896 27.47 37.4996 26.52C38.1096 25.74 38.1596 24.6 37.6696 22.95Z",
        fill: $setup.props.color || '#B2BBC7'
      }, null, 8 /* PROPS */, _hoisted_3),
      _createElementVNode("path", {
        d: "M34.9096 29.36C34.6496 29.06 34.2696 28.89 33.8796 28.89H30.2996C28.5396 28.89 27.1096 27.46 27.1096 25.7V22.12C27.1096 21.73 26.9396 21.35 26.6396 21.09C26.3496 20.83 25.9496 20.71 25.5696 20.76C23.2196 21.06 21.0596 22.35 19.6496 24.29C18.2296 26.24 17.7096 28.62 18.1596 31C18.8096 34.44 21.5596 37.19 25.0096 37.84C25.5596 37.95 26.1096 38 26.6596 38C28.4696 38 30.2196 37.44 31.7096 36.35C33.6496 34.94 34.9396 32.78 35.2396 30.43C35.2896 30.04 35.1696 29.65 34.9096 29.36Z",
        fill: $setup.props.color || '#B2BBC7'
      }, null, 8 /* PROPS */, _hoisted_4)
    ]))
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}