import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "56",
  height: "56",
  viewBox: "0 0 56 56",
  fill: "none"
}
const _hoisted_2 = ["fill"]
const _hoisted_3 = ["fill"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _createElementVNode("path", {
      d: "M21.0023 36C21.3576 36 21.7128 35.8691 21.9933 35.5887L28.5933 28.9886C29.1356 28.4464 29.1356 27.5489 28.5933 27.0067L21.9933 20.4067C21.4511 19.8644 20.5536 19.8644 20.0114 20.4067C19.4692 20.9489 19.4692 21.8463 20.0114 22.3885L25.6205 27.9977L20.0114 33.6068C19.4692 34.149 19.4692 35.0464 20.0114 35.5887C20.2732 35.8691 20.6284 36 21.0023 36Z",
      fill: $setup.props.color ?? '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_2),
    _createElementVNode("path", {
      d: "M30.3976 36C30.7528 36 31.1081 35.8691 31.3885 35.5887L37.9886 28.9886C38.5308 28.4464 38.5308 27.5489 37.9886 27.0067L31.3885 20.4067C30.8463 19.8644 29.9489 19.8644 29.4067 20.4067C28.8644 20.9489 28.8644 21.8463 29.4067 22.3885L35.0158 27.9977L29.4067 33.6068C28.8644 34.149 28.8644 35.0464 29.4067 35.5887C29.6684 35.8691 30.0237 36 30.3976 36Z",
      fill: $setup.props.color ?? '#FF5C00'
    }, null, 8 /* PROPS */, _hoisted_3)
  ]))
}