import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock($setup["MoleculeCardSimple"], {
    title: $setup.props.title,
    onClickIconTitle: _cache[0] || (_cache[0] = ($event: any) => ($setup.emit('clickIconTitle')))
  }, {
    iconTitle: _withCtx(() => [
      ($props.tooltip)
        ? (_openBlock(), _createBlock(_component_v_tooltip, {
            key: 0,
            activator: "parent",
            location: "top"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString($props.tooltip), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }))
        : _createCommentVNode("v-if", true),
      _createVNode($setup["ReorderIcon"], { color: "var(--color-title)" })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        (_openBlock(), _createBlock($setup["VueHighcharts"], {
          key: `${$setup.props.title}-chart`,
          options: $setup.chartOptions,
          redrawOnUpdate: true,
          oneToOneUpdate: true,
          animateOnUpdate: true
        }, null, 8 /* PROPS */, ["options"]))
      ])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["title"]))
}