import { renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_tooltip = _resolveComponent("v-tooltip")!

  return (_openBlock(), _createBlock(_component_v_tooltip, {
    text: $setup.props.text,
    activator: "parent",
    location: $setup.props.location
  }, {
    activator: _withCtx(({ props }) => [
      _createElementVNode("span", _normalizeProps(_guardReactiveProps(props)), [
        _renderSlot(_ctx.$slots, "default")
      ], 16 /* FULL_PROPS */)
    ]),
    _: 3 /* FORWARDED */
  }, 8 /* PROPS */, ["text", "location"]))
}