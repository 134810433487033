import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-center" }
const _hoisted_2 = { class: "flex-1" }
const _hoisted_3 = { class: "d-flex justify-space-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_progress_linear = _resolveComponent("v-progress-linear")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        ($setup.props.hasTitle)
          ? (_openBlock(), _createBlock($setup["AtomTitle"], {
              key: 0,
              color: "var(--color-subTitle)",
              "no-margin": "",
              title: $setup.props.title ?? '',
              classes: ['text-sm']
            }, null, 8 /* PROPS */, ["title"]))
          : _createCommentVNode("v-if", true),
        ($setup.props.hasTitle && !$setup.props.noValueTitle)
          ? (_openBlock(), _createBlock($setup["AtomSubTitle"], {
              key: 1,
              noMargin: "",
              text: $setup.props.hasPercentTitle ? `${$setup.formatLang($setup.progress)}%` : $setup.formatLang($setup.progress) ?? ''
            }, null, 8 /* PROPS */, ["text"]))
          : _createCommentVNode("v-if", true)
      ]),
      _createVNode(_component_v_progress_linear, {
        "bg-color": "stroke-40",
        color: $props.progressColor,
        class: _normalizeClass(["flex-1", {'my-2': $setup.props.margin1, 'my-4': !$setup.props.margin1}]),
        modelValue: $setup.progress,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.progress) = $event)),
        height: $props.height,
        max: $setup.props.max ?? 100,
        rounded: ""
      }, null, 8 /* PROPS */, ["color", "class", "modelValue", "height", "max"])
    ]),
    ($setup.props.hasPercent)
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          style: _normalizeStyle({ color: $setup.props.colorPercent || 'var(--color-title)' }),
          class: "ms-4 mt-4 text-xs font-500 font-poppins"
        }, _toDisplayString(Math.ceil($setup.progress)) + "% ", 5 /* TEXT, STYLE */))
      : _createCommentVNode("v-if", true)
  ]))
}