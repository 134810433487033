import { defineComponent as _defineComponent } from 'vue'
import { ref, watch, onMounted } from 'vue';
import {
  AtomInput,
  SearchIcon,
  AtomButton,
  DeleteClear,
} from '@/components/atoms';

interface IProps {
  modelValue?: any;
  title?: string;
  hasSearch?: boolean;
  hasOptions?: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeFilters',
  props: {
    modelValue: { type: null, required: false },
    title: { type: String, required: false },
    hasSearch: { type: Boolean, required: false },
    hasOptions: { type: Boolean, required: false, default: true }
  },
  emits: ["update:modelValue", "handleUpload", "handleCopy", "handleDownloadExcel", "handleDownloadPdf", "handlePrinter", "handleBlur"],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const search = ref<string>('');

const emit = __emit;
watch(
  () => search.value,
  () => {
    emit('update:modelValue', search.value);
  }
);
onMounted(() => {
  search.value = props.modelValue;
});
function handleBlur() {
  emit('handleBlur', search.value);
}

function clear() {
  search.value = '';
  emit('handleBlur', search.value);
}



const __returned__ = { props, search, emit, handleBlur, clear, get AtomInput() { return AtomInput }, get SearchIcon() { return SearchIcon }, get AtomButton() { return AtomButton }, get DeleteClear() { return DeleteClear } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})