import { defineComponent as _defineComponent } from 'vue'

import { MoleculeCardSimple } from '@/components/molecules';
import { AtomProgressBar } from '@/components/atoms';
import { DownloadIcon2 } from '@/components/atoms';

// Definindo as props para receber os dados dinamicamente

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsEconomy',
  props: {
  value: {
    type: Array,
    required: true,
    default: () => [],
  },
  title: {
    type: String,
    default: '',
  },
  tooltip: {
    type: String,
    default: '',
  },
},
  emits: ['clickIconTitle'],
  setup(__props, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;

const emit = __emit;

const __returned__ = { props, emit, get MoleculeCardSimple() { return MoleculeCardSimple }, get AtomProgressBar() { return AtomProgressBar }, get DownloadIcon2() { return DownloadIcon2 } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})