import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, withCtx as _withCtx, createSlots as _createSlots, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_2 = {
  key: 1,
  class: "text-gray-600 font-500 mb-1"
}
const _hoisted_3 = {
  key: 2,
  class: "text-gray-600 font-500 mb-1"
}
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "consumo-internet" }
const _hoisted_6 = { class: "d-flex align-center" }
const _hoisted_7 = { class: "num-destac badge ml-3 me-auto" }
const _hoisted_8 = { class: "conectividade-grafico" }
const _hoisted_9 = { class: "d-flex align-center" }
const _hoisted_10 = { class: "num-destac badge ml-3" }
const _hoisted_11 = { class: "perda-pacote" }
const _hoisted_12 = { class: "d-flex align-center" }
const _hoisted_13 = { class: "num-destac badge ml-3 me-auto" }
const _hoisted_14 = { class: "my-5 area-items gap-4" }
const _hoisted_15 = {
  key: 0,
  class: "geolocalizacao"
}
const _hoisted_16 = {
  key: 1,
  class: "d-flex flex-column gap-4"
}
const _hoisted_17 = {
  key: 0,
  class: "consumo-internet"
}
const _hoisted_18 = { class: "d-flex align-center" }
const _hoisted_19 = { class: "num-destac badge ml-3 me-auto" }
const _hoisted_20 = { class: "conectividade-grafico" }
const _hoisted_21 = { class: "d-flex align-center px-md-0 px-5" }
const _hoisted_22 = { class: "num-destac badge ml-3 me-auto" }
const _hoisted_23 = { key: 0 }
const _hoisted_24 = { class: "perda-pacote" }
const _hoisted_25 = { class: "d-flex align-center px-md-0 px-5" }
const _hoisted_26 = { class: "num-destac badge ml-3 me-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", null, [
    ($setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["HomeIcon2"], { color: "var(--primary-)" }),
          _cache[8] || (_cache[8] = _createElementVNode("p", { class: "ms-2 my-5 font-600 text-primary text-lg" }, "Observabilidade", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("p", _hoisted_2, "Status"))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["AtomToogleButton"], {
      width: "172px",
      buttons: $setup.buttonsTogglePredict ?? [],
      onHandleToggle: _cache[0] || (_cache[0] = 
        (e) => {
          $setup.handleToogleAtualPredict(e);
          $setup.geo();
        }
      ),
      class: _normalizeClass(`${!$setup.state.isMobile ? 'mb-5 me-5' : ''}`)
    }, null, 8 /* PROPS */, ["buttons", "class"]),
    ($setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, "Acesso"))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["AtomToogleButton"], {
      width: "172px",
      buttons: $setup.buttonsToggle ?? [],
      onHandleToggle: $setup.handleToogleBtnAdpt,
      class: _normalizeClass(`mb-5 ${$setup.state.isMobile ? '' : 'mt-10'}`)
    }, null, 8 /* PROPS */, ["buttons", "class"]),
    _createElementVNode("div", {
      class: _normalizeClass(
        $setup.state.isMobile
          ? 'area-items-diagnostics-mobile '
          : $setup.toogleTotaisPredict != 'predict'
            ? 'area-items-diagnostics my-3'
            : 'area-items-diagnostics-predict my-3'
      )
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.itemsDiagnostics, (item, index) => {
        return (_openBlock(), _createBlock($setup["MoleculeDiagnostic"], {
          loading: item.loading,
          "item-diagnostic": item,
          hasTooltip: true,
          key: String(index),
          class: "",
          badgeColor: $setup.activeColor,
          loadingColor: $setup.activeColor,
          "text-tooltip": $setup.toogleTotaisPredict == 'predict' ? item.tooltipsTextPrev : item.tooltipsText,
          onClickMore: ($event: any) => ($setup.handleClickCard(item))
        }, null, 8 /* PROPS */, ["loading", "item-diagnostic", "badgeColor", "loadingColor", "text-tooltip", "onClickMore"]))
      }), 128 /* KEYED_FRAGMENT */))
    ], 2 /* CLASS */),
    _createVNode($setup["OrganismsChart"], {
      loading: $setup.loaderChartGeral,
      title: `Gráficos ${$setup.toogleTotaisPredict == 'atual' ? 'atuais' : 'preditivos'}`,
      hasTabs: "",
      type: "line",
      legend: true,
      select: true,
      headerNoPaddingX: $setup.state.isMobile,
      loadingColor: $setup.activeColor,
      data: $setup.dataChartGeralOptimize,
      "has-dropdown-list": false,
      "has-toggle": $setup.toogleTotaisPredict != 'predict',
      "text-tooltip": 
        $setup.tabPrevisibilidade == 'Links'
          ? $setup.toggleMarcado == 'Qualidade'
            ? 'O gráfico mostra a evolução de sua conexão, se ela está em perfeito estado, ou com alguma falha, por exemplo, lentidão ou quedas. Além de também mostrar a previsão para as para as próximas horas.'
            : 'Aqui você consegue analisar a evolução de disponibilidade de sua conexão, e a previsão para as próximas horas.'
          : $setup.toggleMarcado == 'Qualidade'
            ? 'O gráfico mostra a evolução dos serviços, se eles estão em perfeito estado, ou com alguma falha, por exemplo, algum está com lentidão, ou até mesmo indisponível. Além de também mostrar a previsão para  as próximas horas.'
            : 'O gráfico mostra a evolução da qualidade média de seus serviços e a previsão para as próximas horas.'
      ,
      buttons: $setup.buttonsTogglePrevi,
      onHandleToggle: $setup.togglePrevisivilidade,
      "regex-mostrar-y": $setup.regexValidarY
    }, _createSlots({
      tabs: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(`${$setup.state.isMobile ? 'px-2' : ' me-auto'}`)
        }, [
          (!$setup.state.isMobile)
            ? (_openBlock(), _createBlock($setup["MoleculeTabs"], {
                key: 0,
                modelValue: $setup.tabPrevisibilidade,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.tabPrevisibilidade) = $event)),
                tabs: $setup.tabsPrevisibilidade,
                color: $setup.activeColor
              }, null, 8 /* PROPS */, ["modelValue", "tabs", "color"]))
            : _createCommentVNode("v-if", true),
          ($setup.state.isMobile)
            ? (_openBlock(), _createBlock($setup["AtomToogleButton"], {
                key: 1,
                width: "172px",
                buttons: $setup.buttonsTogglePredict ?? [],
                onHandleToggle: _cache[3] || (_cache[3] = 
              (e) => {
                $setup.handleToogleAtualPredict(e);
                $setup.geo();
              }
            ),
                class: _normalizeClass(`${!$setup.state.isMobile ? 'mb-5 me-5' : ''}`)
              }, null, 8 /* PROPS */, ["buttons", "class"]))
            : _createCommentVNode("v-if", true)
        ], 2 /* CLASS */),
        (!$setup.state.isMobile)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString($setup.getters.time.value), 1 /* TEXT */))
          : _createCommentVNode("v-if", true)
      ]),
      _: 2 /* DYNAMIC */
    }, [
      ($setup.toogleTotaisPredict == 'atual')
        ? {
            name: "superiorDireita",
            fn: _withCtx(() => [
              _createVNode($setup["AtomSelect"], {
                modelValue: $setup.linksOrServices,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.linksOrServices) = $event)),
                width: "100%",
                placeholder: $setup.tabPrevisibilidade,
                options: $setup.optionsLinksOrServices,
                multiple: ""
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "options"])
            ]),
            key: "0"
          }
        : undefined
    ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["loading", "title", "headerNoPaddingX", "loadingColor", "data", "has-toggle", "text-tooltip", "buttons"]),
    ($setup.toogleTotaisPredict == 'a')
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 3,
          class: "my-5"
        }, {
          default: _withCtx(() => [
            (!$setup.dataChartComputado.every((f) => !f.data.length))
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  cols: "6"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      _createVNode($setup["OrganismsChart"], {
                        loading: false,
                        title: "Consumo de internet",
                        type: "column",
                        legend: true,
                        data: $setup.dataChartComputado,
                        "color-series": "#FF5C00",
                        height: 166,
                        hasToggleHeader: true,
                        buttons: $setup.optionsListConsumo,
                        onOnChangeDropDownList: _cache[4] || (_cache[4] = ($event: any) => ($setup.optionConsumo = $event)),
                        text: "",
                        textTooltip: "É a soma do consumo de download (Arquivos baixados para o computador) e upload (arquivos transferidos do computador para outro meio de rede) de todos as conexões monitoradas."
                      }, {
                        topfreeArea: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            _createElementVNode("p", _hoisted_7, _toDisplayString($setup.optionConsumo) + " " + _toDisplayString($setup.optionConsumo == 'minutos'
                      ? Number($setup.consumoTotalizadores.mbpsPorMinuto).toFixed(2)
                      : $setup.optionConsumo == 'horas'
                        ? Number($setup.consumoTotalizadores.mbpsPorHora).toFixed(2)
                        : $setup.optionConsumo == 'dias'
                          ? Number($setup.consumoTotalizadores.mbpsPorDia).toFixed(2)
                          : 0) + " " + _toDisplayString($setup.optionConsumo == 'minutos'
                      ? 'Mbps/minuto'
                      : $setup.optionConsumo == 'horas'
                        ? 'Mbps/hora'
                        : $setup.optionConsumo == 'dias'
                          ? 'Mbps/dia'
                          : ''), 1 /* TEXT */),
                            _createElementVNode("p", null, [
                              _createElementVNode("strong", null, _toDisplayString($setup.getters.time.value), 1 /* TEXT */)
                            ])
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["data", "buttons"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode($setup["OrganismsChart"], {
                    loading: $setup.loadingScoreConectividade,
                    loadingColor: $setup.activeColor,
                    title: "Score de Conectividade",
                    time: true,
                    type: "line",
                    legend: false,
                    data: $setup.dataCharAreaScoreConectividade,
                    "color-series": "#FF5C00",
                    height: 166,
                    "has-dropdown-list": false,
                    buttons: $setup.optionsList,
                    "text-tooltip": "Com este gráfico você consegue acompanhar a evolução da funcionalidade de sua conexão em relação ao dado anterior."
                  }, {
                    topfreeArea: _withCtx(() => [
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode($setup["AtomSubTitle"], {
                          "no-margin": "",
                          text: "Pontos por hora"
                        }),
                        _createElementVNode("p", _hoisted_10, _toDisplayString($setup.pontosPorHoraScoreConectividade), 1 /* TEXT */),
                        _createElementVNode("p", null, [
                          _createElementVNode("strong", null, _toDisplayString($setup.getters.time.value), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading", "loadingColor", "data", "buttons"])
                ])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_col, { cols: "6" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode($setup["OrganismsChart"], {
                    loading: false,
                    title: "Perda de Pacotes",
                    type: "line",
                    legend: false,
                    data: $setup.dataChartPerdaComputado,
                    "color-series": "#FF5C0020",
                    height: 166,
                    hasToggleHeader: true,
                    buttons: $setup.optionsList,
                    onOnChangeDropDownList: _cache[5] || (_cache[5] = ($event: any) => ($setup.optionPerda = $event)),
                    "text-tooltip": `Essa consulta fornece um resumo do percentual de erro de pacotes em roteamentos de rede ao longo do tempo, agrupado por até 1 hora.`
                  }, {
                    topfreeArea: _withCtx(() => [
                      _createElementVNode("div", _hoisted_12, [
                        _createVNode($setup["AtomSubTitle"], {
                          "no-margin": "",
                          text: "Média de perda de pacotes"
                        }),
                        _createElementVNode("p", _hoisted_13, _toDisplayString($setup.optionPerda == 'minutos'
                      ? Number($setup.perdaTotalizadores?.avg_perc_pacote_min ?? 0).toFixed(2)
                      : Number($setup.perdaTotalizadores?.avg_perc_pacote_hora ?? 0).toFixed(2)), 1 /* TEXT */),
                        _createElementVNode("p", null, [
                          _createElementVNode("strong", null, _toDisplayString($setup.getters.time.value), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["data", "buttons"])
                ])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_14, [
      ($setup.toogleTotaisPredict == 'atual')
        ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
            _createVNode($setup["OrganismsMapCharts"], {
              title: "Geolocalização de unidades",
              data: $setup.dataMaps,
              name: "Unidades",
              content: true,
              loading: $setup.loadingGeo,
              "color-label": $setup.activeColorGeo,
              loadingColor: $setup.activeColor,
              height: 985,
              optionMaps: $setup.FiltersUnit.getters.filters.value.length > 0,
              textTooltip: "Geolocalização de Unidades: Além de visualizar a localização das unidade no mapa, clicando em um estado e selecionando a unidade você também filtra a tela inteira para a unidade escolhida."
            }, null, 8 /* PROPS */, ["data", "loading", "color-label", "loadingColor", "optionMaps"])
          ]))
        : _createCommentVNode("v-if", true),
      ($setup.toogleTotaisPredict == 'atual')
        ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
            (!$setup.dataChartComputado.every((f) => !f.data.length))
              ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                  _createVNode($setup["OrganismsChart"], {
                    loading: false,
                    title: "Consumo de internet",
                    type: "column",
                    legend: true,
                    data: $setup.dataChartComputado,
                    "color-series": "#FF5C00",
                    height: 166,
                    hasToggleHeader: true,
                    buttons: $setup.optionsListConsumo,
                    onOnChangeDropDownList: _cache[6] || (_cache[6] = ($event: any) => ($setup.optionConsumo = $event)),
                    text: "",
                    textTooltip: "É a soma do consumo de download (Arquivos baixados para o computador) e upload (arquivos transferidos do computador para outro meio de rede) de todos as conexões monitoradas."
                  }, {
                    topfreeArea: _withCtx(() => [
                      _createElementVNode("div", _hoisted_18, [
                        _createCommentVNode(" <AtomSubTitle\n                  no-margin\n                  :text=\"\"\n                /> "),
                        _createElementVNode("p", _hoisted_19, _toDisplayString($setup.optionConsumo == 'minutos'
                      ? Number($setup.consumoTotalizadores.mbpsPorMinuto).toFixed(2)
                      : $setup.optionConsumo == 'horas'
                        ? Number($setup.consumoTotalizadores.mbpsPorHora).toFixed(2)
                        : $setup.optionConsumo == 'dias'
                          ? Number($setup.consumoTotalizadores.mbpsPorDia).toFixed(2)
                          : 0) + " " + _toDisplayString($setup.optionConsumo == 'minutos'
                      ? 'Mbps/minuto'
                      : $setup.optionConsumo == 'horas'
                        ? 'Mbps/hora'
                        : $setup.optionConsumo == 'dias'
                          ? 'Mbps/dia'
                          : ''), 1 /* TEXT */),
                        _createElementVNode("p", null, [
                          _createElementVNode("strong", null, _toDisplayString($setup.getters.time.value), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["data", "buttons"])
                ]))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_20, [
              _createVNode($setup["OrganismsChart"], {
                loading: $setup.loadingScoreConectividade,
                loadingColor: $setup.activeColor,
                title: "Score de Conectividade",
                time: $setup.state.isMobile ? false : true,
                type: "line",
                legend: false,
                data: $setup.dataCharAreaScoreConectividade,
                "color-series": "#FF5C00",
                height: 166,
                "has-dropdown-list": false,
                buttons: $setup.optionsList,
                "text-tooltip": "Com este gráfico você consegue acompanhar a evolução da funcionalidade de sua conexão em relação ao dado anterior."
              }, {
                topfreeArea: _withCtx(() => [
                  _createElementVNode("div", _hoisted_21, [
                    _createVNode($setup["AtomSubTitle"], {
                      "no-margin": "",
                      text: "Pontos por hora"
                    }),
                    _createElementVNode("p", _hoisted_22, _toDisplayString($setup.pontosPorHoraScoreConectividade), 1 /* TEXT */),
                    _createElementVNode("p", null, [
                      ($setup.state.isMobile)
                        ? (_openBlock(), _createElementBlock("strong", _hoisted_23, _toDisplayString($setup.getters.time.value), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "loadingColor", "time", "data", "buttons"])
            ]),
            _createElementVNode("div", _hoisted_24, [
              _createVNode($setup["OrganismsChart"], {
                loading: false,
                title: "Perda de Pacotes",
                type: "line",
                legend: false,
                data: $setup.dataChartPerdaComputado,
                "color-series": "#FF5C0020",
                height: 166,
                hasToggleHeader: true,
                buttons: $setup.optionsListPacotes,
                onOnChangeDropDownList: _cache[7] || (_cache[7] = ($event: any) => ($setup.optionPerda = $event)),
                "text-tooltip": `Essa consulta fornece um resumo do percentual de erro de pacotes em roteamentos de rede ao longo do tempo, agrupado por até 1 hora.`
              }, {
                topfreeArea: _withCtx(() => [
                  _createElementVNode("div", _hoisted_25, [
                    _createVNode($setup["AtomSubTitle"], {
                      "no-margin": "",
                      text: "Média de perda de pacotes"
                    }),
                    _createElementVNode("p", _hoisted_26, _toDisplayString($setup.optionPerda == 'minutos'
                      ? Number($setup.perdaTotalizadores?.avg_perc_pacote_min ?? 0).toFixed(2)
                      : Number($setup.perdaTotalizadores?.avg_perc_pacote_hora ?? 0).toFixed(2)), 1 /* TEXT */),
                    _createElementVNode("p", null, [
                      _createElementVNode("strong", null, _toDisplayString($setup.getters.time.value), 1 /* TEXT */)
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["data", "buttons"])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ])
  ]))
}