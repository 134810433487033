import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between w-100" }
const _hoisted_2 = {
  class: "d-flex",
  style: {"gap":"10px"}
}
const _hoisted_3 = {
  key: 0,
  class: "d-flex",
  style: {"gap":"10px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Gestão financeira\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      prependIcon: "",
      "add-btn": "false",
      title: "Gestão Financeira"
    }, {
      prependIcon: _withCtx(() => [
        _createVNode($setup["DolarIcon"], { class: "" })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_v_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { cols: "12" }, {
          default: _withCtx(() => [
            _createVNode($setup["MoleculeTable"], {
              onChangePerPage: _cache[7] || (_cache[7] = (ev) => ($setup.perPage = ev)),
              onChangePage: _cache[8] || (_cache[8] = (ev) => ($setup.page = ev)),
              onHandleUpload: $setup.handleUpload,
              onRowClick: $setup.handleClickRow,
              onUpdateRow: $setup.updateRow,
              onSearching: $setup.searching,
              filters: true,
              headers: $setup.headers,
              items: $setup.paginationTableData,
              "index-select": $setup.selectedRow,
              perPage: $setup.perPage,
              page: $setup.page,
              totalItems: $setup.filteredItems.length,
              update: !!$setup.selectedRow,
              "item-select": $setup.selected,
              textEmpty: "Por favor, atualize para a listagem dos dados financeiros, caso não tenha sido gerado apenas selecionar o mês e clicar em gerar"
            }, {
              areaFilter: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode($setup["AtomMonthPicker"], {
                      width: "200px",
                      onClean: _cache[0] || (_cache[0] = ($event: any) => ($setup.clearFilterDate())),
                      modelValue: $setup.dateRange,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.dateRange) = $event)),
                      classes: 'ms-4'
                    }, null, 8 /* PROPS */, ["modelValue"]),
                    _createCommentVNode(" <AtomDatePicker\n                :dateRange=\"dateRange\"\n                @cleanRange=\"\n                  () => {\n                    dateRange.start = undefined;\n                    dateRange.end = undefined;\n                  }\n                \"\n                v-model=\"dateRange\"\n              /> "),
                    _createVNode($setup["AtomButton"], {
                      text: "Atualizar",
                      onClick: _cache[2] || (_cache[2] = ($event: any) => ($setup.filter()))
                    }),
                    _createVNode($setup["AtomButton"], {
                      background: "white",
                      outlined: "",
                      text: "Gerar dados",
                      onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => ($setup.gerarDados()), ["prevent"]))
                    })
                  ]),
                  (!!$setup.filters)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode($setup["AtomButton"], {
                          background: "white",
                          outlined: "",
                          text: "Salvar",
                          onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.save()))
                        }),
                        _createVNode($setup["AtomButton"], {
                          onClick: _cache[5] || (_cache[5] = ($event: any) => ($setup.goGastos())),
                          background: "white",
                          outlined: "",
                          text: "Listar gasto"
                        }),
                        _createVNode($setup["AtomButton"], {
                          background: "white",
                          outlined: "",
                          text: "Baixar NF",
                          onClick: _withModifiers($setup.handleDownload, ["prevent"])
                        }),
                        _createVNode($setup["AtomButton"], {
                          onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.handleUpload('notas'))),
                          background: "white",
                          outlined: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["PaperUploadIcon"])
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createCommentVNode(" <AtomButton @click=\"handleUpload('comprovantes')\" prepend-icon text=\"Comp.\" background=\"white\" outlined\n                v-if=\"!!filters\">\n                <template #prependIcon>\n                  <PaperUploadIcon />\n                </template>\n              </AtomButton> ")
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ]),
              areaFilterMobile: _withCtx(() => [
                _createVNode($setup["AtomInput"], {
                  placeholder: "Pesquisar",
                  width: "100%",
                  classes: "check-class py-6 mb-3",
                  type: "date"
                }),
                _createVNode($setup["AtomButton"], {
                  center: "text-center",
                  class: "mx-auto w-100 my-6 rounded-pill",
                  text: "Gerar Dados"
                })
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["headers", "items", "index-select", "perPage", "page", "totalItems", "update", "item-select"])
          ]),
          _: 1 /* STABLE */
        })
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode($setup["MoleculeModal"], {
      onHandleConfirm: _cache[9] || (_cache[9] = ($event: any) => ($setup.showModalUpload = false)),
      onHandleCancel: _cache[10] || (_cache[10] = ($event: any) => ($setup.showModalUpload = false)),
      title: $setup.titleModal,
      subtitle: $setup.subTitleModal,
      show: $setup.showModalUpload
    }, {
      body: _withCtx(() => [
        _createVNode($setup["MoleculeUploadInfo"], { onHandleUpload: $setup.handleUploadFinal })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["title", "subtitle", "show"]),
    _createCommentVNode(" <ModalGastos\n    @close-modal=\"showModalGastos = false\"\n    @load-data=\"loadData\"\n    @load-gastos-data=\"loadGastosData\"\n    :showModalGastos=\"showModalGastos\"\n    :handle-update-gastos-selected=\"handleUpdateGastosSelected\"\n    :handle-delete=\"handleDelete\"\n    :handle-edit=\"handleEdit\"\n    :handle-cancel=\"showModalGastos\"\n    :gastos=\"selected\"\n  /> ")
  ], 64 /* STABLE_FRAGMENT */))
}