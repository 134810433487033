import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass, withCtx as _withCtx, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex align-center"
}
const _hoisted_2 = { class: "my-5 area-items" }
const _hoisted_3 = { class: "d-flex justify-end pt-3 pb-2" }
const _hoisted_4 = {
  key: 0,
  class: "card-financeiras border rounded shadow"
}
const _hoisted_5 = {
  key: 0,
  class: "orientacoes"
}
const _hoisted_6 = { class: "card-financeiras border rounded shadow mt-4" }
const _hoisted_7 = { class: "d-flex justify-space-around" }
const _hoisted_8 = {
  key: 0,
  class: "provisionado text-center value"
}
const _hoisted_9 = { class: "value-val" }
const _hoisted_10 = {
  key: 1,
  class: "provisionado text-center value"
}
const _hoisted_11 = {
  key: 0,
  class: "desconto text-center value"
}
const _hoisted_12 = { class: "value-val" }
const _hoisted_13 = {
  key: 1,
  class: "provisionado text-center value"
}
const _hoisted_14 = {
  key: 0,
  class: "custo text-center value"
}
const _hoisted_15 = { class: "value-val" }
const _hoisted_16 = {
  key: 1,
  class: "provisionado text-center value"
}
const _hoisted_17 = { class: "card-financeiras border rounded shadow mt-4" }
const _hoisted_18 = { class: "d-flex justify-space-around" }
const _hoisted_19 = {
  key: 0,
  class: "previsao text-center value"
}
const _hoisted_20 = { class: "value-val" }
const _hoisted_21 = {
  key: 1,
  class: "text-center previsao value"
}
const _hoisted_22 = {
  key: 0,
  class: "text-center"
}
const _hoisted_23 = { class: "value-val" }
const _hoisted_24 = {
  key: 1,
  class: "text-center value"
}
const _hoisted_25 = {
  key: 0,
  class: "custo text-center"
}
const _hoisted_26 = { class: "value-val" }
const _hoisted_27 = {
  key: 1,
  class: "text-center value"
}
const _hoisted_28 = {
  key: 0,
  class: "card-financeiras border rounded shadow mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.state.isMobile)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["HomeIcon2"], { color: "var(--primary-)" }),
          _cache[3] || (_cache[3] = _createElementVNode("p", { class: "ms-2 my-5 font-600 text-primary text-lg" }, "Informações financeiras", -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_col, { md: "6" }, {
            default: _withCtx(() => [
              _createVNode($setup["MoleculeFinanceInfo"], {
                loading: $setup.loadingInfo,
                footer: "",
                title: "Informações financeiras",
                data: $setup.financeInfos
              }, {
                footer: _withCtx(() => [
                  _createElementVNode("div", {
                    class: _normalizeClass(`d-flex align-center ${$setup.state.isMobile ? 'justify-center px-4' : 'justify-end'} pt-3 pb-2`)
                  }, [
                    _createVNode($setup["AtomButton"], {
                      background: "#fff",
                      outlined: "",
                      onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.showDetails = true), ["prevent"])),
                      class: _normalizeClass(`${$setup.state.isMobile ? 'rounded-pill w-100 d-flex justify-center bg-gray-06 font-500' : ''}`)
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" Ver mais detalhes ")
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["class"])
                  ], 2 /* CLASS */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "data"])
            ]),
            _: 1 /* STABLE */
          }),
          false
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 0,
                md: "6"
              }, {
                default: _withCtx(() => [
                  _createVNode($setup["MoleculeFinanceInfo"], {
                    text: ' Aqui voce pode observar e conferir o seu custo provisionado para o mês e o desconto preditivo (previsto), levando em conta o SLA entregue versus o o que foi prometido pelo provedor de internet. Contendo também o Desconto Atual, Custo Atual e Disponibilidade do link de internet.',
                    loading: $setup.loadingInfo,
                    footer: "",
                    title: "Contas a pagar",
                    data: $setup.financeInfos
                  }, {
                    footer: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, [
                        _createCommentVNode(" <AtomSubTitle no-margin text=\"Mais detalhes\" class=\"text-center cursor-pointer\"\n                @click.prevent=\"showDetails = true\" />\n              <ArrowRightIcon /> "),
                        _createVNode($setup["AtomButton"], {
                          background: "#fff",
                          outlined: "",
                          onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => ($setup.showDetails = true), ["prevent"]))
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createTextVNode(" Ver mais detalhes ")
                          ])),
                          _: 1 /* STABLE */
                        })
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["loading", "data"])
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    ($setup.showDetails)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!$setup.state.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode($setup["AtomButton"], {
                  class: "pa-2 mb-4 mx-auto",
                  background: "transparent",
                  outlined: "",
                  onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => ($setup.showOrientacao = !$setup.showOrientacao), ["prevent"]))
                }, {
                  default: _withCtx(() => _cache[6] || (_cache[6] = [
                    _createTextVNode(" Clique para orientações")
                  ])),
                  _: 1 /* STABLE */
                }),
                ($setup.showOrientacao)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[7] || (_cache[7] = [
                      _createElementVNode("h4", null, "Orientações:", -1 /* HOISTED */),
                      _createElementVNode("ol", null, [
                        _createElementVNode("li", null, "Clique sobre um Link na tabela para visualizar suas informações específicas no 2º card"),
                        _createElementVNode("li", null, "Os valores listados abaixo são referentes ao mês corrente"),
                        _createElementVNode("li", null, "*—> Aguardando filtro de um Link especifico"),
                        _createElementVNode("li", null, "— -> Erro, valor indisponível ou não informado no cadastro ao recuperar o dado esperado"),
                        _createElementVNode("li", null, " Custo provisionado: Esse campo vai usar o valor atual cadastrado na gerencia financeira, caso este valor não esteja preenchido para o mês corrente, será utilizado o custo provisionado informado no momento do cadastro do Link no sistema. "),
                        _createElementVNode("li", null, "Marcação Laranja claro na tabela indica qual o Link está filtrado no momento."),
                        _createElementVNode("li", null, " Para limpar o filtro do Card, clique sobre o ícone limpeza de filtro no canto superior direito da tela. ")
                      ], -1 /* HOISTED */)
                    ])))
                  : _createCommentVNode("v-if", true)
              ]))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { md: "6" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", null, [
                        ($setup.totalizadoresFinanceiro.custoProvisionado)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_8, [
                              _cache[8] || (_cache[8] = _createTextVNode(" R$")),
                              _createElementVNode("span", _hoisted_9, _toDisplayString($setup.totalizadoresFinanceiro.custoProvisionado), 1 /* TEXT */)
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_10, "-")),
                        _cache[9] || (_cache[9] = _createElementVNode("p", { class: "text-center value" }, "Custo provisionado", -1 /* HOISTED */))
                      ]),
                      _createElementVNode("div", null, [
                        ($setup.totalizadoresFinanceiro.descontoEstimado)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_11, [
                              _cache[10] || (_cache[10] = _createTextVNode(" R$")),
                              _createElementVNode("span", _hoisted_12, _toDisplayString($setup.totalizadoresFinanceiro.descontoEstimado), 1 /* TEXT */)
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_13, "-")),
                        _cache[11] || (_cache[11] = _createElementVNode("p", { class: "text-center value" }, "Desconto atual", -1 /* HOISTED */))
                      ]),
                      _createElementVNode("div", null, [
                        ($setup.totalizadoresFinanceiro.custoAtual)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_14, [
                              _cache[12] || (_cache[12] = _createTextVNode(" R$")),
                              _createElementVNode("span", _hoisted_15, _toDisplayString($setup.totalizadoresFinanceiro.custoAtual), 1 /* TEXT */)
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_16, "-")),
                        _cache[13] || (_cache[13] = _createElementVNode("p", { class: "text-center value" }, "Custo atual", -1 /* HOISTED */))
                      ])
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { md: "6" }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("div", _hoisted_18, [
                      _createElementVNode("div", null, [
                        ($setup.dadoFinanceiro.custoAtual)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_19, [
                              _cache[14] || (_cache[14] = _createTextVNode(" R$")),
                              _createElementVNode("span", _hoisted_20, _toDisplayString($setup.dadoFinanceiro.custoAtual), 1 /* TEXT */)
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_21, "*")),
                        _cache[15] || (_cache[15] = _createElementVNode("p", { class: "text-center value" }, "Previsão de custo", -1 /* HOISTED */))
                      ]),
                      _createElementVNode("div", null, [
                        ($setup.dadoFinanceiro.sla)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_22, [
                              _createElementVNode("span", _hoisted_23, _toDisplayString($setup.dadoFinanceiro.sla) + "%", 1 /* TEXT */)
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_24, "*")),
                        _cache[16] || (_cache[16] = _createElementVNode("p", { class: "text-center value" }, "Acordo de serviço", -1 /* HOISTED */))
                      ]),
                      _createElementVNode("div", null, [
                        ($setup.dadoFinanceiro.disponibilidade)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_25, [
                              _createElementVNode("span", _hoisted_26, _toDisplayString($setup.dadoFinanceiro.disponibilidade), 1 /* TEXT */)
                            ]))
                          : (_openBlock(), _createElementBlock("p", _hoisted_27, "*")),
                        _cache[17] || (_cache[17] = _createElementVNode("p", { class: "text-center value" }, "Disponibilidade", -1 /* HOISTED */))
                      ])
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { md: "12" }, {
                default: _withCtx(() => [
                  (!$setup.state.isMobile)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createVNode($setup["MoleculeTable"], {
                          headers: $setup.headersDetails,
                          items: $setup.totalizadoresFinanceiro.dadoFinanceiroLinkDTOLista,
                          isFinanceira: "",
                          loading: $setup.loadingTable,
                          onRowClick: $setup.handleRowClick,
                          searchMobile: "",
                          "index-select": $setup.selectedRow
                        }, {
                          actions: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              outlined: "",
                              onClick: _withModifiers($setup.handleGerar, ["prevent","stop"])
                            }, {
                              default: _withCtx(() => _cache[18] || (_cache[18] = [
                                _createTextVNode(" Gerar ")
                              ])),
                              _: 1 /* STABLE */
                            })
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["headers", "items", "loading", "index-select"])
                      ]))
                    : (_openBlock(), _createBlock($setup["MoleculeTable"], {
                        key: 1,
                        headers: $setup.headersDetails,
                        items: $setup.totalizadoresFinanceiro.dadoFinanceiroLinkDTOLista,
                        isFinanceira: "",
                        loading: $setup.loadingTable,
                        onRowClick: $setup.handleRowClick,
                        searchMobile: "",
                        "index-select": $setup.selectedRow
                      }, {
                        actions: _withCtx(() => [
                          _createVNode($setup["AtomButton"], {
                            outlined: "",
                            onClick: _withModifiers($setup.handleGerar, ["prevent","stop"])
                          }, {
                            default: _withCtx(() => _cache[19] || (_cache[19] = [
                              _createTextVNode(" Gerar ")
                            ])),
                            _: 1 /* STABLE */
                          })
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["headers", "items", "loading", "index-select"]))
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ], 64 /* STABLE_FRAGMENT */))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}