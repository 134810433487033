import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "preloader"
}
const _hoisted_2 = {
  key: 2,
  class: "preloaderGo bg-appers"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.Preloader.getters.isLoading.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.LoaderIcon)))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile && $setup.Preloader.getters.isLoadingLogin.value)
      ? (_openBlock(), _createBlock($setup["LoginPartTopIcon"], {
          key: 1,
          class: "login-icon-right-animate"
        }))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile && $setup.Preloader.getters.isLoadingLogin.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode($setup["LogoGoOrangeIcon"], { class: "me-3 fade-in-element" }),
          _cache[0] || (_cache[0] = _createTextVNode()),
          _createVNode($setup["LogoGoIcon"], { class: "fade-in-element" }),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "loader dots" }, null, -1 /* HOISTED */))
        ]))
      : _createCommentVNode("v-if", true),
    ($setup.state.isMobile && $setup.Preloader.getters.isLoadingLogin.value)
      ? (_openBlock(), _createBlock($setup["LoginPartBottomIcon"], {
          key: 3,
          class: "login-icon-left-animate"
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}