import { createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "d-inline-flex align-center justify-space-between",
  style: {"gap":"5px"}
}
const _hoisted_2 = { class: "d-flex justify-end w-100" }
const _hoisted_3 = { class: "cursor-pointer position-relative d-inline-block label-img" }
const _hoisted_4 = { class: "edit-img" }
const _hoisted_5 = { class: "area-form" }
const _hoisted_6 = { class: "cursor-pointer position-relative d-inline-block label-img" }
const _hoisted_7 = { class: "edit-img" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_v_avatar = _resolveComponent("v-avatar")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createCommentVNode(" <AtomTitle title=\"Gerenciar usuários\" /> "),
    _createVNode($setup["MoleculeTitleSetings"], {
      prependIcon: "",
      addBtn: $setup.addNew,
      title: $setup.type == 'C' ? 'Cadastrar usuário' : $setup.type == 'E' ? 'Editar usuário' : 'Gerenciar usuários',
      onHandleConfirm: $setup.replacement
    }, {
      prependIcon: _withCtx(() => [
        _createVNode($setup["ProfileMobileIcon"], { class: "mt-2" })
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["addBtn", "title"]),
    (!$setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_row, { key: 0 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { cols: "12" }, {
              default: _withCtx(() => [
                _createVNode($setup["MoleculeTable"], {
                  onChangePerPage: _cache[1] || (_cache[1] = (ev) => ($setup.perPage = ev)),
                  onChangePage: _cache[2] || (_cache[2] = (ev) => ($setup.page = ev)),
                  onBlur: _cache[3] || (_cache[3] = ($event: any) => ($setup.term = $event)),
                  filters: "",
                  headers: $setup.headers,
                  items: $setup.itemsComputed,
                  totalItems: $setup.itemsComputed.length,
                  perPage: $setup.perPage,
                  page: $setup.page,
                  onItemClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.handleEdit($event, true))),
                  onItemClickDelete: _cache[5] || (_cache[5] = ($event: any) => ($setup.handleDelete($event)))
                }, {
                  actions: _withCtx(({ props }) => [
                    _createElementVNode("div", _hoisted_1, [
                      _createVNode($setup["AtomButton"], {
                        background: "white",
                        radius: "",
                        class: "pa-0",
                        onClick: _withModifiers(($event: any) => ($setup.handleEdit(props.row)), ["stop"])
                      }, {
                        default: _withCtx(() => [
                          _createVNode($setup["EditSquareIcon"])
                        ]),
                        _: 2 /* DYNAMIC */
                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]),
                      (props.row.archived == 0)
                        ? (_openBlock(), _createBlock($setup["AtomButton"], {
                            key: 0,
                            background: "white",
                            radius: "",
                            class: "pa-0",
                            onClick: _withModifiers(($event: any) => ($setup.handleDelete(props.row)), ["stop"])
                          }, {
                            default: _withCtx(() => [
                              _createVNode($setup["DeleteIcon"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]),
                  areaFilter: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      _createVNode($setup["AtomButton"], {
                        class: "ms-2",
                        text: "Novo usuário",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => {
                $setup.clear();
                _ctx.showModalUpload = true;
              })
                      }),
                      _createCommentVNode(" <div class=\"d-flex\" style=\"gap: 10px\">\n             <AtomButton background=\"white\" outlined>\n               <PaperUploadIcon />\n             </AtomButton>\n             <AtomButton :disabled=\"true\" text=\"Importar usuários\" background=\"white\" outlined> </AtomButton>\n             <AtomButton text=\"Exportar usuários\" background=\"white\" outlined> </AtomButton>\n             <AtomButton text=\"Excel exemplo\" background=\"white\" outlined> </AtomButton>\n             <AtomButton prependIcon appendIcon background=\"white\" outlined text=\"Opções\">\n               <template #prependIcon>\n                 <SettingIcon />\n               </template>\n      <template #appendIcon>\n                 <ArrowDown3Icon />\n               </template>\n      </AtomButton>\n      </div> ")
                    ])
                  ]),
                  "content-action": _withCtx(({ props }) => [
                    _createVNode(_component_v_row, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleEdit(props, true)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: 'Editar'
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */),
                        _createVNode(_component_v_col, {
                          cols: "12",
                          class: "text-center"
                        }, {
                          default: _withCtx(() => [
                            _createVNode($setup["AtomButton"], {
                              center: "text-center",
                              onClick: _withModifiers(($event: any) => ($setup.handleDelete(props)), ["stop"]),
                              class: "mx-auto w-100 rounded-pill",
                              text: "Excluir",
                              background: "gray-05"
                            }, null, 8 /* PROPS */, ["onClick"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1024 /* DYNAMIC_SLOTS */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["headers", "items", "totalItems", "perPage", "page"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    ($setup.addNew)
      ? (_openBlock(), _createBlock(_component_v_container, { key: 1 }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "my-3" }, {
                  default: _withCtx(() => _cache[15] || (_cache[15] = [
                    _createElementVNode("ul", null, [
                      _createElementVNode("li", { class: "text-gray-02" }, "Preencha todos os campos para validr o cadastro do novo usuario.")
                    ], -1 /* HOISTED */)
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("label", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createVNode(_component_v_icon, {
                          "aria-hidden": "false",
                          color: "white"
                        }, {
                          default: _withCtx(() => _cache[16] || (_cache[16] = [
                            _createTextVNode(" mdi-pencil ")
                          ])),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _createCommentVNode(" <v-img cover :src=\"fileBase64\">\n             <v-avatar color=\"#FFEFE6\" size=\"180\" rounded=\"5\">\n              </v-avatar>\n             </v-img> "),
                      _createVNode(_component_v_avatar, {
                        color: "#FFEFE6",
                        size: "180",
                        rounded: "5"
                      }, {
                        default: _withCtx(() => [
                          ($setup.type == 'C')
                            ? (_openBlock(), _createBlock($setup["RegisterIcon"], { key: 0 }))
                            : _createCommentVNode("v-if", true),
                          ($setup.type == 'E')
                            ? (_openBlock(), _createBlock(_component_v_img, {
                                key: 1,
                                cover: "",
                                src: $setup.fileBase64
                              }, null, 8 /* PROPS */, ["src"]))
                            : _createCommentVNode("v-if", true)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createElementVNode("input", {
                        ref: "inputfile",
                        type: "file",
                        class: "sr-only",
                        onChange: $setup.changeImg
                      }, null, 544 /* NEED_HYDRATION, NEED_PATCH */)
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      type: 'text',
                      label: "Nome",
                      width: "100%",
                      modelValue: $setup.dataUser.nome,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.dataUser.nome) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomInput"], {
                      label: "Email*",
                      width: "100%",
                      placeholder: "exemplo@email.com",
                      class: "mb-5",
                      modelValue: $setup.dataUser.email,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.dataUser.email) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, { cols: "12" }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomSelect"], {
                      width: "100%",
                      placeholder: "Selecione",
                      label: "Funções",
                      multiple: true,
                      options: $setup.optionsPapeis,
                      modelValue: $setup.dataUser.papelDTOLista,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.dataUser.papelDTOLista) = $event))
                    }, null, 8 /* PROPS */, ["options", "modelValue"])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_v_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[9] || (_cache[9] = ($event: any) => ($setup.handleSave())),
                      class: "mx-auto w-100 rounded-pill",
                      text: $setup.type == 'C' ? 'Concluir Cadastro' : 'Concluir Edição'
                    }, null, 8 /* PROPS */, ["text"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_v_col, {
                  cols: "12",
                  class: "text-center"
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["AtomButton"], {
                      center: "text-center",
                      onClick: _cache[10] || (_cache[10] = ($event: any) => (($setup.addNew = false), ($setup.type = ''), ($setup.dataUser = {}))),
                      class: "mx-auto w-100 rounded-pill",
                      text: "Voltar",
                      background: "gray-05"
                    })
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["MoleculeModal"], {
      width: "800px",
      onHandleCancel: _cache[14] || (_cache[14] = ($event: any) => {
      $setup.showModalUsers = false;
      $setup.clear();
    }),
      title: "Cadastrar Usuário",
      subtitle: "Preencha todos os campos para validar o cadastro do Novo usuário",
      el: "form",
      show: $setup.showModalUsers,
      btnCloseText: "Fechar",
      btnConfirmText: "salvar",
      onHandleConfirm: $setup.handleSave
    }, {
      body: _withCtx(() => [
        _createElementVNode("form", _hoisted_5, [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createElementVNode("label", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_v_icon, {
                        "aria-hidden": "false",
                        color: "white"
                      }, {
                        default: _withCtx(() => _cache[17] || (_cache[17] = [
                          _createTextVNode(" mdi-pencil ")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _createVNode(_component_v_avatar, {
                      color: "grey",
                      size: "150",
                      rounded: "0"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          cover: "",
                          src: $setup.fileBase64
                        }, null, 8 /* PROPS */, ["src"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createElementVNode("input", {
                      ref: "inputfile",
                      type: "file",
                      class: "sr-only",
                      onChange: $setup.changeImg
                    }, null, 544 /* NEED_HYDRATION, NEED_PATCH */)
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode($setup["AtomInput"], {
                    type: 'text',
                    label: "Nome",
                    modelValue: $setup.dataUser.nome,
                    "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => (($setup.dataUser.nome) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "6" }, {
                default: _withCtx(() => [
                  _createVNode($setup["AtomInput"], {
                    label: "Email*",
                    placeholder: "exemplo@email.com",
                    class: "mb-5",
                    modelValue: $setup.dataUser.email,
                    "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.dataUser.email) = $event))
                  }, null, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_v_col, { cols: "12" }, {
                default: _withCtx(() => [
                  _createVNode($setup["AtomSelect"], {
                    width: "100%",
                    placeholder: "Papeis",
                    multiple: true,
                    options: $setup.optionsPapeis,
                    modelValue: $setup.dataUser.papelDTOLista,
                    "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => (($setup.dataUser.papelDTOLista) = $event))
                  }, null, 8 /* PROPS */, ["options", "modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["show"])
  ], 64 /* STABLE_FRAGMENT */))
}