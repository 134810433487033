import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  width: "24",
  height: "22",
  viewBox: "0 0 24 22",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_2 = {
  id: "paint0_linear_872_20827",
  x1: "23.7377",
  y1: "0.81188",
  x2: "-18.1454",
  y2: "47.0863",
  gradientUnits: "userSpaceOnUse"
}
const _hoisted_3 = ["stop-color"]
const _hoisted_4 = {
  id: "paint1_linear_872_20827",
  x1: "23.7377",
  y1: "0.81188",
  x2: "-18.1454",
  y2: "47.0863",
  gradientUnits: "userSpaceOnUse"
}
const _hoisted_5 = ["stop-color"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("path", {
      d: "M22.2971 0.502708C22.6943 0.502026 23.0792 0.623817 23.385 0.846945C23.5572 0.972589 23.6995 1.1269 23.8038 1.30103C23.908 1.47517 23.9723 1.66571 23.9928 1.86174C24.0133 2.05777 23.9898 2.25544 23.9234 2.44343C23.857 2.63141 23.7492 2.80603 23.606 2.95726L15.9905 10.9795L23.334 19.0167C23.4753 19.1697 23.5807 19.3459 23.6443 19.535C23.708 19.7241 23.7285 19.9224 23.7048 20.1185C23.6811 20.3146 23.6136 20.5046 23.5063 20.6777C23.3989 20.8508 23.2537 21.0035 23.0791 21.127C22.9032 21.2632 22.6972 21.366 22.4741 21.4288C22.2509 21.4917 22.0155 21.5132 21.7824 21.4922C21.5494 21.4711 21.3238 21.4078 21.1198 21.3063C20.9159 21.2049 20.738 21.0674 20.5972 20.9025L12.3867 11.9224C12.1367 11.6546 12 11.3187 12 10.972C12 10.6253 12.1367 10.2894 12.3867 10.0216L20.8862 1.04152C21.0567 0.860392 21.2733 0.717213 21.5185 0.623596C21.7636 0.52998 22.0304 0.488564 22.2971 0.502708Z",
      fill: "url(#paint0_linear_872_20827)"
    }, null, -1 /* HOISTED */)),
    _cache[3] || (_cache[3] = _createElementVNode("path", {
      d: "M10.2971 0.502708C10.6943 0.502026 11.0792 0.623817 11.385 0.846945C11.5572 0.972589 11.6995 1.1269 11.8038 1.30103C11.908 1.47517 11.9723 1.66571 11.9928 1.86174C12.0133 2.05777 11.9898 2.25544 11.9234 2.44343C11.857 2.63141 11.7492 2.80603 11.606 2.95726L3.99049 10.9795L11.334 19.0167C11.4753 19.1697 11.5807 19.3459 11.6443 19.535C11.708 19.7241 11.7285 19.9224 11.7048 20.1185C11.6811 20.3146 11.6136 20.5046 11.5063 20.6777C11.3989 20.8508 11.2537 21.0035 11.0791 21.127C10.9032 21.2632 10.6972 21.366 10.4741 21.4288C10.2509 21.4917 10.0155 21.5132 9.78242 21.4922C9.54938 21.4711 9.3238 21.4078 9.11984 21.3063C8.91588 21.2049 8.73795 21.0674 8.59721 20.9025L0.386705 11.9224C0.136681 11.6546 0 11.3187 0 10.972C0 10.6253 0.136681 10.2894 0.386705 10.0216L8.8862 1.04152C9.05673 0.860392 9.27335 0.717213 9.51849 0.623596C9.76363 0.52998 10.0304 0.488564 10.2971 0.502708Z",
      fill: "url(#paint1_linear_872_20827)"
    }, null, -1 /* HOISTED */)),
    _createElementVNode("defs", null, [
      _createElementVNode("linearGradient", _hoisted_2, [
        _createElementVNode("stop", {
          "stop-color": $setup.props.color || '#FF5C00'
        }, null, 8 /* PROPS */, _hoisted_3),
        _cache[0] || (_cache[0] = _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#FFA573"
        }, null, -1 /* HOISTED */))
      ]),
      _createElementVNode("linearGradient", _hoisted_4, [
        _createElementVNode("stop", {
          "stop-color": $setup.props.color || '#FF5C00'
        }, null, 8 /* PROPS */, _hoisted_5),
        _cache[1] || (_cache[1] = _createElementVNode("stop", {
          offset: "1",
          "stop-color": "#FFA573"
        }, null, -1 /* HOISTED */))
      ])
    ])
  ]))
}