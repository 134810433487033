import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("p", null, [
    _createElementVNode("span", {
      class: "destac me-1",
      style: _normalizeStyle({ background: $props.bgColor })
    }, null, 4 /* STYLE */),
    _createElementVNode("span", null, _toDisplayString($setup.props.text), 1 /* TEXT */),
    _cache[0] || (_cache[0] = _createTextVNode()),
    _cache[1] || (_cache[1] = _createElementVNode("br", null, null, -1 /* HOISTED */))
  ]))
}