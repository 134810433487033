import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createVNode as _createVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-100"
}
const _hoisted_2 = { class: "d-flex flex-row-reverse" }
const _hoisted_3 = {
  key: 0,
  class: "my-6"
}
const _hoisted_4 = { class: "text-value" }
const _hoisted_5 = { class: "text-muted mb-5" }
const _hoisted_6 = {
  key: 2,
  id: "element-to-convert"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return ($props.noCard)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (!$setup.isEmpty && $setup.chartOptions)
          ? (_openBlock(), _createBlock($setup["VueHighcharts"], {
              key: 0,
              type: "chart",
              options: $setup.chartOptions,
              redrawOnUpdate: true,
              oneToOneUpdate: true,
              animateOnUpdate: true
            }, null, 8 /* PROPS */, ["options"]))
          : _createCommentVNode("v-if", true)
      ]))
    : (_openBlock(), _createBlock($setup["MoleculeCard"], {
        key: 1,
        height: $setup.props.heightCard,
        footer: $setup.props.footer,
        onOnChangeDropDownList: $setup.handleChangeDropdownList,
        "has-dropdown-list": $setup.props.hasDropdownList,
        hasToggle: $setup.props.hasToggleHeader,
        optionsToggleButton: $setup.props.buttons,
        headerNoPaddingXn: $props.headerNoPaddingX,
        label: $setup.props.label,
        "is-calendar-select": $setup.props.isCalendarSelect,
        textTooltip: $setup.props.textTooltip,
        title: $setup.props.title,
        hasTabs: $setup.props.hasTabs,
        loading: $setup.props.loading,
        loadingColor: $setup.props.loadingColor
      }, {
        tabs: _withCtx(() => [
          _renderSlot(_ctx.$slots, "tabs", {}, undefined, true)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_v_row, null, {
            default: _withCtx(() => [
              ($setup.props.hasToggle && !$setup.isEmpty)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 0,
                    cols: 12,
                    md: $setup.props.PdfBtn ? 6 : $setup.props.select ? 9 : 12,
                    class: _normalizeClass(`${$setup.state.isMobile ? 'px-2' : 'mb-5'}`)
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["AtomToogleButton"], {
                        buttons: $setup.props.buttons ?? [],
                        onHandleToggle: $setup.handleToogleChange
                      }, null, 8 /* PROPS */, ["buttons"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["md", "class"]))
                : _createCommentVNode("v-if", true),
              ($setup.props.select && !$setup.isEmpty)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 1,
                    cols: 12,
                    md: $setup.props.select ? 3 : 12,
                    class: _normalizeClass([`${$setup.state.isMobile ? 'px-2' : 'mb-5'}`, "text-end"])
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "superiorDireita", {}, undefined, true)
                    ]),
                    _: 3 /* FORWARDED */
                  }, 8 /* PROPS */, ["md", "class"]))
                : _createCommentVNode("v-if", true),
              ($setup.props.PdfBtn)
                ? (_openBlock(), _createBlock(_component_v_col, {
                    key: 2,
                    cols: $setup.props.hasToggle && !$setup.isEmpty ? 12 : 12,
                    md: $setup.props.hasToggle && !$setup.isEmpty ? 6 : 12,
                    class: _normalizeClass(`${$setup.state.isMobile ? 'px-2' : 'mb-5'}`)
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createVNode($setup["AtomButton"], {
                          prependIcon: "",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.exportToPDF())),
                          class: "ms-2 my-2 py-3",
                          background: "gray-05",
                          text: "Exportar"
                        }, {
                          prependIcon: _withCtx(() => [
                            _createVNode($setup["exportIcon"])
                          ]),
                          _: 1 /* STABLE */
                        })
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["cols", "md", "class"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 3 /* FORWARDED */
          }),
          (!$setup.isEmpty && $setup.chartOptions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "topfreeArea", {}, undefined, true)
              ]))
            : _createCommentVNode("v-if", true),
          ($props.showInfoTitle)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("p", _hoisted_4, _toDisplayString($props.valueTitle), 1 /* TEXT */),
                _createElementVNode("p", _hoisted_5, _toDisplayString($props.textValueTitle), 1 /* TEXT */)
              ], 64 /* STABLE_FRAGMENT */))
            : _createCommentVNode("v-if", true),
          (!$setup.isEmpty && $setup.chartOptions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createVNode($setup["VueHighcharts"], {
                  type: "chart",
                  options: $setup.chartOptions,
                  redrawOnUpdate: true,
                  oneToOneUpdate: true,
                  animateOnUpdate: true
                }, null, 8 /* PROPS */, ["options"])
              ]))
            : _createCommentVNode("v-if", true),
          ($setup.isEmpty && $setup.props.textEmpty)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: _normalizeClass($setup.state.isMobile ? 'px-2' : '')
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass($setup.state.isMobile ? 'quadrado px-3 text-center' : 'emptyText pa-3')
                }, _toDisplayString($setup.props.textEmpty), 3 /* TEXT, CLASS */)
              ], 2 /* CLASS */))
            : _createCommentVNode("v-if", true)
        ]),
        _: 3 /* FORWARDED */
      }, 8 /* PROPS */, ["height", "footer", "has-dropdown-list", "hasToggle", "optionsToggleButton", "headerNoPaddingXn", "label", "is-calendar-select", "textTooltip", "title", "hasTabs", "loading", "loadingColor"]))
}