import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { state, actions } from '../../store/modules/layout';
import {
  AtomTitle,
  AtomSubTitle,
  // CallingIcon,
  ChartIcon,
  GraphIcon,
  // HomeIcon,
  // LogoutIcon,
  ProfileIcon,
  DobbleLeftIcon,
  SettingIcon,
  // VersionIcon,
  RelatoryIcon,
  IconExpandir,
} from '../atoms';
// import Sidebar from '@/store/modules/sideBar';
import { Authentication } from '@/store';

// import FormLogin from '@/pages/meusdados/components/FormLogin.vue';
import router from '@/router';
import IconLogo from '../atoms/icons/IconLogo.vue';
import IconAnalisys from '../atoms/icons/IconAnalisys.vue';
// import AtomTooltip from '../atoms/AtomTooltip.vue';
// const { links } = Sidebar();
interface IProps {
  title?: string;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'OrganismsNavigation',
  props: {
    title: { type: String, required: false }
  },
  setup(__props: any, { expose: __expose }) {
  __expose();

_useCssVars(_ctx => ({
  "74d5f627-widthVListItem": (widthVListItem.value)
}))

const showModalProfile = ref<boolean>(false);

const version = ref<string>('');
const hover = ref<string>('');
const props = __props;
const route = useRoute();
const widthVListItem = computed(() => {
  return state.menuRecolhido ? '56px' : '100%';
})
watch(
  () => state.menuRecolhido,
  (newVal) => {
    document.body.style.setProperty(
      '--v-layout-left',
      newVal ? '56px' : '200px'
    );
  }
);



function hasRecurso(caminho: string): boolean {
  return !!Authentication.getters.getUser.value.recursoLista?.find((f) => f.caminho === `/${caminho}`);
}
function getFunction() {
  return Authentication.state.papelLista?.map((f) => f.nome).join(', ') ?? '';
}

function handleHover(e: string) {
  hover.value = e;
}

function handleRouterPush(name?: string) {
  router.push({
    name: name,
  });
}
function handleRouterPushSettings(name: string) {
  router.push({
    name: 'Configurações',
    query: {
      page: name,
    },
  });
}

function handleRouterPushAnalitics(name: string) {
  router.push({
    name: 'Agentes',
    query: {
      page: name,
    },
  });
}

onMounted(() => {
  fetch('version')
    .then((response) => response.text())
    .then((csvString) => {
      //Split the csv into rows
      console.log(csvString);
      version.value = csvString;
    });
});

const __returned__ = { showModalProfile, version, hover, props, route, widthVListItem, hasRecurso, getFunction, handleHover, handleRouterPush, handleRouterPushSettings, handleRouterPushAnalitics, get state() { return state }, get actions() { return actions }, get AtomTitle() { return AtomTitle }, get AtomSubTitle() { return AtomSubTitle }, get ChartIcon() { return ChartIcon }, get GraphIcon() { return GraphIcon }, get ProfileIcon() { return ProfileIcon }, get DobbleLeftIcon() { return DobbleLeftIcon }, get SettingIcon() { return SettingIcon }, get RelatoryIcon() { return RelatoryIcon }, get IconExpandir() { return IconExpandir }, get Authentication() { return Authentication }, IconLogo, IconAnalisys }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})