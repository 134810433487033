import { defineComponent as _defineComponent } from 'vue'

import { ArrowDown3Icon } from '@/components/atoms';
interface IProps {
  infos: string[];
}

export default /*@__PURE__*/_defineComponent({
  __name: 'MoleculeInfos',
  props: {
    infos: { type: Array, required: true }
  },
  emits: ['handleToggle'],
  setup(__props: any, { expose: __expose, emit: __emit }) {
  __expose();

const props = __props;
const emit = __emit;
function handleToggle() {
  emit('handleToggle')
}

const __returned__ = { props, emit, handleToggle, get ArrowDown3Icon() { return ArrowDown3Icon } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})